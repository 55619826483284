import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import Config from "../../config/Config";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

//Your react component
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1 ljkshdlkjsbd slkkkkdnlkmsf klshd.kjsdbms",
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      let datas = this.props.data.data;
      let catagory = this.props.data.catagory;
      this.setState({
        datas: datas,
        loadingData: false,
        data: this.props.data.data,
        catagory: catagory,
      });
    }
  }

  render() {
    let data = "",
      datas = "",
      catagory = "";

    data = this.state.data;
    datas = this.state.data;
    catagory = this.state.catagory;

    let width = 740,
      height = 220,
      legendItemFontSize=14;
    let headers = "";
    let footers =
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.footers) footers = this.props.footers;
    if(data.length>=8)
    legendItemFontSize=11;
    footers = "";
    let chartConfigs = {
      type: "stackedbar2d",
      width: width,
      height: height,
      dataFormat: "json",
      id: this.props.id ? this.props.id : "stacked",
      dataSource: {
        chart: {
          // caption: (headers+"").toUpperCase(),
          // captionFont:"Avenir Medium",
          // captionFontColor:"#002F75",
          // captionFontSize:16,
          // captionAlignment: "left",
          // captionOnTop: 1,
          // captionFontBold:"0",
          // captionHorizontalPadding:0,
          theme: "fusion",
          paletteColors: Config.COLORS,
          showValues: this.props.labels === true ? true : false,
          labelDisplay: "WRAP",
          divLineColor: "#ffffff",
          legendPosition: "absolute",
          legendNumColumns: 1,
          legendXPosition: 360,
          chartRightMargin: 200,
          legendYPosition: 48+this.props.legendsYPosition,
          legendItemFontColor:"#7f7f7f",
          labelFontColor:"#7f7f7f",
          plotSpacePercent: 40,
          animation: false,
          labelFontSize: 14,
          showYAxisValues: false,
          valueFontBold: false,
          chartTopMargin: 80+this.props.chartHeadingGap,
          legendItemFontSize: legendItemFontSize,
          placeValuesInside: "0",
          yAxisNamePadding: 0,
          chartBottomMargin: 0,
          formatNumberScale: 0,
          centerXaxisName: false,
          valueFont: "Avenir Heavy",
          valueFontSize: 12,
          valueFontBold: true,
          valueFontColor: "#ffffff",
          minPlotHeightForValue: 0,
          legendItemFont: "Avenir Medium",
          baseFont: "Avenir Medium",
          baseFontSize:14,
          baseFontColor:"#7f7f7f"
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {},
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers+"").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${570+this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                },
              ],
            },
            {
              id: "user-images",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",

                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: "9",
                  font: "Avenir semibold",
                  text: footers,
                  bold: "1",
                  wrap: "1",
                  wrapWidth: width - 20,
                  x: "15",
                  y: "$canvasEndY",
                },
              ],
            },
          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    return (
      <div className="bar" id="bar">
        <div>
          <ReactFC {...chartConfigs} />
        </div>
      </div>
    );
  }
}

function parseData(data) {
  let allKeys = Object.keys(data[0]).filter((val) => val !== "label");

  let dataset = [];

  for (let item_key of allKeys) {
    let dataname = {
      seriesname: item_key,
      data: [],
    };
    dataset.push(dataname);
  }
  for (let item of data) {
    for (let item_label of allKeys) {
      for (let item_key of dataset) {
        if (item_key.seriesname === item_label) {
          let value = {
            value: item[item_label],
          };
          item_key.data.push(value);
        }
      }
    }
  }
  return dataset;
}
function parseCatagory(data) {
  let alllabels = [];
  for (let item of data) {
    alllabels.push({
      label: item.label,
    });
  }
  return alllabels;
}

export default Chart;
