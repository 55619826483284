import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import {roundOfMaxYAxisValue} from '../commanFunction';
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

//Your react component
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxYaxisValue: 0,
      data: [
        {
          label: "Venezuela",
          value: "290",
        },
        {
          label: "Saudi",
          value: "260",
        },
        {
          label: "Canada",
          value: "180",
        },
        {
          label: "Iran",
          value: "140",
        },
        {
          label: "Russia",
          value: "115",
        },
        {
          label: "UAE",
          value: "100",
        },
        {
          label: "US",
          value: "30",
        },
        {
          label: "China",
          value: "30",
        },
      ],
      cagr_year:[2,3]
    };
    this.datavalidate = this.datavalidate.bind(this);
  }
  componentDidMount() {
    if (this.props.data && this.props.data) {
      this.setState({
        data: this.datavalidate(this.props.data),
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState &&
      JSON.stringify(prevState.data) !== JSON.stringify(this.props.data)
    ) {
      if (this.props.data && this.props.data) {
        this.setState({
          data: this.datavalidate(this.props.data),
        });
      }
    }
  }
  datavalidate(data) {
    let arr = [],cagr_year=[];
    if(this.props.data.cagr_year)
    cagr_year=this.props.data.cagr_year
    for (let [index,item] of data.entries()) {
      item.label = item.label + "";
      if(this.props.data&&this.props.cagr_year&&this.props.cagr_year.length>0){
        if(item.label==(this.props.cagr_year[0]+"")||item.label==(this.props.cagr_year[1]+"")){
          cagr_year.push(index);
        }
      }
      arr.push(item.value);
      if (item.label == "2020") {
        item["showValue"] = true;
      }
    }
    arr.sort(function (a, b) {
      if (a > b) return -1;
      else return 1;
    });
    // let divValue=arr[0]/5;
    
    this.setState({ maxYaxisValue: roundOfMaxYAxisValue(arr[0]),cagr_year });
    return data;
  }

  render() {
    let width = 400,
      height = 240;
    let headers = "";
    let footers =
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    cagr_year=[2,6];
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.footers) footers = this.props.footers;
    if(this.state.cagr_year.length>0) cagr_year=this.state.cagr_year;
    let cagr_year_median=Math.ceil((cagr_year[0]+cagr_year[1])/2);
    footers = "";
    let chartConfigs = {
      type: "column2d",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          paletteColors: "#4A90E2",
          plotSpacePercent: 50,
          captionFontColor: "#7f7f7f",
          showValues: !this.props.dataHidden,
          rotateValues: 0,
          showPlotBorder: "0",
          valueFontColor: "#002F75",
          animation: false,
          labelFontSize: 14,
          rotateLabels: "0",
          // showYAxisValues: true,
          captionFontSize: 14,
          valueFontBold: false,
          theme: "fusion",
          captionAlignment: "left",
          placeValuesInside: "0",
          captionFont: "Avenir Heavy",
          yAxisNamePadding: 0,
          chartTopMargin: 80+this.props.chartHeadingGap,
          valuePosition: "outside",
          centerXaxisName: false,
          valueFont: "Avenir Heavy",
          valueFontSize: 12,
          valueFontBold: false,
          yAxisValueFontColor:"#7f7f7f",
          xAxisValueFontColor:"#7f7f7f",
          // divLineColor: "#ffffff",
          labelDisplay: "WRAP",
          xaxislinecolor: "#4A90E2",
          yaxislinecolor: "#4A90E2",
          showxaxisline: !this.props.dataHidden,
          showYAxisValues: !this.props.dataHidden,
          showYAxisLine: !this.props.dataHidden,
          formatNumberScale: 1,
          yAxisMaxValue: this.state.maxYaxisValue,
          decimals: 1,
          yAxisValueFontSize: 14,
          xAxisValueFontSize: 14,
          yAxisValueDecimals:1,
          "numberScaleValue": "1000,1000,1000",
          "numberScaleUnit": "k,M,B",
          baseFont: "Avenir Medium",
          baseFontSize:14,
          baseFontColor:"#7f7f7f"
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {},
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers+"").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${570+this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                },
              ],
            },
            {
              id: "user-images",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",

                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: "9",
                  font: "Avenir semibold",
                  text: footers,
                  bold: "1",
                  wrap: "1",
                  wrapWidth: width - 20,
                  x: "15",
                  y: height - 12,
                },
              ],
            },
          ],
        },
        data: this.state.data,
      },
    };
    if(this.props.allow_cagr){
      chartConfigs['dataSource']['annotations']['groups'].push(
        {
          "id": "infobar",
          "items": [
            {
              "id": "2",
              "type": "line",
              "x": `$dataset.0.set.${cagr_year[0]}.startx+25`,
              "y": `$dataset.0.set.${cagr_year[0]}.starty-50`,
              "tox": `$dataset.0.set.${cagr_year[0]}.startx+25`,
              "toy": `$dataset.0.set.${cagr_year[0]}.starty-30`,
              "color": "#2F9AC4",
              "dashed": "0",
              "thickness": "1"
            },
            {
              "id": "2.1",
              "type": "line",
              "x": `$dataset.0.set.${cagr_year[0]}.startx+25`,
              "y": `$dataset.0.set.${cagr_year[0]}.starty-50`,
              "tox": `$dataset.0.set.${cagr_year_median}.startx-5`,
              "toy": `$dataset.0.set.${cagr_year_median}.starty-50`,
              "color": "#2F9AC4",
              "dashed": "0",
              "thickness": "1"
            },
            {
              "id": "2.2",
              "type": "line",
              "x": `$dataset.0.set.${cagr_year_median}.startx+55`,
              "y": `$dataset.0.set.${cagr_year_median}.starty-50`,
              "tox": `$dataset.0.set.${cagr_year[1]}.startx+25`,
              "toy": `$dataset.0.set.${cagr_year[1]}.starty-50`,
              "color": "#2F9AC4",
              "dashed": "0",
              "thickness": "1"
            },
            {
              "id": "3",
              "type": "line",
              "x": `$dataset.0.set.${cagr_year[1]}.startx+25`,
              "y": `$dataset.0.set.${cagr_year[1]}.starty-50`,
              "tox": `$dataset.0.set.${cagr_year[1]}.startx+25`,
              "toy": `$dataset.0.set.${cagr_year[1]}.starty-30`,
              "color": "#2F9AC4",
              "dashed": "0",
              "thickness": "1"
            },
            {
              "id": "shape",
              "type": "polygon",
              "startangle": "270",
              "sides": "3",
              "radius": "6",
              "color": "#2F9AC4",
              "x": `$dataset.0.set.${cagr_year[0]}.startx+25`,
              "y": `$dataset.0.set.${cagr_year[0]}.starty-30`
            },
            {
              "id": "shape",
              "type": "polygon",
              "startangle": "270",
              "sides": "3",
              "radius": "6",
              "color": "2F9AC4",
              "x": `$dataset.0.set.${cagr_year[1]}.startx+25`,
              "y": `$dataset.0.set.${cagr_year[1]}.starty-30`
            },
            {
              "id": "shape",
              "type": "arc",
              "radius": "30",
              "innerRadius":"27.5",
              "color": "2F9AC4",
              "x": `$dataset.0.set.${cagr_year_median}.startx+25`,
              "y": `$dataset.0.set.${cagr_year_median}.starty-50`
            },
            {
              "id": "cagr",
              "type": "text",
              align: "center",
              fillcolor: "#002F75",
              fontsize: 14,
              font: "Avenir Medium",
              text: `${this.props.cagr_value?this.props.cagr_value+"%":"0%"} CAGR`,
              bold: "0",
              wrap: "1",
              wrapWidth: `${40}`,
              x: `$dataset.0.set.${cagr_year_median}.startx+25`,
              y: `$dataset.0.set.${cagr_year_median}.starty-50`,
            }

          ]
        }
      )
    }
    return (
      <div
        className="bar"
        id="bar"
        style={{
          textAlign: "left",
          width: 400,
          marginBottom: 10,
          fontFamily: "regular",
        }}
      >
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

export default Chart;
