import React, { Component } from "react";
import SearchComponent from ".././Search";
import Interface from "../Interface";
import InterFaceHeader from "../InterfaceHeader";
import {
  setTocData,
  setSearchParams,
  setActiveTab,
  setPublishedDrawer,
  setDraftDrawer,
  setDraftStage,
  setNoDraftsFound,
  setSelectStageChangeLoader,
  setDraftId
} from "../../reducersAndActions/Actions";
import { Tabs, Button, Switch, Menu, Dropdown, Select, Modal, Drawer, notification } from "antd";
import { Spin, message } from "antd";
import { LoadingOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import { LayoutWrapper } from "../../styles/Layout.style";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config/Api";

import isEqual from 'react-fast-compare';


const { Option } = Select;

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} />;
class Layout extends Component {
  state = {
    blob: [],
    downloadloader: false,
    pdfurl: "",
    checked: false,
    publish_modal_warning: false,
    draft_publish_loader: false,
    duplicate_rd_modal_warning: false,
    duplicate_rd_modal_message:"",
    reset_urls_modal_warning:false
  };
  hubLeaderMenu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );
  componentDidMount() {
    if (!this.props.TOC_DATA) {
      this.props.setSearchParams(false);
    }
  }

  setGetTocParams = (item) => {
    this.props.history.push(`/editing/report/${item.title}`);
    this.props.setTocData(item);
    this.props.setSearchParams(true);
    // this.props.setActiveTab("report");
    this.props.setActiveTab("drafts");
  };
  tabChange = (value) => {
    this.props.setActiveTab(value);
    if (value == "report") {
      this.props.history.push(`/editing/report/${this.props.TOC_DATA.title}`);
    } else {
      this.props.history.push(`/editing/${value}`);
    }
  };
  tabClick = (key, event) => { };


  tabData = () => {
    if (this.props.ACTIVE_TAB == "report") {
      return !this.props.SEARCH_PARAMS ? null : (
        <Interface
          TocApiParams={this.props.TOC_DATA}
          reportName={this.props.TOC_DATA.title}
        />
      );
    } else if (
      this.props.ACTIVE_TAB == "published" ||
      this.props.ACTIVE_TAB == "drafts"
    ) {
      return !this.props.SEARCH_PARAMS ? null : (
        <Interface
          TocApiParams={this.props.TOC_DATA}
          reportName={this.props.TOC_DATA.title}
          edit_mode={this.state.checked}
          draft_drawer_visible={this.props.DRAFT_DRAWER_VISIBLE}
          published_drawer_visible={this.props.PUBLISHED_DRAWER_VISIBLE}
          handleSwitchchange={this.handleSwitchchange}
        />
      );

    } else if (this.props.ACTIVE_TAB == "datacharts") {
      return <div>Data and Charts</div>;
    } else if (this.props.ACTIVE_TAB == "customize") {
      return <div>Customize</div>;
    } else if (this.props.ACTIVE_TAB == "faqs") {
      return <div>FAQs</div>;
    } else {
      return <div>Purchases</div>;
    }
  };

  deleteOldRD = async() => {
    const payload = {
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
      deleteOlderRd: this.state.duplicate_rd_modal_message ? true : false,
      meta: { sample: false, rd: true },
    };
    this.setState({
      downloadloader: true,
    });
    try {
      let url = config.api.base_url;
        let response = await axios.post(`${url}/api/createRd`, payload);
        if (response.status == 200) {
          message.success(`rd generated successfully ${response.data.slugs}`);
          let a = document.createElement('a');
          a.target = '_blank';
          a.href = `${config.api.website_url}industry-reports/${response.data.slugs}`;
          a.click();
          message.success(`Old RD Deleted successfully ${response.data.slugs}`);
          this.setState({
            downloadloader: false,
            duplicate_rd_modal_warning: false,
          });
        } else if (response.data.message) {
          message.error(response.message);
          this.setState({
            downloadloader: false,
          });
        } else if (!response) {
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
    } catch (error) {
      message.error("Failed");
      this.setState({
        downloadloader: false,
      });
    }
  }
  deleteOldUrls=async()=>{
    const payload = {
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions
    };
    this.setState({
      downloadloader: true,
    });
    try{
      let url = config.api.base_url;
        let response = await axios.delete(`${url}/api/additional_routes/deleteHubUrls`, {data:payload});
        if (response.status == 200) {
          message.success(`${this.props.TOC_DATA.title} report and sample url delete successfully..!`);
        
          this.setState({
            downloadloader: false,
            reset_urls_modal_warning: false,
          });
        } else {
          message.error(`Something went wrong, please try again later...!`);
          this.setState({
            downloadloader: false,
          });
        }
    }catch(e){
      message.error("Failed");
      this.setState({
        downloadloader: false,
        reset_urls_modal_warning: false
      });
    }
  }

  generatePdf = async (report) => {
    // console.log("generating....");
    try {
      let payload;
      if (report == "Report") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: false },
        };
        this.setState({
          downloadloader: true,
        });
      } else if ((report + "").toLowerCase() == "sample") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          // sample: true,
          meta: { sample: true, reseller: false },
        };
        this.setState({
          downloadloader: true,
        });
      } else if ((report + "").toLowerCase() == "rd") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: false, rd: true },
        };
        this.setState({
          downloadloader: true,
        });
      }else if((report + "").toLowerCase()=="reset_urls"){
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: false, rd: true },
        };
        this.setState({
          downloadloader: true,
        });
      }


      if ((report + "").toLowerCase() == "report" || (report + "").toLowerCase() == "sample") {
        let url = config.api.puppeteer_ms_url;
        let response = await axios.post(`${url}/api/createReport`, payload);

        if (response.status == 200) {
          message.success("Success");
          this.downloadReport(response.data.pdfUrl);
        } else if (response.data.message) {
          message.error(response.message);
          this.setState({
            downloadloader: false,
          });
        } else if (!response) {
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
      } else if ((report + "").toLowerCase() == "rd") {
        let url = config.api.base_url;
        let response = await axios.post(`${url}/api/createRd`, payload);
        if (response.status == 200) {
          message.success(`rd generated successfully ${response.data.slugs}`);
          let a = document.createElement('a');
          a.target = '_blank';
          a.href = `${config.api.website_url}industry-reports/${response.data.slugs}`;
          a.click();
          this.setState({
            downloadloader: false,
          });
        } else if (response.data.message) {
          message.error(response.message);
          this.setState({
            downloadloader: false,
          });
        } else if (!response) {
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
      }else if((report + "").toLowerCase()=="reset_urls"){
        this.setState({
          downloadloader: false,
          reset_urls_modal_warning:true
        });
      }

    } catch (error) {
      if(error.response.status == 409){
        this.setState({
          duplicate_rd_modal_warning: true,
          duplicate_rd_modal_message: error.response?.data?.message,
        })
      }
      message.error("Failed");
      this.setState({
        downloadloader: false,
      });
    }
  };

  handleDuplicateModalCancel = () => {
    this.setState({
      duplicate_rd_modal_warning: false
    })
  }
  handleResetUrlsModalCancel = () => {
    this.setState({
      reset_urls_modal_warning: false
    })
  }

  downloadReport = (pdfurl) => {
    var link = document.createElement("a");
    link.download = "sample.pdf";
    link.href = `${pdfurl}`;
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent("click"));
    this.setState({
      downloadloader: false,
    });
  };

  handleSwitchchange = (checked) => {
    this.props.setNoDraftsFound(false)
    this.setState({
      checked: checked,
    });
    this.props.setDraftId(null)
  };

  handleDraftTabStageChange = (stage) => {

    this.continueStagechange(stage);

  }

  showPublishedDrawer = () => {
    this.props.setPublishedDrawer(true)
  }

  showDraftDrawer = () => {
    this.props.setDraftDrawer(true);
  }

  handlePublishModalWarningCancel = () => {
    this.setState({
      publish_modal_warning: false
    })
  }

  continueDraftPublish = () => {
    this.setState({
      draft_publish_loader: true
    })
    //User agreed, proceed to publish
    this.props.setDraftStage('PUBLISHED')
    const data = {
      "type": this.props.template_type,
      "stage": 'PUBLISHED',
      "parent_id": this.props.parent_id,
      "cacheId": this.props.cache_id,
      "draftId": this.props.draft_id,
      "hub_id": this.props.TOC_DATA.hub_id,
      "dimensions": this.props.TOC_DATA.dimensions,
    }

    /* CHECK IF USER HAS ACCESS */
    if (this.props.USER_DATA.obj.access.move.includes(this.props.draft_versions.stage || 'INITIAL')) {
      axios({
        method: "PATCH",
        url: `${config.api.base_url}/api/editing/drafts`,
        data: data,
      })
        .then((response) => {
          this.setState({
            checked: false,
            draft_publish_loader: false,
            publish_modal_warning: false,
          })

          if (response.status === 200 || response.status === 201) {
            this.props.setActiveTab('published');
            notification.success({
              message: "Info",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          this.props.setDraftStage(this.props.draft_stage_from_get_specific_data); //revert to previous stage if api call fails
          this.setState({
            draft_publish_loader: false,
            publish_modal_warning: false,
          })
          if (error.response) {
            notification.error({
              message: 'Error',
              description: error.response.data.message
            })
          }
        });
    } else {
      this.props.setDraftStage(this.props.draft_stage_from_get_specific_data); //revert to previous stage
      this.setState({
        publish_modal_warning: false,
        draft_publish_loader: false
      })
      notification.warning({
        message: 'Info',
        description: 'You dont have access to move stage'
      })
    }
  }


  continueStagechange = (stage) => {
    if (stage === 'PUBLISHED') {
      // If the stage about to move is PUBLISHED, show warning modal
      this.setState({
        publish_modal_warning: true
      })
    } else {
      //Let the stage change happen. Don't show any modal.
      this.props.setDraftStage(stage)
      this.props.setSelectStageChangeLoader(true);
      const data = {
        "type": this.props.template_type,
        "stage": stage,
        "parent_id": this.props.parent_id,
        "draftId": this.props.draft_id,
        "cacheId": this.props.cache_id,
      }



      /* CHECK IF USER HAS ACCESS */
      if (this.props.USER_DATA.obj.access.move.includes(this.props.draft_versions.stage || 'INITIAL')) {
        axios({
          method: "PATCH",
          url: `${config.api.base_url}/api/editing/drafts`,
          data: data,
        })
          .then((response) => {
            this.props.setSelectStageChangeLoader(false);
            if (response.status === 200 || response.status === 201) {
              // throw new Error('something went wrong');
              notification.success({
                message: "Info",
                description: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.props.setSelectStageChangeLoader(false);
            this.props.setDraftStage(this.props.draft_stage_from_get_specific_data); //revert to previous stage if api call fails
            if (error.response) {
              notification.error({
                message: 'Error',
                description: error.response.data.message
              })
            }
          });
      } else {
        this.props.setDraftStage(this.props.draft_stage_from_get_specific_data); //revert to previous stage
        notification.warning({
          message: 'Info',
          description: 'You dont have access to move stage'
        })
      }

    }
  }

  render() {
    const { width } = this.props;

    return (
      <LayoutWrapper
        mobile={width > 960 ? false : true}
        checked={this.state.checked}
        template_type={this.props.template_type}
      >
        <div className="layoutCOntainer">
          <InterFaceHeader
            title={this.props.TOC_DATA.title}
            generatePdf={this.generatePdf}
            indicator={antIcon}
            spinning={this.state.downloadloader}
          />
          {!this.props.SEARCH_PARAMS ? (
            <SearchComponent setGetTocParams={this.setGetTocParams} />
          ) : (
            <>
              <div className="antd-tabContainer">
                {/* {width > 960 ? <div className="empty-div"></div> : null} */}
                <Tabs
                  activeKey={this.props.ACTIVE_TAB}
                  centered
                  animated={width > 960 ? true : false}
                  tabPosition={width > 960 ? "top" : "bottom"}
                  onChange={(value) => this.tabChange(value)}
                  className="simulateClick"
                >
                  {/* <TabPane tab="Report" key="report">
                    {" "}
                    <Link to="/layout/report" />
                  </TabPane>
                  <TabPane tab="Data and Charts" key="datacharts">
                    <Link to="/layout/data-charts" />
                  </TabPane>
                  <TabPane tab="Customize" key="customize">
                    <Link to="/layout/customize" />
                  </TabPane>

                  <TabPane tab="FAQs" key="faqs">
                    <Link to="/layout/faqs" />
                  </TabPane>

                  <TabPane tab="Purchases" key="purchases">
                    <Link to="/layout/purchases" />
                  </TabPane> */}

                  <TabPane tab="Drafts" key="drafts">
                    <Link to="/editing/report" />
                  </TabPane>
                  <TabPane tab="Published" key="published">
                    <Link to="/editing/report" />
                  </TabPane>
                </Tabs>

                <div className="editing-controls-container">
                  <Switch
                    // defaultChecked
                    checked={this.state.checked}
                    onChange={this.handleSwitchchange}
                    checkedChildren="EDIT"
                    unCheckedChildren="VIEW"
                    disabled={this.props.switch_disabled}
                    className="edit-mode-switch"
                  />
                  {this.props.ACTIVE_TAB === "published" ? (
                    <div className="tab-buttons-container">

                      {/* <Button type="primary" disabled={!this.state.checked}>PUBLISHED</Button> */}
                      <Button type="link" onClick={this.showPublishedDrawer} disabled={!this.state.checked}>Published Versions</Button>
                    </div>
                  ) : (
                    <div className="tab-buttons-container">

                      <Select
                        value={this.props.draft_stage}
                        style={{ width: 120 }}
                        onChange={this.handleDraftTabStageChange}
                        disabled={!this.state.checked || this.props.draft_stage === 'NONE'}
                        className='draft-tab-dropdown'
                        loading={this.props.select_stage_change_loader}
                      >
                        {this.props.USER_DATA && this.props.USER_DATA.obj && this.props.USER_DATA.obj.access && this.props.USER_DATA.obj.access.move && this.props.USER_DATA.obj.access.move.length && this.props.USER_DATA.obj.access.move.map((item) => {
                          return (
                            <Option value={item}>{item}</Option>
                          )
                        })}
                      </Select>


                      <Button type="link" onClick={this.showDraftDrawer} disabled={!this.state.checked}>Draft Versions</Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="body-section">{this.tabData()}</div>
            </>
          )}
        </div>
        <Modal title="Notification" visible={this.state.publish_modal_warning} onCancel={this.handlePublishModalWarningCancel} onOk={this.continueDraftPublish} okText='Continue' cancelText='Cancel' footer={[
          <Button key="back" onClick={this.handlePublishModalWarningCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={this.state.draft_publish_loader} onClick={this.continueDraftPublish}>
            Continue
          </Button>,
        ]}>
          Once you publish this draft, it replaces the current version with this version.
          Are you sure?
        </Modal>

        <Modal 
          title="Sorry! RD already exist"
          visible={this.state.duplicate_rd_modal_warning} okText='Continue' 
          cancelText='Cancel'
          onCancel={this.handleDuplicateModalCancel} 
          centered
          footer={[
          <Button 
            key="back" 
            onClick={this.handleDuplicateModalCancel}
          >
           Cancel
         </Button>,
         <Button 
            key="submit" 
            type="primary" 
            onClick={this.deleteOldRD}
            loading={this.state.downloadloader}
          >
           Proceed
         </Button>,
        ]}>
         {this.state.duplicate_rd_modal_message}
        </Modal>
        <Modal 
          title="Are you sure removing existing Sample, Report?"
          visible={this.state.reset_urls_modal_warning} okText='Continue' 
          cancelText='Cancel'
          onCancel={this.handleResetUrlsModalCancel} 
          centered
          footer={[
          <Button 
            key="back" 
            onClick={this.handleResetUrlsModalCancel}
          >
           Cancel
         </Button>,
         <Button 
            key="submit" 
            type="primary" 
            onClick={this.deleteOldUrls}
            loading={this.state.downloadloader}
          >
           Proceed
         </Button>,
        ]}>
         {this.state.duplicate_rd_modal_message}
        </Modal>

      </LayoutWrapper>
    );
  }
}
function mapStateToPros(state) {
  return {
    IS_AUTH: state.IS_AUTH,
    SEARCH_PARAMS: state.SEARCH_PARAMS,
    TOC_DATA: state.TOC_DATA,
    ACTIVE_TAB: state.ACTIVE_TAB,
    PLACEMENT: state.PLACEMENT,
    PUBLISHED_DRAWER_VISIBLE: state.PUBLISHED_DRAWER_VISIBLE,
    DRAFT_DRAWER_VISIBLE: state.DRAFT_DRAWER_VISIBLE,
    draft_versions: state.draft_versions,
    published_versions: state.published_versions,
    USER_DATA: state.USER_DATA,
    draft_stage: state.draft_stage,
    draft_stage_from_get_specific_data: state.draft_stage_from_get_specific_data,
    select_stage_change_loader: state.select_stage_change_loader,
    draft_data_analysis: state.draft_data_analysis,
    draft_data_sources: state.draft_data_sources,
    analysis_from_latest_draft: state.analysis_from_latest_draft,
    sources_from_latest_draft: state.sources_from_latest_draft,
    published_access_warning: state.published_access_warning,
    switch_disabled: state.switch_disabled,
    parent_id: state.parent_id,
    cache_id: state.cache_id,
    template_type: state.template_type,
    draft_id: state.draft_id,
  };
}
const mapDispatchToProps = {
  setTocData,
  setSearchParams,
  setActiveTab,
  setPublishedDrawer,
  setDraftDrawer,
  setDraftStage,
  setNoDraftsFound,
  setSelectStageChangeLoader,
  setDraftId
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(Layout))
);
