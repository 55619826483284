import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Graph from "../components/Graphs";
import { Tabs, Radio, Button, notification, Spin } from "antd";
import "antd/dist/antd.css";
import Analysis from "../components/Analysis";
import { TypeDetailContainer } from "../styles/TypeDetail.style";
import DataHidden from "../components/DataHidden";
import Editor from "../components/Editor";
import axios from "axios";
import {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId
} from "../reducersAndActions/Actions";
import config from "../config/Api";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../Common/withWindowSizeHOC";

var Base64 = require("js-base64").Base64;

const { TabPane } = Tabs;

class TypeDetail extends React.Component {
  state = {
    graphFooterText:
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: this.props.data ? this.props.data : {},
    save_draft_loader: false,
    tabPosition: "top",
    editor_active_tab: "analysis",
    direct_publish_loader: false,
    reseller: false
  };


  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
    if(nextProps.data && nextProps.data.meta) {
      if(nextProps.data.meta.sample && nextProps.data.meta.reseller ) {
        this.setState({ reseller: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.title != this.props.data.title) {
      this.setState({
        data: this.props.data,
      });
      if(this.props.data && this.props.data.meta) {
        if(this.props.data.meta.sample && this.props.data.meta.reseller ) {
          this.setState({ reseller: true });
        }
      }
    }

    if (this.props.draft_drawer_visible !== prevProps.draft_drawer_visible) {
      if (this.props.draft_drawer_visible) {
        // console.log("perform api call");
        this.props.getDrafts(this.props.data.data.parent_id, "typedetail");
      }
    }

    if (
      this.props.published_drawer_visible !== prevProps.published_drawer_visible
    ) {
      if (this.props.published_drawer_visible) {
        // console.log("perform api call from type detail");
        this.props.getPublishedVersions(this.props.data.data.parent_id);
      }
    }
    
    
    if((this.props.edit_mode !== prevProps.edit_mode)){
      this.setState({
        editor_active_tab: 'analysis'
      })
    }
  }

  editorTabChange = (tab) => {
    this.setState({
      editor_active_tab: tab,
    });
  };

  handleSaveDraft = () => {
    this.setState({
      save_draft_loader: true,
    });
    // console.log("save draft button clicked ; perform save draft api call");
    const data = {
      parent_id: this.props.parent_id,
      type: "typedetail",
      analysis: this.props.draft_data_analysis,
      source: this.props.draft_data_sources,
      stage: this.props.draft_versions.stage ? this.props.draft_versions.stage : "INITIAL"
    };
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/drafts`,
      data: data,
    })
      .then((response) => {
        this.setState({
          save_draft_loader: false,
        });
        // console.log(response, "RESP");
        if (response.status === 200 || response.status === 201) {
          this.props.setDraftId(null)
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          save_draft_loader: false,
        });
        if (error.response) {
          console.log(error.response)
          notification.error({
            message: 'Error',
            description: error.response.data.message
          })
        } 
      });
  };

  handleDirectPublish = () => {
    this.setState({
      direct_publish_loader: true,
    });
    // console.log("publish direct");
    const data = {
      parent_id: this.props.parent_id,
      analysis: this.props.draft_data_analysis,
      source: this.props.draft_data_sources,
      directPublished: true,
      type: "typedetail",
      stage: "PUBLISHED",
      cacheId: this.props.cache_id,
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions
    };
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/directPublish`,
      data: data,
    })
      .then((response) => {
        this.props.handleSwitchchange(false);
        this.setState({
          direct_publish_loader: false,
        });
        // console.log(response, "RESP");
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          direct_publish_loader: false,
        });
        if (error.response) {
          notification.error({
            message: 'Error',
            description: error.response.data.message
          })
        } 
      });
  };

  handleEditoverlayClick=()=>{
    this.props.setNoDraftsFound(false);
    this.props.setDraftStage('INITIAL');
  }

  render() {
    const { section_id, subtitle, slug, title, type } = this.props.data;
    const { trends, graphs, slideName } = this.props.data.data;
    const { slideNo } = this.props;
    const { tabPosition } = this.state;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    let tabsElement =  (<Tabs
    activeKey={this.state.editor_active_tab}
    tabPosition={tabPosition}
    onChange={this.editorTabChange}
    tabBarExtraContent={
      <div style={{ display: "flex", gap: "20px" }}>
        {this.props.ACTIVE_TAB ==='drafts' ? 
        <Button
          type="primary"
          onClick={this.handleSaveDraft}
          loading={this.state.save_draft_loader}
          disabled={this.props.character_count_error}
        >
          Save
        </Button> : ''}
        {this.props.ACTIVE_TAB === 'published' ? <Button
          type="primary"
          onClick={this.handleDirectPublish}
          loading={this.state.direct_publish_loader}
          disabled={this.props.character_count_error}
        >
          DIRECT PUBLISH
        </Button>: ''}
        
      </div>
    }
    type="card"
    style={{ height: "100%" }}
  >
    <TabPane tab="Analysis" key="analysis" forceRender={true}>
    {this.props.specific_draft_loading ? <Spin style={{display:'flex', justifyContent:'center'}}/> : 
      <Analysis
        trends={trends[0]}
        edit_mode={this.props.edit_mode}
        section_id={`${section_id}-analysis`}
      /> }
    </TabPane>
    <TabPane tab="Sources" key="sources" forceRender={true}>
      {/* Please enter list of Sources */}
      {this.props.specific_draft_loading ? <Spin style={{display:'flex', justifyContent:'center'}}/> : 
      <Editor
        section_id={`${section_id}-source`}
        sources={this.props.data.source}
      /> }
    </TabPane>
  </Tabs>)

    return (
      <TypeDetailContainer id={pageId}>
        <div className="page">
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Header slideTitle={this.state.data.data.slideName} />
          ) : null}
          <div className="container" style={{height: this.props.fromInterface ? 'auto': '626px'}}>
            <h2 className="heading" style={{ height: "10%" }}>
              {title}
            </h2>

            <div className="top-container" style={{ height: "45%" }}>
              {graphs.length == 3 || (graphs.length == 2 && graphs[1].graphType != "pie")?(
                <>
                  <div className="left-container">
                  {/* {graphs[0] ? 
                    <h3 className="subheading" style={{height:graphs[0].heading.length >107 || graphs[1].heading.length >107 ? "65px":"50px"}}>
                     {graphs[0].heading }
                    </h3>: null} */}

                    {graphs[0].data && graphs[0].data != "hidden" ? (
                      <Graph
                      chartHeadingGap={-20}
                        yHeadingMargin={25}
                        xHeadingMargin={0}
                        headers={graphs[0].heading}
                        width={
                          !this.props.fromInterface ||
                          this.props.fromInterface == undefined
                            ? "566"
                            : "100%"
                        }
                        height={
                          !this.props.fromInterface ||
                          this.props.fromInterface == undefined
                            ? 260
                            : 250
                        }
                        totalData={graphs[0]}
                        data={{ data: graphs[0].data }}
                        graphType={graphs[0].graphType}
                        dataType={graphs[0]["dataType"]}
                        dynamiclegends={graphs[0]["legends"]}
                        rotateValues={1}
                        slideNo
                      />
                    ) : null}
                  </div>
                  <div className="right-container">
                  {/* {graphs[1] ?
                    <h3 className="subheading" style={{height:graphs[1].heading.length >107 || graphs[0].heading.length >107 ? "65px":"50px"}}>
                      {graphs[1].heading }
                    </h3>: ""} */}
                    {graphs[1] &&
                    graphs[1].data &&
                    graphs[1].data.length &&
                    graphs[1].data != "hidden" ? (
                      <Graph
                      chartHeadingGap={-20}
                        yHeadingMargin={25}
                        xHeadingMargin={0}
                        headers={graphs[1].heading}
                        slideNo
                        totalData={graphs[1]}
                        data={{ data: graphs[1].data }}
                        height={260}
                        width={
                          !this.props.fromInterface ||
                          this.props.fromInterface == undefined
                            ? "566"
                            : "100%"
                        }
                        dynamiclegends={graphs[1]["legends"]}
                        rotateValues={1}
                        graphType={graphs[1].graphType}
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="fullWidthCOntainer">
                  {/* {graphs[0] ?  <h3 className="subheading" >
                   {graphs[0].heading }
                  </h3>: null} */}
                  {graphs[0].data && graphs[0].data != "hidden" ? (
                    <Graph
                        wrapHeadingWidth={400}
                        chartHeadingGap={-20}
                        yHeadingMargin={15}
                        xHeadingMargin={0}
                        headers={graphs[0].heading}
                      width={
                        !this.props.fromInterface ||
                        this.props.fromInterface == undefined
                          ? "1070"
                          : "100%"
                      }
                      height={
                        !this.props.fromInterface ||
                        this.props.fromInterface == undefined
                          ? 260
                          : 250
                      }
                      totalData={graphs[0]}
                      data={{ data: graphs[0].data }}
                      graphType={graphs[0].graphType}
                      dataType={graphs[0]["dataType"]}
                      dynamiclegends={graphs[0]["legends"]}
                      slideNo
                    />
                  ) : null}
                </div>
              )}
            </div>
            <div className="bottom-container" style={{ height: "45%",marginTop:(graphs[0].type !="pie" || graphs[1].type !="pie") || graphs[1].heading.length >107 || graphs[0].heading.length >107?"25px":"15px" }}>
              {trends.length || this.props.fromInterface ? (
                 (graphs.length != 1 && (graphs.length == 3 && graphs[2].graphType == "pie" && graphs[2].data.length!=0))||(graphs.length != 1 &&(graphs[1].graphType == "pie" && graphs[1].data.length!=0 ))? (
              
                  <div className="left-container">
                    {this.props.edit_mode ?
                    (
                        this.props.no_drafts_found && this.props.ACTIVE_TAB === 'drafts' ? 
                        <div style={{border:'1px solid grey', padding:'20px'}} className='no-drafts-overlay'>
                          <p>There is nothing in draft right now</p>
                          <Button onClick={this.handleEditoverlayClick}>Edit</Button>
                          </div>
                        : this.props.initial_draft_versions_loading ? <Spin style={{display:'flex', justifyContent:'center'}}/>:

                        tabsElement
                        
                     
                    ) : (
                      <Analysis
                        trends={trends[0]}
                        edit_mode={this.props.edit_mode}
                        section_id={section_id}
                      />
                    )}
                  </div>
              
              ): (
              <div className="left-container-full">
                {this.props.edit_mode ?
                    (
                        this.props.no_drafts_found && this.props.ACTIVE_TAB === 'drafts' ? 
                        <div style={{border:'1px solid grey', padding:'20px'}} className='no-drafts-overlay'>
                          <p>There is nothing in draft right now</p>
                          <Button onClick={this.handleEditoverlayClick}>Edit</Button>
                          </div>
                        : this.props.initial_draft_versions_loading ? <Spin style={{display:'flex', justifyContent:'center'}}/>:

                        tabsElement
                        
                     
                    ) : (
                      <Analysis
                        trends={trends[0]}
                        edit_mode={this.props.edit_mode}
                        section_id={section_id}
                      />
                    )}

              </div>
              )) : null}
              {(graphs.length != 1 && (graphs.length == 3 && graphs[2].graphType == "pie" && graphs[2].data.length!=0))||(graphs.length != 1 &&(graphs[1].graphType == "pie" && graphs[1].data.length!=0 ))? (
                graphs.length === 3 ? (
                  <div
                    className="right-container"
                    style={{ position: "relative" }}
                  >
                     {/* {graphs[2] ?<h3 className="subheading" style={{height:graphs[0].heading.length >107 || graphs[1].heading.length >107 ? "65px":"50px"}}>
                     {graphs[2].heading }
                    </h3>: ""} */}
                    {graphs[2] &&
                    graphs[2].data &&
                    graphs[2].data != "hidden" ? (
                      <Graph
                        headers={graphs[2].heading}
                        slideNo
                        data={graphs[2].data}
                        totalData={graphs[2]}
                        height={260}
                        chartHeadingGap={0}
                        yHeadingMargin={20}
                        width={
                          !this.props.fromInterface ||
                          this.props.fromInterface == undefined
                            ? "566"
                            : "566"
                        }
                        graphType={graphs[2].graphType}
                      />
                    ) : (
                      <DataHidden />
                    )}
                  </div>
                ) : (
                  <div
                    className="right-container"
                    style={{ position: "relative" }}
                  >
                    {/* {graphs[1] ?  <h3 className="subheading" style={{height:graphs[0].heading.length >107 || graphs[1].heading.length >107 ? "65px":"50px"}}>
                     {graphs[1].heading }
                    </h3>: ""} */}
                    {graphs[1] &&
                    graphs[1].data &&
                    graphs[1].data != "hidden" ? (
                      <Graph
                        yHeadingMargin={25}
                        xHeadingMargin={0}
                        headers={graphs[1].heading}
                        slideNo
                        data={graphs[1].data}
                        height={260}
                        totalData={graphs[1]}
                        chartHeadingGap={0}                        
                        width={
                          !this.props.fromInterface ||
                          this.props.fromInterface == undefined
                            ? "566"
                            : "566"
                        }
                        graphType={graphs[1].graphType}
                      />
                    ) : (
                      <DataHidden />
                    )}
                  </div>
                )
              ) : null}
            </div>
          </div>
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              fromInterface={this.props.fromInterface}
              reseller={this.state.reseller}
            />
          ) : null}
        </div>
      </TypeDetailContainer>
    );
  }
}

// export default TypeDetail;

function mapStateToPros(state) {
  return {
    draft_data_analysis: state.draft_data_analysis,
    draft_data_sources: state.draft_data_sources,
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_versions: state.draft_versions,
    no_drafts_found: state.no_drafts_found,
    user_edit_access: state.user_edit_access,
    specific_draft_loading: state.specific_draft_loading,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    TOC_DATA: state.TOC_DATA,
    cache_id: state.cache_id,
    parent_id: state.parent_id,
    character_count_error: state.character_count_error
  };
}
const mapDispatchToProps = {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(TypeDetail))
);
