import React, { Component } from "react";
import Bar from "../graphs/bar/Main";
import Line from "../graphs/line/Main";
import Graph from '../components/Graphs';
import Analysis from "./Analysis";
import { Tabs, Radio, Button, notification, Spin, Input } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Editor from "./Editor";
import {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setSelectedIndicatorHeading,
  setIndicatorObjectToShow,
  setParentId,
  setDraftDataHeading,
  setDraftId
} from "../reducersAndActions/Actions";
import EditableSelect from "./EditableSelect";
import axios from "axios";
import config from "../config/Api";
const { TabPane } = Tabs;

class IndicatorEditing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphFooterText:
        "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
      tabPosition: "top",
      editor_active_tab: "analysis",
      save_draft_loader: false,
      direct_publish_loader: false,
      indicator_heading_input: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.draft_drawer_visible !== prevProps.draft_drawer_visible) {
      if (this.props.draft_drawer_visible) {
      //perform drafts api call
        this.props.getDrafts(
          this.props.indicator_object_to_show.parent_id,
          "indicator"
        );
      }
    }

    if (
      this.props.published_drawer_visible !== prevProps.published_drawer_visible
    ) {
      if (this.props.published_drawer_visible) {
        //perform api call from type indicator editing
        this.props.getPublishedVersions(
          this.props.indicator_object_to_show.parent_id
        );
      }
    }

    if((this.props.edit_mode !== prevProps.edit_mode)){
      this.setState({
        editor_active_tab: 'analysis'
      })
    }
  }

  editorTabChange = (tab) => {
    this.setState({
      editor_active_tab: tab,
    });
  };

  handleSaveDraft = () => {
    this.setState({
      save_draft_loader: true,
    });
   //save draft button clicked ; perform save draft api call
    const data = {
      parent_id: this.props.indicator_object_to_show.parent_id,
      type: "indicator",
      analysis: this.props.draft_data_analysis,
      source: this.props.draft_data_sources,
      heading: this.props.draft_data_heading,
      stage: this.props.draft_versions.stage
        ? this.props.draft_versions.stage
        : "INITIAL",
    };
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/drafts`,
      data: data,
    })
      .then((response) => {
        this.setState({
          save_draft_loader: false,
        });
        console.log(response, "RESP");
        if (response.status === 200 || response.status === 201) {
          this.props.setDraftId(null)
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          save_draft_loader: false,
        });
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };

  handleDirectPublish = () => {
    this.setState({
      direct_publish_loader: true,
    });
    // publish direct"
    const data = {
      parent_id: this.props.indicator_object_to_show.parent_id,
      heading: this.props.draft_data_heading,
      analysis: this.props.draft_data_analysis,
      source: this.props.draft_data_sources,
      directPublished: true,
      type: "indicator",
      stage: "PUBLISHED",
      cacheId: this.props.cache_id,
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions
    };
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/directPublish`,
      data: data,
    })
    .then((response) => {
        console.log(response, "RESP");
        this.props.handleSwitchchange(false);
        this.setState({
          direct_publish_loader: false,
        });
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          direct_publish_loader: false,
        });
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };

  getSelectedIndicatorObject=(parent_id)=>{
    this.setState({editor_active_tab: "analysis"})
    this.props.setParentId(parent_id);
    this.props.setIndicatorObjectToShow(
      this.props.data.data.filter((item) => item.parent_id === parent_id)[0]
    );
    let selected_heading = this.props.indicator_dropdown_options.filter((item)=>item.parent_id === parent_id)[0].trends.heading
    this.props.setSelectedIndicatorHeading(selected_heading);
    this.props.setDraftDataHeading(selected_heading);
  }

  handleIndicatorInputHeadingChange = (e) => {
    this.props.setDraftDataHeading(e.target.value);
  };

  handleEditoverlayClick=()=>{
    this.props.setNoDraftsFound(false);
    this.props.setDraftStage('INITIAL');
  }

  render() {
    let item = this.props.indicator_object_to_show;
    let section_id = this.props.data.section_id;
    return (
      <div className="container" style={{height: 'auto'}}>
        <h2 className="heading">{this.props.data.title}</h2>
        <div className="indicator-container">
          <EditableSelect
            headingDropdownArray={this.props.indicator_dropdown_options}
            getSelectedIndicatorObject={this.getSelectedIndicatorObject}
            selected_indicator_heading={this.props.selected_indicator_heading}
          />

          {/* <h3 className="subheading">{item.graph ? item.graph.heading : ""}</h3> */}

          {this.props.template_type === "indicator1" ? (
            <Graph
                        // wrapHeadingWidth={-100}
                        chartHeadingGap={item.graph.heading.length > 60 ? 0 : -10}
                        yHeadingMargin={item.graph.heading.length > 60 ? 30 : 20}
                        xHeadingMargin={0}
                        headers={item.graph.heading}
                        graphType={item.graph.graphType}
                        width={
                        !this.props.fromInterface ||
                        this.props.fromInterface == undefined
                          ? "100%"
                          : "100%"
                      }
                        height={250}
                          data={item.graph.data }
                          dynamiclegends={item.graph["legends"]}
                          axes={false}
                          totalData={item.graph}
                          labels={true}
                          footers={this.state.graphFooterText}
                          rotateValues={1}
                        />
          ) : this.props.template_type === "indicator2" ||
            this.props.template_type === "indicator3" ? (
              <Graph
              // wrapHeadingWidth={-100}
              chartHeadingGap={item.graph.heading.length > 60 ? 0 : -10}
              yHeadingMargin={item.graph.heading.length > 60 ? 30 : 20}
              xHeadingMargin={0}
              headers={item.graph.heading}
              graphType={item.graph.graphType}
              width={
              !this.props.fromInterface ||
              this.props.fromInterface == undefined
                ? "100%"
                : "100%"
            }
              height={200}
                data={ item.graph.data }
                dynamiclegends={item.graph["legends"]}
                axes={false}
                totalData={item.graph}
                labels={true}
                footers={this.state.graphFooterText}
                rotateValues={1}
              />
          ) : (
            ""
          )}

          {/* <h3 className="subheading subheading2">
                    {item.trends.heading}
                  </h3> */}

                  {this.props.initial_draft_versions_loading || this.props.specific_draft_loading ? <Spin style={{display:'flex', justifyContent:'center'}}/> 
                  : this.props.ACTIVE_TAB === 'drafts' && this.props.no_drafts_found ? 
                  <div style={{border:'1px solid grey', padding:'20px'}} className='no-drafts-overlay'>
                    <p>There is nothing in draft right now</p>
                    <Button onClick={this.handleEditoverlayClick}>Edit</Button>
                    </div>
                  : 
                  <div className='indicator-editing-container'>
                  <Input
                value={this.props.draft_data_heading}
                onChange={this.handleIndicatorInputHeadingChange}
                style={{ marginBottom: "20px" }}/>

                  <Tabs
            activeKey={this.state.editor_active_tab}
            tabPosition={this.state.tabPosition}
            onChange={this.editorTabChange}
            tabBarExtraContent={
              <div style={{ display: "flex", gap: "20px" }}>
                {this.props.ACTIVE_TAB === "drafts" ? (
                  <Button
                    type="primary"
                    onClick={this.handleSaveDraft}
                    loading={this.state.save_draft_loader}
                    disabled={this.props.character_count_error}
                  >
                    Save
                  </Button>
                ) : (
                  ""
                )}
                {this.props.ACTIVE_TAB === "published" ? (
                  <Button
                    type="primary"
                    onClick={this.handleDirectPublish}
                    loading={this.state.direct_publish_loader}
                    disabled={this.props.character_count_error}
                  >
                    DIRECT PUBLISH
                  </Button>
                ) : (
                  ""
                )}
              </div>
            }
            type="card"
            style={{ height: "100%" }}
          >
            <TabPane tab="Analysis" key="analysis" forceRender={true}>
                <Analysis
                  trends={this.props.indicator_object_to_show.trends}
                  edit_mode={this.props.edit_mode}
                  section_id={`${section_id}-analysis`}
                />
            </TabPane>
            <TabPane tab="Sources" key="sources" forceRender={true}>
              {/* Please enter list of Sources */}
                <Editor
                  section_id={`${section_id}-source`}
                  sources={this.props.indicator_object_to_show.source}
                />
            </TabPane>
          </Tabs>
          </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToPros(state) {
  return {
    draft_data_analysis: state.draft_data_analysis,
    draft_data_sources: state.draft_data_sources,
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_versions: state.draft_versions,
    no_drafts_found: state.no_drafts_found,
    user_edit_access: state.user_edit_access,
    specific_draft_loading: state.specific_draft_loading,
    selected_indicator_heading: state.selected_indicator_heading,
    indicator_object_to_show: state.indicator_object_to_show,
    draft_data_heading: state.draft_data_heading,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    indicator_dropdown_options: state.indicator_dropdown_options,
    TOC_DATA: state.TOC_DATA,
    cache_id: state.cache_id,
    parent_id: state.parent_id,
    character_count_error: state.character_count_error
  };
}
const mapDispatchToProps = {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setSelectedIndicatorHeading,
  setIndicatorObjectToShow,
  setParentId,
  setDraftDataHeading,
  setDraftId
};

export default withRouter(
  connect(mapStateToPros, mapDispatchToProps)(IndicatorEditing)
);
