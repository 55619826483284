import React, { Component}  from 'react';



function autoFocusAndSelect(input) {
  input && input.focus();
  input && input.select();
}


export default function TextEditor({
  row,
  column,
  onRowChange,
  onClose
}) {

  return (
    <textarea
      ref={autoFocusAndSelect}
      value={row[column.key]}
      onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value })}
      onBlur={() => onClose(true)}
    ></textarea>
  );
}
