import React from "react";
import { Typography, Image } from "antd";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { RegulatoryFrameworkV2Wrapper } from "../styles/RegulatoryFramework.style";
import Analysis from "../components/Analysis";
const { Title } = Typography;
var Base64 = require("js-base64").Base64;

export const regulatoryFrameworkDemoData = {
  slideName: "regulatoryframework",
  id: "2.3 REGULATORY FRAMEWORK",
  title: "3 PARENT MARKET TRENDS",
  subtitle: "2.3 REGULATORY FRAMEWORK",
  slideReference: "2. KEY INDUSTRY TRENDS",
  slideNo: 10,
  slug: "US PROBIOTICS MARKET",
  icon: "https://cdn.webshopapp.com/shops/94414/files/54959460/the-united-states-flag-icon-free-download.jpg",
  country: "UNITED STATES",
  RFData: [
    {
      type: "Agencies & Acts",
      analysis: [
        {
          text: "Feed Enzymes have to comply by the regulations of USFDA",
          superScript: 1,
          hyperLink: "#abc",
        },
        {
          text: `The FD&C Act requires that any substance that is added to or is expected to become a component of animal food, either directly or indirectly, must beused in accordance with a food additive regulation unless it is generallyrecognized as safe for that intended use (GRAS)`,
        },
        {
          text: ``,
        },
        {
          text: ``,
        },
        {
          text: ``,
        },
      ],
    },
    {
      type: "Approval Process",
      analysis: [
        {
          text: `Under section 409(c)(3) of the FD&C Act, FDA is not to approve a food additive petition if a fair evaluation of the data fails to establish that the proposed use of the feed additive, under the conditions of use to be specified in the regulation, will be safe.`,
          superScript: 1,
          hyperLink: "#abc",
        },
      ],
    },
    {
      type: "Labeling",
      analysis: [
        {
          text: "Feed Enzymes have to comply by the regulations of USFDA",
          superScript: 1,
          hyperLink: "#abc",
        },
        {
          text: `     Labeling guidelines should include: brand name, product name, Purpose Statement, Guaranteed Analysis, List of Ingredients, Directions for Use, Warning or Caution Statements;, Name and Address of Manufacturer, and Quantity Statement.`,
        },
        {
          text: ``,
        },
      ],
    },
    {
      type: "Safety Guidelines",
      analysis: [
        {
          text: "Feed Enzymes have to comply by the regulations of USFDA",
          superScript: 1,
          hyperLink: "#abc",
        },
        {
          text: `The feed enzyme product should address human food safety, target animal safety, environmental impact, utility, manufacturing chemistry, and labeling.`,
        },
      ],
    },
  ],
};

function ReturnTitleAndBulletPoints(arrayOfContent) {
  arrayOfContent.map((item1) => {
    return item1.analysis.forEach(function (BulletPoint, j) {
      if (!j)
        return (
          <div>
            {item1.type}
            {BulletPoint.text}
          </div>
        );
      else return <div>{BulletPoint.text}</div>;
    });
  });
}

class RegulatoryFramework extends React.Component {
  state = {
    data: this.props.data || regulatoryFrameworkDemoData,
    reseller: false
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.title != this.props.data.title) {
      this.setState({
        data: this.props.data,
      });
    }
   
    }

    static getDerivedStateFromProps(props, state) {
      if(props.data && props.data.meta) {
        if(props.data.meta.sample && props.data.meta.reseller ) {
          return {
            reseller: true
          }
        }
      }
    }
  
  render() {
    const { section_id, slug } = this.props.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <RegulatoryFrameworkV2Wrapper id={pageId} className="page">
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Header slideTitle={this.state.data.type}/>
        ) : null}
        <div className="container regulatoryFramework">
          <h2 className="heading regulatoryFramework">
            {this.state.data.title}
          </h2>
          {this.state.data.data.map((CountryData, k) => {
            return (
              <>
                <div className="regulatoryFrameworkCountry">
                {CountryData.icon?  <img
                    className="RegulatoryFrameworkCountryFlag"
                    src={CountryData.icon}
                    style={{ width: "50px" }}
                  />:null}
                  <span>{CountryData.country.toUpperCase()}</span>
                </div>
                <div className="RegulatoryFrameworkBoxSlide">
                  {CountryData.data.map(function (Box, i) {
                    let Heading = Box.type.toUpperCase();
                    // let BulletPoints = {};
                    // Box.analysis.forEach(function (BulletPoint, j) {
                    //   if (BulletPoint.text !== "")
                    //     !j
                    //       ? (BulletPoints =
                    //           "• " + BulletPoint.text.trim() + "\n" + "\n")
                    //       : (BulletPoints =
                    //           BulletPoints +
                    //           "• " +
                    //           BulletPoint.text.trim() +
                    //           "\n" +
                    //           "\n");
                    // });
                    return (
                      <div
                        className="RegulatoryFrameworkBox"
                        id={"RegulatoryFrameworkBox" + i}
                      >
                        <span
                          className="RegulatoryFrameworkBoxHeading"
                          id={"RegulatoryFrameworkBoxHeading" + i}
                        >
                          {Heading}
                        </span>
                        <p className="RegulatoryFramework_BulletPoints">
                          {/* {" "}
                          {BulletPoints}{" "} */}
                          <Analysis
                            trends={{ analysis: Box.analysis }}
                            fromRegulatoryFrameWork
                            index={i}
                          />
                        </p>
                      </div>
                    );
                  })}
                </div>

                {ReturnTitleAndBulletPoints(CountryData.data)}
              </>
            );
          })}
        </div>
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Footer slug={slug} slideNo={slideNo} slideNoElementId={"27"} reseller={this.state.reseller}/>
        ) : null}
      </RegulatoryFrameworkV2Wrapper>
    );
  }
}

export default RegulatoryFramework;
