import React from "react";

const DataHiddenStyle = {
  display: "flex",
  flexGrow: "1",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  color: "#2E538A",
  fontSize: "24px",
  lineHeight: "65.57px",
  fontFamily: "Avenir",
  fontWeight: "800",
  fontStyle: "normal",
  background: "#FFFFFF",
  border: "2px solid #E5E5E5",
  boxSizing: "border-box",
  zIndex: "999",
};

export default function DataHidden(props) {
  return <div style={DataHiddenStyle}>Data Hidden from View</div>;
}
