import React from "react";
import Footer from "../components/Footer";
import Pie from "../graphs/pie/Main";
import { Typography,Tabs, Radio, Button, notification, Spin  } from "antd";
import Header from "../components/Header";
import Analysis from "../components/Analysis";
import Graph from "../components/Graphs";
import Editor from "../components/Editor";
import axios from "axios";
import config from "../config/Api";

import {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId
} from "../reducersAndActions/Actions";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../Common/withWindowSizeHOC";

var Base64 = require("js-base64").Base64;
const { TabPane } = Tabs;


class MarketShareAnalysis extends React.Component {
  state = {
    graphFooterText: " ",
    data: this.props.data ? this.props.data : {},
    tabPosition: "top",
    editor_active_tab: "analysis",
    save_draft_loader: false,
    direct_publish_loader: false,
    reseller: false
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
    if(nextProps.data && nextProps.data.meta) {
      if(nextProps.data.meta.sample && nextProps.data.meta.reseller ) {
        this.setState({ reseller: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.title != this.props.data.title) {
      this.setState({
        data: this.props.data,
      });
      if(this.props.data && this.props.data.meta) {
        if(this.props.data.meta.sample && this.props.data.meta.reseller ) {
          this.setState({ reseller: true });
        }
      }
    }
    if (this.props.draft_drawer_visible !== prevProps.draft_drawer_visible) {
      if (this.props.draft_drawer_visible) {
        // console.log("perform api call");
        this.props.getDrafts(this.props.data.data.parent_id, "marketShares");
      }
    }

    if (
      this.props.published_drawer_visible !== prevProps.published_drawer_visible
    ) {
      if (this.props.published_drawer_visible) {
        // console.log("perform api call fromtype overview");
        this.props.getPublishedVersions(this.props.data.data.parent_id);
      }
    }


    if ((this.props.edit_mode !== prevProps.edit_mode)) {
      this.setState({
        editor_active_tab: 'analysis'
      })
    }

  }

  editorTabChange = (tab) => {
    this.setState({
      editor_active_tab: tab,
    });
  };

  handleSaveDraft = () => {
    this.setState({
      save_draft_loader: true,
    });
    // console.log("save draft button clicked ; perform save draft api call");
    const data = {
      parent_id: this.props.data.data.parent_id,
      type: "marketShares",
      analysis: this.props.draft_data_analysis,
      source: this.props.draft_data_sources,
      stage: this.props.draft_versions.stage ? this.props.draft_versions.stage : "INITIAL"
    };
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/drafts`,
      data: data,
    })
      .then((response) => {
        this.setState({
          save_draft_loader: false,
        });
        // console.log(response, "RESP");
        if (response.status === 200 || response.status === 201) {
          this.props.setDraftId(null)
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          save_draft_loader: false,
        });
        if (error.response) {
          notification.error({
            message: 'Error',
            description: error.response.data.message
          })
        }
      });
  };

  handleDirectPublish = () => {
    this.setState({
      direct_publish_loader: true,
    });
    // console.log("publish direct");
    const data = {
      parent_id: this.props.parent_id,
      analysis: this.props.draft_data_analysis,
      source: this.props.draft_data_sources,
      directPublished: true,
      type: "marketShares",
      stage: "PUBLISHED",
      cacheId: this.props.cache_id,
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions
    };
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/directPublish`,
      data: data,
    })
      .then((response) => {
        this.props.handleSwitchchange(false);
        this.setState({
          direct_publish_loader: false,
        });
        // console.log(response, "RESP");
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          direct_publish_loader: false,
        });
        if (error.response) {
          notification.error({
            message: 'Error',
            description: error.response.data.message
          })
        }
      });
  };

  handleEditoverlayClick = () => {
    this.props.setNoDraftsFound(false);
    this.props.setDraftStage('INITIAL');
  }

  render() {
    const { section_id, subtitle, slug, data, title } = this.props.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    const { tabPosition } = this.state;

    let tabsElement = (<Tabs
      activeKey={this.state.editor_active_tab}
      tabPosition={tabPosition}
      onChange={this.editorTabChange}
      tabBarExtraContent={
        <div style={{ display: "flex", gap: "20px" }}>
          {this.props.ACTIVE_TAB === 'drafts' ?
            <Button
              type="primary"
              onClick={this.handleSaveDraft}
              loading={this.state.save_draft_loader}
              disabled={this.props.character_count_error}
            >
              Save
            </Button> : ''}
          {this.props.ACTIVE_TAB === 'published' ? <Button
            type="primary"
            onClick={this.handleDirectPublish}
            loading={this.state.direct_publish_loader}
          disabled={this.props.character_count_error}

          >
            DIRECT PUBLISH
          </Button> : ''}


        </div>
      }
      type="card"
      style={{ height: "100%" }}
    >
      <TabPane tab="Analysis" key="analysis" forceRender={true}>
        {this.props.specific_draft_loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} /> :
          <Analysis
            trends={this.state.data.data}
            edit_mode={this.props.edit_mode}
            section_id={`${section_id}-analysis`}
          />}
      </TabPane>
      <TabPane tab="Sources" key="sources" forceRender={true}>
        {/* Please enter list of Sources */}
        {this.props.specific_draft_loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} /> :
          <Editor
            section_id={`${section_id}-source`}
            sources={this.props.data.source}
          />}
      </TabPane>
    </Tabs>)

    return (
      <>
        <div className="page" id={pageId}>
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Header
              slideTitle={this.props.data.type}
              style={{ height: "10%" }}
            />
          ) : null}

          <div className="marketshareanalysis container" style={{ display:"flex", flexDirection:"column", height: this.props.fromInterface ? 'auto' : '626px' }}>
            <h2 className="heading">{title}</h2>
            <div className="marketshareanalysis-main main-container">
              {data.analysis.length || this.props.fromInterface ? (
                <div
                  className="main-content-left"
                  style={{ display: "flex", flexDirection: "column", height:520 }}
                >

                          {this.props.edit_mode ? (
                          this.props.no_drafts_found && this.props.ACTIVE_TAB === 'drafts' ?
                            <div style={{ border: '1px solid grey', padding: '20px' }} className='no-drafts-overlay'>
                              <p>There is nothing in draft right now</p>
                              <Button onClick={this.handleEditoverlayClick}>Edit</Button>
                            </div>
                            : this.props.initial_draft_versions_loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                              : tabsElement

                        ) : (
                          <Analysis
                            trends={this.state.data.data}
                            edit_mode={this.props.edit_mode}
                            fromInterface={this.props.fromInterface}
                          />
                        )}
                </div>
              ) : null}

              {data.graph_data.data && data.graph_data.data.length ? (
                <div className="main-content-right">
                  {/* <h3 className="subheadings">{data.graph_data.heading}</h3> */}
              
                  {data.graph_data.data && data.graph_data.data != "hidden" ? (
                    <Graph
                      headers={data.graph_data.heading}
                      data={data.graph_data.data}
                      totalData={data.graph_data}
                      graphType={data.graph_data.graphType}
                      height={460}
                      legends_zero_elimination={false}
                      width={this.props.fromInterface ? 400 : 550}
                      // chartHeadingGap={-180}
                      // yHeadingMargin={25}
                      chartHeadingGap={data.graph_data.heading.length > 60 ? -160 : -180}
                   yHeadingMargin={data.graph_data.heading.length > 60 ? 30 : 15}
                      xHeadingMargin={0}
                      legendsYPosition={10}
                      dataHidden={
                        data.graph_data.dataHidden &&
                        data.graph_data.dataHidden == true
                          ? true
                          : false
                      }
                    />
                  ) : null}
                  </div>
                
              ) : null}
            </div>
          </div>
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              slideNoElementId={slideNoElementId}
              reseller={this.state.reseller}
            />
          ) : null}
        </div>
      </>
    );
  }
}

// export default MarketShareAnalysis;

function mapStateToPros(state) {
  return {
    draft_data_analysis: state.draft_data_analysis,
    draft_data_sources: state.draft_data_sources,
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_versions: state.draft_versions,
    no_drafts_found: state.no_drafts_found,
    user_edit_access: state.user_edit_access,
    specific_draft_loading: state.specific_draft_loading,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    TOC_DATA: state.TOC_DATA,
    cache_id: state.cache_id,
    parent_id: state.parent_id,
    character_count_error: state.character_count_error
  };
}
const mapDispatchToProps = {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(MarketShareAnalysis))
);
