import React, { useEffect, useRef, useState } from 'react';
import EditorJs from 'react-editor-js';
import {EDITOR_JS_TOOLS} from '../constants';
import { message, Button} from "antd";
import DataHidden from "./DataHidden";
import { setLinkCounter,setDraftDataAnalysis,setCharacterCountError } from "../reducersAndActions/Actions";
import { connect } from "react-redux";
import parse from 'html-react-parser';
import isEqual from 'react-fast-compare';
import { InfoCircleOutlined } from '@ant-design/icons';




const Analysis= (props) => {
  const instanceRef = useRef(null);
  const [editorState, seteditorState] = useState([])
  const [init, setInit] = useState(false);
  const [skip, setSkip] = useState(false);

 useEffect(() => {
   if(instanceRef && instanceRef.current){
     instanceRef.current.isReady.then(()=>{
       instanceRef.current.clear();
       setInit(true);
     }).catch(()=>{})
   }
}, [JSON.stringify(props.trends.referenced_analysis)]);

const handleSkip =()=>{
  setSkip(true);
  message.destroy()
}

  async function handleSave() {
    setInit(false);
    instanceRef.current
      .save()
      .then((outputData) => {
        let result  = outputData.blocks[0].data.items.reduce((sum,ele) => sum + ele.length, 0);
        if(result>920){
          props.setCharacterCountError(true);
          if(!skip){
            message.destroy()
            message.warning({
              message: 'Info',
              content: <div><InfoCircleOutlined/> Character count is more than 920</div>,
              icon: <Button onClick={handleSkip} type="primary">Don't show again</Button>,
              duration: 5,
            })
          }
        } else {
          props.setCharacterCountError(false);
        }
        props.setDraftDataAnalysis(outputData.blocks[0].data.items);
        seteditorState(outputData.blocks[0].data.items)
      })
      .catch((error) => {
        console.log('Saving failed: ', error);
      });
  }

      let removeHiddenDuplicates = [
      ...new Map(
        props.trends.analysis.map((item) => [item["text"], item])
      ).values(),
    ];

    //DOES Analysis array contain hidden items?
    let hiddenItemExists = props.trends.analysis.some(
      (item) => item.text === "hidden"
    );


  let sectionID = props.section_id;
  return (
    <div
    style={{
      flexGrow: "1",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    }}
  >
    {props.fromRegulatoryFrameWork && hiddenItemExists ? (
      <DataHidden />
    ) : (
      props.edit_mode ? (
      <EditorJs
      holder='analysis-editor'
        tools={EDITOR_JS_TOOLS}
        
        enableReInitialize={init}
        instanceRef={(instance) => (instanceRef.current = instance)}
        data={{
          blocks: [
            {
              type: "list",
              data: {
                style: "unordered",
                items: props.trends.referenced_analysis && props.trends.referenced_analysis.length ? props.trends.referenced_analysis.map(
                  (item, index) => item.text
                ) : []
              },
            },
          ],
          version: "2.12.4",
        }}
        onCompareBlocks={(newData, oldData) =>   isEqual(newData, oldData)}

        onChange={handleSave}

      >
          <div 
        id='analysis-editor'
      >        
      </div>
      </EditorJs>
      ): (
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginBottom: "0",
        }}
      >
        {removeHiddenDuplicates && removeHiddenDuplicates.length ? removeHiddenDuplicates.map(
          ({ text, superScript, hyperLink, counter }, index) => {
            return text && text.toLowerCase() !== "hidden" ? (
              <li key={index}>
                <span>
                 {parse(`${text}`)}{" "}
                 {hyperLink.length ? (
                   <sup>
                      [<a href={"#" + hyperLink}>{counter}</a>]
                    </sup>
                  ) : null}
                </span>
              </li>
            ) : text && text.toLowerCase() === "hidden" ? (
              <DataHidden />
            ) : null;
          }
        ):''}
      </ul>
      )
    )}
  </div>
  );
};


function mapStateToPros(state) {
  return {
    ANALYSIS_LINK_COUNTER: state.ANALYSIS_LINK_COUNTER,
  };
}
const mapDispatchToProps = {
  setLinkCounter,
  setDraftDataAnalysis,
  setCharacterCountError
};
export default connect(mapStateToPros, mapDispatchToProps)(Analysis);