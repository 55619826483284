import React, { Component } from "react";
import { useState, useEffect, useContext, createContext, useMemo } from "react";
import moment from 'moment';
// import DataGrid from "react-data-grid";
import {
  Button,
  Select,
  Radio,
  Space,
  Spin,
  Tooltip,
  notification,
  Input,
  Checkbox
} from "antd";
import CellEditor from "../components/CellEditor";
import DateEditor from "../components/DateEditor";
import {
  setCompanyProfileData,
  setCompanyType,
  setUpdateCompanyProfileData,
  setFetchFulldata
} from "../reducersAndActions/Actions";

import { countries, listOfRegionFilters } from "../countries";

import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";

import { useFocusRef } from "../hooks/useFocusRef";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import axios from "axios";
import config from "../config/Api";
import responsiveObserve from "antd/lib/_util/responsiveObserve";
import DropDownEditor from "./DropDownEditor";

const { Option } = Select;

const options = [
  { label: "Market Company", value: "marketCompany" },
  { label: "Global Company", value: "globalCompany" },
  { label: "Product & Services", value: "productServices" },
  { label: "Recent Development", value: "recentDevelopment" },
];

const FilterContext = createContext(undefined);

function inputStopPropagation(event) {
  if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
    event.stopPropagation();
  }
}

function CompanyProfileEditing(props) {
  const [rows, setRows] = useState([]);
  const [saveLoader, setSaveLoader] = useState([]);
  const [stageChangeLoader, setStageChangeLoader] = useState([]);
  const [directPublishLoader, setDirectPublishLoader] = useState([]);
  const [specificVersionLoader, setSpecificVersionLoader] = useState([]);


  const [filters, setFilters] = useState({
    company_name: "",
    company_id: "",
    region: "",
    country: [],
    CountriesIncluded:[],
    CountriesExcluded:[],
    end_user: "",
    vehicle_type: "",
    product_type: "",
    product_grade: "",
  });

  const handleDraftVersionSelect = (draft_id, row) => {
    let loader_array_copy = specificVersionLoader.slice();
    loader_array_copy.push(row._id);
    setSpecificVersionLoader(loader_array_copy);
    //specific draft api call
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/drafts?parent_id=${row._id}&type=${props.companyType}&draftId=${draft_id}`,
    })
      .then((response) => {
        setSpecificVersionLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: "Draft version fetched successfully",
          });
          let myArray = cloneDeep(props.company_profile_data);

          let objIndex = myArray.findIndex(
            (obj) => obj._id == response.data._id
          );

          console.log(objIndex, 'objIndex');
          let drafts_obj = myArray[objIndex].drafts; // Copy drafts key because its not coming from response
          myArray[objIndex] = response.data;
          myArray[objIndex].drafts = drafts_obj; // Append the stored drafts key
          myArray[objIndex].latest_version_id = draft_id;
          props.setCompanyProfileData(myArray);
          console.log(myArray, 'MyArray')
        }
      })
      .catch((error) => {
        setSpecificVersionLoader([]);
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };

  const handlePublishedVersionSelect = (publish_id, row) => {
    let loader_array_copy = specificVersionLoader.slice();
    loader_array_copy.push(row._id);
    setSpecificVersionLoader(loader_array_copy);
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/publish?publish_id=${publish_id}`,
    })
      .then((response) => {
        setSpecificVersionLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: "Published version fetched successfully",
          });

          let myArray = cloneDeep(props.company_profile_data);

          let objIndex = myArray.findIndex(
            (obj) => obj._id == response.data.parent_id
          );

          console.log(objIndex, 'objIndex');

          let published_versions_obj = myArray[objIndex].published; // Copy published versions key because its not coming from response
          myArray[objIndex] = renameKeys(response.data, {parent_id: "_id"}) // rename response parent_id key to _id
          myArray[objIndex].published = published_versions_obj; // Append the stored published versions key
          myArray[objIndex].latest_version_id = publish_id;
          props.setCompanyProfileData(myArray);
          console.log(myArray, 'MyArray')
        }
      })
      .catch((error) => {
        setSpecificVersionLoader([]);
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };

  const handleStageChange = (stage, row) => {

    let loader_array_copy = stageChangeLoader.slice();
    loader_array_copy.push(row._id);
    setStageChangeLoader(loader_array_copy);

    const data = {
      type: props.companyType,
      stage: stage,
      parent_id: row._id,
      draftId: row.latest_version_id,
    };

    /* CHECK IF USER HAS ACCESS */
      if(props.USER_DATA.obj.access.move.includes(stage || 'INITIAL')){
      axios({
        method: "PATCH",
        url: `${config.api.base_url}/api/editing/drafts`,
        data: data,
      })
        .then((response) => {
          if(stage !== 'PUBLISHED'){
            //Toggle to edit mode to fetch the updated stage except for PUBLISHED stage
            props.setUpdateCompanyProfileData(!props.update_company_profile_data);
          } else {
            props.handleSwitchchange(false);
          }
          setStageChangeLoader([]);
          if (response.status === 200 || response.status === 201) {
            // throw new Error('something went wrong');
            notification.success({
              message: "Info",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          setStageChangeLoader([]);
          if (error.response) {
            notification.error({
              message: "Error",
              description: error.response.data.message,
            });
          }
        });
    } else {
      notification.warning({
        message: "Info",
        description: "You dont have access to move stage",
      });
    }
  };

  const handleMarketCompanySave = (row, company_profile_dimensions) => {
    let loader_array_copy = saveLoader.slice();
    loader_array_copy.push(row._id);
    setSaveLoader(loader_array_copy);
    const data = {
      parent_id: row._id,
      type: "marketCompany",
      obj: {
        _id: row._id,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        company_id: row.company_id,
        company_name: row.company_name,
        company_highlights: [
          row.company_highlights_1,
          row.company_highlights_2,
          row.company_highlights_3,
          row.company_highlights_4,
        ].filter(Boolean),
        key_information: [
          row.key_information_1,
          row.key_information_2,
          row.key_information_3,
          row.key_information_4,
        ].filter(Boolean),
        company_vertical: row.company_vertical,
        market_segment: row.market_segment,
        segmental_revenue: row.segmental_revenue,
        "Core Solutions/Product Offerings": [
          row.products_1,
          row.products_2,
          row.products_3,
          row.products_4,
        ].filter(Boolean),
      },
    };
    saveDraftAxiosCall(data, row._id);
  };

  const handleGlobalCompanySave = (row) => {
    let loader_array_copy = saveLoader.slice();
    loader_array_copy.push(row._id);
    setSaveLoader(loader_array_copy);
    const data = {
      parent_id: row._id,
      type: "globalCompany",
      obj: {
        _id: row._id,
        company_id: row.company_id,
        company_name: row.company_name,
        core_business_segments: row.core_business_segment,
        company_overview: [
          row.company_overview_1,
          row.company_overview_2,
          row.company_overview_3,
          row.company_overview_4,
        ].filter(Boolean),
        employees: row.employees,
        financial_year: row.financial_year,
        founded: row.founded,
        headquarters: row.headquarters,
        revenue: row.revenue,
        unit: row.unit,
        source: row.source,
      },
    };
    saveDraftAxiosCall(data);
  };

  const handleRecentDevelopmentSave = (row, company_profile_dimensions) => {
    let loader_array_copy = saveLoader.slice();
    loader_array_copy.push(row._id);
    setSaveLoader(loader_array_copy);
    const data = {
      parent_id: row._id,
      type: "recentDevelopment",
      obj: {
        _id: row._id,
        company_id: row.company_id,
        date: row.date,
        description: row.description,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        type: row.type,
        company_name: row.company_name,
        CountriesIncluded: row.CountriesIncluded,
        CountriesExcluded: row.CountriesExcluded,
      },
    };
    saveDraftAxiosCall(data);
  };

  const handleProductServicesSave = (row, company_profile_dimensions) => {
    let loader_array_copy = saveLoader.slice();
    loader_array_copy.push(row._id);
    setSaveLoader(loader_array_copy);
    const data = {
      parent_id: row._id,
      type: "productServices",
      obj: {
        _id: row._id,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        company_id: row.company_id,
        company_name: row.company_name,
        first_level_brand: row.first_level_brand,
        description: row.description,
        source: row.source,
        CountriesIncluded: row.CountriesIncluded,
        CountriesExcluded: row.CountriesExcluded,
      },
    };
    saveDraftAxiosCall(data);
  };

  const saveDraftAxiosCall = (data) => {
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/drafts`,
      data: data,
    })
      .then((response) => {
        props.setUpdateCompanyProfileData(!props.update_company_profile_data);
        setSaveLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        setSaveLoader([]);
        notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      });
  };

  const directPublishAxiosCall = (data) => {
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/directPublish`,
      data: data,
    })
      .then((response) => {
        props.handleSwitchchange(false);
        setDirectPublishLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        setDirectPublishLoader([]);
        notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      });
  };

  const handleMarketCompanyDirectPublish = (
    row,
    company_profile_dimensions
  ) => {
    let loader_array_copy = directPublishLoader.slice();
    loader_array_copy.push(row._id);
    setDirectPublishLoader(loader_array_copy);

    const data = {
      parent_id: row._id,
      type: "marketCompany",
      stage: "PUBLISHED",
      directPublished: true,
      obj: {
        _id: row._id,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        company_id: row.company_id,
        company_name: row.company_name,
        company_highlights: [
          row.company_highlights_1,
          row.company_highlights_2,
          row.company_highlights_3,
          row.company_highlights_4,
        ].filter(Boolean),
        key_information: [
          row.key_information_1,
          row.key_information_2,
          row.key_information_3,
          row.key_information_4,
        ].filter(Boolean),
        company_vertical: row.company_vertical,
        market_segment: row.market_segment,
        segmental_revenue: row.segmental_revenue,
        "Core Solutions/Product Offerings": [
          row.products_1,
          row.products_2,
          row.products_3,
          row.products_4,
        ].filter(Boolean),
      },
    };

    directPublishAxiosCall(data);
  };

  const handleGlobalCompanyDirectPublish = (row) => {
    let loader_array_copy = directPublishLoader.slice();
    loader_array_copy.push(row._id);
    setDirectPublishLoader(loader_array_copy);

    const data = {
      parent_id: row._id,
      type: "globalCompany",
      stage: "PUBLISHED",
      directPublished: true,
      obj: {
        _id: row._id,
        company_id: row.company_id,
        company_name: row.company_name,
        core_business_segments: row.core_business_segment,
        company_overview: [
          row.company_overview_1,
          row.company_overview_2,
          row.company_overview_3,
          row.company_overview_4,
        ].filter(Boolean),
        employees: row.employees,
        financial_year: row.financial_year,
        founded: row.founded,
        headquarters: row.headquarters,
        revenue: row.revenue,
        unit: row.unit,
        source: row.source,
      },
    };

    directPublishAxiosCall(data);
  };

  const handleRecentDevelopmentDirectPublish = (
    row,
    company_profile_dimensions
  ) => {
    let loader_array_copy = directPublishLoader.slice();
    loader_array_copy.push(row._id);
    setDirectPublishLoader(loader_array_copy);

    const data = {
      parent_id: row._id,
      type: "recentDevelopment",
      stage: "PUBLISHED",
      directPublished: true,
      obj: {
        _id: row._id,
        company_id: row.company_id,
        description: row.description,
        date: row.date,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        type: row.type,
        company_name: row.company_name,
        CountriesIncluded: row.CountriesIncluded,
        CountriesExcluded: row.CountriesExcluded,
      },
    };

    directPublishAxiosCall(data);
  };

  const handleProductServicesDirectPublish = (
    row,
    company_profile_dimensions
  ) => {
    let loader_array_copy = directPublishLoader.slice();
    loader_array_copy.push(row._id);
    setDirectPublishLoader(loader_array_copy);

    const data = {
      parent_id: row._id,
      type: "productServices",
      stage: "PUBLISHED",
      directPublished: true,
      obj: {
        _id: row._id,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        company_id: row.company_id,
        company_name: row.company_name,
        first_level_brand: row.first_level_brand,
        description: row.description,
        source: row.source,
        CountriesIncluded: row.CountriesIncluded,
        CountriesExcluded: row.CountriesExcluded,
      },
    };

    directPublishAxiosCall(data);
  };

  function EmptyRowsRenderer() {
    return (
      <div style={{ textAlign: "center" }}>
        No Data Found
      </div>
    );
  }

  function rowKeyGetter(row) {
    return row.id;
  }

  function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }

  const columns = {
    marketCompany: [
      ...Object.keys(props.company_profile_dimensions).map((dimension) => {
        return {
          key: dimension,
          name: startCase(dimension),
          // editor: CellEditor,
          width: dimension === "country" ? 200 : 100,
          resizable: true,
          headerRenderer: (p) => {
            return (
              <FilterRenderer {...p}>
                {({ filters, ...rest }) => {
                  if (p.column.key !== "country") {
                    return (
                      <Input
                        allowClear
                        {...rest}
                        className="filterClassname"
                        value={filters[p.column.key]}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            [p.column.key]: e.target.value,
                          })
                        }
                        onKeyDown={inputStopPropagation}
                      />
                    );
                  } else {
                    return (
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Select Country"
                        value={filters.country || undefined}
                        maxTagCount="responsive"
                        onChange={(value) =>
                          setFilters({
                            ...filters,
                            country: value,
                          })
                        }
                      >
                        {countries.map((item) => (
                          <Option key={item}>{item}</Option>
                        ))}
                      </Select>
                    );
                  }
                }}
              </FilterRenderer>
            );
          },
        };
      }),
      {
        key: "company_id",
        name: "Company ID",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "company_name",
        name: "Company Name",
        width: 150,
        editor: CellEditor,
        frozen: true,
        resizable: true,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <Input
                allowClear
                {...rest}
                className="filterClassname"
                value={filters.company_name}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    company_name: e.target.value,
                  })
                }
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      {
        key: "company_highlights_1",
        name: "Company Highlights 1",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.company_highlights_1}>
            <span>{row.company_highlights_1}</span>
          </Tooltip>
        ),
      },
      {
        key: "company_highlights_2",
        name: "Company Highlights 2",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.company_highlights_2}>
            <span>{row.company_highlights_2}</span>
          </Tooltip>
        ),
      },
      {
        key: "company_highlights_3",
        name: "Company Highlights 3",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.company_highlights_3}>
            <span>{row.company_highlights_3}</span>
          </Tooltip>
        ),
      },
      {
        key: "company_highlights_4",
        name: "Company Highlights 4",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.company_highlights_4}>
            <span>{row.company_highlights_4}</span>
          </Tooltip>
        ),
      },
      {
        key: "key_information_1",
        name: "Key Information 1",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.key_information_1}>
            <span>{row.key_information_1}</span>
          </Tooltip>
        ),
      },
      {
        key: "key_information_2",
        name: "Key Information 2",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.key_information_2}>
            <span>{row.key_information_2}</span>
          </Tooltip>
        ),
      },
      {
        key: "key_information_3",
        name: "Key Information 3",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.key_information_3}>
            <span>{row.key_information_3}</span>
          </Tooltip>
        ),
      },
      {
        key: "key_information_4",
        name: "Key Information 4",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.key_information_4}>
            <span>{row.key_information_4}</span>
          </Tooltip>
        ),
      },
      {
        key: "company_vertical",
        name: "Company Vertical",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "market_segment",
        name: "Market Segment",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "segmental_revenue",
        name: "Segmental Revenue",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "products_1",
        name: "Core Solutions/Product Offerings 1",
        width: 350,
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "products_2",
        name: "Core Solutions/Product Offerings 2",
        width: 350,
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "products_3",
        name: "Core Solutions/Product Offerings 3",
        width: 350,
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "products_4",
        name: "Core Solutions/Product Offerings 4",
        width: 350,
        editor: CellEditor,
        resizable: true,
      },
    ],
    globalCompany: [
      {
        key: "company_id",
        name: "Company ID",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "company_name",
        name: "Company Name",
        width: 150,
        editor: CellEditor,
        frozen: true,
        resizable: true,
        headerCellClass: "filter-cell",
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <Input
                allowClear
                {...rest}
                className="filterClassname"
                value={filters.company_name}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    company_name: e.target.value,
                  })
                }
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      {
        key: "core_business_segment",
        name: "Core Business Segment",
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.core_business_segment}>
            <span>{row.core_business_segment}</span>
          </Tooltip>
        ),
      },
      {
        key: "company_overview_1",
        name: "Company Overview 1",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.company_overview_1}>
            <span>{row.company_overview_1}</span>
          </Tooltip>
        ),
      },
      {
        key: "company_overview_2",
        name: "Company Overview 2",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.company_overview_2}>
            <span>{row.company_overview_2}</span>
          </Tooltip>
        ),
      },
      {
        key: "company_overview_3",
        name: "Company Overview 3",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.company_overview_3}>
            <span>{row.company_overview_3}</span>
          </Tooltip>
        ),
      },
      {
        key: "company_overview_4",
        name: "Company Overview 4",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.company_overview_4}>
            <span>{row.company_overview_4}</span>
          </Tooltip>
        ),
      },
      {
        key: "employees",
        name: "Employees",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "financial_year",
        name: "Financial Year",
        editor: CellEditor,
        resizable: true,
      },
      { key: "founded", name: "Founded", editor: CellEditor, resizable: true },
      {
        key: "headquarters",
        name: "Headquarters",
        editor: CellEditor,
        resizable: true,
      },
      { key: "revenue", name: "Revenue", editor: CellEditor, resizable: true },
      { key: "unit", name: "Unit", editor: CellEditor, resizable: true },
      { key: "source", name: "Source", editor: CellEditor, resizable: true },
    ],
    recentDevelopment: [
      ...Object.keys(props.company_profile_dimensions)
        .filter((item, index, array) => !["country", "region"].includes(item))
        .map((dimension) => {
          return {
            key: dimension,
            name: startCase(dimension),
            // editor: CellEditor,
            width: 100,
            resizable: true,
            headerRenderer: (p) => {
              return (
                <FilterRenderer {...p}>
                  {({ filters, ...rest }) => {
                    if (p.column.key !== "country") {
                      return (
                        <Input
                          allowClear
                          {...rest}
                          className="filterClassname"
                          value={filters[p.column.key]}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              [p.column.key]: e.target.value,
                            })
                          }
                          onKeyDown={inputStopPropagation}
                        />
                      );
                    } else {
                      return (
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Select Country"
                          value={filters.country || undefined}
                          maxTagCount="responsive"
                          onChange={(value) =>
                            setFilters({
                              ...filters,
                              country: value,
                            })
                          }
                        >
                          {countries.map((item) => (
                            <Option key={item}>{item}</Option>
                          ))}
                        </Select>
                      );
                    }
                  }}
                </FilterRenderer>
              );
            },
          };
        }),
      {
        key: "company_id",
        name: "Company ID",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "company_name",
        name: "Company Name",
        width: 150,
        editor: CellEditor,
        frozen: true,
        resizable: true,
        headerCellClass: "filter-cell",
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <Input
                allowClear
                {...rest}
                className="filterClassname"
                value={filters.company_name}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    company_name: e.target.value,
                  })
                }
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      { key: "type", name: "Type", editor: CellEditor, resizable: true },
      {
        key: "description",
        name: "Description",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.description}>
            <span>{row.description}</span>
          </Tooltip>
        ),
      },
      {
        key: "CountriesIncluded",
        name: "Countries Included",
        width: 200,
        editor: DropDownEditor,
        formatter({row}) {
          return <>{row.CountriesIncluded}</>;
        },
        editorOptions: {
          editOnClick: true
        },
        resizable: true,
        headerRenderer: (p) => {
          return (
            <FilterRenderer {...p}>
              {({ filters, ...rest }) => {
                  return (
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Country"
                      value={filters.CountriesIncluded || undefined}
                      maxTagCount="responsive"
                      onChange={(value) =>
                        setFilters({
                          ...filters,
                          CountriesIncluded: value,
                        })
                      }
                    >
                      {listOfRegionFilters.map((item) => (
                        <Option key={item}>{item}</Option>
                      ))}
                    </Select>
                  );
              }}
            </FilterRenderer>
          );
        },
      },
      {
        key: "CountriesExcluded",
        name: "Countries Excluded",
        width: 200,
        formatter({row}) {
          return <>{row.CountriesExcluded}</>;
        },
        editorOptions: {
          editOnClick: true
        },
        editor: DropDownEditor,
        resizable: true,
        headerRenderer: (p) => {
          return (
            <FilterRenderer {...p}>
              {({ filters, ...rest }) => {
                  return (
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Country"
                      value={filters.CountriesExcluded || undefined}
                      maxTagCount="responsive"
                      onChange={(value) =>
                        setFilters({
                          ...filters,
                          CountriesExcluded: value,
                        })
                      }
                    >
                      {listOfRegionFilters.map((item) => (
                        <Option key={item}>{item}</Option>
                      ))}
                    </Select>
                  );
              }}
            </FilterRenderer>
          );
        },
      },
      {
        key: "date",
        name: "Date",
        width: 200,
        editor: DateEditor,
        cellClass(row) {
          return "centerClassname";
        },

      }
    ],
    productServices: [
      ...Object.keys(props.company_profile_dimensions)
        .filter((item, index, array) => !["country", "region"].includes(item))
        .map((dimension) => {
          return {
            key: dimension,
            name: startCase(dimension),
            // editor: CellEditor,
            width: 100,
            resizable: true,
            headerRenderer: (p) => {
              return (
                <FilterRenderer {...p}>
                  {({ filters, ...rest }) => {
                    if (p.column.key !== "country") {
                      return (
                        <Input
                          allowClear
                          {...rest}
                          className="filterClassname"
                          value={filters[p.column.key]}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              [p.column.key]: e.target.value,
                            })
                          }
                          onKeyDown={inputStopPropagation}
                        />
                      );
                    } else {
                      return (
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Select Country"
                          value={filters.country || undefined}
                          maxTagCount="responsive"
                          onChange={(value) =>
                            setFilters({
                              ...filters,
                              country: value,
                            })
                          }
                        >
                          {countries.map((item) => (
                            <Option key={item}>{item}</Option>
                          ))}
                        </Select>
                      );
                    }
                  }}
                </FilterRenderer>
              );
            },
          };
        }),
      {
        key: "company_id",
        name: "Company ID",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "company_name",
        name: "Company Name",
        width: 150,
        editor: CellEditor,
        frozen: true,
        resizable: true,
        headerCellClass: "filter-cell",
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <Input
                allowClear
                {...rest}
                className="filterClassname"
                value={filters.company_name}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    company_name: e.target.value,
                  })
                }
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      {
        key: "first_level_brand",
        name: "First Level Brand",
        editor: CellEditor,
        resizable: true,
      },
      {
        key: "description",
        name: "Description",
        width: 350,
        editor: CellEditor,
        resizable: true,
        formatter: ({ row }) => (
          <Tooltip title={row.description}>
            <span>{row.description}</span>
          </Tooltip>
        ),
      },
      { key: "source", name: "Source", editor: CellEditor, resizable: true },
      {
        key: "CountriesIncluded",
        name: "Countries Included",
        width: 200,
        editor: DropDownEditor,
        formatter({row}) {
          return <>{row.CountriesIncluded}</>;
        },
        editorOptions: {
          editOnClick: true
        },
        resizable: true,
        headerRenderer: (p) => {
          return (
            <FilterRenderer {...p}>
              {({ filters, ...rest }) => {
                  return (
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Country"
                      value={filters.CountriesIncluded || undefined}
                      maxTagCount="responsive"
                      onChange={(value) =>
                        setFilters({
                          ...filters,
                          CountriesIncluded: value,
                        })
                      }
                    >
                      {listOfRegionFilters.map((item) => (
                        <Option key={item}>{item}</Option>
                      ))}
                    </Select>
                  );
              }}
            </FilterRenderer>
          );
        },
      },
      {
        key: "CountriesExcluded",
        name: "Countries Excluded",
        width: 200,
        editor: DropDownEditor,
        formatter({row}) {
          return <>{row.CountriesExcluded}</>;
        },
        editorOptions: {
          editOnClick: true
        },
        resizable: true,
        headerRenderer: (p) => {
          return (
            <FilterRenderer {...p}>
              {({ filters, ...rest }) => {
                  return (
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Country"
                      value={filters.CountriesExcluded || undefined}
                      maxTagCount="responsive"
                      onChange={(value) =>
                        setFilters({
                          ...filters,
                          CountriesExcluded: value,
                        })
                      }
                    >
                      {listOfRegionFilters.map((item) => (
                        <Option key={item}>{item}</Option>
                      ))}
                    </Select>
                  );
              }}
            </FilterRenderer>
          );
        },
      },
    ],
  };

  useEffect(() => {
    props.setCompanyType("marketCompany");
  }, []);

  useEffect(() => {
    setRows(createRows(props.company_profile_data));
  }, [JSON.stringify(props.company_profile_data)]);

  function createRows(data) {
    let company_type = props.companyType;
    let active_tab = props.ACTIVE_TAB;
    if (company_type === "marketCompany") {
      let rows = [];
      rows = data.map((item) => {
        let dimensionObj;
        dimensionObj = item.dimensions;

        let staticObj = {
          _id: item._id,
          company_id: item.company_id,
          company_name: item.company_name,
          company_highlights_1: item.meta.company_highlights[0],
          company_highlights_2: item.meta.company_highlights[1],
          company_highlights_3: item.meta.company_highlights[2],
          company_highlights_4: item.meta.company_highlights[3],
          key_information_1: item.meta.key_information[0],
          key_information_2: item.meta.key_information[1],
          key_information_3: item.meta.key_information[2],
          key_information_4: item.meta.key_information[3],
          company_vertical: item.meta.company_vertical,
          market_segment: item.meta.market_segment,
          segmental_revenue: item.segmental_revenue.value,
          products_1: item.products[0],
          products_2: item.products[1],
          products_3: item.products[2],
          products_4: item.products[3],
          versions: item[active_tab],
          latest_version_id: item.hasOwnProperty("latest_version_id")
            ? item.latest_version_id
            : "",
        };
        return { ...dimensionObj, ...staticObj };
      });
      return rows;
    } else if (company_type === "globalCompany") {
      let rows = [];
      rows = data.map((item) => {
        return {
          _id: item._id,
          company_id: item.company_id,
          company_name: item.company_name,
          core_business_segment: item.core_business_segments,
          company_overview_1: item.analysis[0],
          company_overview_2: item.analysis[1],
          company_overview_3: item.analysis[2],
          company_overview_4: item.analysis[3],
          employees: item.meta.employees,
          financial_year: item.meta.financial_year,
          founded: item.meta.founded,
          headquarters: item.meta.headquarters,
          revenue: item.revenue.value,
          unit: item.revenue.units,
          source: item.revenue.source,
          versions: item[active_tab],
          latest_version_id: item.hasOwnProperty("latest_version_id")
            ? item.latest_version_id
            : "",
        };
      });
      return rows;
    } else if (company_type === "productServices") {
      let rows = [];
      rows = data.map((item) => {
        let dimensionObj;

        dimensionObj = item.dimensions;

        let staticObj = {
          _id: item._id,
          company_id: item.company_id,
          company_name: item.company_name,
          first_level_brand: item.first_level_brand,
          description: item.description,
          source: item.source,
          CountriesIncluded: item.CountriesIncluded,
          CountriesExcluded: item.CountriesExcluded,
          versions: item[active_tab],
          latest_version_id: item.hasOwnProperty("latest_version_id")
            ? item.latest_version_id
            : "",
        };
        return { ...dimensionObj, ...staticObj };
      });
      return rows;
    } else if (company_type === "recentDevelopment") {
      let rows = [];
      rows = data.map((item) => {
        let dimensionObj;

        dimensionObj = item.dimensions;

        let staticObj = {
          _id: item._id,
          company_id: item.company_id,
          company_name: item.company_name,
          type: item.type,
          date: moment(item.date).format('YYYY-MM-DD'),
          description: item.description,
          CountriesIncluded: item.CountriesIncluded,
          CountriesExcluded: item.CountriesExcluded,
          versions: item[active_tab],
          latest_version_id: item.hasOwnProperty("latest_version_id")
            ? item.latest_version_id
            : "",
        };
        return { ...dimensionObj, ...staticObj };
      });
      return rows;
    }
  }

  const filteredRows = useMemo(() => {
    let filtered = rows.filter((r) => {
      return (
        (filters.company_name
          ? r.company_name
              .toLowerCase()
              .includes(filters.company_name.toLowerCase())
          : rows) &&
        (filters.region
          ? r.region.toLowerCase().includes(filters.region.toLowerCase())
          : true) &&
        (filters.country && filters.country.length
          ? filters.country.includes(r.country)
          : true) &&
        (filters.end_user
          ? r.end_user.toLowerCase().includes(filters.end_user.toLowerCase())
          : true) &&
        (filters.vehicle_type
          ? r.vehicle_type
              .toLowerCase()
              .includes(filters.vehicle_type.toLowerCase())
          : true) &&
        (filters.product_type
          ? r.product_type
              .toLowerCase()
              .includes(filters.product_type.toLowerCase())
          : true) &&
        (filters.product_grade
          ? r.product_grade
              .toLowerCase()
              .includes(filters.product_grade.toLowerCase())
          : true)&&
          (filters.CountriesIncluded && filters.CountriesIncluded.length && !filters.CountriesIncluded.includes('All')
            ? r.CountriesIncluded.split(',').some(item=>filters.CountriesIncluded.includes(item))
            : true) &&
            (filters.CountriesExcluded && filters.CountriesExcluded.length && !filters.CountriesExcluded.includes('All')
              ? r.CountriesExcluded.split(',').some(item=>filters.CountriesExcluded.includes(item))
              : true)
      );
    });
    return filtered;
  }, [rows, filters]);


  function handleFill({ columnKey, sourceRow, targetRow }) {
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  function handlePaste({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) {
    const incompatibleColumns = ["email", "zipCode", "date"];
    if (
      sourceColumnKey === "avatar" ||
      ["id", "avatar"].includes(targetColumnKey) ||
      ((incompatibleColumns.includes(targetColumnKey) ||
        incompatibleColumns.includes(sourceColumnKey)) &&
        sourceColumnKey !== targetColumnKey)
    ) {
      return targetRow;
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
  }

  const onChange = (e) => {
    props.setCompanyType(e.target.value);
    props.setFetchFulldata(false);
    //reset Filters
    setFilters({
      company_name: "",
      company_id: "",
      region: "",
      country: [],
      end_user: "",
      vehicle_type: "",
      product_type: "",
      product_grade: "",
      CountriesIncluded:[],
      CountriesExcluded:[]
    });
    setSaveLoader([]);
    setDirectPublishLoader([]);
  };


  let saveButton;
  let directPublishButton;
  if (props.companyType === "marketCompany") {
    saveButton = handleMarketCompanySave;
    directPublishButton = handleMarketCompanyDirectPublish;
  } else if (props.companyType === "globalCompany") {
    saveButton = handleGlobalCompanySave;
    directPublishButton = handleGlobalCompanyDirectPublish;
  } else if (props.companyType === "recentDevelopment") {
    saveButton = handleRecentDevelopmentSave;
    directPublishButton = handleRecentDevelopmentDirectPublish;
  } else if (props.companyType === "productServices") {
    saveButton = handleProductServicesSave;
    directPublishButton = handleProductServicesDirectPublish;
  }

  if (props.ACTIVE_TAB === "drafts") {
    columns[props.companyType].push(
      {
        key: "save",
        name: "Save",
        width: 40,
        formatter: ({ row }) => (
          <Button
            type="primary"
            loading={saveLoader.includes(row._id)}
            onClick={() => saveButton(row, props.company_profile_dimensions)}
          >
            Save
          </Button>
        ),
        cellClass(row) {
          return "centerClassname";
        },
      },
      {
        key: "stage",
        name: "Stage",
        width: 160,
        formatter: ({ row }) => (
          <Select
            value={
              row.versions &&
              Object.keys(row.versions) &&
              row.versions.hasOwnProperty("stage")
                ? row.versions.stage
                : "NONE"
            }
            style={{width: '90%'}}
            className={row.versions &&
            Object.keys(row.versions) &&
            row.versions.hasOwnProperty("stage")
              ? `${row.versions.stage}-stage-color stage-dropdown-color black-loader` : 'draft-tab-dropdown black-loader'}
            loading={stageChangeLoader.includes(row._id)}
            placeholder="Select Stage"
            onChange={(value) => handleStageChange(value, row)}
          >
            {props.USER_DATA &&
              props.USER_DATA.obj &&
              props.USER_DATA.obj.access &&
              props.USER_DATA.obj.access.move &&
              props.USER_DATA.obj.access.move.length &&
              props.USER_DATA.obj.access.move.map((item) => {
                return <Option value={item}>{item}</Option>;
              })}
          </Select>
        ),
        cellClass(row) {
          return "centerClassname";
        },
      },
      {
        key: "drafts",
        name: "Draft Versions",
        width: 250,
        formatter: ({ row }) => {
          return(
          <Select
          className="black-loader"
            style={{ width: "100%" }}
            loading={specificVersionLoader.includes(row._id)}
            value={row.latest_version_id || undefined}
            placeholder="Select Draft Version"
            onChange={(draft_id) => handleDraftVersionSelect(draft_id, row)}
          >
            {row.versions &&
              Object.keys(row.versions).length &&
              row.versions && row.versions.versions &&
              row.versions.versions.length &&
              row.versions.versions.map((version) => (
                <Option value={version._id}>
                  {new Date(version.createdAt).toLocaleString()}, {version.name}
                </Option>
              ))}
          </Select>
        )},
        cellClass(row) {
          return "centerClassname";
        },
      }
    );
  } else {
    columns[props.companyType].push(
      {
        key: "direct_publish",
        name: "Direct Publish",
        width: 150,
        formatter: ({ row }) => (
          <Button
            type="primary"
            loading={directPublishLoader.includes(row._id)}
            onClick={() =>
              directPublishButton(row, props.company_profile_dimensions)
            }
          >
            Direct Publish
          </Button>
        ),
        cellClass(row) {
          return "centerClassname";
        },
      },
      {
        key: "published_versions",
        name: "Published Versions",
        width: 250,
        formatter: ({ row }) => (
          <Select
            className="black-loader"
            style={{ width: "100%" }}
            loading={specificVersionLoader.includes(row._id)}
            value={row.latest_version_id || undefined}
            placeholder="Select Published Version"
            onChange={(publish_id) =>handlePublishedVersionSelect(publish_id, row)}
          >
            {row.versions &&
              row.versions.length &&
              row.versions.map((version) => (
                <Option value={version.publish_id}>
                  {new Date(version.date).toLocaleString()}, {version.name}
                </Option>
              ))}
          </Select>
        ),
        cellClass(row) {
          return "centerClassname";
        },
      }
    );
  }

  // const toggleRowColor = (row) => {
  //   console.log(row, "");
  // };

  const clearFilters = () => {
    //reset Filters
    setFilters({
      company_name: "",
      company_id: "",
      region: "",
      country: [],
      end_user: "",
      vehicle_type: "",
      product_type: "",
      product_grade: "",
    });
  };


  return (
    <div
      className="company-profile-editing-container"
      style={{ height: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <Radio.Group
          options={options}
          onChange={onChange}
          value={props.companyType}
          optionType="button"
          disabled={props.initial_draft_versions_loading}
        />
              {['productServices', 'recentDevelopment'].includes(props.companyType) ? <Checkbox checked={props.fetch_full_data} onChange={(e)=>props.setFetchFulldata(e.target.checked)} disabled={props.initial_draft_versions_loading}>Fetch Full Data</Checkbox> : ''}
        <Button type="primary" onClick={clearFilters}>
          Clear Filters
        </Button>
      </div>
      {props.initial_draft_versions_loading ? (
        <Spin
          style={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%)",
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            margin: "0 10px",
          }}
        >
          <div
            style={{ height: "100%" }}
            className="react-grid-multiline-content"
          >
            {/* <FilterContext.Provider value={filters}>
              <DataGrid
                columns={columns[props.companyType]}
                rows={filteredRows}
                rowKeyGetter={rowKeyGetter}
                onRowsChange={setRows}
                onFill={handleFill}
                onPaste={handlePaste}
                rowHeight={80}
                rowScrollTimeout={200}
                minHeight={850}
                minColumnWidth={100}
                defaultColumnOptions={{
                  sortable: true,
                  resizable: true,
                }}
                headerRowHeight={70}
                className="fill-grid"
                noRowsFallback={<EmptyRowsRenderer />}
                // rowClass={(row) => toggleRowColor(row)}
              />
            </FilterContext.Provider> */}
          </div>
        </div>
      )}
    </div>
  );
}

function FilterRenderer({ isCellSelected, column, children }) {
  const filters = useContext(FilterContext);
  const { ref, tabIndex } = useFocusRef(isCellSelected);

  return (
    <>
      <div>{column.name}</div>
      {<div>{children({ ref, tabIndex, filters })}</div>}
    </>
  );
}

function mapStateToPros(state) {
  return {
    company_profile_data: state.company_profile_data,
    update_company_profile_data: state.update_company_profile_data,
    companyType: state.companyType,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    company_profile_dimensions: state.company_profile_dimensions,
    USER_DATA: state.USER_DATA,
    ACTIVE_TAB: state.ACTIVE_TAB,
    fetch_full_data: state.fetch_full_data
  };
}
const mapDispatchToProps = {
  setCompanyProfileData,
  setCompanyType,
  setUpdateCompanyProfileData,
  setFetchFulldata
};

export default withRouter(
  connect(mapStateToPros, mapDispatchToProps)(CompanyProfileEditing)
);
