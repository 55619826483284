import React from "react";

export default function CardComponent(props) {
  return (
    <>
      {props.type === "card-2" ? (
        <div className="stats-card" style={{background: props.bgColor}}>
          <div className="heading">{props.heading}</div>
          <div className="stats_content_card">{props.text}</div>
        </div>
      ) : props.type === "card-3" ? (
        <div class="stats-card" style={{background: props.bgColor}}>
          <div className="white_heading" style={{margin: '8px'}}>{props.heading}</div>
          <div className="info_content_card" style={{margin: '8px'}}>{props.text}</div>
        </div>
      ) : (
        <div className="stats-card">
          <div className="heading">
            {props.revenue} {props.currencyDenominator}
          </div>
          <div className="stats_content_card">
            <p>Global Revenue in {props.currency}</p>
            <p>FY {props.year} ( All Segments )</p>
          </div>
        </div>
      )}
    </>
  );
}
