import React, { Component}  from 'react';
import { DatePicker } from 'antd';
import moment from 'moment'


export default function DateEditor({
  row,
  column,
  onRowChange,
  onClose
}) {

  return (
    <DatePicker
    format={"YYYY-MM-DD"}
      value={moment(row[column.key])}
      onChange={(date, dateString) => onRowChange({ ...row, [column.key]: dateString})}
      onBlur={() => onClose(true)}
      allowClear={false}
    ></DatePicker>
  );
}
