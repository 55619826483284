import styled from "styled-components";

export const RegulatoryFrameworkV2Wrapper = styled.div`
  height: 100vh;

  .regulatoryFrameworkCountryv2 {
    text-align: center;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .RegulatoryFrameworkCountryFlag {
    width: 53px;
    height: auto;
    border: 2px solid #eee;
  }

  .RegulatoryFrameworkBoxSlide {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: calc(100% - 94px);
  }

  .RegulatoryFrameworkBoxv2 {
    font-family: "Avenir Heavy";
    position: relative;
    width: 30%;
    margin: 12px;
  }

  span.RegulatoryFrameworkBoxHeading {
    font-family: "Avenir Heavy";
    font-weight: 400;
    position: absolute;
    background-color: white;
    padding: 7px;
    top: -18px;
    left: 8px;
    z-index: 3;
    overflow-y: auto;
  }

  p.RegulatoryFramework_BulletPointsv2 {
    font-family: "Avenir Roman";
    font-size: 14px;
    align-content: center;
    text-align: left;
    white-space: pre-line;
    overflow-wrap: break-word;
    position: relative;
    padding: 6%;
    height: 100%;
    margin: 0;
    color: var(--blue);
    line-height: 18px;
  }
  @media only screen and (max-width: 960px) {
    height: auto;
    .RegulatoryFrameworkBoxSlide {
      flex-direction: column;
    }
    .RegulatoryFrameworkBoxv2 {
      width: 100%;
    }
  }
`;
