import React from "react";
import { Form, Input, Button, message } from "antd";
import { LoginWrapper } from "../styles/Login.style";
import { useAuth } from "../firebase/contexts/AuthContext";
import { useHistory } from "react-router-dom";
import configs from "../config/Api";
const axios = require("axios").default;

export default function Login(props) {
  const { ResetPassword } = useAuth();
  const history = useHistory();
  const key = "LoginMessage";
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const openLoading = (msg) => {
    message.loading({ content: msg ? msg : "Logging In...", key, duration: 0 });
  };
  const openSuccess = (msg) => {
    message.success({ content: msg ? msg : "Welcome", key, duration: 1 });
  };
  const openFailed = (msg) => {
    message.error({
      content: msg ? msg : "Invalid email / password.",
      key,
      duration: 1.2,
    });
  };

  async function checkUserInDb(email_id) {
    let responseGetUser,
      url = configs.api.base_url;
    responseGetUser = await axios.post(`${url}/api/users/getUser`, {
      email_id: email_id,
    });
    responseGetUser = responseGetUser.data;

    if (responseGetUser && responseGetUser.obj)
      if (responseGetUser.obj.active) return "VALID USER";
      else return "INACTIVE USER";
    else return "INVALID USER";
  }

  const onFinish = async (values) => {
    let isValidUserInDb,
      msg = null;
    try {
      isValidUserInDb = await checkUserInDb(values.email);
      if (isValidUserInDb === "INACTIVE USER") {
        msg = "User Account is currently not active.";
        throw msg;
      } else if (isValidUserInDb === "INVALID USER") {
        msg = "Sorry, User Account not found";
        throw msg;
      }

      openLoading("Sending reset link...");
      let ResetPasswordResponse;
      ResetPasswordResponse = await ResetPassword(values.email);
      if (ResetPasswordResponse.code === "auth/too-many-requests") {
        msg = "Too many requests. Please try again later";
        throw msg;
      }
      if (ResetPasswordResponse)
        openSuccess("Password reset link sent to Email!");
      else {
        msg = "Something went wrong. Please check your network.";
        throw msg;
      }
    } catch (e) {
      openFailed(msg);
    }
  };

  return (
    <LoginWrapper>
      <div className="login-form">
        <div className="login-title">
          Enter Email to send password reset link
        </div>
        <Form
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <div className="buttons">
            <Button htmlType="submit" className="signup_login_form_button">
              Send Password reset link
            </Button>
            <Button
              className="signup_login_form_button"
              onClick={() => {
                history.push("/login");
              }}
            >
              Back to Login
            </Button>
          </div>
        </Form>
      </div>
    </LoginWrapper>
  );
}
