import React, { Component } from "react";
import { Table, Typography } from "antd";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Analysis from "../components/Analysis";
const { Title } = Typography;

export const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: "left",
  },
  {
    title: "Type of Key Development",
    dataIndex: "type_of_key_development",
    key: "type_of_key_development",
    align: "left",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
    align: "left",
  },
];

const KeyDevelopment = [
  [
    {
      date: "March 2021",
      type: "Product Innovation",
      description: [
        {
          text: "In 2020, Royal Dutch Shell Plc decided to market two of its refineries in the United States. The organization revealed its plans to reshape its refining portfolio across the world to use Shell's characteristic strengths and integration contingency.",
          hyperLink: "developments5610",
          counter: 1,
        },
      ],
    },
    {
      date: "February 2021",
      type: "Product Innovation",
      description: [
        {
          text: "Royal Dutch Shell introduced and added new range of lubricants called carbon neutral lubricants to its products portfolio for passenger cars, heavy duty diesel engines and industrial applications to reduce the carbon emissions globally.",
          hyperLink: "developments5611",
          counter: 2,
        },
      ],
    },
    {
      date: "August 2019",
      type: "Partnership",
      description: [
        {
          text: "Shell Lubricants and Comercial Importadora SA de CV (CISA) announced a new joint venture partnership in Mexico. This partnership may strengthen CS’s leading position in automotive lubricants in Mexico.",
          hyperLink: "developments5612",
          counter: 3,
        },
      ],
    },
  ],
];

export const pageId = 25;

export class RecentDevelopment extends Component {
  state = {
    data: this.props.data ? this.props.data : {},
    list: [],
    reseller: false
  };
  componentDidMount() {
    this.handleTableUpdate();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.data !== prevProps.data) {
      this.handleTableUpdate();
    }
  };

  // static getDerivedStateFromProps(props, state) {
  //   if(props.data && props.data.meta) {
  //     if(props.data.meta.sample && props.data.meta.reseller ) {
  //       return {
  //         reseller: true
  //       }
  //     }
  //   }
  // }


  handleTableUpdate = () => {
    let arr = this.props.data.Key_development.map((item, index) => {
      let details = (
        <p>
          {item.description[0].text}
          {item.description[0].hyperLink.length ? (
            <sup>
              [
              <a href={"#" + item.description[0].hyperLink}>
                {item.description[0].counter}
              </a>
              ]
            </sup>
          ) : null}
        </p>
      );
      return {
        key: index,
        date: item.date,
        type_of_key_development: item.type,
        details: details,
      };
    });
    this.setState({ list: arr });
  };

  render() {
    const { slug } = this.props.data;
    const { slideNo } = this.props;

    return (
      <>
        <div className="page" id={pageId}>
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Header slideTitle={this.state.data.type} />
          ) : null}
          <div className="KeyDevelopment container">
            <div>
              <h2 className="heading">
                {!this.props.fromInterface ||
                this.props.fromInterface == undefined
                  ? this.props.data.title
                  : this.props.title}{" "}
                - Key Developments
              </h2>
              <Table
                className="KeyDevelopmentTable"
                bordered={false}
                pagination={false}
                columns={columns}
                dataSource={this.state.list}
              />
            </div>
          </div>
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer slug={slug} slideNo={slideNo} slideNoElementId={"2"} reseller={this.state.reseller} />
          ) : null}
        </div>
      </>
    );
  }
}

export default RecentDevelopment;
