import React, { Component } from "react";
import { Table, Typography } from "antd";
import Footer from "../components/Footer";
const { Title } = Typography;
var Base64 = require("js-base64").Base64;

export const ProductsAndServicesDemoData = {
  slideName: "appendix2",
  id: "APPENDIX-2: PRODUCTS AND SERVICES",
  subtitle: "APPENDIX-2: PRODUCTS AND SERVICES",
  slug: "North America Enzymes Market",
  firstSlide: 1,
  data: [
    {
      company_name: "A",
      company_data: [
        {
          company_name: "A",
          product: "Natugrain TS 5XG​",
          product_type: "Phytase​",
          target_segment: "Poultry, Swine",
        },
        {
          company_name: "TEst2",
          product: "Natugrain TS​",
          product_type: "Phytase​",
          target_segment: "Swine",
        },
        {
          company_name: "A",
          product: "RONOZYME® WX​",
          product_type: "Carbohydrase​",
          target_segment: "Ruminants,Poultry,Swine",
        },

        {
          company_name: "A",
          product: "RONOZYME® RumiStar™​",
          product_type: "Carbohydrase​",
          target_segment: "Ruminants",
        },
        {
          company_name: "A",
          product: "RONOZYME® RumiStar™​",
          product_type: "Carbohydrase​",
          target_segment: "Ruminants",
        },
      ],
    },
    {
      company_name: "A",
      company_data: [
        {
          company_name: "A",
          product: "Natugrain TS 5XG​",
          product_type: "Phytase​",
          target_segment: "Poultry, Swine",
        },
        {
          company_name: "TEst2",
          product: "Natugrain TS​",
          product_type: "Phytase​",
          target_segment: "Swine",
        },
        {
          company_name: "TEst2",
          product: "Natugrain TS​",
          product_type: "Phytase​",
          target_segment: "Swine",
        },
      ],
    },
  ],
};

export const columns = [
  {
    title: "Company Name",
    dataIndex: "company_name",
    key: "company_name",
    align: "left",
    width: "20%",
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    align: "left",
    width: "25%",
  },
  {
    title: "Product Type",
    dataIndex: "product_type",
    key: "product_type",
    align: "left",
    width: "25%",
  },
  {
    title: "Target Segment",
    dataIndex: "target_segment",
    key: "target_segment",
    align: "left",
    width: "25%",
  },
];

export class ProductsAndServices extends Component {
  state = {
    data: this.props.data || ProductsAndServicesDemoData,
  };
  render() {
    const { slideNo } = this.props;
    let { id, slug } = this.state.data;
    const pageId = id ? Base64.encode(id) : undefined;
    return (
      <>
        <div className="page" id={pageId}>
          <div className="ProductsAndServices" style={{ height: "663px" }}>
            <h2 class="ProductsAndServicesHeading heading">
              {ProductsAndServicesDemoData.subtitle}
            </h2>

            {this.state.data.data.map((element, index) => {
              let rows = element.company_data;
              return (
                <div>
                  <div
                    className={
                      index > 0 ? "HideTableHeader" : "ShowTableHeader"
                    }
                    style={{ maxHeight: 520 }}
                  >
                    <Table
                      rowClassName={(record, index) => {
                        if (index % 2) return "table-row-light";
                        else return "table-row-dark";
                      }}
                      className="ProductsAndServicesTable "
                      bordered={false}
                      pagination={false}
                      columns={columns}
                      dataSource={rows}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {!this.props.fromInterface || this.props.fromInterface==undefined?<Footer slug={slug} slideNo={slideNo} slideNoElementId={"2"} />:null}
        </div>
      </>
    );
  }
}
export default ProductsAndServices;
