import React from "react";
import { Image } from "antd";
import axios from "axios";
import config from "../config/Config";
import configs from "../config/Api";
import mordorLogo from "../images/mordorLogo.png";
import lubricants_noLogos from "../images/Homepage_nologo.svg";
import lubricants_withLogos from "../images/lubricants_withlogo.jpg";
import proteins_noLogos from "../images/Homepage_nologo.svg";
import proteins_withLogos from "../images/protein_testing.jpg";

var Base64 = require("js-base64").Base64;

export const HomeDemoData = {
  title: "North America Lubricant",
  subtitle: "Home",
  type: "home",
  template: "home",
  data: {
    Domain: "",
    baseYear: "2020",
    forcastPeriod: "2021-2026",
  },
  id: "home",
};

class Home extends React.Component {
  state = {
    data: this.props.data ? this.props.data : {},
    test_resp: null,
    reseller: false,
  };

  componentDidMount() {
    if (config.LOCAL_STORAGE_TOKEN.Name in localStorage) {
      let url = configs.api.base_url;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
  }

  render() {
    let { id } = this.state.data;
    const pageId = id ? Base64.encode(id) : undefined;
    return (
      <div className="HomeSlide page" style={{ height: "720px" }} id={pageId}>
        {this.props.data ? (
          <>
            <h3 className="heading HomeTitle">{this.state.data.title} </h3>
            <div className="DomainContainer">
              <img
                src={mordorLogo}
                style={{ height: 40, marginTop: -820, marginLeft: -40 }}
              />
              {this.state.data &&
              this.state.data.data &&
              this.state.data.data.Domain ? (
                <p className="HomeSubHeading">
                  Domain: <span>{this.state.data.data.Domain}</span>
                </p>
              ) : null}
              {this.state.data &&
              this.state.data.data &&
              this.state.data.data.baseYear ? (
                <p className="HomeSubHeading">
                  Base Year: <span>{this.state.data.data.baseYear}</span>
                </p>
              ) : null}
              {this.state.data &&
              this.state.data.data &&
              this.state.data.data.forcastPeriod ? (
                <p className="HomeSubHeading">
                  Forcast Period:{" "}
                  <span>{this.state.data.data.forcastPeriod}</span>
                </p>
              ) : null}
            </div>
            {this.state.reseller ? (
              <Image
                className="HomeBackground"
                preview={false}
                src={lubricants_noLogos}
              />
            ) : this.state.data &&
              this.state.data.data &&
              this.state.data.data.Domain == "Proteins" ? (
              <Image
                className="HomeBackground"
                preview={false}
                src={proteins_withLogos}
              />
            ) : (
              <Image
                className="HomeBackground"
                preview={false}
                src={lubricants_withLogos}
              />
            )}
          </>
        ) : null}
      </div>
    );
  }
}

export default Home;
