import React, { Component } from "react";
import Config from "../../config/Config";
import IdCodeConfig from "./IdCodeConfig";

import config from "../../config/Api";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Maps from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.maps";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/maps/fusioncharts.world";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Maps, Charts, FusionTheme);

const CODE_COUNTRY_CONFIG = Config.CODE_COUNTRY_CONFIG;
const CONTINENT_CONFIG = Config.CONTINENT_CONFIG;
const SUBCONTINENT_CONFIG = Config.SUBCONTINENT_CONFIG;
const COUNTRY_CONFIG = Config.COUNTRY_CONFIG;
const COLOR_CONFIG = Config.COLOR_CONFIG;

const Id_CONTINENT_CONFIG = IdCodeConfig.CONTINENT_CONFIG;

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      range: [],
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
    };
    this.resetChart = this.resetChart.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      let datas = parseContinentData(this.props.data);
      let range = [];
      let max = maxObject(this.props.data).value;
      let min = minObject(this.props.data).value;
      range.push(max);
      range.push((max + min) / 2);
      range.push(min);
      this.setState({ data: datas, loadingData: false, range });
    }
  }
  resetChart(event) {
    //event.preventDefault();
    let a = window.btoa(
      JSON.stringify({
        slug: "aquaculture",
        graph_type: "aquaculture",
        heading: "aquaculture",
        component: "aquaculture",
        time: Date.now(),
      })
    );

    FusionCharts.batchExport({
      charts: [
        {
          id: "image",
        },
      ],
      exportFormat: "png",

      exportHandler: `${config.api.base_url}/api/graphs/data?data=${a}`,
      exportAction: "SAVE",

      exportMode: "client",
    });
  }
  render() {
    let data = "",
      datas = "";
    if (!this.state.loadingData) {
      data = this.state.data;
    }

    let width = 571,
      height = 393;
    let headers = "Countries With Most Oil Reserves [2017-18]";
    let footers =
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.footers) footers = this.props.footers;
    let chartConfigs = {
      id: "image",
      type: "world",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          captionFontSize: 14,
          captionFontColor: "#7f7f7f",
          animation: false,
          includeValueInLabels: "0",
          theme: "fusion",
          showlabel: "0",

          nullEntityAlpha: "0",
          nullEntityColor: "#ffffff",
          captionFont: "regular bold",
          showLegend: false,
          bgColor: "#FFEDC7",
          interactiveLegend: "0",

          reverseLegend: "0",
          showCanvasBorder: "0",
          fillColor: "#6C27FF",
        },
        colorrange: {
          minvalue: this.state.range[2],
          maxvalue: this.state.range[0],
          gradient: "0",
          color: [
            {
              minvalue: this.state.range[2],
              maxvalue: this.state.range[1],
              code: "#EFD699",
            },
            {
              minvalue: this.state.range[1],
              maxvalue: this.state.range[0],
              code: "#FCA31E",
            },
          ],
        },
        data: data,
      },
    };

    return (
      <div
        className="geography"
        id="geography"
        style={{
          textAlign: "left",
          width: 400,
          marginBottom: 10,
          fontFamily: "regular",
          backgroundColor: "#E0F9FF",
        }}
      >
        <ReactFC {...chartConfigs} />
        <button onClick={this.resetChart}>Click</button>
      </div>
    );
  }
}
function parseData(data) {
  let temp_arr = [];
  for (let key in data) {
    let color = COLOR_CONFIG[(data[key] + "").toLowerCase()];
    if (CONTINENT_CONFIG[key.toLowerCase()]) {
      for (let subCont of CONTINENT_CONFIG[key.toLowerCase()]) {
        for (let country of SUBCONTINENT_CONFIG[subCont]) {
          let index = temp_arr.findIndex((elem) => elem.id == country);
          if (index !== -1) {
            if (color == -1) {
              temp_arr.splice(index, 1);
            } else {
              temp_arr[index].value = color;
            }
          } else if (color != -1) {
            temp_arr.push({
              id: country,
              value: color,
            });
          }
        }
      }
    } else if (SUBCONTINENT_CONFIG[key.toLowerCase()]) {
      for (let country of SUBCONTINENT_CONFIG[key.toLowerCase()]) {
        let index = temp_arr.findIndex((elem) => elem.id == country);
        if (index !== -1) {
          if (color == -1) {
            temp_arr.splice(index, 1);
          } else {
            temp_arr[index].value = color;
          }
        } else if (color != -1) {
          temp_arr.push({
            id: country,
            value: color,
          });
        }
      }
    } else if (COUNTRY_CONFIG[key.toLowerCase()]) {
      let country = COUNTRY_CONFIG[key.toLowerCase()];
      let index = temp_arr.findIndex((elem) => elem.id == country);
      if (index !== -1) {
        if (color == -1) {
          temp_arr.splice(index, 1);
        } else {
          temp_arr[index].value = color;
        }
      } else if (color != -1) {
        temp_arr.push({
          id: country,
          value: color,
        });
      }
    }
  }
  let data_arr = [];

  for (let item of temp_arr) {
    let temp = {
      id: CODE_COUNTRY_CONFIG[item.id],
      value: item.value,
    };
    data_arr.push(temp);
  }
  return data_arr;
}
function parseContinentData(data) {
  let arr = [];
  for (let item of data) {
    if (Id_CONTINENT_CONFIG[item.label.toLowerCase()]) {
      arr.push({
        id: Id_CONTINENT_CONFIG[item.label.toLowerCase()],
        value: item.value,
        displayValue: item.value.toFixed(1),
        showLabel: "0",
      });
      if (Id_CONTINENT_CONFIG[item.label.toLowerCase()] == "AS")
        arr.push({
          id: "AU",
          value: item.value,
          displayValue: item.value.toFixed(1),
          showLabel: "0",
        });
    }
  }
  return arr;
}
const maxObject = function (data) {
  return data.reduce(function (prev, current) {
    return prev.value > current.value ? prev : current;
  });
};
const minObject = function (data) {
  return data.reduce(function (prev, current) {
    return prev.value < current.value ? prev : current;
  });
};

export default Chart;
