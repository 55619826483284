import React from "react";
import BarLine from "../graphs/barline/Main";
import Pie from "../graphs/pie/Main";
import Bar from "../graphs/bar/Main";
import StackedColumn from "../graphs/StackedColumn/Main";
import StackedBar from "../graphs/StackedBar/Main";

var Base64 = require("js-base64").Base64;
const { v4: uuidv4 } = require("uuid");
export const allGraphs = {
  bar: Bar,
  barline: BarLine,
  pie: Pie,
  stackedColumn: StackedColumn,
  Hstackedbar: StackedBar
};

class Graph extends React.Component {
  render() {
    const Graph = allGraphs[this.props.graphType];
    let dataHidden = false;
    if (this.props.totalData && this.props.totalData.dataHidden) {
      dataHidden = this.props.totalData.dataHidden;
    }

    return (
      <div>
        <Graph
          headers={this.props.headers}
          width={this.props.width}
          dynamiclegends={
            this.props.dynamiclegends ? this.props.dynamiclegends : ""
          }
          valueFontSize={
            this.props.valueFontSize ? this.props.valueFontSize : ""
          }
          dataType={this.props.dataType ? this.props.dataType : ""}
          chartHeadingGap={this.props.chartHeadingGap ? this.props.chartHeadingGap : 0}
          yHeadingMargin={this.props.yHeadingMargin ? this.props.yHeadingMargin : 0}
          xHeadingMargin={this.props.xHeadingMargin ? this.props.xHeadingMargin : 0}
          wrapHeadingWidth={this.props.wrapHeadingWidth ? this.props.wrapHeadingWidth : 0}
          rotateValues={this.props.rotateValues ? this.props.rotateValues : "0"}
          legendsYPosition={this.props.legendsYPosition ? this.props.legendsYPosition : 0}
          legendsPositionDynamic={this.props.legendsPositionDynamic ? this.props.legendsPositionDynamic : false}
          height={this.props.height}
          cagr_year={["2020","2025"]}
          cagr_value={"36"}
          allow_cagr={this.props.allow_cagr?this.props.allow_cagr:false}
          data={this.props.data}
          id={Base64.encode(uuidv4())}
          labels={this.props.labels === true ? true : false}
          legends={true}
          dataHidden={dataHidden}
        />
      </div>
    );
  }
}

export default Graph;
