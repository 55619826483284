import Footer from "../components/Footer";
import Header from "../components/Header";
import React, { Component } from "react";
import Geography from "../graphs/geography/Main";
import Pie from "../graphs/pie/Main";
import { Progress } from "antd";
import { GlobalOverviewContainer } from "../styles/GlobalOverview.style";
var Base64 = require("js-base64").Base64;

export const globalOverviewDemoData = {
  slideName: "globaloverview",
  id: "3.3 By Geography",
  title: "3.3 By Geography",
  subtitle: "3.3 By Geography",
  slug: "Global Probiotics Market",
  slideReference: "marketsegment",
  graphs: [
    {
      graphType: "pie",
      data: [
        {
          label: "Aquaculture",
          value: 244049.82248428,
        },
        {
          label: "Swine",
          value: 620113.2152154809,
        },
        {
          label: "Poultry",
          value: 1519375.1999531426,
        },
        {
          label: "Ruminant",
          value: 1470436.8295140783,
        },
        {
          label: "Other Animal Types",
          value: 35033.51265634857,
        },
        {
          label: "Overview",
          value: 1369718.0478628622,
        },
      ],
      heading: "Revenue Share (%), by Animal Type, 2019",
    },
    {
      graphType: "pie",
      data: [
        {
          label: "Lactobacilli",
          value: 690212.277294742,
        },
        {
          label: "Bifidobacteria",
          value: 1155918.783316923,
        },
        {
          label: "Other Probioics",
          value: 733139.7638642708,
        },
        {
          label: "Overview",
          value: 1318987.7497938673,
        },
      ],
      heading: "Revenue Share (%), by Type, 2019",
    },
    {
      graphType: "geography",
      data: [
        {
          label: "Europe",
          value: 5.09693877735895,
        },
        {
          label: "North America",
          value: 3.56485087540106,
        },
        {
          label: "South America",
          value: 7.812401596956375,
        },
        {
          label: "Middle East",
          value: 7.802213235213573,
        },
        {
          label: "Asia-Pacific",
          value: 10.069839862965813,
        },
        {
          label: "Africa",
          value: 7.887262429963382,
        },
      ],
      heading:
        "Feed Enzyme Market in USD million,2019 and growth rate by Region, 2016-2015",
    },
  ],
};

class GlobalOverview extends Component {
  state = {
    data: this.props.data || globalOverviewDemoData,
    reseller: false
  };


  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }


  render() {
    const { id, title, subtitle, slug } = this.state.data;
    const { graphs } = this.state.data.data;
    const { slideNo } = this.props;
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <GlobalOverviewContainer id={pageId}>
        <div className="page">
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Header slideTitle={this.state.data.slideName} />
          ) : null}
          <div className="container overview-container">
            <h2 className="heading">{title}</h2>
            <div className="main-container">
              <div className="left-container">
                <h3 className="subheading">
                  {graphs ? graphs[2].heading : null}
                </h3>
                {graphs ? (
                  <Geography data={graphs[2].data} height={450} />
                ) : null}
              </div>
              {graphs ? (
                <div className="right-container">
                  <h3 className="subheading">{graphs[1].heading}</h3>
                  <Pie width={550} height={220} data={graphs[0].data} />
                  <h3 className="subheading">{graphs[2].heading}</h3>
                  <Pie width={550} height={220} data={graphs[1].data} />
                </div>
              ) : null}
            </div>
          </div>
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer slideNo={slideNo} slug={slug} reseller={this.state.reseller}/>
          ) : null}
        </div>
      </GlobalOverviewContainer>
    );
  }
}

export default GlobalOverview;
