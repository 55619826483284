import React, { Component } from "react";
import { Typography } from "antd";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductAndServicesWrapper from "../styles/ProductAndServices.style";

const { Title } = Typography;
var Base64 = require("js-base64").Base64;

class ProductAndServicesV2 extends Component {
  state = {
    data: this.props.data ? this.props.data : {},
    splittedResult: [],
    reseller: false
  };

  // static getDerivedStateFromProps(props, state) {
  //   if(props.data && props.data.meta) {
  //     if(props.data.meta.sample && props.data.meta.reseller ) {
  //       return {
  //         reseller: true
  //       }
  //     }
  //   }
  // }


  splitToChunks = () => {
    const { subtitle, products } = this.props.data
      ? this.props.data
      : this.state.data;

    const newProductsArray = [...products];
    let MeanData = products.length / 8;
    let result = [];
    for (let i = MeanData; i > 0; i--) {
      result.push(
        newProductsArray.splice(0, Math.ceil(newProductsArray.length / i))
      );
    }
    this.setState({
      splittedResult: result,
    });
  };

  render() {
    const { products } = this.props.data ? this.props.data : this.state.data;
    const { section_id } = this.props;
    const { splittedResult } = this.state;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    return (
      <>
        <div className="page">
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Header slideTitle={this.state.data.type} />
          ) : null}
          <ProductAndServicesWrapper
            interface={
              !this.props.fromInterface || this.props.fromInterface == undefined
                ? false
                : true
            }
            oneproduct={products && products.length != 1 ? false : true}
          >
            <h1 className="heading">
              {!this.props.fromInterface ||
              this.props.fromInterface == undefined
                ? this.props.data.title
                : this.props.title}{" "}
              - Products & Services
            </h1>
            <div className="Container-label">
              <div className="left-text-label-container">
                <div className="text-label">Popular Brands</div>
                <div className="text-label">Description</div>
              </div>
              {products && products.length != 1 ? (
                <div className="left-text-label-container">
                  <div className="text-label">Popular Brands</div>
                  <div className="text-label">Description</div>
                </div>
              ) : null}
            </div>
            <div className="card-container">
              {products &&
                products.length &&
                products.map((elem) => {
                  return (
                    <div className="container-cards">
                      <div className="card-text-left">
                        {elem.first_level_brand}
                      </div>
                      <div className="card-text-right">
                        {(elem.description&&elem.description.text)?
                        elem.description.text:""}
                         {elem.description.hyperLink.length ? (
                   <sup>
                      [<a href={"#" + elem.description.hyperLink}>{elem.description.counter}</a>]
                    </sup>
                  ) : null}
                        </div>
                    </div>
                  );
                })}
            </div>
          </ProductAndServicesWrapper>
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer slideNo={slideNo} reseller={this.state.reseller}/>
          ) : null}
        </div>
      </>
    );
  }
}

export default ProductAndServicesV2;
