import React, { useState } from "react";
import { Button, message } from "antd";
import { useAuth } from "../firebase/contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";
import config from "../config/Config";
import { resetState } from "../reducersAndActions/Actions";
import { Menu } from "antd";
import { auth } from "../firebase/Firebase";

export default function Logout() {
  const { logout } = useAuth();
  const history = useHistory();

  const key = "LogOutMessage";
  const dispatch = useDispatch();

  const openLoading = () => {
    message.loading({ content: "Logging Out...", key, duration: 0 });
  };
  const openSuccess = () => {
    message.success({ content: "See you soon!", key, duration: 1 });
  };
  const openFailed = () => {
    message.error({
      content: "Oops something went wrong!",
      key,
      duration: 1.2,
    });
  };

  const doLogOut = async () => {
    try {
      openLoading();
      window.localStorage.removeItem(config.LOCAL_STORAGE_TOKEN.Name);
      window.localStorage.removeItem(config.LOCAL_STORAGE_TOKEN.meta);
      window.localStorage.removeItem("user");

      await logout().then(async () => {
        setTimeout(() => {
          openSuccess();
        }, 250);
        dispatch(resetState());
      });
    } catch (e) {
      console.log("error at logout", e);
      openFailed();
    }
  };

  return <div onClick={() => doLogOut()}>Logout</div>;
}
