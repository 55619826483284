import styled from 'styled-components';


export const ProteinsVCWrapper = styled.div`


.lubricant-container {
  height: 560px;
  position: relative;
}

.lubricant-container img {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.proteins_title {
    font-family: Avenir Roman;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 14px;
    color: #002F75;
    text-transform: uppercase;
}

.proteins_title_2 {
    font-family: Avenir Roman;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 14px;
    color: #002F75;
    text-transform: uppercase;
}

.section1_list {
    left: ${props => props.fromInterface ? '10%': '3%'};
    width: ${props => props.fromInterface ? '22%': '25%'};
    position: absolute;
    top: 51%;
    word-break: break-word;
  }

  .section2_list {
    position: absolute;
    left: ${props => props.fromInterface ? '36%': '34%'};
    width: ${props => props.fromInterface ? '15%': '17%'};
    top: 51%;
    word-break: break-word;
  }
  .section3_list {
    position: absolute;
    left: ${props => props.fromInterface ? '54%': '53%'};
    width: ${props => props.fromInterface ? '20%': '25%'};
    top: 51%;
    word-break: break-word;
  }
  
  .section4_list {
    position: absolute;
    left: ${props => props.fromInterface ? '78%': '81%'};
    width: ${props => props.fromInterface ? '15%': '17%'};
    top: 51%;
    word-break: break-word;
  }
`