import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScopeStudyWrapper from "../styles/ScopeStudyRegional.style";
import by_animal_type from "../images/by_animal_type.png";
import by_geography from "../images/by_geography.png";
import bytype from "../images/bytype.png";
var Base64 = require("js-base64").Base64;

export const scopeofstudydata = {
  slug: "north america",
  id: "SCOPE OF STUDY",
  title: "1.2 SCOPE OF THE STUDY",
  data: [
    {
      label: "BY TYPE",
      icon: bytype,
      values: [
        "VITAMIN A",
        "VITAMIN B",
        "VITAMIN C",
        "VITAMIN D",
        "VITAMIN E",
        "VITAMIN z1",
        "VITAMIN z2",
        "VITAMIN z4",
        "VITAMIN z10",
        "vitamin z100",
      ],
    },
    {
      label: "BY ANIMAL TYPE",
      icon: by_animal_type,
      values: [
        "Ruminants",
        "poultry",
        "swine",
        "AQUACULTURE",
        "OTHER ANIMAL TYPES",
      ],
    },
    {
      label: "BY GEOGRAPHY",
      icon: by_geography,
      values: [
        "North America",
        "Europe",
        "Asia-Pacific",
        "MIDDLE-EAST",
        "South America",
      ],
    },
  ],
};

export const scopeofstudydata2 = {
  title: "Scope of the Study​",
  subtitle: "Scope of the Study​",
  type: "scopeOfStudy",
  section_id: "5100a804-e50a-440a-863c-d60c8bcecf82",
  template: "scopeOfStudy",
  data: [
    {
      label: "By sub end user",
      image: "",
      values: ["Commercial Vehicles", "Motorcycles", "Passenger Vehicles"],
    },
    {
      label: "By product type",
      image: "",
      values: [
        "Engine Oils",
        "Greases",
        "Hydraulic Fluids",
        "Transmission Oils",
      ],
    },
    {
      label: "By product type",
      image: "",
      values: [
        "Engine Oils",
        "Greases",
        "Hydraulic Fluids",
        "Transmission Oils",
      ],
    },
    {
      label: "By product type",
      image: "",
      values: [
        "Engine Oils",
        "Greases",
        "Hydraulic Fluids",
        "Transmission Oils",
      ],
    },
  ],
};

export default class ScopeStudyRegional extends React.Component {
  state = {
    data: this.props.data ? this.props.data : scopeofstudydata2,
    reseller: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }


  render() {
    const { data, title } = this.props.data ? this.props.data : this.state.data;
    const { slideNo } = this.props;
    const { section_id } = this.props.data;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    return (
      <div id={pageId} className="page">
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <ScopeStudyWrapper>
          <h2 className="heading">{title}</h2>
          <div className="inner_container">
            {data.map((item, index) => {
              return (
                <div className="content">
                  <div
                    className={index % 2 == 0 ? "list_header" : "list_header1"}
                  >
                    {item.label}
                  </div>
                  <img src={item.image} />
                  {item.values.map((val) => {
                    return <p>{val}</p>;
                  })}
                </div>
              );
            })}
          </div>
        </ScopeStudyWrapper>
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Footer slideNo={slideNo} reseller={this.state.reseller}/>
        ) : null}
      </div>
    );
  }
}
