import styled from "styled-components";

export const InterfaceHeaderWrapper = styled.div`
  position: fixed;
  background: white;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eee;
  padding: ${(props) => (props.mobile ? "10px 20px" : "21px 50px 10px 50px")};
  .logo-wrapper {
    display: flex;
    align-items: center;
    img.desktoplogo {
      width: 190px;
    }
    img.mobilelogo {
      width: 40px;
    }
    p {
      margin: 0 0 0 8px;
      color: #4acdcf;
      font-size: 18px;
      span {
        color: #2c8ebf;
      }
    }
  }

  h1 {
    width: ${(props) => (props.mobile ? "75%" : "60%")};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: ${(props) => (props.mobile ? "0 0 0 10px" : "0 20px")};
    margin: 0;
    font-size: ${(props) => (props.mobile ? "15px" : "22px")};
    color: #2c8ebf;
    text-align: center;
    font-size: 18px;
  }
  .cart-icon {
    cursor: pointer;
    margin-top: ${(props) => (props.mobile ? "4px" : "0px")};
  }
  .header-icons {
    width: ${(props) => (props.mobile ? "15%" : "20%")};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .anticon {
      margin-left: ${(props) => (props.mobile ? "15px" : "20px")};
      font-size: ${(props) => (props.mobile ? "18px" : "18px")};
    }
    .ant-badge-count {
      width: ${(props) => (props.mobile ? "15px" : "20px")};
      height: ${(props) => (props.mobile ? "15px" : "20px")};
      min-width: ${(props) => (props.mobile ? "15px" : "20px")};
      right: 0;
      padding: 0px 0px 0px 0px;
      .ant-scroll-number-only {
        margin: ${(props) => (props.mobile ? "-3px 0 0 0" : "0")};
      }
    }
    .tour-icon {
      transform: rotate(0deg);
      transition: all 0.5s ease-out;
      &:hover {
        transform: rotate(180deg);
      }
    }
  }

  .menuMobile {
    display: none;
  }

  @media only screen and (max-width: 960px) {
    padding: 21px 10px 10px 10px;
    .menuMobile {
      display: block;
      margin: 10px 0;
    }
    .menuMobile svg {
      height: 25px;
      width: 25px;
      color: #189cde;
    }
  }
  #profilePicture {
    margin-left: 15px;
    clip-path: circle(50% at 50% 50%);
  }
`;
