import { Select, Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { Component } from 'react';

const { Option } = Select;

class EditableSelect extends Component {
    constructor(props){
    super(props)
        this.state = {
         
        };
    }

  handleChange=(value)=>{
      this.props.getSelectedIndicatorObject(value)
  }

  render() {
    const items = this.props.headingDropdownArray;
    return (
      <Select
        style={{ width: '100%', marginBottom:'20px' }}
        onChange={this.handleChange}
        value={this.props.selected_indicator_heading}
      >
        {items.map(item => (
          <Option key={item.parent_id}>{item.trends.heading}</Option>
        ))}
      </Select>
    );
  }
}

export default EditableSelect;