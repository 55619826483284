import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import Config from "../../config/Config";
import { roundOfMaxYAxisValue } from '../commanFunction';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

//Your react component
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
      total_legend_length: "",
      max_legend_length:0,
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1 ljkshdlkjsbd slkkkkdnlkmsf klshd.kjsdbms",
          showValue: true,
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
    this.bytesToString = this.bytesToString.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      let datas = this.props.data.data;
      let arr = parseData(this.props.data.data);
      let maxValue = roundOfMaxYAxisValue(arr[0] * 1.7);
      let catagory = this.props.data.catagory,
        new_data = [...this.props.data.data];
      let total_legend_length = "",max_legend_length=0;
      new_data = new_data.map(item => {
        if(item.seriesname.length>max_legend_length)
        max_legend_length=item.seriesname.length;
        // if (this.props.legendsPositionDynamic) 
          item.seriesname = this.bytesToString(item.seriesname, 35)
        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname
        return item;
      })

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState &&
      JSON.stringify(prevState.data) !== JSON.stringify(this.props.data.data)
    ) {
      let datas = this.props.data.data;
      let arr = parseData(this.props.data.data);
      let maxValue = roundOfMaxYAxisValue(arr[0] * 1.7);
      let catagory = this.props.data.catagory,
        new_data = [...this.props.data.data];
      let total_legend_length = "",max_legend_length=0;
      new_data = new_data.map(item => {
        if(item.seriesname.length>max_legend_length)
        max_legend_length=item.seriesname.length;
        // if (this.props.legendsPositionDynamic) {
          item.seriesname = item.seriesname.replace(/<br\/>/g, '');
          item.seriesname = this.bytesToString(item.seriesname, 35);
        // }
        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname
        return item;
      })

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length
      });
    }
  }
  bytesToString(d, width) {
    let str = "";
    let computed_array = [];
    let string_arr = d.trim().split(" ");
    for (let item of string_arr) {
      if ((item + str).length >= width) {
        computed_array.push(str);
        str = ""
      }
      str += " " + item;
    }
    if (str !== "")
      computed_array.push(str);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  }
  render() {
    let data = "",
      datas = "",
      catagory = "",
      rotateValues = 0,
      legendsPositionDynamic = false,
      max_legend_length=0,
      valueFontSize = 12;

    data = this.state.data;
    datas = this.state.data;
    catagory = this.state.catagory;

    let width = 740,
      height = 220,
      id = "1";
    let headers = "";
    let footers =
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.footers) footers = this.props.footers;
    if (this.props.valueFontSize) valueFontSize = this.props.valueFontSize;
    if (this.props.rotateValues == 1) rotateValues = this.props.rotateValues;
    if (this.props.legendsPositionDynamic) legendsPositionDynamic = this.props.legendsPositionDynamic;
    if (this.props.id) id = this.props.id;
    footers = "";
    let legendLength = data.length > 5 ? 11 : 14;
    if (valueFontSize == 11)
      legendLength = data.length > 5 ? 11 : 14;
      if ( legendsPositionDynamic)
      legendLength=11
    // if (this.state.total_legend_length.length > 81) {
    //   legendLength = 11
    // }
    if(this.state.max_legend_length<35){
      max_legend_length=35-this.state.max_legend_length;
    }
console.log(this.props.wrapHeadingWidth,"foundedddd")
    let chartConfigs1 = {
      id: id,
      type: "stackedcolumn2d",
      width: width,
      height: height,
      dataFormat: "json",

      dataSource: {
        chart: {
          // caption: (headers+"").toUpperCase(),
          // captionFont:"Avenir Medium",
          // captionFontColor:"#002F75",
          // captionFontSize:16,
          // captionAlignment: "left",
          // captionOnTop: 1,
          // captionFontBold:"0",
          // captionHorizontalPadding:-50,
          paletteColors: Config.COLORS,
          formatNumberScale: 1,
          showValues: false,
          showsum: !this.props.dataHidden,
          // divLineColor: "#ffffff",
          legendPosition: "bottom",
          // legendNumColumns: 1,
          legendXPosition: 370+max_legend_length,
          legendYPosition: 15+this.props.legendsYPosition,
          showPlotBorder: "0",
          plotSpacePercent: 40,
          animation: false,
          labelFontSize: 14,
          xAxisLineColor: "#4A90E2",
          yAxisLineColor: "#4A90E2",
          xAxisLineThickness:1,
          yAxisLineThickness:1,
          showxaxisline: !this.props.dataHidden,
          showYAxisValues: !this.props.dataHidden,
          showYAxisLine: !this.props.dataHidden,
          valueFontBold: false,
          theme: "fusion",
          legendItemFontSize: legendLength,
          chartTopMargin: 100+this.props.chartHeadingGap,
          chartLeftMargin: 4,
          // chartRightMargin: 200,
          valueFontColor: "#002F75",
          placeValuesInside: "0",
          yAxisNamePadding: 0,
          xAxisNameFont: "Avenir Medium",
          // chartBottomMargin: 95,
          xAxisNameFontColor: "#444444",
          centerXaxisName: false,
          valueFont: "Avenir Heavy",
          valueFontSize: valueFontSize,
          valueFontBold: true,
          legendItemFont: "Avenir Medium",
          legendItemFontColor:"#7f7f7f",
          sumPadding: "15",
          yAxisMaxValue: this.state.maxValue,
          minPlotHeightForValue: 10,
          decimals: 1,
          "rotateValues": rotateValues,
          yAxisValueFontSize: 14,
          xAxisValueFontSize: 14,
          yAxisValueDecimals: 1,
          yAxisValueFontColor:"#7f7f7f",
          labelFontColor:"#7f7f7f",
          adjustDiv: "0",
          "numDivLines": "4",
          "numberScaleValue": "1000,1000,1000",
          "numberScaleUnit": "k,M,B",
          baseFont: "Avenir Medium",
          baseFontSize:14,
          baseFontColor:"#7f7f7f"
        },
        annotations:{
          autoScale: "1",
          groups:[
            {
              id: "caption",
              items: [
                {},
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers+"").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${570+this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                },
              ],
            }
          ]
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    if ( legendsPositionDynamic) {
      chartConfigs1['dataSource']['chart'] = {
        ...chartConfigs1['dataSource']['chart'],
        legendPosition: "absolute",
        legendNumColumns: 1,
        chartRightMargin: this.state.max_legend_length>35?220:200,
      }
    }
    else{
      chartConfigs1['dataSource']['chart'] = {
        ...chartConfigs1['dataSource']['chart'],
        legendPosition: "absolute",
        legendNumColumns: 1,
        chartRightMargin: 310-max_legend_length,
        legendXPosition: 980+max_legend_length
      }
    }
    return (
      <div className="bar" id="bar">
        <div style={{}}>
          <ReactFC {...chartConfigs1} />
        </div>
      </div>
    );
  }
}

function parseData(data) {
  if (!data || data.length == 0) return [0, 0];
  let arr = [];
  for (let item of data) {
    for (let [index, child_item] of item.data.entries()) {
      if (arr[index]) arr[index] += child_item.value;
      else arr[index] = child_item.value;
    }
  }
  arr.sort(function (a, b) {
    if (a > b) return -1;
    else return 1;
  });

  return arr;
}
function parseCatagory(data) {
  let alllabels = [];
  for (let item of data) {
    alllabels.push({
      label: item.label,
    });
  }
  return alllabels;
}

export default Chart;
