
import React, {Component} from 'react';
import {listOfRegionFilters} from "../countries";
import {
  Select,
} from "antd";

const {Option} = Select;

export default function DropDownEditor({row, column, onRowChange}) {
  return (
    <Select
      mode="multiple"
      placeholder='Select Country'
      className='dropdown-css'
      value={row[column.key] && row[column.key].length ? row[column.key].split(',') : undefined}
      onChange={(value) => onRowChange({...row, [column.key]: value.join(',')}, true)}
      autoFocus
    >
      {listOfRegionFilters.map((country) => (
        <Option key={country} value={country}>
          {country}
        </Option>
      ))}
    </Select>
  );
}
