import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Typography } from "antd";
const { Title } = Typography;

export const appendixDemoData = {
  slideName: "appendix",
  id: "appendix",
  subtitle: "References",
  slug: "US PROBIOTICS MARKET",
  data: [{ text: "http://something", hyperLink: "something" }],
};

class Appendix extends React.Component {
  state = {
    data: this.props.data || appendixDemoData,
  };
  render() {
    const { id, subtitle, slug, data, sourceCountStart } = this.state.data;
    const { slideNo } = this.props;
    const pageId = id;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <div className="page" id={pageId}>
        <Title class="ProductsAndServicesHeading">
          {appendixDemoData.subtitle}
        </Title>
        <div className="main-content">
          <div>
            {data.map((source, index) => (
              <p>
                <a
                  href={source.text}
                  target="_blank"
                  key={source.hyperLink}
                  style={{ wordBreak: "break-all" }}
                >
                  {sourceCountStart + index}. {source}
                </a>
              </p>
            ))}
          </div>
        </div>
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Footer
            slug={slug}
            slideNo={slideNo}
            slideNoElementId={slideNoElementId}
          />
        ) : null}
      </div>
    );
  }
}

export default Appendix;
