import React, { Component } from "react";
import { Table, Typography } from "antd";
import Footer from "../components/Footer";
var Base64 = require("js-base64").Base64;
const { Title } = Typography;
export const SegmentDefinitionDemoData = {
  slideName: "recentdevelopment",
  title: "APPENDIX-1: SEGMENT DEFINITIONS",
  subtitle: "4.1 By Type",
  slug: "North America Probiotics Market",
  slideNo: 1,
  data: [
    {
      type: "Product Launches",
      date: "February 2018",
      description: [
        {
          text: "Calpis Co. Ltd. received approval for marketing Calsporin probiotic has been permitted in Europe for the use in feed for sows, suckling piglets, and dogs.",
          superScript: 6,
          hyperLink: "#abc",
        },
        {
          text: "Calpis Co. Ltd. received approval for marketing Calsporin probiotic has been permitted in Europe for the use in feed for sows, suckling piglets, and dogs.",
        },
      ],
    },
    {
      type: "Product Launches",
      date: "December 2019",
      description: [
        {
          text: "Kemin launched VANNIX™ C4 for the poultry producers.  The ingredient is a registered formulation of the natural, gut-health-fortifying ingredient, fabricated as a cost-effective solution for the producers to minimize the impact of enteric challenges to poultry performance. ",
        },
      ],
    },
    {
      type: "Product Launches",
      date: "June 2017",
      description: [
        {
          text: "Chr. Hansen launched an innovative multi-strain probiotic called GalliPro® Fit, it consists of three novel strains into one product. The probiotic is effective in improving performance parameters, intestinal stability, digestibility, and stimulate immune system and competitive exclusion in the poultry industry.",
        },
      ],
    },
    {
      type: "Mergers & Acquisitions",
      date: "June 2016",
      description: [
        {
          text: "Lallemand Animal Nutrition acquired Nova Microbial Technologies with an aim to provide natural microbial products and services for augmented animal performance and well-being.",
        },
      ],
    },
  ],
  id: "4.1 By Type",
};
export const data = [
  {
    key: "1",
    segment: "Carbohydrases",
    definition:
      "Carbohydrase improves the digestibility of animal feed, resulting in higher feed intake. Thus, this may increase the amount of energy an animal can use to develop muscle and grow. It works mainly by opening the cell wall structure of intact plant cells, releasing not only energy (starch) but also other nutrients, such as protein, minerals, and lipids.",
  },
  {
    key: "2",
    segment: "Phytases",
    definition:
      "Phytases are phosphor-hydrolytic enzymes that initiate the step-wise removal of phosphate from phytase. Adding phytases to animal feed allows the animal to absorb more of the phosphorus within the feed.",
  },
  {
    key: "3",
    segment: "Other Enzymes",
    definition:
      "Other enzymes comprise proteases, thioglucosidase, and celluloses. Among these, the majorly used enzyme is proteas",
  },
];

export const columns = [
  {
    title: "Segment",
    dataIndex: "segment",
    key: "segment",
    align: "left",
    width: "15%",
  },
  {
    title: "Definition",
    dataIndex: "definition",
    key: "definition",
    align: "left",
  },
];

export class SegmentDefiniton extends Component {
  state = {
    data: this.props.data || SegmentDefinitionDemoData,
    reseller: false
  };


  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }


  render() {
    let { id, slug } = this.state.data;
    const pageId = id ? Base64.encode(id) : undefined;
    const { slideNo } = this.props;
    return (
      <>
        <div className="page" id={pageId}>
          <div className="container" style={{ height: "663px" }}>
            <h2 class="heading">{SegmentDefinitionDemoData.title}</h2>
            <div className="SegmentDefinitionSubHeading">
              MARKET SEGMENTATION BY TYPE
            </div>
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              className="SegmentDefinitionTable"
              bordered={false}
              pagination={false}
              columns={columns}
              dataSource={data}
            />
          </div>
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer slug={slug} slideNo={slideNo} slideNoElementId={"2"} reseller={this.state.reseller}/>
          ) : null}
        </div>
      </>
    );
  }
}

export default SegmentDefiniton;
