import List from "@editorjs/list";

export const EDITOR_JS_TOOLS = {
  list: {
    class: List,
    inlineToolbar: true,
    toolbox: {
        title: 'Insert List',
    },
},
};
