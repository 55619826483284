import React, { Component } from "react";
import Mordor_icon from "../favicon.png";
import config from "../../../config/Api";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
FusionCharts.options.export.useCanvas = "true";
//Your react component
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "Venezuela",
          value: "290",
        },
        {
          label: "Saudi",
          value: "260",
        },
        {
          label: "Canada",
          value: "180",
        },
        {
          label: "Iran",
          value: "140",
        },
        {
          label: "Russia",
          value: "115",
        },
        {
          label: "UAE",
          value: "100",
        },
        {
          label: "US",
          value: "30",
        },
        {
          label: "China",
          value: "30",
        },
      ],
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
    };
    this.resetChart = this.resetChart.bind(this);
  }
  componentDidMount() {
    let datas = parseData(this.props.data);
    this.setState({ datas: datas, loadingData: false, data: this.props.data });
    setTimeout(() => {
      this.resetChart();
    }, 1000);
  }
  resetChart(event) {
    let a = window.btoa(JSON.stringify(this.props.data));
    let data = "cagr";
    FusionCharts.batchExport({
      charts: [
        {
          id: data,
        },
      ],
      exportFormat: "png",
      exportHandler: `${config.api.base_url}/api/graphs/${data}?data=${a}`,
      exportAction: "SAVE",
      exportMode: "client",
    });
  }
  render() {
    let data = "",
      datas = "";
    if (!this.state.loadingData) {
      data = this.state.datas;
      datas = this.state.data;
    }

    let width = 508,
      height = 700;

    let headers = "Countries With Most Oil Reserves [2017-18]";
    let footers = "Source:";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (datas && datas.heading) headers = datas.heading;
    if (datas && datas.heading) footers = datas.source;
    let chartConfigs = {
      id: "cagr",
      type: "column2d",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Market Summary",
          subCaption: `CAGR ${datas.cagr}%`,
          subCaptionFontSize: 28,
          subCaptionFont: "regular_regular",
          subCaptionFontColor: "#7f7f7f",
          divLineColor: "#ffffff",
          paletteColors: "#00CED1",
          captionFontColor: "#7f7f7f",
          showValues: this.props.data.labels === true ? true : false,
          showPlotBorder: "0",
          maxBarHeight: 2,
          animation: false,
          labelFontSize:
            datas.component && datas.component.toLowerCase() === "trends1"
              ? 34
              : datas.component && datas.component.toLowerCase() === "trends2"
              ? 34
              : 28,
          showYAxisValues: false,
          captionFontSize:
            datas.component && datas.component.toLowerCase() === "trends1"
              ? 36
              : datas.component &&
                datas.component.toLowerCase() === "key_factor"
              ? 28
              : 28,
          valueFontBold: false,
          theme: "fusion",
          plotSpacePercent: 40,
          chartLeftMargin:
            datas.component && datas.component.toLowerCase() === "trends1"
              ? 100
              : datas.component &&
                datas.component.toLowerCase() === "key_factor"
              ? 0
              : 100,
          captionAlignment: "center",
          valueFontColor: "#7f7f7f",
          placeValuesInside: "0",
          captionFont: "regular_semibold",
          yAxisNamePadding: 0,
          xAxisNameFont: "regular_medium",
          chartBottomMargin: 90,
          xAxisNameFontSize: 12,
          xAxisNameFontColor: "#7f7f7f",
          centerXaxisName: false,
          valueFont: "regular_medium",
          valueFontSize:
            datas.component && datas.component.toLowerCase() === "trends1"
              ? 36
              : datas.component && datas.component.toLowerCase() === "trends2"
              ? 36
              : 28,
        },
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize:
                    datas.component &&
                    datas.component.toLowerCase() === "trends1"
                      ? 36
                      : datas.component &&
                        datas.component.toLowerCase() === "key_factor"
                      ? 24
                      : 28,
                  font: "regular_semibold",
                  text: "Source :",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: width - 20,
                  x:
                    datas.component &&
                    datas.component.toLowerCase() === "trends1"
                      ? 115
                      : datas.component &&
                        datas.component.toLowerCase() === "key_factor"
                      ? 30
                      : 115,
                  y: height - 32,
                },
              ],
            },
            {
              id: "sub-footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "1",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  font: "regular_regular",
                  fillcolor: "#7f7f7f",
                  fontsize:
                    datas.component &&
                    datas.component.toLowerCase() === "trends1"
                      ? 36
                      : datas.component &&
                        datas.component.toLowerCase() === "key_factor"
                      ? 24
                      : 36,
                  text: footers,
                  wrap: "1",
                  wrapWidth: "100%",
                  x:
                    datas.component &&
                    datas.component.toLowerCase() === "trends1"
                      ? 245
                      : datas.component &&
                        datas.component.toLowerCase() === "key_factor"
                      ? 120
                      : 245,
                  y: height - 32,
                },
              ],
            },
            {
              id: "mordor-icon",
              xScale_: "5",
              yScale_: "5",
              items: [
                {
                  id: "Brian-user-icon",
                  type: "image",
                  url: Mordor_icon,
                  x:
                    datas.component &&
                    datas.component.toLowerCase() === "trends1"
                      ? 1022
                      : datas.component &&
                        datas.component.toLowerCase() === "key_factor"
                      ? 400
                      : 1022,
                  y:
                    datas.component &&
                    datas.component.toLowerCase() === "trends1"
                      ? height - 72
                      : datas.component &&
                        datas.component.toLowerCase() === "key_factor"
                      ? height - 72
                      : height - 72,
                  xScale:
                    datas.component &&
                    datas.component.toLowerCase() === "trends1"
                      ? 200
                      : datas.component &&
                        datas.component.toLowerCase() === "key_factor"
                      ? 200
                      : 200,
                  yScale:
                    datas.component &&
                    datas.component.toLowerCase() === "trends1"
                      ? 200
                      : datas.component &&
                        datas.component.toLowerCase() === "key_factor"
                      ? 200
                      : 200,
                },
              ],
            },
          ],
        },
        data: data,
      },
    };
    let scale = 0.3;
    let marginLeft = 400,
      marginTop = -900,
      marginTop_all = -740,
      marginBottom = 700;
    if (datas.component)
      if (datas.component.toLowerCase() === "key_factor") {
        scale = 0.3;
        marginLeft = 100;
        marginTop = -200;
        marginBottom = 160;
        marginTop_all = -90;
      }

    return (
      <div
        className="bar1"
        id="bar1"
        style={{
          textAlign: "center",
          marginBottom: marginBottom,
          fontFamily: "regular_regular",
          marginLeft: marginLeft,
          height: 300,
          width: 500,
          marginTop: marginTop_all,
        }}
      >
        <div
          style={{
            transform: `scale(${scale})`,
            marginLeft: -200,
            marginTop: marginTop,
          }}
        >
          <ReactFC {...chartConfigs} />
        </div>
      </div>
    );
  }
}
function parseData(data) {
  return data.data;
}
export default Chart;
