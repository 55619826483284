const roundOfMaxYAxisValue=(value)=>{

   let divValue=(value*1.22);
    let len=(divValue.toFixed(0)).toString().length-1;

    // console.log(len,"+++++++")
    let lenNO="1"
    for(let i=0;i<len;i++)
    lenNO+="0"
    divValue=(Math.ceil(divValue/Number(lenNO)))*Number(lenNO);
    return divValue;
}
const roundOfpositive_NegativeMaxValue=(max,min)=>{
    
    if(max<-1)
    max=max*-1;
    if(min<-1)
    min=min*-1;

    if(max<min)
    max=min;
    
        return Math.ceil(max / 5) * 5;
 }

module.exports={
    roundOfMaxYAxisValue,
    roundOfpositive_NegativeMaxValue
}