import React from 'react'

export default function LogoComponent(props) {
  return (
    <img
    style={{
      marginBottom: "20px",
      width: "100%",
      height: "143px",
      objectFit: "contain",
    }}
    src={props.logo_url}
    alt="company_logo"
  />
  )
}
