import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Tabs, Radio, Button, notification, Spin } from "antd";
import Analysis from "../components/Analysis";
import Graph from "../components/Graphs";
import { TypeOverviewContainer } from "../styles/TypeOverview.style";
import DataHidden from "../components/DataHidden";
import Editor from "../components/Editor";
import axios from "axios";
import config from "../config/Api";
import {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
} from "../reducersAndActions/Actions";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../Common/withWindowSizeHOC";

var Base64 = require("js-base64").Base64;

const { TabPane } = Tabs;

class TypeOverview extends React.Component {
  state = {
    graphFooterText:
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: this.props.data ? this.props.data : {},
    tabPosition: "top",
    editor_active_tab: "analysis",
    save_draft_loader: false,
    direct_publish_loader: false,
    reseller: false
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
    if(nextProps.data && nextProps.data.meta) {
      if(nextProps.data.meta.sample && nextProps.data.meta.reseller ) {
        this.setState({ reseller: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.title != this.props.data.title) {
      this.setState({
        data: this.props.data,
      });
      if(this.props.data && this.props.data.meta) {
        if(this.props.data.meta.sample && this.props.data.meta.reseller ) {
          this.setState({ reseller: true });
        }
      }
    }
    if (this.props.draft_drawer_visible !== prevProps.draft_drawer_visible) {
      if (this.props.draft_drawer_visible) {
        // console.log("perform api call");
        this.props.getDrafts(this.props.data.data.parent_id, this.props.template_type);
      }
    }

    if (
      this.props.published_drawer_visible !== prevProps.published_drawer_visible
    ) {
      if (this.props.published_drawer_visible) {
        // console.log("perform api call fromtype overview");
        this.props.getPublishedVersions(this.props.data.data.parent_id);
      }
    }


    if ((this.props.edit_mode !== prevProps.edit_mode)) {
      this.setState({
        editor_active_tab: 'analysis'
      })
    }

  }

  editorTabChange = (tab) => {
    this.setState({
      editor_active_tab: tab,
    });
  };

  handleSaveDraft = () => {
    this.setState({
      save_draft_loader: true,
    });
    // console.log("save draft button clicked ; perform save draft api call");
    const data = {
      parent_id: this.props.parent_id,
      type: this.props.template_type,
      analysis: this.props.draft_data_analysis,
      source: this.props.draft_data_sources,
      stage: this.props.draft_versions.stage ? this.props.draft_versions.stage : "INITIAL"
    };
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/drafts`,
      data: data,
    })
      .then((response) => {
        this.setState({
          save_draft_loader: false,
        });
        // console.log(response, "RESP");
        if (response.status === 200 || response.status === 201) {
          this.props.setDraftId(null)
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          save_draft_loader: false,
        });
        if (error.response) {
          notification.error({
            message: 'Error',
            description: error.response.data.message
          })
        }
      });
  };

  handleDirectPublish = () => {
    this.setState({
      direct_publish_loader: true,
    });
    // console.log("publish direct");
    const data = {
      parent_id: this.props.parent_id,
      analysis: this.props.draft_data_analysis,
      source: this.props.draft_data_sources,
      directPublished: true,
      type: this.props.template_type,
      stage: "PUBLISHED",
      cacheId: this.props.cache_id,
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions
    };
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/directPublish`,
      data: data,
    })
      .then((response) => {
        this.props.handleSwitchchange(false);
        this.setState({
          direct_publish_loader: false,
        });
        // console.log(response, "RESP");
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          direct_publish_loader: false,
        });
        if (error.response) {
          notification.error({
            message: 'Error',
            description: error.response.data.message
          })
        }
      });
  };

  handleEditoverlayClick = () => {
    this.props.setNoDraftsFound(false);
    this.props.setDraftStage('INITIAL');
  }

  render() {
    const { title, subtitle, slug, type, section_id } = this.props.data;
    const { slideNo } = this.props;
    const { graphs, slideName } = this.props.data.data;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    const { tabPosition } = this.state;
    let slideNoElementId = undefined;

    let time = Date.now();
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    let tabsElement = (<Tabs
      activeKey={this.state.editor_active_tab}
      tabPosition={tabPosition}
      onChange={this.editorTabChange}
      tabBarExtraContent={
        <div style={{ display: "flex", gap: "20px" }}>
          {this.props.ACTIVE_TAB === 'drafts' ?
            <Button
              type="primary"
              onClick={this.handleSaveDraft}
              loading={this.state.save_draft_loader}
              disabled={this.props.character_count_error}
            >
              Save
            </Button> : ''}
          {this.props.ACTIVE_TAB === 'published' ? <Button
            type="primary"
            onClick={this.handleDirectPublish}
            loading={this.state.direct_publish_loader}
          disabled={this.props.character_count_error}
          >
            DIRECT PUBLISH
          </Button> : ''}


        </div>
      }
      type="card"
      style={{ height: "100%" }}
    >
      <TabPane tab="Analysis" key="analysis" forceRender={true}>
        {this.props.specific_draft_loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} /> :
          <Analysis
            trends={this.state.data.data}
            edit_mode={this.props.edit_mode}
            section_id={`${section_id}-analysis`}
          />}
      </TabPane>
      <TabPane tab="Sources" key="sources" forceRender={true}>
        {/* Please enter list of Sources */}
        {this.props.specific_draft_loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} /> :
          <Editor
            section_id={`${section_id}-source`}
            sources={this.props.data.source}
          />}
      </TabPane>
    </Tabs>)
    return (
      <TypeOverviewContainer graphsLength={graphs.length} id={pageId}>
        <div className="page">
          {!this.props.fromInterface ||
            this.props.fromInterface == undefined ? (
            <Header
              heading={title}
              subheading={subtitle}
              slideTitle={this.state.data.data.slideName}
            />
          ) : null}
          <div className="container" style={{ height: this.props.fromInterface ? 'auto' : '626px' }}>
            <h2 className="heading" style={{ height: "10%" }}>
              {title}
            </h2>
            
            {graphs.length == 1 && graphs[0].graphType == "pie" ?
              <div className="oneGraph-container">
                <div className="pie-container-left">
                {this.props.edit_mode ? (
                          this.props.no_drafts_found && this.props.ACTIVE_TAB === 'drafts' ?
                            <div style={{ border: '1px solid grey', padding: '20px' }} className='no-drafts-overlay'>
                              <p>There is nothing in draft right now</p>
                              <Button onClick={this.handleEditoverlayClick}>Edit</Button>
                            </div>
                            : this.props.initial_draft_versions_loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                              : tabsElement

                        ) : (
                          <Analysis
                            trends={this.state.data.data}
                            edit_mode={this.props.edit_mode}
                            fromInterface={this.props.fromInterface}
                          />
                        )}

                </div>
                 <div className="pie-container-right">
                 <h3 className="subheading">
                          {graphs[0].heading}
                        </h3>
                        {graphs[0] && graphs[0].data != "hidden" ? (
                          <Graph
                          chartHeadingGap={graphs[0].heading.length > 60 ? 10 : 0}
                          yHeadingMargin={graphs[0].heading.length > 60 ? 30 : 25}
                          headers={graphs[0].heading}
                            data={graphs[0].data}
                            slideNo
                            width={
                              !this.props.fromInterface ||
                                this.props.fromInterface == undefined
                                ? "566"
                                : "566"
                            }
                            height={220}
                            totalData={graphs[0]}
                            graphType={graphs[0].graphType}
                          />
                        ) : (
                          <DataHidden />
                        )}

                </div>

              </div>
              :

              <>
                <div className="top-container" style={{ height: "45%" }}>
                  {graphs.length == 3 || (graphs.length == 2 && graphs[1].graphType != "pie") ? (
                    <>
                      <div className="left-graph left">
                        {/* <h3 className="subheading" style={{ marginBottom: 8 }}>
                          {graphs[0].heading}
                        </h3> */}
                        {graphs[0] && graphs[0].data != "hidden" ? (
                          <Graph
                          wrapHeadingWidth={-100}
                          // chartHeadingGap={0}
                          // yHeadingMargin={25}
                          chartHeadingGap={graphs[0].heading.length > 60 ? 10 : 0}
                        yHeadingMargin={graphs[0].heading.length > 60 ? 30 : 25}
                          xHeadingMargin={0}
                          headers={graphs[0].heading}
                          legendsYPosition={50}
                            totalData={graphs[0]}
                            data={graphs[0].data}
                            slideNo
                            width={
                              !this.props.fromInterface ||
                                this.props.fromInterface == undefined
                                ? "566"
                                : "100%"
                            }
                            dynamiclegends={graphs[0]["legends"]}
                            legendsPositionDynamic={true}
                            height={260}
                            rotateValues={1}
                            graphType={graphs[0].graphType}
                          />
                        ) : null}
                      </div>

                      <div className="right-graph right">
                        {/* <h3 className="subheading" style={{ marginBottom: 8 }}>
                          {graphs[1].heading}
                        </h3> */}
                        {graphs[1] && graphs[1].data != "hidden" ? (
                          <Graph
                          wrapHeadingWidth={-100}
                          // chartHeadingGap={0}
                          // yHeadingMargin={25}
                          chartHeadingGap={graphs[0].heading.length > 60 ? 10 : 0}
                          yHeadingMargin={graphs[0].heading.length > 60 ? 30 : 25}
                          xHeadingMargin={0}
                          headers={graphs[1].heading}
                          legendsYPosition={50}
                            totalData={graphs[1]}
                            data={graphs[1].data}
                            slideNo
                            width={
                              !this.props.fromInterface ||
                                this.props.fromInterface == undefined
                                ? "566"
                                : "100%"
                            }
                            height={260}
                            legendsPositionDynamic={true}
                            rotateValues={1}
                            graphType={graphs[1].graphType}
                            dynamiclegends={graphs[1]["legends"]}
                          />
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <div className="fullWidthContainer">
                      {/* <h3 className="subheading" style={{ marginBottom: 8 }}>
                        {graphs[0].heading}
                      </h3> */}
                      {graphs[0] && graphs[0].data != "hidden" ? (
                        <Graph
                        wrapHeadingWidth={400}
                        // chartHeadingGap={-30}
                        // yHeadingMargin={15}
                        chartHeadingGap={graphs[0].heading.length > 60 ? 0 : -30}
                        yHeadingMargin={graphs[0].heading.length > 60 ? 20 : 15}
                        xHeadingMargin={0}
                        headers={graphs[0].heading}
                          totalData={graphs[0]}
                          data={graphs[0].data}
                          slideNo
                          width={
                            !this.props.fromInterface ||
                              this.props.fromInterface == undefined
                              ? "1180"
                              : "100%"
                          }
                          height={260}
                          graphType={graphs[0].graphType}
                          dynamiclegends={graphs[0]["legends"]}
                        />
                      ) : null}
                    </div>
                  )}
                </div>

                <div className="bottom-container" style={{ height: "45%" , marginTop:"20px"}}>
                  {this.state.data.data.analysis.length || this.props.fromInterface ? ( 
                    graphs.length != 1 ? (
                      <div className="left-container left">
                        {this.props.edit_mode ? (
                          this.props.no_drafts_found && this.props.ACTIVE_TAB === 'drafts' ?
                            <div style={{ border: '1px solid grey', padding: '20px' }} className='no-drafts-overlay'>
                              <p>There is nothing in draft right now</p>
                              <Button onClick={this.handleEditoverlayClick}>Edit</Button>
                            </div>
                            : this.props.initial_draft_versions_loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                              : tabsElement

                        ) : (
                          <Analysis
                            trends={this.state.data.data}
                            edit_mode={this.props.edit_mode}
                            fromInterface={this.props.fromInterface}
                          />
                        )}
                      </div>

                    ) : (
                      <div className="left-container-full">
                        {this.props.edit_mode ? (
                          this.props.no_drafts_found && this.props.ACTIVE_TAB === 'drafts' ?
                            <div style={{ border: '1px solid grey', padding: '20px' }} className='no-drafts-overlay'>
                              <p>There is nothing in draft right now</p>
                              <Button onClick={this.handleEditoverlayClick}>Edit</Button>
                            </div>
                            : this.props.initial_draft_versions_loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                              : tabsElement

                        ) : (
                          <Analysis
                            trends={this.state.data.data}
                            edit_mode={this.props.edit_mode}
                            fromInterface={this.props.fromInterface}
                          />
                        )}
                      </div>
                    )) : null}
                  {graphs.length != 1 ? (
                    graphs.length == 3 ? (
                      <div
                        className="right-container right"
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/* <h3 className="subheading" style={{ marginBottom: 8 }}>
                          {graphs[2].heading}
                        </h3> */}
                        {graphs[2] && graphs[2].data != "hidden" ? (
                          <Graph 
                          // chartHeadingGap={0}
                          // yHeadingMargin={25}
                          chartHeadingGap={graphs[2].heading.length > 60 ? 10 : 0}
                          yHeadingMargin={graphs[2].heading.length > 60 ? 30 : 15}
                          xHeadingMargin={0}
                            headers={graphs[2].heading}
                            data={graphs[2].data}
                            slideNo
                            width={
                              !this.props.fromInterface ||
                                this.props.fromInterface == undefined
                                ? "566"
                                : "566"
                            }
                            height={260}
                            totalData={graphs[2]}
                            graphType={graphs[2].graphType}
                          />
                        ) : (
                          <DataHidden />
                        )}
                      </div>
                    ) : (
                      <div
                        className="right-container right"
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/* <h3 className="subheading" style={{ marginBottom: 8 }}>
                          {graphs[1].heading}
                        </h3> */}
                        {graphs[1] && graphs[1].data != "hidden" ? (
                          <Graph
                        //   chartHeadingGap={-30}
                        // yHeadingMargin={25}
                        chartHeadingGap={graphs[0].heading.length > 60 ? 0 : -30}
                        yHeadingMargin={graphs[0].heading.length > 60 ? 30 : 15}
                        xHeadingMargin={0}
                            headers={graphs[1].heading}
                            data={graphs[1].data}
                            slideNo
                            width={
                              !this.props.fromInterface ||
                                this.props.fromInterface == undefined
                                ? "566"
                                : "566"
                            }
                            height={260}
                            totalData={graphs[1]}
                            graphType={graphs[1].graphType}
                          />
                        ) : (
                          <DataHidden />
                        )}
                      </div>
                    )
                  ) : null}
                </div>
              </>
            }
          </div>
          {!this.props.fromInterface ||
            this.props.fromInterface == undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              slideNoElementId={slideNoElementId}
              fromInterface={this.props.fromInterface}
              reseller={this.state.reseller}
            />
          ) : null}
        </div>
      </TypeOverviewContainer>
    );
  }
}

// export default TypeOverview;

function mapStateToPros(state) {
  return {
    draft_data_analysis: state.draft_data_analysis,
    draft_data_sources: state.draft_data_sources,
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_versions: state.draft_versions,
    no_drafts_found: state.no_drafts_found,
    user_edit_access: state.user_edit_access,
    specific_draft_loading: state.specific_draft_loading,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    TOC_DATA: state.TOC_DATA,
    cache_id: state.cache_id,
    parent_id: state.parent_id,
    character_count_error: state.character_count_error,
    template_type: state.template_type,
  };
}
const mapDispatchToProps = {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(TypeOverview))
);
