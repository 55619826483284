import React, { Component } from "react";

import {  Button } from "antd";

class MaskedComponent extends Component {
    render(){
        return(
            <div className="MaskContainer">
                <div className="maskBox">
                <p>NO ACCESS TO {this.props.data.title}</p>
                <p>Please purchase this section to gain access.</p>
                <Button  type="primary" danger>Purchase</Button>
                </div>
                
            </div>

        )
    }

}

export default MaskedComponent;