import React from 'react'

export default function ExecutiveSummaryCard(props) {
  return (
    <div
    className="segment-block"
    style={{ backgroundColor: props.segmentBgcolor }}
  >
    <p className="segblk-value">{props.segmentBlockValue}</p>
    <p className="segblk-label">{props.segmentBlockLabel}</p>
  </div>
  )
}
