import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import scopeofstudystatic from "../images/scopeofstudystatic.svg"
var Base64 = require('js-base64').Base64;

export const scopeofstudystaticdemodata={
  "slug":"North America",
  "title":"North America",
  "id":"1.2 SCOPE OF THE STUDY"
}
export default class ScopeStudy extends React.Component{
  state={
    data:this.props.data||scopeofstudystaticdemodata
  }
  render(){
    const {id,slug} = this.state.data;
    const { slideNo } = this.props;
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
        slideNoElementId = 'slideNO' + pageId;
    };
    return(
      <div className="page" id={pageId}>
      {this.props.showheader?<Header slideTitle={this.state.data.type}/>:null}
      <div className="scopestudy-static">
        <img src={scopeofstudystatic} alt='scopeofstudystatic'/>
      </div>
      {!this.props.fromInterface || this.props.fromInterface==undefined?<Footer slug={slug} slideNo={slideNo}/>:null}
      </div>
    )
  }
}
