import React, { useState, useEffect } from 'react'

export const useWindowSize = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      updateWindowDimensions();
      window.addEventListener("resize", updateWindowDimensions);

      return () => {
        setWidth(0); // This worked for me
      };
    }, [])

     const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
     };

     return width;
}