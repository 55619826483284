import React from "react";
import Footer from "../components/Footer";

export const demodisclaimerdata={
  "slug":"North america"
  }
class Disclaimer extends React.Component{
  state={
    data:this.props.data||demodisclaimerdata,
    reseller: false
  }

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }

  
  render(){
    const { slideNo } = this.props;
    const {slug}=this.state.data
    return(
      <div className="page">
        <div className="disclaimer">
        <h2 className="heading">Disclaimer</h2>
        <p>Any information and/or material provided by Mordor Intelligence, including any and all the analysis and/or research from Mordor Intelligence, is provided to a selected group of customers, in response to orders for such information, material, analysis, and/or research. As a customer of Mordor Intelligence, you acknowledge that our information, material, and/or services are for your internal use only, and not for any external use and/or dissemination, or general publication, and/or disclosure to any third parties. </p>
        <p>Any and all of the information and/or material provided by Mordor Intelligence are based on primary interviews and/or secondary research, and are therefore, subject to fluctuation and variance. Mordor Intelligence takes no responsibility for any incorrect information and/or material supplied to us by sources we rely on, and no part of our analysis or research may be given, lent, resold, or disclosed to any third parties, including non-customers, without explicit or written permission from Mordor Intelligence.​</p>
        <p>Unauthorized reproduction and/or transmission of our information, material, analysis, and/or research in any form and by any means, including photocopying, mechanical/electronic recording, or otherwise, without the explicit and written permission of Mordor Intelligence, is expressly and clearly prohibited.​</p>
        <p>Any use of the information, material, analysis, and/or research provided by Mordor Intelligence is at your sole risk; you acknowledge that the information, material, analysis, and/or research is provided “as is” and that Mordor Intelligence provides no warranty of any kind, express or implied, with regard to the information, material, analysis, and/or research, including but not limited to, merchantability and fitness for any purpose and/or use.​</p>
        </div>
        {!this.props.fromInterface || this.props.fromInterface==undefined?<Footer slug={slug} slideNo={slideNo} reseller={this.state.reseller}/>:null}
      </div>
    )
  }
}

export default Disclaimer;