import React, { useContext, useState, useEffect } from "react";
import { auth } from "../Firebase";
import { sendEmailVerification } from "firebase/auth";
import config from "../../config/Config";

import { useHistory } from "react-router-dom";
import firebase from "firebase";

const firebaseAuth = firebase.auth;
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  let url = window.location.href;
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState();

  function logout() {
    return auth.signOut();
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  async function loginWithCustomToken(token, profilePictureURL, name, email) {
    try {
      let userCredential = await auth.signInWithCustomToken(token);

      if (userCredential) {
        let user = {
          profilePicture: profilePictureURL,
          name: name,
          email: email,
        };
        setProfilePicture(profilePictureURL);
        console.log("Sign In with custom claims result", userCredential);
        setCurrentUser(userCredential.user);

        if (
          window.location.pathname.toLowerCase() !== "/" &&
          //  window.location.pathname.toLowerCase() !== "/login" &&
          window.location.pathname.toLowerCase() !== "/forgotpassword" &&
          window.location.pathname.toLowerCase() !== "/signup"
        ) {
          window.localStorage.setItem("user", JSON.stringify(user));
        }

        return userCredential.user;
      } else throw userCredential;
    } catch (e) {
      console.log("Error at customSignIn", e);
    }
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  async function ResetPassword(email) {
    return auth
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      })
      .catch((err) => {
        return err || false;
      });
  }

  async function getCustomClaims() {
    try {
      let idTokenResult = await auth.currentUser.getIdTokenResult();
      if (idTokenResult && idTokenResult.claims) {
        return idTokenResult.claims;
      }
    } catch (e) {
      return { error: false };
    }
  }

  async function handleMicrosoftLogin() {
    let provider;
    provider = new firebaseAuth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      prompt: "consent",
      tenant: "058ffa1f-5c51-45d3-89a5-cff53e844deb",
    });
    return auth
      .signInWithPopup(provider)
      .then(async (data) => {
        return {
          name: data.additionalUserInfo.profile.displayName,
          email: data.additionalUserInfo.profile.mail.toLowerCase(),
          accessToken: data.credential.accessToken,
        };
      })
      .catch((e) => {
        console.log("LoginMicrosoft ERROR ", e);
        return false;
      });
  }

  async function sendVerificationEmail(user) {
    user = user.user;
    return user
      .sendEmailVerification()
      .then((verificationEmailResponse) => {
        console.log("verificationEmailResponse", verificationEmailResponse);
        return true;
      })
      .catch((e) => {
        console.log("Error at sendverification", e);
        if (e.code === `auth/too-many-requests`) return "attemptTimeout";
        else return false;
      });
  }

  function getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        let idTokenObj = await user.getIdTokenResult(true);
        if (
          window.location.pathname.toLowerCase() !== "/" &&
          window.location.pathname.toLowerCase() !== "/login" &&
          window.location.pathname.toLowerCase() !== "/forgotpassword" &&
          window.location.pathname.toLowerCase() !== "/signup"
        ) {
          window.localStorage.setItem(
            config.LOCAL_STORAGE_TOKEN.Name,
            idTokenObj.token
          );
          window.localStorage.setItem(
            config.LOCAL_STORAGE_TOKEN.meta,
            idTokenObj.issuedAtTime
          );
        }
      } else {
        window.localStorage.removeItem(config.LOCAL_STORAGE_TOKEN.Name);
        window.localStorage.removeItem(config.LOCAL_STORAGE_TOKEN.meta);
        window.localStorage.removeItem("user");
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [url]);

  const value = {
    getCurrentUser,
    currentUser,
    signup,
    login,
    logout,
    ResetPassword,
    sendVerificationEmail,
    loginWithCustomToken,
    getCustomClaims,
    profilePicture,
    handleMicrosoftLogin,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
