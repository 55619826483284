import React from 'react'

export default function OverviewComponent(props) {
  return (
    <div className="company_bio" style={{background: props.bgColor}}>
    <h3 className="subheading" style={{color: props.color}}>
      {props.heading}
    </h3>
    {props.analysis && props.analysis.length ? props.analysis.map((item) => (
            <div className="company_content">
              <p1 style={{ marginBottom: "15px", color: props.color }}>
                {item}
              </p1>
            </div>
          )
        )
          : ""}
  </div>
  )
}
