import React, { Component } from "react";

import MordorIcon from "../../RDGraphs/GraphComponents/favicon.png";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "2015",
          value: "100",
        },
        {
          label: "2016",
          value: "30",
        },
        {
          label: "2017",
          value: "30",
        },
      ],
    };
    this.datavalidate = this.datavalidate.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      this.setState({
        data: this.datavalidate(this.props.data.data),
      });
    }
  }
  datavalidate(data) {
    for (let item of data) {
      item.label = item.label + "";
    }
    return data;
  }

  render() {
    let width = 400,
      height = 240,
      icon = MordorIcon;
    let headers = "Countries With Most Oil Reserves [2017-18]";
    let footers =
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.footers) footers = this.props.footers;
    if (this.props.icon) icon = this.props.icon;
    footers = "";
    let chartConfigs = {
      type: "line",
      width: width,
      height: height,
      dataFormat: "json",

      dataSource: {
        chart: {
          paletteColors: "#9e0e0e",
          captionFontColor: "#7f7f7f",
          showValues: false,
          showPlotBorder: "0",
          maxBarHeight: 2,
          animation: false,
          labelFontSize: 12,
          showYAxisValues: true,
          showLabels: true,
          labelDisplay: "WRAP",
          captionFontSize: 14,
          valueFontBold: false,
          theme: "fusion",
          adjustDiv: "1",
          divLineDashed: false,
          divLineColor: "#C0C0C0",
          bgColor: "#ffffff",
          captionAlignment: "left",
          valueFontColor: "#7f7f7f",
          placeValuesInside: "0",
          captionFont: "Regular Bold",
          yAxisNamePadding: 0,
          chartBottomMargin: 0,
          showanchors: "1",
          anchorRadius: 3,
          anchorBorderColor: "#9e0e0e",
          anchorBorderThickness: 1,
          anchorBgColor: "#ffffff",
          anchorSides: 0,
          centerXaxisName: false,
          lineThickness: "2",
          valueFont: "regular",
          valueFontSize: 12,
          baseFont: "regular semibold",
          bgImageAlpha: "60",
          valuePosition: "outside",
          bgImageScale: "400",
          bgImageVAlign: "bottom",
          bgImageHAlign: "middle",
          bgImageDisplayMode: "center",
        },
        data: [
          ...this.state.data,
          {
            vLine: "true",
          },
        ],
      },
    };
    return (
      <div
        className="line"
        id="line"
        style={{
          textAlign: "left",
          width: "100%",
          marginBottom: 10,
          fontFamily: "regular",
        }}
      >
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

export default Chart;
