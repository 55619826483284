import React from "react";
import GraphAction from "../RDGraphs/GraphActions";
import GraphActionCarg from "../RDGraphs/GraphActionCagr";
import MarketGraph from "../RDGraphs/GraphComponents/MarketConcentration/Market";

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
    };
  }
  componentDidMount() {
    let k = window.location.search.replace("?", "");

    let kata = JSON.parse(atob(k));

    if (kata) this.setState({ data: kata, loading: false });
  }
  render() {
    let graph = "";
    if (!this.state.loading) {
      if (this.state.data.market) {
        graph = <MarketGraph data={this.state.data} />;
      } else
        graph = this.state.data.cagr ? (
          <GraphActionCarg data={this.state.data} />
        ) : (
          <GraphAction data={this.state.data} />
        );
    }

    return <div>{graph}</div>;
  }
}

export default Graph;
