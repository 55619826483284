import React, { useEffect, useRef, useState } from 'react';
import EditorJs from 'react-editor-js';
import {EDITOR_JS_TOOLS} from '../constants';
import { setDraftDataSources, setTocData } from "../reducersAndActions/Actions";
import { connect } from "react-redux";
import isEqual from 'react-fast-compare';


const Editor= (props) => {
  const instanceRef = useRef(null);
  const [editorState, seteditorState] = useState([])
  const [init, setInit] = useState(false);

  useEffect(() => {
    if(instanceRef && instanceRef.current){
      instanceRef.current.isReady.then(()=>{
        instanceRef.current.clear();
        setInit(true);
      }).catch(()=>{})
    }
 }, [JSON.stringify(props.sources)]);

  async function handleSave() {
    setInit(false);
    instanceRef.current
      .save()
      .then((outputData) => {
        props.setDraftDataSources(outputData.blocks[0].data.items);
        seteditorState(outputData.blocks[0].data.items)
      })
      .catch((error) => {
        console.log('Saving failed: ', error);
      });
  }



  let sectionID = props.section_id;
  return (
      <EditorJs
      holder='sources-editor'
      tools={EDITOR_JS_TOOLS}
        enableReInitialize={init}
        instanceRef={(instance) => (instanceRef.current = instance)}
        data={{
          blocks: [
            {
              type: "list",
              data: {
                style: "unordered",
                items: props.sources.map(
                  (item, index) => item.text
                ),
              },
            },
          ],
          version: "2.12.4",
        }}
        onCompareBlocks={(newData, oldData) =>   isEqual(newData,oldData)}
       
        onChange={handleSave}

      >
          <div 
        id='sources-editor'
      >        
      </div>
      </EditorJs>
  );
};


function mapStateToPros(state) {
  return {
    draft_data_sources: state.draft_data_sources,
  };
}
const mapDispatchToProps = {
  setDraftDataSources,
};
export default connect(mapStateToPros, mapDispatchToProps)(Editor);





