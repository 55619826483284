import React, { Component } from "react";
import IntroductionWrapper from "../styles/Introduction.style";
import Footer from "../components/Footer";
import intro_logo from "../images/intro_logo.png";
import frame from "../images/Frame.png";
var Base64 = require("js-base64").Base64;

export const IntroDemoData = {
  "title": "Introduction",
  "subtitle": "Introduction",
  "type": "introduction",
  "template": "introduction",
  "data": [
    {
      "text": "Study Assumptions & Market Definition"
    },
    {
      "text": "Scope of the Study​"
    },
    {
      "text": "Research Methodology"
    }
  ]
}

class Introduction extends Component {
  state = {
    data:  this.props.data || IntroDemoData ,
    reseller: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }

  

  render() {
    let { section_id, slug } = this.state.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
   
    let text = this.state.data && this.state.data.data && this.state.data.data.map((item) => {
     
      let href = "#" + Base64.encode(item.section_id);
      return (
        <p style={{marginRight:"32px"}}>
          <a style={{ color: "white", textDecoration: "none" ,textTransform:"capitalize"}} href={href}>
            {item.text}
          </a>
        </p>
      );
    });

    return (
      <div className="page" id={pageId}>
        <IntroductionWrapper>
          <div style={{ width: "60%" }} >
            <h1>{this.state.data.title}</h1>
            <div className="intro-list-container">
            {text}
            </div>
            
            <img className="bg1" src={intro_logo} alt="" />
            
          </div>
          {/* <img className="img_frame" src={frame} alt="img" /> */}
        </IntroductionWrapper>
        {!this.props.fromInterface || this.props.fromInterface==undefined?<Footer slug={slug} slideNo={slideNo} reseller={this.state.reseller}/>:null}
      </div>
    );
  }
}

export default Introduction;
