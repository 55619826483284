import styled from "styled-components";

export const TypeOverviewContainer = styled.div`
  .top-container {
  }
  .top-container,
  .bottom-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }

  .container .right,
  .container .left {
    width: 48%;
  }
  .left-container-full,
  .fullWidthContainer {
    width: 100%;
  }

  .left-container,
  .right-container {
    display: flex;
    flex-direction: column;
  }
  .oneGraph-container{
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .pie-container-left,.pie-container-right{
    width:48%;
  }

  @media only screen and (max-width: 960px) {
    .top-container,
    .bottom-container {
      flex-direction: column;
    }
    .container .right,
    .container .left {
      width: 100%;
    }
  }
`;
