import React, { Component } from 'react';
import Footer from '../components/Footer';
import TocUpdatedWrapper from '../styles/TocUpdated.style';
import toc_map from '../images/toc_map.png';
var Base64 = require('js-base64').Base64;

export const tocDemoData = [
  {
    slideName: "detailtoc",
    data: [
      [
        {
          text: "​EXECUTIVE SUMMARY",
          type: "superlink",
          pageId: "​EXECUTIVE SUMMARY",
          length: 5,
        },
        {
          text: "1. INTRODUCTION",
          type: "superlink",
          pageId: "1. INTRODUCTION",
          length: 3,
        },
        {
          text: "1.1 Study Assumptions & Market Definitions",
          type: "sublink",
          pageId: "1.1 STUDY ASSUMPTIONS & MARKET DEFINATION",
          length: 2,
        },
        {
          text: "1.2 Scope Of The Study",
          type: "sublink",
          pageId: "1.2 SCOPE OF THE STUDY",
          length: 1,
        },
        {
          text: "1.3 Research Methodology",
          type: "sublink",
          pageId: "1.3 RESEARCH METHODOLOGY",
          length: 2,
        },
        {
          text: "2. MARKET TRENDS",
          type: "superlink",
          pageId: "2 PARENT MARKET TRENDS",
          length: 4,
        },
        {
          text: "2.1 Live Stock Industry Trends",
          type: "sublink",
          pageId: "2.1 LIVESTOCK INDUSTRY TRENDS",
          length: 2,
        },
        {
          text: "2.2 Feed Production Trends",
          type: "sublink",
          pageId: "2.2 FEED PRODUCTION TRENDS",
          length: 2,
        },
        {
          text: "2.3 Regulatory Framework",
          type: "sublink",
          pageId: "2.3 REGULATORY FRAMEWORK",
          length: 2,
        },
        {
          text: "2.4 Decision Maker Analysis",
          type: "sublink",
          pageId: "2.4 DECISION MAKER ANALYSIS",
          length: 2,
        },
        {
          text: "2.5 Value Chain & Distribution Channel Analysis",
          type: "sublink",
          pageId: "2.5 VALUE CHAIN & DISTRIBUTION CHANNEL ANALYSIS",
          length: 3,
        },
      ],
      [
        {
          text: "3 MARKET SEGMENTATION",
          type: "superlink",
          pageId: "3 MARKET SEGMENTATION",
          length: 4,
        },
        {
          text: "3.1 By Type",
          type: "link",
          pageId: "3.1 By Type",
          length: 1,
        },
        {
          text: "3.1.1 Other Probioics",
          type: "sublink",
          pageId: "3.1.1 Other Probioics",
          length: 1,
        },
        {
          text: "3.1.2 Bifidobacteria",
          type: "sublink",
          pageId: "3.1.2 Bifidobacteria",
          length: 1,
        },
        {
          text: "3.1.3 Lactobacilli",
          type: "sublink",
          pageId: "3.1.3 Lactobacilli",
          length: 1,
        },
        {
          text: "3.2 By Animal Type",
          type: "link",
          pageId: "3.2 By Animal Type",
          length: 1,
        },
        {
          text: "3.2.1 Other Animal Types",
          type: "sublink",
          pageId: "3.2.1 Other Animal Types",
          length: 2,
        },
        {
          text: "3.2.2 Ruminant",
          type: "sublink",
          pageId: "3.2.2 Ruminant",
          length: 1,
        },
        {
          text: "3.2.3 Swine",
          type: "sublink",
          pageId: "3.2.3 Swine",
          length: 1,
        },
        {
          text: "3.2.4 Poultry",
          type: "sublink",
          pageId: "3.2.4 Poultry",
          length: 1,
        },
        {
          text: "3.2.5 Aquaculture",
          type: "sublink",
          pageId: "3.2.5 Aquaculture",
          length: 1,
        },
        {
          text: "3.3 By Geography",
          type: "link",
          pageId: "3.3 By Geography",
          length: 1,
        },
        {
          text: "3.3.1 North America",
          type: "link",
          pageId: "3.3.1 North America",
          length: 1,
        },
        {
          text: "3.3.1.1 Mexico",
          type: "sublink",
          pageId: "3.3.1.1 Mexico",
          length: 1,
        },
        {
          text: "3.3.1.2 United States",
          type: "sublink",
          pageId: "3.3.1.2 United States",
          length: 1,
        },
        {
          text: "3.3.1.3 Canada",
          type: "sublink",
          pageId: "3.3.1.3 Canada",
          length: 1,
        },
        {
          text: "3.3.1.4 Rest of North America",
          type: "sublink",
          pageId: "3.3.1.4 Rest of North America",
          length: 2,
        },
      ],
      [
        {
          text: "3.3.2 Africa",
          type: "link",
          pageId: "3.3.2 Africa",
          length: 1,
        },
        {
          text: "3.3.2.1 Rest of Africa",
          type: "sublink",
          pageId: "3.3.2.1 Rest of Africa",
          length: 1,
        },
        {
          text: "3.3.2.2 South Africa",
          type: "sublink",
          pageId: "3.3.2.2 South Africa",
          length: 1,
        },
        {
          text: "3.3.2.3 Kenya",
          type: "sublink",
          pageId: "3.3.2.3 Kenya",
          length: 1,
        },
        {
          text: "3.3.2.4 Egypt",
          type: "sublink",
          pageId: "3.3.2.4 Egypt",
          length: 1,
        },
        {
          text: "3.3.3 Middle East",
          type: "link",
          pageId: "3.3.3 Middle East",
          length: 1,
        },
        {
          text: "3.3.3.1 Turkey",
          type: "sublink",
          pageId: "3.3.3.1 Turkey",
          length: 1,
        },
        {
          text: "3.3.3.2 Rest of Middle East",
          type: "sublink",
          pageId: "3.3.3.2 Rest of Middle East",
          length: 2,
        },
        {
          text: "3.3.3.3 Israel",
          type: "sublink",
          pageId: "3.3.3.3 Israel",
          length: 1,
        },
        {
          text: "3.3.3.4 Iran",
          type: "sublink",
          pageId: "3.3.3.4 Iran",
          length: 1,
        },
        {
          text: "3.3.3.5 Saudi Arabia",
          type: "sublink",
          pageId: "3.3.3.5 Saudi Arabia",
          length: 1,
        },
        {
          text: "3.3.4 Europe",
          type: "link",
          pageId: "3.3.4 Europe",
          length: 1,
        },
        {
          text: "3.3.4.1 Germany",
          type: "sublink",
          pageId: "3.3.4.1 Germany",
          length: 1,
        },
        {
          text: "3.3.4.2 Italy",
          type: "sublink",
          pageId: "3.3.4.2 Italy",
          length: 1,
        },
        {
          text: "3.3.4.3 Spain",
          type: "sublink",
          pageId: "3.3.4.3 Spain",
          length: 1,
        },
        {
          text: "3.3.4.4 France",
          type: "sublink",
          pageId: "3.3.4.4 France",
          length: 1,
        },
        {
          text: "3.3.4.5 Ukraine",
          type: "sublink",
          pageId: "3.3.4.5 Ukraine",
          length: 1,
        },
        {
          text: "3.3.4.6 Denmark",
          type: "sublink",
          pageId: "3.3.4.6 Denmark",
          length: 1,
        },
        {
          text: "3.3.4.7 Poland",
          type: "sublink",
          pageId: "3.3.4.7 Poland",
          length: 1,
        },
      ],
      [
        {
          text: "3.3.4.8 Ireland",
          type: "sublink",
          pageId: "3.3.4.8 Ireland",
          length: 1,
        },
        {
          text: "3.3.4.9 Rest of Europe",
          type: "sublink",
          pageId: "3.3.4.9 Rest of Europe",
          length: 1,
        },
        {
          text: "3.3.4.10 United Kingdom",
          type: "sublink",
          pageId: "3.3.4.10 United Kingdom",
          length: 1,
        },
        {
          text: "3.3.4.11 Russia",
          type: "sublink",
          pageId: "3.3.4.11 Russia",
          length: 1,
        },
        {
          text: "3.3.4.12 Netherlands",
          type: "sublink",
          pageId: "3.3.4.12 Netherlands",
          length: 1,
        },
        {
          text: "3.3.5 South America",
          type: "link",
          pageId: "3.3.5 South America",
          length: 1,
        },
        {
          text: "3.3.5.1 Colombia",
          type: "sublink",
          pageId: "3.3.5.1 Colombia",
          length: 1,
        },
        {
          text: "3.3.5.2 Argentina",
          type: "sublink",
          pageId: "3.3.5.2 Argentina",
          length: 1,
        },
        {
          text: "3.3.5.3 Rest of South America",
          type: "sublink",
          pageId: "3.3.5.3 Rest of South America",
          length: 2,
        },
        {
          text: "3.3.5.4 Brazil",
          type: "sublink",
          pageId: "3.3.5.4 Brazil",
          length: 1,
        },
        {
          text: "3.3.6 Asia-Pacific",
          type: "link",
          pageId: "3.3.6 Asia-Pacific",
          length: 1,
        },
        {
          text: "3.3.6.1 South Korea",
          type: "sublink",
          pageId: "3.3.6.1 South Korea",
          length: 1,
        },
        {
          text: "3.3.6.2 Thailand",
          type: "sublink",
          pageId: "3.3.6.2 Thailand",
          length: 1,
        },
        {
          text: "3.3.6.3 New Zealand",
          type: "sublink",
          pageId: "3.3.6.3 New Zealand",
          length: 1,
        },
        {
          text: "3.3.6.4 Rest of Asia-Pacific",
          type: "sublink",
          pageId: "3.3.6.4 Rest of Asia-Pacific",
          length: 2,
        },
        {
          text: "3.3.6.5 Japan",
          type: "sublink",
          pageId: "3.3.6.5 Japan",
          length: 1,
        },
        {
          text: "3.3.6.6 Pakistan",
          type: "sublink",
          pageId: "3.3.6.6 Pakistan",
          length: 1,
        },
        {
          text: "3.3.6.7 Australia",
          type: "sublink",
          pageId: "3.3.6.7 Australia",
          length: 1,
        },
      ],
    ],
    title: "TABLE OF CONTENTS",
    slug: "Global Probiotics Market",
  },
  {
    slideName: "detailtoc",

    data: [
      [
        {
          text: "3.3.6.8 India",
          type: "sublink",
          pageId: "3.3.6.8 India",
          length: 1,
        },
        {
          text: "3.3.6.9 Indonesia",
          type: "sublink",
          pageId: "3.3.6.9 Indonesia",
          length: 1,
        },
        {
          text: "3.3.6.10 Vietnam",
          type: "sublink",
          pageId: "3.3.6.10 Vietnam",
          length: 1,
        },
        {
          text: "3.3.6.11 China",
          type: "sublink",
          pageId: "3.3.6.11 China",
          length: 1,
        },
        {
          text: "4 COMPETITIVE LANDSCAPE",
          type: "superlink",
          pageId: "4 COMPETITIVE LANDSCAPE",
          length: 4,
        },
        {
          text: "4.1 KEY STRATEGY MOVES",
          type: "sublink",
          pageId: "4.1 KEY STRATEGY MOVES",
          length: 1,
        },
        {
          text: "4.2 MARKET SHARE ANALYSIS",
          type: "sublink",
          pageId: "4.2 MARKET SHARE ANALYSIS",
          length: 2,
        },
        {
          text: "4.3 COMPANY PROFILES",
          type: "sublink",
          pageId: "4.3 COMPANY PROFILES",
          length: 1,
        },
        {
          text: "​KEY STRATEGIC THEMES FOR FEED ADDITIVE CEOS",
          type: "superlink",
          pageId: "​KEY STRATEGIC THEMES FOR FEED ADDITIVE CEOS",
          length: 7,
        },
        {
          text: "APPENDIX",
          type: "superlink",
          pageId: "APPENDIX",
          length: 3,
        },
        {
          text: "Appendix-1: Segment Definations",
          type: "sublink",
          pageId: "Appendix-1: Segment Definations",
          length: 2,
        },
        {
          text: "Appendix-1: Segment Definations",
          type: "sublink",
          pageId: "Appendix-1: Segment Definations",
          length: 2,
        },
        {
          text: "Appendix-1: Segment Definations",
          type: "sublink",
          pageId: "Appendix-1: Segment Definations",
          length: 2,
        },
      ],
      [
        {
          text: "Appendix-2: Products & Services Considered",
          type: "sublink",
          pageId: "Appendix-2: Products & Services Considered",
          length: 2,
        },
        {
          text: "Appendix-1: Sources & references",
          type: "sublink",
          pageId: "Appendix-1: Sources & references",
          length: 2,
        },
        {
          text: "Appendix-1: List Of Tables & Figures",
          type: "sublink",
          pageId: "Appendix-1: List Of Tables & Figures",
          length: 2,
        },
      ],
    ],
    title: "TABLE OF CONTENTS",
    slug: "Global Probiotics Market",
  },
];

const pdata = {
  slideName: "detailtoc",
  detailtoc: 0,
  data: [
    [
      {
        text: "​EXECUTIVE SUMMARY",
        type: "superlink",
        pageId: "​EXECUTIVE SUMMARY",
        length: 5,
      },
      {
        text: "1. INTRODUCTION",
        type: "superlink",
        pageId: "1. INTRODUCTION",
        length: 3,
      },
      {
        text: "1.1 STUDY ASSUMPTIONS & MARKET DEFINATION",
        type: "sublink",
        pageId: "1.1 STUDY ASSUMPTIONS & MARKET DEFINATION",
        length: 2,
      },
      {
        text: "1.2 SCOPE OF THE STUDY",
        type: "sublink",
        pageId: "1.2 SCOPE OF THE STUDY",
        length: 1,
      },
      {
        text: "1.3 RESEARCH METHODOLOGY",
        type: "sublink",
        pageId: "1.3 RESEARCH METHODOLOGY",
        length: 2,
      },
      {
        text: "2 PARENT MARKET TRENDS",
        type: "superlink",
        pageId: "2 PARENT MARKET TRENDS",
        length: 4,
      },
      {
        text: "2.1 LIVESTOCK INDUSTRY TRENDS",
        type: "sublink",
        pageId: "2.1 LIVESTOCK INDUSTRY TRENDS",
        length: 2,
      },
      {
        text: "2.2 FEED PRODUCTION TRENDS",
        type: "sublink",
        pageId: "2.2 FEED PRODUCTION TRENDS",
        length: 2,
      },
      {
        text: "2.3 REGULATORY FRAMEWORK",
        type: "sublink",
        pageId: "2.3 REGULATORY FRAMEWORK",
        length: 2,
      },
      {
        text: "2.4 DECISION MAKER ANALYSIS",
        type: "sublink",
        pageId: "2.4 DECISION MAKER ANALYSIS",
        length: 2,
      },
      {
        text: "2.5 VALUE CHAIN & DISTRIBUTION CHANNEL ANALYSIS",
        type: "sublink",
        pageId: "2.5 VALUE CHAIN & DISTRIBUTION CHANNEL ANALYSIS",
        length: 3,
      },
    ],
    [
      {
        text: "3 MARKET SEGMENTATION",
        type: "superlink",
        pageId: "3 MARKET SEGMENTATION",
        length: 4,
      },
      {
        text: "3.1 By Type",
        type: "link",
        pageId: "3.1 By Type",
        length: 1,
      },
      {
        text: "3.1.1 Carbohydrases",
        type: "sublink",
        pageId: "3.1.1 Carbohydrases",
        length: 1,
      },
      {
        text: "3.1.2 Phytases",
        type: "sublink",
        pageId: "3.1.2 Phytases",
        length: 1,
      },
      {
        text: "3.1.3 Other Enzymes",
        type: "sublink",
        pageId: "3.1.3 Other Enzymes",
        length: 1,
      },
      {
        text: "3.2 By Animal Type",
        type: "link",
        pageId: "3.2 By Animal Type",
        length: 1,
      },
      {
        text: "3.2.1 Ruminant",
        type: "sublink",
        pageId: "3.2.1 Ruminant",
        length: 1,
      },
      {
        text: "3.2.2 Aquaculture",
        type: "sublink",
        pageId: "3.2.2 Aquaculture",
        length: 1,
      },
      {
        text: "3.2.3 Poultry",
        type: "sublink",
        pageId: "3.2.3 Poultry",
        length: 1,
      },
      {
        text: "3.2.4 Swine",
        type: "sublink",
        pageId: "3.2.4 Swine",
        length: 1,
      },
      {
        text: "3.2.5 Other Animal Types",
        type: "sublink",
        pageId: "3.2.5 Other Animal Types",
        length: 2,
      },
      {
        text: "3.3 North America",
        type: "link",
        pageId: "3.3 North America",
        length: 1,
      },
      {
        text: "3.3.1 Canada",
        type: "sublink",
        pageId: "3.3.1 Canada",
        length: 1,
      },
      {
        text: "3.3.2 Mexico",
        type: "sublink",
        pageId: "3.3.2 Mexico",
        length: 1,
      },
      {
        text: "3.3.3 Rest of North America",
        type: "sublink",
        pageId: "3.3.3 Rest of North America",
        length: 2,
      },
      {
        text: "3.3.4 United States",
        type: "sublink",
        pageId: "3.3.4 United States",
        length: 1,
      },
    ],
    [
      {
        text: "4 COMPETITIVE LANDSCAPE",
        type: "superlink",
        pageId: "4 COMPETITIVE LANDSCAPE",
        length: 4,
      },
      {
        text: "4.1 KEY STRATEGY MOVES",
        type: "sublink",
        pageId: "4.1 KEY STRATEGY MOVES",
        length: 1,
      },
      {
        text: "4.2 MARKET SHARE ANALYSIS",
        type: "sublink",
        pageId: "4.2 MARKET SHARE ANALYSIS",
        length: 2,
      },
      {
        text: "4.3 COMPANY PROFILES",
        type: "link",
        pageId: "4.3 COMPANY PROFILES",
        length: 1,
      },
      {
        text: "4.3.1 BASF SE",
        type: "sublink",
        pageId: "4.3.1 BASF SE",
        length: 1,
      },
      {
        text: "​KEY STRATEGIC THEMES FOR FEED ADDITIVE CEOS",
        type: "superlink",
        pageId: "​KEY STRATEGIC THEMES FOR FEED ADDITIVE CEOS",
        length: 7,
      },
      {
        text: "APPENDIX",
        type: "superlink",
        pageId: "APPENDIX",
        length: 3,
      },
      {
        text: "Appendix-1: Segment Definations",
        type: "sublink",
        pageId: "Appendix-1: Segment Definations",
        length: 2,
      },
      {
        text: "Appendix-2: Products & Services Considered",
        type: "sublink",
        pageId: "Appendix-2: Products & Services Considered",
        length: 2,
      },
    ],
    [
      {
        text: "Appendix-1: Sources & references",
        type: "sublink",
        pageId: "Appendix-1: Sources & references",
        length: 2,
      },
      {
        text: "Appendix-1: List Of Tables & Figures",
        type: "sublink",
        pageId: "Appendix-1: List Of Tables & Figures",
        length: 2,
      },
    ],
  ],
  title: "TABLE OF CONTENTS",
  slug: "North America Enzymes Market",
};
let toc = [
  {
    hub_id: "5ff7e8ae1a0d2e710d2e6749",
    dimensions: {
      region: "All",
      country: "All",
      additive: "Enzymes",
      animal: "All",
      "sub-additive": "All",
      "sub-animal": "All",
    },
    meta: {
      subtitle:
        "Global market size and forecasts by type and by animal type, including sub-segment-level analysis. ​\n        (Volume in thousand metric ton and Revenue in USD million)",
      slideName: "introduction",
      index: 3,
    },
    title: "MARKET SEGMENTATION",
    children: [
      {
        title: "By Type",
        dimensions: {
          region: "All",
          country: "All",
          additive: "Enzymes",
          animal: "All",
          "sub-additive": "All",
          "sub-animal": "All",
        },
        meta: {
          type: "sub-additive",
          template: "overviewSlide",
          slideName: "typeoverview",
        },
        children: [
          {
            title: "Carbohydrases",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "Carbohydrases",
              "sub-animal": "All",
            },
            meta: {
              type: "sub-additive",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Phytases",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "Phytases",
              "sub-animal": "All",
            },
            meta: {
              type: "sub-additive",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Other Enzymes",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "Other Enzymes",
              "sub-animal": "All",
            },
            meta: {
              type: "sub-additive",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
        ],
      },
      {
        title: "By Animal Type",
        dimensions: {
          region: "All",
          country: "All",
          additive: "Enzymes",
          animal: "All",
          "sub-additive": "All",
          "sub-animal": "All",
        },
        meta: {
          type: "animal",
          template: "overviewSlide",
          slideName: "typeoverview",
        },
        children: [
          {
            title: "Ruminant",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Ruminant",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Poultry",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Poultry",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Aquaculture",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Aquaculture",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Swine",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Swine",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Other Animal Types",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Other Animal Types",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
        ],
      },
      {
        title: "By Geography",
        dimensions: {
          region: "All",
          country: "All",
          additive: "Enzymes",
          animal: "All",
          "sub-additive": "All",
          "sub-animal": "All",
        },
        meta: {
          template: "globalOverviewSlide",
          type: "region",
          slideName: "globaloverview",
        },
        children: [
          {
            title: "Asia-Pacific",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Australia",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Australia",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "China",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "China",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "India",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "India",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Indonesia",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Indonesia",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Japan",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Japan",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "New Zealand",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "New Zealand",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Pakistan",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Pakistan",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "South Korea",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "South Korea",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Thailand",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Thailand",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Vietnam",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Vietnam",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of Asia-Pacific",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Rest of Asia-Pacific",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
          {
            title: "North America",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Canada",
                dimensions: {
                  region: "North America",
                  country: "Canada",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Mexico",
                dimensions: {
                  region: "North America",
                  country: "Mexico",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "United States",
                dimensions: {
                  region: "North America",
                  country: "United States",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of North America",
                dimensions: {
                  region: "North America",
                  country: "Rest of North America",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
          {
            title: "Europe",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Denmark",
                dimensions: {
                  region: "Europe",
                  country: "Denmark",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "France",
                dimensions: {
                  region: "Europe",
                  country: "France",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Germany",
                dimensions: {
                  region: "Europe",
                  country: "Germany",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Ireland",
                dimensions: {
                  region: "Europe",
                  country: "Ireland",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Italy",
                dimensions: {
                  region: "Europe",
                  country: "Italy",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Netherlands",
                dimensions: {
                  region: "Europe",
                  country: "Netherlands",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Poland",
                dimensions: {
                  region: "Europe",
                  country: "Poland",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Russia",
                dimensions: {
                  region: "Europe",
                  country: "Russia",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Spain",
                dimensions: {
                  region: "Europe",
                  country: "Spain",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Ukraine",
                dimensions: {
                  region: "Europe",
                  country: "Ukraine",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "United Kingdom",
                dimensions: {
                  region: "Europe",
                  country: "United Kingdom",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of Europe",
                dimensions: {
                  region: "Europe",
                  country: "Rest of Europe",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
          {
            title: "Africa",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Egypt",
                dimensions: {
                  region: "Africa",
                  country: "Egypt",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Kenya",
                dimensions: {
                  region: "Africa",
                  country: "Kenya",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "South Africa",
                dimensions: {
                  region: "Africa",
                  country: "South Africa",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of Africa",
                dimensions: {
                  region: "Africa",
                  country: "Rest of Africa",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
          {
            title: "Middle East",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Iran",
                dimensions: {
                  region: "Middle East",
                  country: "Iran",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Israel",
                dimensions: {
                  region: "Middle East",
                  country: "Israel",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Saudi Arabia",
                dimensions: {
                  region: "Middle East",
                  country: "Saudi Arabia",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Turkey",
                dimensions: {
                  region: "Middle East",
                  country: "Turkey",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of Middle East",
                dimensions: {
                  region: "Middle East",
                  country: "Rest of Middle East",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    hub_id: "5ff7e8ae1a0d2e710d2e6749",
    dimensions: {
      region: "All",
      country: "All",
      additive: "Enzymes",
      animal: "All",
      "sub-additive": "All",
      "sub-animal": "All",
    },
    meta: {
      subtitle:
        "Global market size and forecasts by type and by animal type, including sub-segment-level analysis. ​\n        (Volume in thousand metric ton and Revenue in USD million)",
      slideName: "introduction",
      index: 3,
    },
    title: "MARKET SEGMENTATION",
    children: [
      {
        title: "By Type",
        dimensions: {
          region: "All",
          country: "All",
          additive: "Enzymes",
          animal: "All",
          "sub-additive": "All",
          "sub-animal": "All",
        },
        meta: {
          type: "sub-additive",
          template: "overviewSlide",
          slideName: "typeoverview",
        },
        children: [
          {
            title: "Carbohydrases",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "Carbohydrases",
              "sub-animal": "All",
            },
            meta: {
              type: "sub-additive",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Phytases",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "Phytases",
              "sub-animal": "All",
            },
            meta: {
              type: "sub-additive",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Other Enzymes",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "Other Enzymes",
              "sub-animal": "All",
            },
            meta: {
              type: "sub-additive",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
        ],
      },
      {
        title: "By Animal Type",
        dimensions: {
          region: "All",
          country: "All",
          additive: "Enzymes",
          animal: "All",
          "sub-additive": "All",
          "sub-animal": "All",
        },
        meta: {
          type: "animal",
          template: "overviewSlide",
          slideName: "typeoverview",
        },
        children: [
          {
            title: "Ruminant",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Ruminant",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Poultry",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Poultry",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Aquaculture",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Aquaculture",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Swine",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Swine",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
          {
            title: "Other Animal Types",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "Other Animal Types",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "animal",
              template: "detailSlide",
              slideName: "typedetail",
            },
          },
        ],
      },
      {
        title: "By Geography",
        dimensions: {
          region: "All",
          country: "All",
          additive: "Enzymes",
          animal: "All",
          "sub-additive": "All",
          "sub-animal": "All",
        },
        meta: {
          template: "globalOverviewSlide",
          type: "region",
          slideName: "globaloverview",
        },
        children: [
          {
            title: "Asia-Pacific",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Australia",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Australia",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "China",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "China",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "India",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "India",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Indonesia",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Indonesia",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Japan",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Japan",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "New Zealand",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "New Zealand",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Pakistan",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Pakistan",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "South Korea",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "South Korea",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Thailand",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Thailand",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Vietnam",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Vietnam",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of Asia-Pacific",
                dimensions: {
                  region: "Asia-Pacific",
                  country: "Rest of Asia-Pacific",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
          {
            title: "North America",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Canada",
                dimensions: {
                  region: "North America",
                  country: "Canada",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Mexico",
                dimensions: {
                  region: "North America",
                  country: "Mexico",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "United States",
                dimensions: {
                  region: "North America",
                  country: "United States",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of North America",
                dimensions: {
                  region: "North America",
                  country: "Rest of North America",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
          {
            title: "Europe",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Denmark",
                dimensions: {
                  region: "Europe",
                  country: "Denmark",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "France",
                dimensions: {
                  region: "Europe",
                  country: "France",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Germany",
                dimensions: {
                  region: "Europe",
                  country: "Germany",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Ireland",
                dimensions: {
                  region: "Europe",
                  country: "Ireland",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Italy",
                dimensions: {
                  region: "Europe",
                  country: "Italy",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Netherlands",
                dimensions: {
                  region: "Europe",
                  country: "Netherlands",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Poland",
                dimensions: {
                  region: "Europe",
                  country: "Poland",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Russia",
                dimensions: {
                  region: "Europe",
                  country: "Russia",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Spain",
                dimensions: {
                  region: "Europe",
                  country: "Spain",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Ukraine",
                dimensions: {
                  region: "Europe",
                  country: "Ukraine",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "United Kingdom",
                dimensions: {
                  region: "Europe",
                  country: "United Kingdom",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of Europe",
                dimensions: {
                  region: "Europe",
                  country: "Rest of Europe",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
          {
            title: "Africa",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Egypt",
                dimensions: {
                  region: "Africa",
                  country: "Egypt",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Kenya",
                dimensions: {
                  region: "Africa",
                  country: "Kenya",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "South Africa",
                dimensions: {
                  region: "Africa",
                  country: "South Africa",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of Africa",
                dimensions: {
                  region: "Africa",
                  country: "Rest of Africa",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
          {
            title: "Middle East",
            dimensions: {
              region: "All",
              country: "All",
              additive: "Enzymes",
              animal: "All",
              "sub-additive": "All",
              "sub-animal": "All",
            },
            meta: {
              type: "country",
              template: "overviewSlide",
              slideName: "typeoverview",
            },
            children: [
              {
                title: "Iran",
                dimensions: {
                  region: "Middle East",
                  country: "Iran",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Israel",
                dimensions: {
                  region: "Middle East",
                  country: "Israel",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Saudi Arabia",
                dimensions: {
                  region: "Middle East",
                  country: "Saudi Arabia",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Turkey",
                dimensions: {
                  region: "Middle East",
                  country: "Turkey",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
              {
                title: "Rest of Middle East",
                dimensions: {
                  region: "Middle East",
                  country: "Rest of Middle East",
                  additive: "Enzymes",
                  animal: "All",
                  "sub-additive": "All",
                  "sub-animal": "All",
                },
                meta: {
                  type: "country",
                  template: "detailSlide",
                  slideName: "typedetail",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

class TocUpdated extends Component {
  state = {
    data: this.props.toc_child || pdata,
    toc: toc,
    reseller: false
  };


  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.data) {
      if(props.data.data[0].meta && props.data.data[0].meta.sample && props.data.data[0].meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }

  render() {
    const { data, reseller } = this.state;
    let pageId = Base64.encode("toc");

    console.log("FROM TOC", this.props)
   
    const { slideNo } = this.props || "02";
    return (
      <>
        <div className="page" id={pageId}>
          <TocUpdatedWrapper>
            <img src={toc_map} />
            <div className="toc_container">
              <>
                {this.props.toc_child && this.props.toc_child.length > 0
                  ? this.props.toc_child
                  : ""}
              </>
            </div>
          </TocUpdatedWrapper>
          <Footer
            slideNo={slideNo}
            className="toc_updated_footer"
            reseller={reseller}
          />
        </div>
      </>
    );
  }
}

export default TocUpdated;
