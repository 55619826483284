import React, { Component } from "react";
import Column from "./GraphComponents/Column/Main";

import Pie from "./GraphComponents/Pie/Main";

import World from "./GraphComponents/GeoGraphy/Main";

import Config from "../config/Config";
const CONTINENT_CONFIG = Config.CONTINENT_CONFIG;
const SUBCONTINENT_CONFIG = Config.SUBCONTINENT_CONFIG;
const COUNTRY_CONFIG = Config.COUNTRY_CONFIG;
const COLOR_CONFIG = Config.COLOR_CONFIG;

class GraphAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      propsData: {},
    };
  }

  componentDidMount() {
    let datas = reset(this.props.data);
    this.setState({ data: datas, loading: false, propsData: this.props.data });
  }
  componentDidUpdate(prevProps, prevState) {
    let datas = reset(this.props.data);

    if (this.props.data !== prevProps.data)
      this.setState({
        data: datas,
        loading: false,
        propsData: this.props.data,
      });
  }
  render() {
    let datas = "";
    if (!this.state.loading) datas = this.state.data;
    let selected_graph = "";
    if (datas != "") {
      let type = datas.graph_type.toLowerCase();
      switch (type) {
        case "bar":
          selected_graph = <Column data={datas} />;
          break;
        case "pie":
          selected_graph = (
            <Pie
              data={datas}
              headers={this.props.headers}
              footers={this.props.footers}
              labels={this.props.labels}
            />
          );
          break;

        case "geography":
          selected_graph = <World data={datas} />;
          break;

        default:
          selected_graph = null;
          break;
      }
    }

    return <div>{selected_graph}</div>;
  }
}
function getFinalValue(beginningValue, CAGR, numOfPeriods) {
  beginningValue = parseInt(beginningValue);
  CAGR = parseFloat(CAGR) / 100;
  return beginningValue * Math.pow(1 + CAGR, numOfPeriods);
}

function prepareGraphDatafromReqData(graph_type, data) {
  let temp = {},
    temp_arr = [];
  switch (graph_type.toLowerCase()) {
    case "hbar":
    case "bar": {
      temp_arr = data;
      break;
    }
    case "hgroupedbar":
    case "hstackedbar":
    case "groupedbar":
    case "stackedbar": {
      for (let item in data) {
        temp = { label: item };
        for (let key in data[item]) {
          temp[key] = data[item][key];
        }
        temp_arr.push(temp);
        temp = {};
      }
      break;
    }
    case "line": {
      const arr = [];
      for (let key in data) {
        arr.push({
          x: key,
          y: data[key],
        });
      }
      temp_arr.push({
        id: "line",
        data: arr,
      });
      break;
    }
    case "pie": {
      temp_arr = data;
      break;
    }
    case "geography": {
      for (let key in data) {
        let color = COLOR_CONFIG[(data[key] + "").toLowerCase()];
        if (CONTINENT_CONFIG[key.toLowerCase()]) {
          for (let subCont of CONTINENT_CONFIG[key.toLowerCase()]) {
            for (let country of SUBCONTINENT_CONFIG[subCont]) {
              let index = temp_arr.findIndex((elem) => elem.id == country);
              if (index !== -1) {
                if (color == -1) {
                  temp_arr.splice(index, 1);
                } else {
                  temp_arr[index].value = color;
                }
              } else if (color != -1) {
                temp_arr.push({
                  id: country,
                  value: color,
                });
              }
            }
          }
        } else if (SUBCONTINENT_CONFIG[key.toLowerCase()]) {
          for (let country of SUBCONTINENT_CONFIG[key.toLowerCase()]) {
            let index = temp_arr.findIndex((elem) => elem.id == country);
            if (index !== -1) {
              if (color == -1) {
                temp_arr.splice(index, 1);
              } else {
                temp_arr[index].value = color;
              }
            } else if (color != -1) {
              temp_arr.push({
                id: country,
                value: color,
              });
            }
          }
        } else if (COUNTRY_CONFIG[key.toLowerCase()]) {
          let country = COUNTRY_CONFIG[key.toLowerCase()];
          let index = temp_arr.findIndex((elem) => elem.id == country);
          if (index !== -1) {
            if (color == -1) {
              temp_arr.splice(index, 1);
            } else {
              temp_arr[index].value = color;
            }
          } else if (color != -1) {
            temp_arr.push({
              id: country,
              value: color,
            });
          }
        }
      }
      break;
    }
    default:
      break;
  }
  return temp_arr;
}

function reset(data) {
  if (data.base_year) {
    let base_year = parseInt(data.base_year);
    let cagr = "";
    if (data.carg) cagr = parseFloat(data.cagr);
    let end_year = parseInt(data.study_period_end);
    let time_periods = end_year - (base_year + 1),
      slug = data.slug,
      mode = data.mode;
    let graph_data = {
      heading: `${slug}_Market Summary`,
      alt_tag: `${slug}_Market Summary`,
      graph_type: "bar",
      component: "key_factor",
      slug: slug,
      cagr: cagr,
      source: "Mordor Intelligence",
      data: [],
      time: data.time,
    };
    let initial_data = {
      label: base_year + "",
      value: 10,
    };

    let final_data = {
      label: end_year + "",
      value: getFinalValue(initial_data.value, cagr, time_periods),
    };
    graph_data.data = [initial_data, final_data];
    return graph_data;
    //this.setState({data:graph_data,loading:false});
  } else {
    data.data = prepareGraphDatafromReqData(data.graph_type, data.data);
    return data;
    //this.setState({data,loading:false});
  }
}
export default GraphAction;
