import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDrafts,
  setgetTocData,
  setDraftStage,
  setNoDraftsFound,
  setSpecificDraftLoading,
} from "../reducersAndActions/Actions";
import InterFaceHeader from "./InterfaceHeader";
import { Drawer, Button, notification, Modal } from "antd";
import {
  LockOutlined,
  ReloadOutlined,
  EditOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { MenuUnfoldOutlined,
  MenuFoldOutlined} from "@ant-design/icons";
import ListOfFigures, { newSourcesData } from "../pages/ListOfFigures";
import {
  setDraftDrawer,
  setPublishedDrawer,
  setDraftVersions,
  setDefaultDraftStageFromGetSpecificDataAndDraftVersions,
  setDraftDataAnalysis,
  setDraftDataSources,
  setActiveTab,
  setSwitchDisable,
  setSelectedIndicatorHeading,
  setIndicatorObjectToShow,
  setParentId,
  setCacheId,
  setTemplateType,
  setDraftId,
  setDraftDataHeading,
  setInitialDraftVersionsLoading,
  setIndicatorDropdownOptions,
  setCompanyProfileData,
  setCompanyProfileDimensions,
  setCompanyType,
  setCharacterCountError
} from "../reducersAndActions/Actions";

import { InterFaceStyleComponent } from "../styles/Interface.style";
import axios from "axios";
import { Spin } from "antd";
import Introduction, { IntroDemoData } from "../pages/Introduction";
import Indicator1 from "../pages/Indicators/Indicator1";
import Indicator2 from "../pages/Indicators/Indicator2";
import Indicator3 from "../pages/Indicators/Indicator3";
import FeedProduction, {
  feedProductionDemoData,
} from "../pages/FeedProduction";
import ExecutiveSummary, {
  ExecutiveSummeryData,
} from "../pages/ExecutiveSummary";
import TypeOverview, { newtypeOverviewDemoData } from "../pages/TypeOverview";
import TypeDetail, { typeDetailDemoData } from "../pages/TypeDetail";
import RegionOverview, {
  regionOverviewDemoData,
} from "../pages/RegionOverview";
import CompanyProfile, {
  companyProfileDemoData,
} from "../pages/CompanyProfile";
import CompanyProfileOverview, {
  companyProfileFinalData,
} from "../pages/CompanyProfileOverview";
import CompanyMarketSpecific from "../pages/CompanyMarketSpecific";
import MarketShare, { marketShareDemoData } from "../pages/MarketShareAnalysis";
import ProductAndServices, {
  ProductsAndServicesDemoData,
} from "../pages/ProductsAndServices";
import ScopeStudyRegional from "../pages/ScopeStudy";
import GlobalOverview, {
  globalOverviewDemoData,
} from "../pages/GlobalOverview";
import Sources from "../pages/Sources";
//static slides
import ReportOffer from "../pages/ReportOffers";
import StudyAssumption from "../pages/StudyAssumptions";
import ScopeOfStudyStatic from "../pages/ScopeStudyStatic";
import KeyStrategicThemesCEOS from "../pages/KeyStrategicThemesForFeedAdditiveCEOS";
import ResearchMethodology from "../pages/ResearchMethodology";
import LubricantVC from "../pages/Lubricants-VC";
import ArchitecturalCoatingVC from "../pages/ArchitecturalCoating-VC";
import ProteinsVC from "../pages/Proteins-VC";
import DecisionMaker from "../pages/DecisionMaker";
import SegmentDefination, { data } from "../pages/SegmentDefinition";
import Disclaimer from "../pages/Disclaimer";
import FeedbackAndAudit from "../pages/FeedbackAndAudit";
import Contact from "../pages/ForMoreInformationPleaseContact";
import KeyStrategicMoves, {
  newRecentDevelopmentDemoData,
} from "../pages/KeyStratagicMoves";
import RecentDevelopment, {
  recentDevelopmentDemoData,
} from "../pages/RecentDevelopment";
import RegulatoryFramework, {
  regulatoryFrameworkDemoData,
} from "../pages/RegulatoryFramework";
import RegulatoryFrameworkV2 from "../pages/RegulatoryFrameworkv2";
import CompanyProfileContainer from "../pages/CompanyProfileContainer";
import GrowthStrategyMatrix, {
  growthstrategymatricsDemoData,
} from "../pages/GrowthStrategyMatrix";
import MaskedComponent from "./MaskComponent";
import Appendix, { appendixDemoData } from "../pages/Appendix";
import Home from "../pages/Home";
import { Collapse } from "antd";
import config from "../config/Api";
import { Menu } from 'antd';
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../Common/withWindowSizeHOC";


const { SubMenu } = Menu;


var Base64 = require("js-base64").Base64;

const { Panel } = Collapse;

let URL = config.api.base_url;

const componentMapping = {
  home: Home,
  disclaimer: Disclaimer,
  introduction: Introduction,
  scopeOfStudy: ScopeStudyRegional,
  scopeofstudystatic: ScopeOfStudyStatic,
  reportoffers: ReportOffer,
  studyAssumptions: StudyAssumption,
  researchMethodology: ResearchMethodology,
  indicator1: Indicator1,
  indicator2: Indicator2,
  indicator3: Indicator3,
  feedproduction: FeedProduction,
  regulatoryFramework: RegulatoryFrameworkV2,
  regulatoryFramework2: RegulatoryFramework,
  decisionMakerAnalysis: DecisionMaker,
  valueChainlubricant: LubricantVC,
  valueChain_architectural_coatings:ArchitecturalCoatingVC,
  valueChainProteins:ProteinsVC,
  valueChain: LubricantVC,
  globaloverview: GlobalOverview,
  typeoverview: TypeOverview,
  typedetail: TypeOverview,
  regionoverview: RegionOverview,
  keyStrategicMoves: KeyStrategicMoves,
  recentdevelopment: RecentDevelopment,
  executive_summary: ExecutiveSummary,
  marketShares: MarketShare,
  companyProfiles: CompanyProfile,
  companyProfile2: CompanyProfileContainer,
  companyProfile1: CompanyProfileContainer,
  growthstrategymatrics: GrowthStrategyMatrix,
  keyStrategicThemes: KeyStrategicThemesCEOS,
  appendix2: ProductAndServices,
  appendix1: SegmentDefination,
  sources: Sources,
  listOfFighure: ListOfFigures,
  feedbackandaudit: FeedbackAndAudit,
  contact: Contact,
  mask: MaskedComponent,
};

class Interface extends Component {
  state = {
    data: this.props.data ? this.props.data : [],
    slide: "executive_summary",
    drawer: false,
    activetab: false,
    loading: true,
    fromInterface: true,
    slideData: [],
    expandedKeys: [],
    slectedTocElement: null,
    defaultSelectedTocElement: [],
    openKeys: [],
    subArrayKeys: [],
    fetch_specific_draft_warning: false,
    fetch_specific_published_warning: false,
    published_access_warning: false,
    drafts_access_warning: false,
    drafts_different_user_warning: false,
    // active_slide: "",
    draft_versions_email_id: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.edit_mode !== prevProps.edit_mode) || (this.props.selected_indicator_heading !== prevProps.selected_indicator_heading)) {
      if (this.props.edit_mode && this.props.ACTIVE_TAB === "drafts" && !this.props.template_type.includes('companyProfile')) {
        this.props.setInitialDraftVersionsLoading(true);
        if(!this.props.parent_id){
          axios({
            method: "POST",
            url: `${config.api.base_url}/api/editing/parent_doc`,
            data: {
              "type": this.props.template_type,
              "obj": {
                  "hub_id": this.state.slide_details.hub_id,
                  "dimensions": this.state.slide_details.dimensions,
                  "meta":{
                      "typeValue": this.state.slide_details.meta.typeValue
                  }
              },
              "cacheId": this.props.cache_id
          }
          })
            .then((response) => {
              this.props.setParentId(response.data.parent_id);
              this.getDraftVersionsAndFetchFirstDraft()
            })
            .catch((error) => {
              console.log(error)
            });
        }  else {
          this.getDraftVersionsAndFetchFirstDraft()
        }   
      }

      if (this.props.edit_mode && this.props.template_type.includes('companyProfile')) {
        this.companyProfileApi()
      }
      
      if (this.props.edit_mode && this.props.ACTIVE_TAB === "published") {
        if (
          this.props.USER_DATA &&
          this.props.USER_DATA.obj &&
          this.props.USER_DATA.obj.access &&
          this.props.USER_DATA.obj.access.edit &&
          !this.props.USER_DATA.obj.access.edit.includes("PUBLISHED")
        ) {
          this.setState({
            published_access_warning: true,
          });
          this.props.setSwitchDisable(true);
          this.props.handleSwitchchange(false);
        }

        if(!this.props.parent_id){
          axios({
            method: "POST",
            url: `${config.api.base_url}/api/editing/parent_doc`,
            data: {
              "type": this.props.template_type,
              "obj": {
                  "hub_id": this.state.slide_details.hub_id,
                  "dimensions": this.state.slide_details.dimensions,
                  "meta":{
                      "typeValue": this.state.slide_details.meta.typeValue
                  }
              },
              "cacheId": this.props.cache_id
          }
          })
            .then((response) => {
              this.props.setParentId(response.data.parent_id);
            })
            .catch((error) => {
              console.log(error)
            });
        } 


      }
    }

    if (this.props.ACTIVE_TAB !== prevProps.ACTIVE_TAB) {
      //call get specific data api on tab change from drafts to published or vice versa
      this.changeslide(null, this.state.slide_details, this.state.hub_id);
      this.props.setSwitchDisable(false);
    }

    if((this.props.edit_mode !== prevProps.edit_mode) && !this.props.edit_mode){
      if(this.props.template_type.includes('companyProfile')){
        //clear cache for company profiles
        axios({
          method: "POST",
          url: `${config.api.base_url}/api/clearRedisCache`,
        })
          .then((response) => {
            if (response.status === 200) {
        this.changeslide(null, this.state.slide_details, this.state.hub_id);
            }
          })
          .catch((error) => {
        this.changeslide(null, this.state.slide_details, this.state.hub_id);
          });

      } else {
        this.changeslide(null, this.state.slide_details, this.state.hub_id);
      }
    }

    //Only for company profiles, we have different types of companies - marketCompany, globalCompany etc
    if(this.props.companyType !== prevProps.companyType || this.props.update_company_profile_data !== prevProps.update_company_profile_data){
      if(this.props.edit_mode){
        this.companyProfileApi()
      }
    }

    //checkbox toggle for company profiles recentDevelopment and productServices

    if(this.props.fetch_full_data !== prevProps.fetch_full_data){
        this.companyProfileApi()
    }

   
  }

  companyProfileApi=()=>{
    this.props.setInitialDraftVersionsLoading(true);
            axios({
              method: "POST",
              url: `${config.api.base_url}/api/editing/cpData`,
              data: {
                "type": this.props.companyType,
                "hub_id": this.state.slide_details.hub_id,
                "dimensions": this.state.slide_details.dimensions,
                "versions": this.props.ACTIVE_TAB,
                "meta":{
                  "full_data": this.props.fetch_full_data
                }
            }
            })
              .then((response) => {
                console.log(response,'cpData');
                this.props.setCompanyProfileDimensions(response.data.dimensions);
                this.props.setCompanyProfileData(response.data.data);
                this.props.setInitialDraftVersionsLoading(false);
              })
              .catch((error) => {
                this.props.setInitialDraftVersionsLoading(false);
                console.log(error)
              });
  }


  getDraftVersionsAndFetchFirstDraft=()=>{
    axios
    .get(
      `${config.api.base_url}/api/editing/drafts?parent_id=${this.props.parent_id}&type=${this.props.template_type}&list=true`
    )
    .then((response) => {
  this.props.setInitialDraftVersionsLoading(false);
      // console.log("drafts promise chain api", response);
      if (response.status === 200 || response.status === 201) {
        this.props.setDraftVersions(response.data);
        if (!response.data.user_edit_access) {
          this.props.handleSwitchchange(false);
          this.setState({
            drafts_access_warning: true,
          });
          this.props.setSwitchDisable(true);
        }
        if (response.data.versions && !response.data.versions.length) {
          // console.log("no versions found");
          this.props.setNoDraftsFound(true);
          this.props.setDraftStage("NONE");
          this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
            response.data.stage ? response.data.stage : "INITIAL"
          );
        } else if (
          response.data.versions &&
          response.data.versions.length
        ) {
          if (
            response.data.versions[0].hasOwnProperty("email_id") &&
            this.props.USER_DATA.obj.email_id !==
              response.data.versions[0].email_id
          ) {
            this.setState({
              drafts_different_user_warning: true,
              draft_versions_email_id: response.data.versions[0].email_id,
            });
          }

          this.props.setNoDraftsFound(false);
          this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
            response.data.stage ? response.data.stage : "INITIAL"
          );
      this.props.setDraftStage(response.data.stage ? response.data.stage : 'INITIAL');

          // console.log("fetch latest draft");
          return this.getSpecificDraftApi(
            response.data.versions[0].draftId
          );
        }
      }
    })
    .catch((error) => {
      console.log(error, 'error')
  this.props.setInitialDraftVersionsLoading(false);
      if (error.response) {
        notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      }
      this.props.setDraftVersions({});
    });
  }

  componentDidMount() {
    this.props.handleSwitchchange(false);
    let tocDimensions;
    tocDimensions = this.props.TocApiParams;

    let new_toc_obj;
    let overview_element;
    let payload = {
      hub_id: tocDimensions.hub_id,
      dimensions: tocDimensions.dimensions,
      meta: {
        reports: true,
        sample: false,
        interface:true
      }
    };
    axios
      .post(`${URL}/api/generateToc`, payload)
      .then((response) => {
        if (response.status === 200) {
          new_toc_obj = [...response.data];
          overview_element = new_toc_obj.map((item, i) => {
            if (
              item.children &&
              item.children.length &&
              item.title == "Market Segmentation"
            ) {
              this.AddOverviewSlideToToc(item);
            }
          });

          let initialSlideParams = new_toc_obj.filter((item, i) => {
            return item.title == "Executive Summary & Key Findings";
          });

          this.getdefaultSlideData(
            initialSlideParams[0],
            initialSlideParams[0].hub_id
          );
          this.getSubArrayKeysmethod(new_toc_obj);
          this.setState({
            data: new_toc_obj,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  getSubArrayKeysmethod = (keys) => {
    let getSubArrayKeys = keys.map((item, i) => {
      if (item.children && item.children.length) {
        this.state.subArrayKeys.push(item.meta.section_id);
        // this.getSubArrayKeysmethod(item.children);
      }


    })
  }
  AddOverviewSlideToToc = (SegmentationToc) => {
    return (
      SegmentationToc.children &&
      SegmentationToc.children.length &&
      SegmentationToc.children.map((item, i) => {
        if (item.children && item.children.length) {
          if(item.meta.hasOwnProperty("specificIntroductionIndex")){
            item.children.splice(item.meta.specificIntroductionIndex,0,{
              title: item.title,
            dimensions: item.dimensions,
            meta: {
              ...item.meta,
              section_id: item.meta.section_id,
              // section_id: Base64.encode(SegmentationToc.meta.type + "overview"),
            },
            hub_id: SegmentationToc.hub_id,
            children: [],
            depth: 1,
            })
          }
          else{
            item.children.unshift({
              title: item.title,
              dimensions: item.dimensions,
              meta: {
                ...item.meta,
                section_id: item.meta.section_id,
                // section_id: Base64.encode(SegmentationToc.meta.type + "overview"),
              },
              hub_id: SegmentationToc.hub_id,
              children: [],
              depth: 1,
            });
          }
          
          this.AddOverviewSlideToToc(item);
        }
      })
    );
  };
  getdefaultSlideData = (slide, hub_id) => {
    let body = {
      hub_id: hub_id,
      obj: {
        title: slide.title,
        dimensions: slide.dimensions,
        meta: {
          ...slide.meta,
        },
      },
    };
    axios
      .post(`${URL}/api/getSpecificData`, body)
      .then((response) => {
        console.log(response, "RESPONSE");
        if (response.status === 200) {
          this.setState({
            slide: "executive_summary",
            slideData: response.data,
            defaultSelectedTocElement: [response.data.section_id],
            loading: false,
          });
          this.props.setTemplateType(response.data.template);
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };
  getTreeNodes = (TOC_TREE) => {
    // return (
    //   TOC_TREE &&
    //   TOC_TREE.map((item, i) => {
    //     if (item.children && item.children.length) {
    //       return (
    //         <Panel
    //           header={item.title.toLowerCase()}
    //           key={item.meta.section_id}
    //           className="tocscollapse"
    //           showArrow={item.children ? true : false}
    //         >
    //           <Collapse accordion>{this.getTreeNodes(item.children)}</Collapse>
    //         </Panel>
    //       );
    //     }
    //     return (
    //       <p
    //         onClick={(e) => this.changeslide(e, item, item.hub_id)}
    //         key={item.meta.section_id}
    //         className="ant-collapse-header panel-text"
    //       >
    //         {item.meta.template == "typeoverview"
    //           ? "Introduction"
    //           : item.title.toLowerCase()}
    //       </p>
    //     );
    //   })
    // );

    return TOC_TREE && TOC_TREE.map((item, i) => {

      if (item.children && item.children.length) {
        return (
          <SubMenu key={item.meta.section_id} title={item.title}>
            {this.getTreeNodes(item.children)}

          </SubMenu>
        );
      }
      return (
        <Menu.Item key={item.meta.section_id} onClick={(e) => this.changeslide(e, item, item.hub_id)}> {item.meta.template == "typeoverview" && item.meta.typeValue != "product_grade" && !item.meta.hasOwnProperty("heading")
          ? "Introduction" : item.meta.template == "typeoverview"  && item.meta.hasOwnProperty("heading")? item.meta.heading
          : item.title}</Menu.Item>
      );

    });
  };
  onOpenChange = keys => {
    // console.log("keys",keys,this.state.data,this.state.subArrayKeys);
    const latestOpenKey = keys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.state.subArrayKeys.indexOf(latestOpenKey) === -1) {
      this.setState({
        openKeys:keys
      })
      
    } else {
      this.setState({
        openKeys:latestOpenKey ? [latestOpenKey] : []
      })
    }
  };

  onChangeSlideClick = (e, slide, hub_id) => {
    this.props.setActiveTab("drafts");
    this.props.setSwitchDisable(false);
    this.changeslide(e, slide, hub_id);
  };
  changeslide = (e, slide, hub_id) => {
    this.props.handleSwitchchange(false);
    this.props.setNoDraftsFound(true);
    this.props.setDraftDataHeading("");
    this.props.setDraftDataAnalysis([]);
    this.props.setDraftDataSources([]);
    this.props.setIndicatorObjectToShow({});
    this.props.setSelectedIndicatorHeading('');
    this.props.setParentId('');
    this.props.setCacheId('');
    this.props.setDraftId(null);
    this.props.setTemplateType('');
    this.props.setCompanyType('marketCompany');
    this.props.setCompanyProfileData([]);
    this.props.setCompanyProfileDimensions({});
    this.props.setCharacterCountError(false);
    if(slide && hub_id){
      this.setState({
        loading: true,
        drawer: false,
        slide_details: slide,
        hub_id: hub_id,
        active_slide: slide.meta.section_id,
      defaultSelectedTocElement:[slide.meta.section_id]
      });
      let body = {
        hub_id: hub_id,
        obj: {
          hub_id: hub_id,
          title: slide.title,
          dimensions: slide.dimensions,
          meta: {
            ...slide.meta,
          },
        },
        sample: false,
      };
  
      axios
        .post(`${URL}/api/getSpecificData`, body)
        .then((response) => {
          console.log(response.data, '566')

          if (response.status === 200) {
            //----------Set response data-----------//
            if (
              slide.meta.template == "overview" &&
              slide.meta.typeValue == "introduction"
            ) {
              this.setState({
                slide: "introduction",
                slideData: response.data,
                loading: false,
              });
            } else {
              this.setState({
                slide: slide.meta.template,
                slideData: response.data,
                loading: false,
              });
            }

            //-----------Editing Code--------//
            if(["typeoverview", 'typedetail', 'keyStrategicMoves', 'marketShares','indicator1', 'indicator2', 'indicator3', 'companyProfiles', 'companyProfile1', 'companyProfile2'].includes(response.data.template)){
                      
              let parent_id = response.data.template.includes("indicator")
              ? response.data.data[0].parent_id
              : response.data.data.parent_id;
            let template_type = response.data.template.includes("indicator")
              ? "indicator"
              : response.data.template;
    
            this.props.setParentId(parent_id);
            this.props.setCacheId(response.data.hasOwnProperty("cacheId") ? JSON.parse(response.data.cacheId) : '');
            this.props.setTemplateType(template_type);
    
            if (response.data.template.includes("indicator")) {
              //There are 3 types of indicators - indicator1, indicator2 and indicator. However, we are using single template for editing - IndicatorEditing.js. They have multiple inicator objects in same page and for editing covenicence, we use dropdown.
    
               //Set Indicator Dropdown Options
               this.props.setIndicatorDropdownOptions(response.data.data)
    
              // Set first item in heading dropdown
              this.props.setSelectedIndicatorHeading(
                response.data.data[0].trends.heading
              );
    
              //Set first item heading in indicator editing input
              this.props.setDraftDataHeading(response.data.data[0].trends.heading);
             
              // Show the first indicator object
              this.props.setIndicatorObjectToShow(response.data.data[0]);
              this.props.setDraftStage(response.data.data.length > 1 ? "MULTIPLE" : response.data.data[0].current_stage);
              // this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions('INITIAL');
            }
    
            if (!response.data.template.includes("indicator")) {
              //For typeoverview and typedetail templates
              this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
                response.data.data.current_stage
                  ? response.data.data.current_stage
                  : "INITIAL"
              );
              this.props.setDraftStage(
                response.data.data.current_stage
                  ? response.data.data.current_stage
                  : "INITIAL"
              );
            }
              }
          }
        })
  
        .catch((err) => {
          console.log(err, "ERR");
          this.setState({
            loading: false,
          });
        });
    }
  };
  
  showDrawer = () => {
    this.setState({
      drawer: true,
    });
  };
  handleExpand = (expandedKeys) => {
    let ancestors = [];
    if (expandedKeys && expandedKeys.length)
      ancestors = this.fetchAncestors(expandedKeys[expandedKeys.length - 1]);
    this.setState({
      expandedKeys: ancestors,
    });
  };
  handleSelect = (keys, e) => { };
  setDrawer = (menuOpend) => {
    this.setState({
      drawer: menuOpend,
    });
  };

  hidePublishedDrawer = () => {
    this.props.setPublishedDrawer(false);
  };

  hideDraftDrawer = () => {
    this.props.setDraftDrawer(false);
  };

  getSpecificDraftApi = (draft_id) => {
    this.props.setDraftId(draft_id);
    this.props.setSpecificDraftLoading(true);
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/drafts?parent_id=${this.props.parent_id}&type=${this.props.template_type}&draftId=${draft_id}`,
    })
      .then((response) => {
        this.props.setSpecificDraftLoading(false);
        // console.log(response, "RESP GET SPECIFIC DRAFT");
        if (response.status === 200 || response.status === 201) {
          this.props.setDraftDataHeading(response.data.hasOwnProperty('heading') ? response.data.heading : '');
          if (['marketShares', 'typeoverview'].includes(this.props.template_type)) {
            const newStateOverview = JSON.parse(
              JSON.stringify(this.state.slideData)
            );
            newStateOverview.source = response.data.source.map((item) => {
              return {
                text: item,
                hyperLink: "",
              };
            });

            newStateOverview.data.referenced_analysis = response.data.analysis.map(
              (item) => {
                return {
                  text: item,
                  hyperLink: "",
                };
              }
            );

            this.setState({
              slideData: newStateOverview,
            });
          } else if (['keyStrategicMoves', 'typedetail'].includes(this.props.template_type)) {
            const newStateDetail = JSON.parse(
              JSON.stringify(this.state.slideData)
            );
            newStateDetail.source = response.data.source.map((item) => {
              return {
                text: item,
                hyperLink: "",
              };
            });
            newStateDetail.data.trends[0].referenced_analysis = response.data.analysis.map(
              (item) => {
                return {
                  text: item,
                  hyperLink: "",
                };
              }
            );

            
            this.setState({
              slideData: newStateDetail,
            });
          } else if (this.props.template_type.includes("indicator")) {
            const newIndicatorState = JSON.parse(
              JSON.stringify(this.state.slideData)
            );

            //Find index of specific object using findIndex method and modify analysis for specific indicator object.
            let objIndex = newIndicatorState.data.findIndex(
              (obj) => obj.parent_id === this.props.parent_id
            );

            //Update heading
            newIndicatorState.data[objIndex].trends.heading = response.data.heading

            //Update analysis
            newIndicatorState.data[objIndex].trends.referenced_analysis =
              response.data.analysis.map((item) => {
                return {
                  text: item,
                  hyperLink: "",
                };
              });

              //Update source
            newIndicatorState.data[objIndex].source = response.data.source.map(
              (item) => {
                return {
                  text: item,
                  hyperLink: "",
                };
              }
            );

            //Set specific indicator object based on selected_indicator_heading after modifying newIndicatorState and set state to SlideData

            this.props.setIndicatorObjectToShow(newIndicatorState.data[objIndex]);
        
            this.setState({
              slideData: newIndicatorState,
            });
          }
        }
      })
      .catch((error) => {
        this.props.setSpecificDraftLoading(false);
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };

  getSpecificPublishedVersionApi = (publish_id) => {
    this.props.setSpecificDraftLoading(true);
    //get specific published version api
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/publish?publish_id=${publish_id}`,
    })
      .then((response) => {
        this.props.setSpecificDraftLoading(false);
        // console.log(response, "RESP GET SPECIFIC PUBLISHED VERSION");
        if (response.status === 200 || response.status === 201) {
          this.props.setDraftDataHeading(response.data.hasOwnProperty('heading') ? response.data.heading : '');
          if (['marketShares', 'typeoverview'].includes(this.props.template_type)) {
            const newStateOverview = JSON.parse(
              JSON.stringify(this.state.slideData)
            );
            newStateOverview.source = response.data.source.map((item) => {
              return {
                text: item,
                hyperLink: "",
              };
            });
            newStateOverview.data.referenced_analysis = response.data.analysis.map(
              (item) => {
                return {
                  text: item,
                  hyperLink: "",
                };
              }
            );
            this.setState({
              slideData: newStateOverview,
            });
          } else if (['keyStrategicMoves', 'typedetail'].includes(this.props.template_type)) {
            const newStateDetail = JSON.parse(
              JSON.stringify(this.state.slideData)
            );
            newStateDetail.source = response.data.source.map((item) => {
              return {
                text: item,
                hyperLink: "",
              };
            });
            newStateDetail.data.trends[0].referenced_analysis = response.data.analysis.map(
              (item) => {
                return {
                  text: item,
                  hyperLink: "",
                };
              }
            );
            this.setState({
              slideData: newStateDetail,
            });
          }else if (this.props.template_type.includes("indicator")) {
            const newIndicatorState = JSON.parse(
              JSON.stringify(this.state.slideData)
            );

            //Find index of specific object using findIndex method and modify analysis for specific indicator object.
            let objIndex = newIndicatorState.data.findIndex(
              (obj) => obj.parent_id === this.props.parent_id
            );

              //Update heading
              newIndicatorState.data[objIndex].trends.heading = response.data.heading

              //update analysis
            newIndicatorState.data[objIndex].trends.referenced_analysis =
              response.data.analysis.map((item) => {
                return {
                  text: item,
                  hyperLink: "",
                };
              });

              //update source
            newIndicatorState.data[objIndex].source = response.data.source.map(
              (item) => {
                return {
                  text: item,
                  hyperLink: "",
                };
              }
            );

             //Set specific indicator object based on selected_indicator_heading after modifying newIndicatorState and set state to SlideData
             this.props.setIndicatorObjectToShow(newIndicatorState.data[objIndex]);

            this.setState({
              slideData: newIndicatorState,
            });
          }
        }
      })
      .catch((error) => {
        this.props.setSpecificDraftLoading(false);
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };

  //-- draft version modal

  triggerSpecificDraftModal = (draftId) => {
    this.setState({
      fetch_specific_draft_warning: true,
      draftId,
    });
  };

  continueFetchSpecificDraft = () => {
    this.props.setDraftDrawer(false);
    this.setState(
      {
        fetch_specific_draft_warning: false,
      },
      () => this.getSpecificDraftApi(this.state.draftId)
    );
  };

  cancelFetchSpecificDraft = () => {
    this.props.setDraftDrawer(false);
    this.setState({
      fetch_specific_draft_warning: false,
    });
  };

  //--published version modal

  triggerSpecificPublishedModal = (publishedId) => {
    this.setState({
      fetch_specific_published_warning: true,
      publishedId,
    });
  };

  continueFetchSpecificPublished = () => {
    this.props.setPublishedDrawer(false);
    this.setState(
      {
        fetch_specific_published_warning: false,
      },
      () => this.getSpecificPublishedVersionApi(this.state.publishedId)
    );
  };

  cancelFetchSpecificPublished = () => {
    this.props.setPublishedDrawer(false);
    this.setState({
      fetch_specific_published_warning: false,
    });
  };

  handleDfferentUserDraftAccessCancel = () => {
    this.setState({
      drafts_different_user_warning: false,
    });
    this.props.handleSwitchchange(false);
  };

  render() {
    const Component = componentMapping[this.state.slide];

    return (
      <Spin tip="Loading..." spinning={this.state.loading}>
        <InterFaceStyleComponent>
          {window.screen.width > 960 ? null : (
            <div className="drawer">
              <MenuUnfoldOutlined
                onClick={this.showDrawer}
                style={{ paddingTop: "50px" }}
              />
              {this.state.slectedTocElement}
            </div>
          )}
          <div className="content">
            {window.screen.width > 960 ? (
              <div className="leftColumn sideNav">
                <h4>Contents</h4>

          

                {/* <Collapse accordion expandIconPosition={"right"}> */}
                <Menu mode="inline" openKeys={this.state.openKeys} onOpenChange={this.onOpenChange} selectedKeys={this.state.defaultSelectedTocElement}>
                  {this.getTreeNodes(this.state.data)}
                  </Menu>
                {/* </Collapse> */}
                {/* <Button className="customize-report-button">
                  Customize the Report
                </Button>
                <Button className="data-charts-button">Data & Charts</Button> */}
              </div>
            ) : (
              <Drawer
                width="90%"
                placement="left"
                closable={true}
                onClose={() => this.setDrawer(false)}
                visible={this.state.drawer}
              >
                <h4>Contents</h4>
                {/* <Collapse accordion expandIconPosition={"right"}> */}
                <Menu mode="inline" openKeys={this.state.openKeys} onOpenChange={this.onOpenChange} selectedKeys={this.state.defaultSelectedTocElement}>
                  {this.getTreeNodes(this.state.data)}
                  </Menu>
                {/* </Collapse> */}
                <Button className="customize-report-button drawerbtns">
                  Customize the Report
                </Button>
                <Button className="data-charts-button drawerbtns">
                  Data & Charts
                </Button>
                <Button danger className="drawerbtns">
                  Purchase Report
                </Button>
                <Button type="primary" className="drawerbtns">
                  Purchases
                </Button>
                <Button className="faq-btn drawerbtns">
                  Frequently Asked Questions
                </Button>
              </Drawer>
            )}
            <div className="rightColumn respectiveContent">
              <Component
                fromInterface={this.state.fromInterface}
                data={this.state.slideData}
                edit_mode={this.props.edit_mode}
                draft_drawer_visible={this.props.draft_drawer_visible}
                published_drawer_visible={this.props.published_drawer_visible}
                handleSwitchchange={this.props.handleSwitchchange}
                slide_details={this.state.slide_details}
              />
            </div>
          </div>
        </InterFaceStyleComponent>
        <Drawer
          title="Draft Versions"
          closable={false}
          onClose={this.hideDraftDrawer}
          visible={this.props.DRAFT_DRAWER_VISIBLE}
          width="320"
        >
          {this.props.draft_versions_loading ? (
            <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
          ) : this.props.draft_versions &&
            Object.keys(this.props.draft_versions).length &&
            this.props.draft_versions.versions &&
            this.props.draft_versions.versions.length ? (
            this.props.draft_versions.versions.map((item) => {
              return (
                <div
                  style={{
                    display: "inlineFlex",
                    borderBottom: "1px solid grey",
                    padding: "16px 24px",
                  }}
                >
                  <p style={{ marginBottom: "0" }}>
                    <EditOutlined /> {item.name}
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ width: "75%", margin: "0" }}>
                      <ClockCircleOutlined />{" "}
                      {new Date(item.date).toLocaleString()}
                    </p>
                    <Button
                      type="link"
                      style={{ padding: "0", width: "25%" }}
                      onClick={() =>
                        this.triggerSpecificDraftModal(item.draftId)
                      }
                    >
                      <ReloadOutlined /> Load
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <p style={{ padding: "16px 24px" }}>NO DRAFTS FOUND</p>
          )}
        </Drawer>

        <Drawer
          title="Published Versions"
          placement={this.props.PLACEMENT}
          closable={false}
          onClose={this.hidePublishedDrawer}
          visible={this.props.PUBLISHED_DRAWER_VISIBLE}
          width="320"
        >
          {this.props.published_versions_loading ? (
            <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
          ) : this.props.published_versions &&
            this.props.published_versions.length ? (
            this.props.published_versions.map((item) => {
              return (
                <div
                  style={{
                    display: "inlineFlex",
                    borderBottom: "1px solid grey",
                    padding: "16px 24px",
                  }}
                >
                  <p style={{ marginBottom: "0" }}>
                    <EditOutlined /> {item.name}
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ width: "75%", margin: "0" }}>
                      <ClockCircleOutlined />{" "}
                      {new Date(item.date).toLocaleString()}
                    </p>
                    <Button
                      type="link"
                      style={{ padding: "0", width: "25%" }}
                      onClick={() =>
                        this.triggerSpecificPublishedModal(item.publish_id)
                      }
                    >
                      <ReloadOutlined /> Load
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <p style={{ padding: "16px 24px" }}>NO PUBLISHED VERSIONS FOUND</p>
          )}
        </Drawer>

        <Modal
          title="Notification"
          visible={this.state.fetch_specific_draft_warning}
          onCancel={this.cancelFetchSpecificDraft}
          onOk={this.continueFetchSpecificDraft}
          okText="Continue"
          cancelText="Cancel"
        >
          You are loading a previous version of this draft. Unsaved changes will
          be lost. Continue?
        </Modal>

        <Modal
          title="Notification"
          visible={this.state.fetch_specific_published_warning}
          onCancel={this.cancelFetchSpecificPublished}
          onOk={this.continueFetchSpecificPublished}
          okText="Continue"
          cancelText="Cancel"
        >
          You are loading a previous version of this published version. Unsaved
          changes will be lost. Continue?
        </Modal>

        <Modal
          title="Warning"
          centered
          visible={this.state.published_access_warning}
          footer={null}
          onOk={() =>
            this.setState({
              published_access_warning: false,
            })
          }
          onCancel={() =>
            this.setState({
              published_access_warning: false,
            })
          }
        >
          You don't have access to edit.
        </Modal>

        <Modal
          title="Warning"
          centered
          visible={this.state.drafts_access_warning}
          footer={null}
          onOk={() =>
            this.setState({
              drafts_access_warning: false,
            })
          }
          onCancel={() =>
            this.setState({
              drafts_access_warning: false,
            })
          }
        >
          The current draft is in this {this.props.draft_stage} stage, you don't
          have access to edit in this stage.
        </Modal>

        <Modal
          title="Warning"
          centered
          visible={this.state.drafts_different_user_warning}
          okText="Continue"
          cancelText="Cancel"
          onOk={() =>
            this.setState({
              drafts_different_user_warning: false,
            })
          }
          onCancel={this.handleDfferentUserDraftAccessCancel}
        >
          This draft is being worked on by {this.state.draft_versions_email_id}.
          Do you want to continue?
        </Modal>
      </Spin>
    );
  }
}

function mapStateToPros(state) {
  return {
    PLACEMENT: state.PLACEMENT,
    PUBLISHED_DRAWER_VISIBLE: state.PUBLISHED_DRAWER_VISIBLE,
    DRAFT_DRAWER_VISIBLE: state.DRAFT_DRAWER_VISIBLE,
    draft_versions: state.draft_versions,
    published_versions: state.published_versions,
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_stage: state.draft_stage,
    no_drafts_found: state.no_drafts_found,
    USER_DATA: state.USER_DATA,
    draft_versions_loading: state.draft_versions_loading,
    published_versions_loading: state.published_versions_loading,
    parent_id: state.parent_id,
    template_type: state.template_type,
    selected_indicator_heading: state.selected_indicator_heading,
    cache_id: state.cache_id,
    companyType: state.companyType,
    update_company_profile_data: state.update_company_profile_data,
    fetch_full_data: state.fetch_full_data
  };
}
const mapDispatchToProps = {
  setDraftDrawer,
  setPublishedDrawer,
  setDraftVersions,
  setDraftStage,
  setNoDraftsFound,
  setSpecificDraftLoading,
  setDefaultDraftStageFromGetSpecificDataAndDraftVersions,
  setDraftDataAnalysis,
  setDraftDataSources,
  setActiveTab,
  setSwitchDisable,
  setSelectedIndicatorHeading,
  setIndicatorObjectToShow,
  setParentId,
  setCacheId,
  setTemplateType,
  setDraftId,
  setDraftDataHeading,
  setInitialDraftVersionsLoading,
  setIndicatorDropdownOptions,
  setCompanyProfileData,
  setCompanyProfileDimensions,
  setCompanyType,
  setCharacterCountError
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(Interface))
);
