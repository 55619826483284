import styled from "styled-components";

export const BulkUploadContainerWrapper = styled.div`
    // background-color: #f1f4f7;
    height: auto;
    // min-height: 100vh;
`


export const BulkUploadComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
.search_and_headder{
    display: flex;  
    height: auto;
    padding: 60px;
    align-items: center;
    justify-content: center;
    .manage_team_search{
        width:55%;
        position: relative;
    }
    p {
        font-family: Gilroy,sans-serif; 
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #778a98;
        background-color: #fff;
    }
}

.upload_list {
    // margin-left: 50px;
    // margin-right: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.or {
    text-align: center;
    font-size: 20px;
}

.orblur {
    text-align: center;
    font-size: 20px;
    filter: blur(3px)
}

.blur {
    filter: blur(3px);
    width: 50%;
    justify-content: center;
    margin-top: 40px;
    align-self: center;
    padding-bottom: 40px;
    border-radius: 50px;
    background: none;
    transition: background 1s ease-out;
}

.ant-select-selection {
    font-family: inherit;
    color: #92abb8;
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 30px 0 rgba(43,86,112,.1);
    box-shadow: 0 0 30px 0 rgba(43,86,112,.1);
}

.card {
    width: 70%;
    justify-content: center;
    margin-top: 70px;
    align-self: center;
    padding-bottom: 40px;
    background: #fff;
    transition: background 1s ease-in;
    border-radius: 50px;
    -webkit-box-shadow: 0 0 30px 0 rgba(43,86,112,.1);
    box-shadow: 0 0 30px 0 rgba(43,86,112,.1);
}

.ant-select-selection--multiple {
    min-height: 46px;
}

.ant-select-selection__rendered {
    position: unset;
}

.ant-select-selection--multiple .ant-select-selection__placeholder{
    margin-left: 10px;
}

.ant-checkbox-wrapper {
    margin-right: 70px;
}

.uploadsection {
    display: flex;
    justify-content: center;
    margin: 30px 50px;
    align-items: center;    
}

.ant-upload.ant-upload-select.ant-upload-select-text {
    display: flex;
    justify-content: center;
}

.buttons{
    display:flex;
    justify-content:space-evenly;

}

.sample-details {
    display: flex;
}
  
.samples{
    flex: 50%;
    flex-direction:column;
}

  .samples h3{
      display:flex;
    justify-content:center;

  }

.samples li{
    display:flex;
    list-style-type: none;
    justify-content:center;
}


`