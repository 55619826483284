import React, { Component } from "react";
import Im from "../graphs/experimental/Main";
class Image extends Component {
  state = {
    data: [
      {
        label: "Europe",
        value: 0,
      },
      {
        label: "North America",
        value: 0,
      },
      {
        label: "South America",
        value: 9,
      },
      {
        label: "Middle East",
        value: 0,
      },
      {
        label: "Asia-Pacific",
        value: 0,
      },
      {
        label: "Africa",
        value: 0,
      },
    ],
  };
  render() {
    return <Im data={this.state.data} />;
  }
}

export default Image;
