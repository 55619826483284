import React, { useState, useEffect } from "react";
import { Form, Input, Button, Typography, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { LoginWrapper } from "../styles/Login.style";
import { useAuth } from "../firebase/contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setTocData,
  setAuthstatus,
  setUserData,
} from "../reducersAndActions/Actions";
import configureStore from "../reducersAndActions/Store";
import config from "../config/Config";
import configs from "../config/Api";
import {
  CodeSandboxCircleFilled,
  TranslationOutlined,
} from "@ant-design/icons";
import { auth } from "../firebase/Firebase";
import { isCompositeComponent } from "react-dom/test-utils";
const axios = require("axios").default;

//static images
const LinkedInLogoDefault = require("../images/Sign-In-LinkedIn-Default.png");
const LinkedInLogoActive = require("../images/Sign-In-LinkedIn-Active.png");
const LinkedInLogoHover = require("../images/Sign-In-LinkedIn-Hover.png");
const MSLoginWhite = require("../images/ms-signIn-white.svg");

export default function Login(props) {
  const { Title } = Typography;
  const {
    sendVerificationEmail,
    getCurrentUser,
    login,
    ResetPassword,
    getCustomClaims,
    loginWithCustomToken,
    handleMicrosoftLogin,
  } = useAuth();
  const history = useHistory();
  const key = "LoginMessage";
  const dispatch = useDispatch();
  const [SignInWithLinkedIn, setSignInWithLinkedIn] = useState(false);
  const [SignInWithMicrosoft, setSignInWithMicrosoft] = useState(false);
  const [LinkedInLogo, setLinkedInLogo] = useState(LinkedInLogoDefault);

  // const setSignInWithMicrosoft = (value) => {
  //   SignInWithMicrosoft = value;
  // };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  // useEffect(() => {
  //   if (config.LOCAL_STORAGE_TOKEN.Name in localStorage)
  //     history.push("/editing/search");

  //   let SignInLinkedInAccountResponse, currentURL, code, state;

  //   if (
  //     window.location.href.includes("code=") &&
  //     window.location.href.includes("state=")
  //   ) {
  //     openLoading("Please wait...");
  //     setSignInWithLinkedIn(true);

  //     currentURL = window.location.href;
  //     code = currentURL.substring(
  //       currentURL.indexOf("code=") + 5,
  //       currentURL.lastIndexOf("&")
  //     );

  //     state = currentURL.substring(
  //       currentURL.indexOf("&state=") + 7,
  //       currentURL.length
  //     );
  //     (async function SignInLinkedInAccount() {
  //       try {
  //         let user;
  //         SignInLinkedInAccountResponse = await axios
  //           .post(
  //             `${configs.api.base_url}/api/firebase/SignInLinkedInAccount`,
  //             {
  //               code: code,
  //               state: state,
  //             }
  //           )
  //           .catch((e) => {
  //             throw e;
  //           });

  //         user = SignInLinkedInAccountResponse;
  //         if (
  //           user &&
  //           user.status === 200 &&
  //           user.data &&
  //           user.data.customToken &&
  //           user.data.name &&
  //           user.data.email &&
  //           user.data.profilePicture
  //         ) {
  //           user = user.data;
  //           user = await loginWithCustomToken(
  //             user.customToken,
  //             user.profilePicture,
  //             user.name,
  //             user.email
  //           );
  //           if (!user) throw user;

  //           onFinish(
  //             { searchString: SignInLinkedInAccountResponse.data.email },
  //             {
  //               type: "linkedin",
  //               user: user,
  //             }
  //           );
  //         } else throw SignInLinkedInAccountResponse;
  //       } catch (e) {
  //         openFailed("Sorry! Something went wrong.");
  //         history.push("/login");
  //         setSignInWithLinkedIn(false);
  //         window.localStorage.removeItem("user");
  //       }
  //     })();
  //   }
  // });

  const openLoading = (msg) => {
    message.loading({ content: msg ? msg : "Logging In...", key, duration: 0 });
  };
  const openSuccess = (msg) => {
    message.success({ content: msg ? msg : "Welcome", key, duration: 1 });
  };
  const openFailed = (msg) => {
    let duration = 1.2;
    if (msg === "emailVerification") {
      msg = `Please verify your email. We have sent you an Email verification link`;
      duration = 4;
    }
    message.error({
      content: msg ? msg : "Invalid email / password.",
      key,
      duration: duration,
    });
  };

  async function setToken(token, lastSignInTime) {
    window.localStorage.setItem(config.LOCAL_STORAGE_TOKEN.Name, token.token);
    window.localStorage.setItem(
      config.LOCAL_STORAGE_TOKEN.meta,
      lastSignInTime
    );
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function loginWithLinkedIn() {
    try {
      let LinkedInAuthURL, user, token;

      LinkedInAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=${generateDynamicState()}&scope=r_liteprofile,r_emailaddress&client_id=${
        process.env.REACT_APP_LINKEDIN_CLIENT_ID
      }&redirect_uri=${encodeURI(
        configs.api.base_url.includes("localhost")
          ? `http://localhost:3000/login`
          : configs.api.base_url
      )}`;
      window.location = LinkedInAuthURL;
    } catch (e) {
      // console.log("Error at custom token login", e);
    }

    //suuporting function(s)
    function generateDynamicState() {
      let OAuth2State = configs.api.OAuth2State,
        currentdate = new Date(),
        Seed =
          currentdate.getHours() +
          currentdate.getDate() +
          currentdate.getMonth() +
          currentdate.getFullYear();
      OAuth2State = OAuth2State.split("");
      let j,
        tt = OAuth2State.length;
      for (var i = 0; i < tt; i++) {
        j = ((Seed % (i + 1)) + i) % tt;

        [OAuth2State[i], OAuth2State[j]] = [OAuth2State[j], OAuth2State[i]];
      }
      return OAuth2State.join("");
    }
  }

  async function handleMicrosoft() {
    let currentUser,
      profilePicture,
      isValidMicrosoftUser,
      idToken,
      user,
      base64data,
      reader,
      verifyUserInDb;

    try {
      isValidMicrosoftUser = await handleMicrosoftLogin();
      if (isValidMicrosoftUser)
        verifyUserInDb = await onFinish(
          { searchString: isValidMicrosoftUser.email },
          { type: "microsoft" }
        );
      else throw "Invalid Microsoft User";

      if (verifyUserInDb.user !== "isValidUserInDb") throw "INVALID USER IN DB";

      currentUser = await getCurrentUser();
      if (isValidMicrosoftUser && currentUser) {
        profilePicture = await axios
          .get(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
            headers: {
              authorization: `Bearer ${isValidMicrosoftUser.accessToken}`,
            },
            responseType: "blob",
          })
          .catch((e) => {});

        user = {
          name: currentUser.providerData[0].displayName,
          email: currentUser.providerData[0].email,
        };

        if (profilePicture && profilePicture.status === 200) {
          reader = new window.FileReader();
          reader.readAsDataURL(profilePicture.data);
          reader.onloadend = function () {
            base64data = reader.result;

            user.profilePicture = base64data;
          };
        } else user.profilePicture = undefined;

        idToken = await currentUser.getIdToken(true);
        await setToken(
          { token: { token: idToken } },
          currentUser.metadata.lastSignInTime
        );
        window.localStorage.setItem("user", JSON.stringify(user));

        history.push("/editing/search");
        openSuccess();
      } else {
        throw currentUser;
      }
    } catch (e) {
      setSignInWithMicrosoft(false);
      // console.log("error at MSFT login", e);
      history.push("/login");
      if (verifyUserInDb) openFailed("Sorry! Something went wrong.");
    }
  }

  async function checkUserInDb(searchString, logInType) {
    let responseGetUser, responseEditUser, updateUser, url;
    url = configs.api.base_url;
    logInType = logInType.type ? logInType.type : "firebase";
    responseGetUser = await axios.post(
      `${url}/api/users/getUser`,
      validateEmail(searchString)
        ? {
            email_id: searchString,
          }
        : {
            username: searchString,
          }
    );

    responseGetUser = responseGetUser.data;
    dispatch(setUserData(responseGetUser));

    if (
      responseGetUser &&
      responseGetUser.obj &&
      responseGetUser.obj.authentication_from.includes(logInType)
    )
      if (responseGetUser.obj.active)
        return { eligibility: "VALID USER", obj: responseGetUser };
      else return { eligibility: "INACTIVE USER", obj: null };
    else return { eligibility: "INVALID USER", obj: null };
  }

  const onFinish = async (values, LoggedInUser) => {
    let LogInLinkedIn = false,
      LogInMicrosoft = false,
      isValidUserInDb,
      isValidUserInAuth,
      sendVerificationEmailResponse,
      msg = null,
      email,
      username;

    if (LoggedInUser && LoggedInUser.type)
      switch (LoggedInUser.type) {
        case "linkedin":
          LogInLinkedIn = true;
          break;
        case "microsoft":
          LogInMicrosoft = true;
          break;
        default:
          break;
      }

    try {
      if (
        LogInLinkedIn &&
        validateEmail(values.searchString) &&
        values.searchString.includes("@mordorintelligence.com")
      )
        email = values.searchString;
      else if (
        LogInMicrosoft &&
        validateEmail(values.searchString) &&
        values.searchString.includes("@mordorintelligence.com")
      )
        email = values.searchString;
      else if (validateEmail(values.searchString)) email = values.searchString;
      else if (!validateEmail(values.searchString))
        username = values.searchString;
      else {
        msg = "Invalid Email!";
        throw msg;
      }

      openLoading();
      isValidUserInDb = await checkUserInDb(email ? email : username, {
        type: LogInMicrosoft ? "microsoft" : null,
      });

      if (isValidUserInDb.eligibility === "INACTIVE USER") {
        msg = "User Account is currently not active.";
        throw msg;
      } else if (isValidUserInDb.eligibility === "INVALID USER")
        throw isValidUserInDb;

      if (!email) email = isValidUserInDb.obj.obj.email_id;

      if (LogInLinkedIn && LoggedInUser) {
        isValidUserInAuth = LoggedInUser;
        setTimeout(() => {
          openSuccess();
        }, 250);
      } else if (LogInMicrosoft && LoggedInUser)
        return { user: "isValidUserInDb" };
      else isValidUserInAuth = await login(email, values.password);

      if (
        !config.AUTH_TEST_ACCOUNTS.includes(isValidUserInAuth.user.email) &&
        //!isValidUserInAuth.user.email.includes("@mordorintelligence.com") &&
        !isValidUserInAuth.user.emailVerified
      ) {
        sendVerificationEmailResponse = await sendVerificationEmail(
          isValidUserInAuth
        ).catch(() => {});

        if (sendVerificationEmailResponse === "attemptTimeout")
          msg = "Too many attempts. Please try again later.";
        else msg = "emailVerification";
        throw msg;
      }

      setTimeout(() => {
        openSuccess();
      }, 250);

      setToken(
        isValidUserInAuth.user.getIdTokenResult(true),
        isValidUserInAuth.user.metadata.lastSignInTime
      );
      history.push("/editing/search");
      dispatch(setAuthstatus(true));
    } catch (e) {
      // console.log("error ", e);
      if (LogInMicrosoft && !msg) openFailed("Sorry! Something went wrong.");
      else openFailed(msg);
      history.push("/login");
      setSignInWithLinkedIn(false);
      setSignInWithMicrosoft(false);
      window.localStorage.removeItem(config.LOCAL_STORAGE_TOKEN.Name);
      window.localStorage.removeItem(config.LOCAL_STORAGE_TOKEN.meta);
      window.localStorage.removeItem("user");
    }
  };

  return (
    <LoginWrapper>
      <div className="login-form">
        <div className="login-title">Sign in to your account</div>
        <Form
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="searchString"
            rules={[
              {
                required: true,
                message: "Please input your Email / Username!",
              },
            ]}
          >
            <Input
              background-color="red"
              disabled={SignInWithLinkedIn || SignInWithMicrosoft}
              placeholder="Email / Username"
              id="login"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input
              disabled={SignInWithLinkedIn || SignInWithMicrosoft}
              placeholder="Password"
              type="password"
              id="password"
            />
          </Form.Item>

          <div className="buttons">
            <Form.Item>
              <Button
                disabled={SignInWithLinkedIn || SignInWithMicrosoft}
                htmlType="submit"
                id="signIn"
              >
                Sign In
              </Button>
            </Form.Item>

            <Button
              disabled={SignInWithLinkedIn || SignInWithMicrosoft}
              className="signup_login_form_button"
              onClick={() => {
                history.push("/forgotpassword");
              }}
            >
              Forgot Password
            </Button>
            <Button
              disabled={SignInWithLinkedIn || SignInWithMicrosoft}
              className="signup_login_form_button"
              onClick={() => {
                history.push("/signup");
              }}
            >
              Sign Up
            </Button>
            <Button
              style={{
                position: "absolute",
                display: "flex",
                padding: 0,
                border: "none",
                top: "70%",
                left: "50%",
                justifyContent: "center",
                cursor: SignInWithLinkedIn ? "not-allowed" : "pointer",
                pointerEvents: SignInWithLinkedIn ? "none" : "auto",
              }}
              name="msLogin"
              disabled={SignInWithLinkedIn || SignInWithMicrosoft}
            >
              <img
                draggable={false}
                style={{
                  position: "absolute",
                  width: "210px",
                  pointerEvents:
                    SignInWithLinkedIn || SignInWithMicrosoft ? "none" : "auto",
                }}
                src={MSLoginWhite}
                alt="Sign In with Microsoft"
                // onMouseEnter={() => {
                //  // setLinkedInLogo(LinkedInLogoHover);
                // }}
                // onMouseOut={() => {
                //  // setLinkedInLogo(LinkedInLogoDefault);
                // }}
                onClick={() => {
                  setSignInWithMicrosoft(true);
                  handleMicrosoft();
                }}
              />
            </Button>

            {/* <Button
              style={{
                position: "absolute",
                display: "flex",
                padding: 0,
                border: "none",
                top: "78%",
                left: "50%",
                justifyContent: "center",
                cursor: SignInWithLinkedIn ? "not-allowed" : "pointer",
                "pointer-events": SignInWithLinkedIn ? "none" : "auto",
              }}
              disabled={SignInWithLinkedIn || SignInWithMicrosoft}
            >
              <img
                draggable={false}
                style={{
                  position: "absolute",
                  width: "210px",
                  "pointer-events":
                    SignInWithLinkedIn || SignInWithMicrosoft ? "none" : "auto",
                }}
                src={LinkedInLogo}
                alt="Sign In with LinkedIn"
                onMouseEnter={() => {
                  setLinkedInLogo(LinkedInLogoHover);
                }}
                onMouseOut={() => {
                  setLinkedInLogo(LinkedInLogoDefault);
                }}
                onClick={() => {
                  setLinkedInLogo(LinkedInLogoActive);
                  loginWithLinkedIn();
                  setSignInWithLinkedIn(true);
                }}
              />
            </Button> */}
          </div>
        </Form>
      </div>
    </LoginWrapper>
  );
}

let abc = ``;
