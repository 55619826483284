import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Bar from "../../graphs/bar/Main";
import Graph from "../../components/Graphs";
import { LiveStockContainer } from "../../styles/Livestocks.style";
import Analysis from "../../components/Analysis";
import IndicatorEditing from "../../components/IndicatorEditing";
var Base64 = require("js-base64").Base64;

class Indicator2 extends React.Component {
  state = {
    data: this.props.data ? this.props.data : {},
    reseller: false
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.data != this.props.data.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }

  render() {
    const { section_id, title, subtitle, slug, trends, graphs } =
      this.props.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <LiveStockContainer id={pageId}>
        <div className="page">
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Header slideTitle={this.state.data.type} />
          ) : null}
          {this.props.edit_mode ? 
            <IndicatorEditing data={this.props.data} edit_mode={this.props.edit_mode} template_type='indicator2' draft_drawer_visible={this.props.draft_drawer_visible} slideNo={this.props.slideNo}
            published_drawer_visible={this.props.published_drawer_visible} handleSwitchchange={this.props.handleSwitchchange} fromInterface={this.props.fromInterface}/>
            : 
          <div className="container">
            <h2 className="heading">{title}</h2>
            {this.state.data.data.map((item, i) => {
              return (
                <div className="indicator-container2" key={i}>
                  {/* {item.graph ?<h3 className="subheading">
                       {item.graph.heading }
                    </h3>: null} */}
                  {item.graph && item.graph.data != "hidden" ? (
                    <Graph
                    wrapHeadingWidth={400}
                        chartHeadingGap={item.graph.heading.length > 60 ? 0 : -20}
                        yHeadingMargin={item.graph.heading.length > 60 ? 30 : 15}
                        xHeadingMargin={0}
                      width={
                        !this.props.fromInterface ||
                        this.props.fromInterface == undefined
                          ? "1180"
                          : "100%"
                      }
                      height={250}
                      totalData={item.graph}
                      dataHidden={item.graph.dataHidden}
                      data={ item.graph.data }
                      graphType={item.graph.graphType}
                      axes={false}
                      labels={true}
                      headers={item.graph.heading}
                      footers={this.state.graphFooterText}
                      dataType={item.graph.dataType}
                    />
                  ) : null}
                 {item.trends.heading?<h3 className="subheading subheading2">
                      {item.trends.heading}
                    </h3>:null}
                  <Analysis trends={item.trends} />
                  {i == this.state.data.data.length - 1 ? null : (
                    <hr className="indicator2-hr" />
                  )}
                </div>
              );
            })}
          </div>
  }
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer slideNo={slideNo} slug={slug} reseller={this.state.reseller}/>
          ) : null}
        </div>
      </LiveStockContainer>
    );
  }
}

export default Indicator2;
