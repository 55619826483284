import styled from 'styled-components';


export const ArchitecturalCoatingVCWrapper = styled.div`


.lubricant-container {
  height: 560px;
  position: relative;
}

.lubricant-container img {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.section1_list1 {
    left: ${props => props.fromInterface ? '12%': '7%'};
    width: ${props => props.fromInterface ? '12%': '14%'};
      position: absolute;
      top: 70%;
      max-width: 110px;
      word-break: break-word;
  }

  .section1_list2 {
    left: ${props => props.fromInterface ? '22%': '18%'};
    width: ${props => props.fromInterface ? '12%': '14%'};
    position: absolute;
    top: 70%;
      max-width: 110px;
      word-break: break-word;
  }
  .section1_list3 {
    left: ${props => props.fromInterface ? '32%': '29%'};
    width: ${props => props.fromInterface ? '12%': '14%'};
    position: absolute;
    top: 70%;
      max-width: 110px;
      word-break: break-word;
  }

  .section2_list {
    position: absolute;
    left: ${props => props.fromInterface ? '44%': '43%'};
      top: 70%;
      max-width: 180px;
      word-break: break-word;
  }
  .section3_list1 {
    position: absolute;
    left: ${props => props.fromInterface ? '65%': '69%'};
    top: 70%;
    width: 13%;
    word-break: break-word;
  }
  
  .section3_list2 {
    position: absolute;
    left: ${props => props.fromInterface ? '78%': '83%'};
    top: 70%;
    width: 13%;
    word-break: break-word;
  }
`