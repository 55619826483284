import React from "react";
import Header from "./Header";
import Toc from "../pages/NewToc";
import axios from "axios";
import Introduction, { IntroDemoData } from "../pages/Introduction";
import Indicator1, {
  indicator1,
  livestockDemoData,
} from "../pages/Indicators/Indicator1";
import Indicator2 from "../pages/Indicators/Indicator2";
import Indicator3 from "../pages/Indicators/Indicator3";
import FeedProduction, {
  feedProductionDemoData,
} from "../pages/FeedProduction";
import ExecutiveSummary, {
  ExecutiveSummeryData,
} from "../pages/ExecutiveSummary";
import TypeOverview, { newtypeOverviewDemoData } from "../pages/TypeOverview";
import TypeDetail, { typeDetailDemoData } from "../pages/TypeDetail";
import RegionOverview, {
  regionOverviewDemoData,
} from "../pages/RegionOverview";
import CompanyProfile, {
  companyProfileDemoData,
} from "../pages/CompanyProfile";
import CompanyProfileOverview, {
  companyProfileFinalData,
} from "../pages/CompanyProfileOverview";
import ProductAndServicesV2 from "../pages/ProductAndServicesV2";
import RecentDevelopment, {
  recentDevelopmentDemoData,
} from "../pages/RecentDevelopment";
import CompanyProfileContainer from "../pages/CompanyProfileContainer";
import CompanyMarketSpecific from "../pages/CompanyMarketSpecific";
import MarketShare, { marketShareDemoData } from "../pages/MarketShareAnalysis";
import ProductAndServices, {
  ProductsAndServicesDemoData,
} from "../pages/ProductsAndServices";
import ScopeStudyRegional from "../pages/ScopeStudy";
import GlobalOverview, {
  globalOverviewDemoData,
} from "../pages/GlobalOverview";
import Sources from "../pages/Sources";
//static slides
import ReportOffer from "../pages/ReportOffers";
import StudyAssumption from "../pages/StudyAssumptions";
import ScopeOfStudyStatic from "../pages/ScopeStudyStatic";
import KeyStrategicThemesCEOS from "../pages/KeyStrategicThemesForFeedAdditiveCEOS";
import ResearchMethodology from "../pages/ResearchMethodology";
import LubricantVC from "../pages/Lubricants-VC";
import ArchitecturalCoatingVC from "../pages/ArchitecturalCoating-VC";
import ProteinsVC from "../pages/Proteins-VC";
import DecisionMaker from "../pages/DecisionMaker";
import SegmentDefination from "../pages/SegmentDefinition";
import Disclaimer from "../pages/Disclaimer";
import FeedbackAndAudit from "../pages/FeedbackAndAudit";
import Contact from "../pages/ForMoreInformationPleaseContact";
import KeyStrategicMoves, {
  newRecentDevelopmentDemoData,
} from "../pages/KeyStratagicMoves";

import RegulatoryFrameworkV2, {
  regulatoryFrameworkDemoData,
} from "../pages/RegulatoryFrameworkv2";

import RegulatoryFramework from "../pages/RegulatoryFramework";
import GrowthStrategyMatrix, {
  growthstrategymatricsDemoData,
} from "../pages/GrowthStrategyMatrix";
import Appendix, { appendixDemoData } from "../pages/Appendix";
import ListOfFigures from "../pages/ListOfFigures";
import Home from "../pages/Home";
import data from "../Sample.json";
import { Divider } from "antd";
import { connect } from "react-redux";
import { setReportHeaderData } from "../reducersAndActions/Actions";
import { setLinkCounter } from "../reducersAndActions/Actions";
import config from "../config/Api";
import DisplayStaticPdfs from '../pages/displayPdfs/displaypdfComponent';
// import about_us_with_footer from '../pages/displayPdfs/pdf_files/about_us_with_footer.pdf';
// import about_us_without_footer from '../pages/displayPdfs/pdf_files/about_us_without_footer.pdf';
var Base64 = require("js-base64").Base64;

// object for mapping all slides based on data
const slideMapping = {
  home: Home,
  toc: Toc,
  executive_summary: ExecutiveSummary,
  introduction: Introduction,
  studyAssumptions: StudyAssumption,
  scopeOfStudy: ScopeStudyRegional,
  researchMethodology: ResearchMethodology,
  indicator1: Indicator1,
  indicator2: Indicator2,
  indicator3: Indicator3,
  regulatoryFramework: RegulatoryFrameworkV2,
  regulatoryFramework2: RegulatoryFramework,
  valueChainlubricant: LubricantVC,
  valueChain_architectural_coatings: ArchitecturalCoatingVC,
  valueChainProteins: ProteinsVC,
  typeoverview: TypeOverview,
  typedetail: TypeOverview,
  keyStrategicMoves: KeyStrategicMoves,
  marketShares: MarketShare,
  companyProfiles: CompanyProfile,
  keyStrategicThemes: KeyStrategicThemesCEOS,
  disclaimer: Disclaimer,
  scopeofstudystatic: ScopeOfStudyStatic,
  reportoffers: ReportOffer,
  feedproduction: FeedProduction,
  decisionmaker: DecisionMaker,
  globaloverview: GlobalOverview,
  regionoverview: RegionOverview,
  keystrategicmoves: KeyStrategicMoves,
  recentdevelopment: RecentDevelopment,
  growthstrategymatrics: GrowthStrategyMatrix,
  appendix2: ProductAndServices,
  appendix1: SegmentDefination,
  sources: Sources,
  listOfFighure: ListOfFigures,
  feedbackandaudit: FeedbackAndAudit,
  contact: Contact,
  products: ProductAndServicesV2,
  Key_development: RecentDevelopment,
  global_company: CompanyProfileOverview,
  market_company: CompanyMarketSpecific,
  disclaimer: Disclaimer,
  feedback: FeedbackAndAudit,
  formoreinformation: Contact,
};

class FinalReport extends React.Component {
  state = {
    data: {},
    loading: true,
    sample: false,
    reseller: false,
    domain: "",
    meta:{}
  };
  async componentDidMount() {
    // api call for data, to generate report
    try {
      const folder = this.props.match.params.folder;

      let url = config.api.base_url;

      // let response = await fetch(url + "/" + folder + "/data.json");
      // let json = await response.json();
      // if(json.meta){
      //   if(json.meta.domain){
      //     this.setState({domain:json.meta.domain})
      //   }
      //   if(json.meta.sample==true){
      //     if(json.meta.reseller==true)
      //     this.setState({sample:true,reseller:true});
      //     else
      //     this.setState({sample:true});
      //   }
      // }
      let response = await axios.post(`${url}/api/getSpecificPdfData`, { file_name: folder });
      let json = response.data.data;

      if (json.meta) {
        this.setState({meta:json.meta})
        if (json.meta.domain) {
          this.setState({ domain: json.meta.domain })
        }
        if (json.meta.sample == true) {
          if (json.meta.reseller == true)
            this.setState({ sample: true, reseller: true });
          else
            this.setState({ sample: true });
        }
      }
      // let json = data;
      // console.log("final mount", json);
      // setting report header data in redux
      let headerNavigation_data = json.data.map((item, index) => {
        let num = 0,
          header_navigation;
        if (num == 0 && item.template == "toc") {
          num++;
          header_navigation = item.data.filter((item) => {
            return item.meta.template == "introduction";
          });
          this.props.setReportHeaderData({
            header_navigation,
            report_name: json.reportName,
          });
        }
      });
      if (!json.message) {
        this.setState({ data: json || [], loading: false });
      }
    } catch (e) { }
  }
 
  // componentDidUpdate(prevProps, prevState) {
  //   //get pageNO to show for links in toc
  //   if (!prevState.data.length && this.state.data.length) {
  //     for (let item of this.state.data) {
  //       if (item.template === "toc") {
  //         for (let tocItem of item.data) {
  //           if (tocItem.type === "link") {
  //             const pageId = Base64.encode(tocItem.pageId);
  //             const element = document.getElementById("slideNO" + pageId);
  //             if (element) {
  //               tocItem.pageNo = document.getElementById(
  //                 "slideNO" + pageId
  //               ).innerHTML;
  //             }
  //           }
  //         }
  //       }
  //     }
  //     this.forceUpdate();
  //   }
  // }
  generatePdf = () => {
    const { data } = this.state.data.hasOwnProperty("data")
      ? this.state.data
      : this.state;
    let counter = 0;
    if (!data.length || data.length == 0) {
      return <h2>Loading data...</h2>;
    } else if (data.length > 0 && !this.state.loading) {
      let report_info = data.map((item, index) => {
        switch (item.template) {
          case "toc": {
            // logic for generating toc
            let tocs = item.data;
            // let arr = [],
            let super_arr = [];
            let new_arr;
            for (let toc of tocs) {
              console.log("toc ", toc);
              super_arr.push(
                <h3 className="toc_element">
                  <a href={"#" + Base64.encode(toc.meta.section_id)}>
                    {(toc.meta.index ? toc.meta.index + " " : "") + toc.title}
                  </a>
                </h3>
              );
              // if (!toc.children.length) {
              //   super_arr.push(arr);
              //   arr = [];
              // }

              function recursive(child_arr, side, index) {
                console.log("childarr", child_arr, side, index)
                for (let i = 0; i < child_arr.length; i++) {
                  // if(arr.length) {
                  //   super_arr.push(arr);
                  //   arr = [];
                  // }

                  if (child_arr[i].meta.tocVisibility) {
                    if (child_arr[i].children.length) {
                      super_arr.push(
                        <p
                          style={{ paddingLeft: `${side}px` }}
                          className="toc_element"
                        >
                          <a
                            href={
                              "#" + Base64.encode(child_arr[i].meta.section_id)
                            }
                          >
                            {index}.{i + 1} {child_arr[i].title}
                          </a>
                        </p>
                      );
                      recursive(
                        child_arr[i].children,
                        side + 10,
                        index + "." + (i + 1)
                      );
                    } else {
                      console.log("top1", child_arr[i].title);
                      if (
                        !child_arr[i].title.toLowerCase().includes("Disclaimer") &&
                        child_arr[i].meta.template !== "null"
                      ) {
                        super_arr.push(
                          <p
                            style={{ paddingLeft: `${side}px` }}
                            className="toc_element"
                          >
                            <a
                              href={
                                "#" +
                                Base64.encode(child_arr[i].meta.section_id)
                              }
                            >
                              {toc.title != "Appendix" ? (
                                <>
                                  {index}.{i + 1} {child_arr[i].title}
                                </>
                              ) : (
                                <>{child_arr[i].title}</>
                              )}
                            </a>
                          </p>
                        );
                      } else {
                        super_arr.push(
                          <p
                            style={{
                              paddingLeft: `${0}px`,
                              textTransform: "unset",
                            }}
                            className="toc_element"
                          >
                            <a>{"*" + child_arr[i].title}</a>
                          </p>
                        );
                      }
                    }
                  }
                }
              }
              if (toc.children) recursive(toc.children, 10, toc.meta.index);
            }
            console.log("superRR", super_arr);
            if (super_arr.length >= 63) {

              new_arr = this.splittingSlides({ data: super_arr }, 62);
              console.log("new_", new_arr);
              return (
                new_arr.map((item, i) => {
                  counter++;
                  return <Toc toc_child={item.data} slideNo={counter} data={data[1]} />
                })

              );

            }
            else {
              counter++;
              return <Toc toc_child={super_arr} slideNo={counter} data={data[1]} />
            }

          }
          case "indicator1": {
            let indicator1Arr = this.splittingSlides(item, 2);
            console.log("indicator1", item, indicator1Arr);
            return indicator1Arr.map((compnentData, k) => {
              counter++;
              return (
                <Indicator1 data={compnentData} key={index} slideNo={counter} />
              );
            });
          }
          case "indicator2": {
            let indicator2Arr = this.splittingSlides(item, 1);
            console.log("indicator2", item, indicator2Arr);
            return indicator2Arr.map((compnentValue, k) => {
              counter++;
              return (
                <Indicator2
                  data={compnentValue}
                  key={index}
                  slideNo={counter}
                />
              );
            });
          }
          case "indicator3": {
            let indicator3Arr = this.splittingSlides(item, 1);
            console.log("indicator3", item, indicator3Arr);
            return indicator3Arr.map((compnentValue, k) => {
              counter++;
              return (
                <Indicator3
                  data={compnentValue}
                  key={index}
                  slideNo={counter}
                />
              );
            });
          }
          // case "introduction":{
          //   if(item.type == "companyProfiles"){
          //     let CompanyProfileIntroSlide = this.splittingSlides(item, 8);
          //     console.log("intro",CompanyProfileIntroSlide);
          //     return CompanyProfileIntroSlide && CompanyProfileIntroSlide.map((introslidedata,i)=>{
          //       counter++;
          //       return <Introduction data={introslidedata} key={index} slideNo={counter} />;
          //     })
          //   }
          //   else{
          //     counter++;
          //     return <Introduction data={item} key={index} slideNo={counter} />;
          //   }

          // }

          case "regulatoryFramework": {
            let regulatoryFramework = this.splittingSlides(item, 1);
            return regulatoryFramework.map((compnentValue, k) => {
              counter++;
              return (
                <RegulatoryFrameworkV2
                  data={compnentValue}
                  key={index}
                  slideNo={counter}
                />
              );
            });
          }
          case "sources": {

            let dataWithNo_source = item.data && item.data.filter((sources, i) => {
              return sources.text != "no_source" || ""
            });
            let newSourceData = { ...item, data: dataWithNo_source };
            let sourcesSlides = this.splittingSourceSlides(newSourceData); // this.splittingSlides(newSourceData, 10);
            console.log(newSourceData, "==", sourcesSlides)
            let sourceIndexCounter = 1;
            return sourcesSlides.map((sourceValue, index) => {
              counter++;
              sourceValue = {
                ...sourceValue,

                sourceCountStart: sourceIndexCounter,
              };
              sourceIndexCounter += sourceValue.data.length;
              return (
                <Sources data={sourceValue} key={index} slideNo={counter} />
              );
            });
          }
          case "listOfFighure": {
            let figureSlides = this.splittingSlides(item, 14);
            return figureSlides.map((sourceValue, index) => {
              counter++;
              sourceValue = {
                ...sourceValue,
                sourceCountStart: 14 * index + 1,
              };
              return (
                <ListOfFigures
                  data={sourceValue}
                  key={index}
                  slideNo={counter}
                />
              );
            });
          }
          case "companyProfile1":
          case "companyProfile2": {
            let company_prfles = this.splittingCompanyProfiles(item);
            return (
              company_prfles &&
              company_prfles.data.map((slideData, i) => {
                let key = Object.keys(slideData).filter((keyvalue, j) => {
                  return (
                    keyvalue == "products" ||
                    keyvalue == "Key_development" ||
                    keyvalue == "global_company" ||
                    keyvalue == "market_company"
                  );
                });
                let Slide;

                if (key[0] == "Key_development") {
                  Slide = slideMapping[key[0]];
                  let new_prdct_obj = { ...slideData };
                  return new_prdct_obj.Key_development.length
                    ? new_prdct_obj.Key_development.map((keySlide, k) => {
                      counter++;
                      let newkey_slide = { ...new_prdct_obj };
                      newkey_slide["Key_development"] = keySlide;

                      return (
                        <Slide
                          data={newkey_slide}
                          key={index}
                          slideNo={counter}
                        />
                      );
                    })
                    : null;
                } else if (key[0] == "products") {
                  Slide = slideMapping[key[0]];
                  let new_prdct_obj = { ...slideData };
                  // let { products: _, ...other } = new_prdct_obj;
                  let productSlides = [];
                  console.log(slideData, "===prod");
                  for (let prod_item of slideData.products) {
                    let obj = { ...new_prdct_obj };
                    obj["data"] = prod_item;
                    console.log(obj, prod_item, "===prod2");
                    productSlides.push(obj);
                  }
                  console.log(productSlides, "===prod1");
                  return productSlides.length
                    ? productSlides.map((productSlide, k) => {
                      counter++;
                      let newprodct_slide = { ...productSlide };
                      newprodct_slide["products"] = productSlide.data;

                      return (
                        <Slide
                          data={newprodct_slide}
                          key={index}
                          slideNo={counter}
                        />
                      );
                    })
                    : null;
                } else {
                  Slide = slideMapping[key[0]];
                  counter++;
                  if (slideData.global_company !== undefined || slideData.market_company !== undefined) {
                    return (
                      <Slide data={slideData} template={slideData.template} key={index} slideNo={counter} />
                    );
                  } else {
                    return null
                  }
                }
              })
            );
          }
          case "none":
          case "null": {
            return null;
          }

          default: {
            const Component = slideMapping[item.template];
            counter++;
            return <Component data={item} key={index} slideNo={counter} />;


          }
        }

      });
      if (this.state.reseller) {
        //let about_us_without_footer = require(`../pages/displayPdfs/SamplesPdfByHub/${this.state.domain}/about_us_without_footer.pdf`);
        report_info.push(
          <DisplayStaticPdfs file={this.state.meta['static_pdf_url']} />
        )
        report_info.push(
          <Disclaimer data={{ meta: { sample: true, reseller: true } }} />
        )
      }
      else if (this.state.sample) {
        //let about_us_with_footer = require(`../pages/displayPdfs/SamplesPdfByHub/lubricants/about_us_with_footer.pdf`);
        report_info.push(
          <DisplayStaticPdfs file={this.state.meta['static_pdf_url']} />
        )
        report_info.push(
          <Disclaimer data={{ meta: { reseller: false } }} />
        )
        report_info.push(
          <Contact />
        )
      }

      return report_info;
    }
  };
  splittingCompanyProfiles = (profiles) => {
    // ---example---
    //  incoming data = {"data":[{global_company},{market_company},{products},{Key_development}]} ;
    // output of this function = [{"data":{global_company}},{"data":{market_company}},{"data":{products}},{"data":{Key_development}}]
    console.log("profiles", profiles);
    let obj = {};
    let { data: _, ...new_obj } = profiles;
    obj["data"] = [
      { global_company: profiles.data.global_company, ...new_obj },
      { market_company: profiles.data.market_company, ...new_obj },
      { products: profiles.data.products, ...new_obj },
      { Key_development: profiles.data.Key_development, ...new_obj },
    ];

    return obj;
  };

  splittingSlides = (data, divider) => {
    // ---example---
    // if divider=1 ; incoming data = {"data":[{},{},{}]} ; output of this function = [{"data":[{}]},{"data":[{}]},{"data":[{}]}]
    let arr = [],
      obj = {};
    for (let i = 0; i < data.data.length; i = i + divider) {
      obj = { ...data };
      obj["data"] = [];
      for (let j = i; j < i + divider; j++) {
        if (data.data[j]) obj["data"].push(data.data[j]);
        else break;
      }
      arr.push(obj);
    }
    return arr;
  };
  splittingSourceSlides = (data) => {
    // ---example---
    let elements = [...data.data],
      new_arr = [], child_arr = [];
    let counter = 0;
    for (let [index, item] of elements.entries()) {
      let newData = { ...data };
      let current_lines = Math.ceil(Number(item.text.length / 210));
      let max_size = current_lines == 1 ? current_lines : (current_lines - 1) * 2 + 1;
      if ((counter + max_size) > 35) {
        newData.data = child_arr;
        new_arr.push(newData);//console.log(new_arr[new_arr.length-1].data.length);
        child_arr = [];
        child_arr.push(item);//console.log(new_arr.length)
        counter = max_size;
      }
      else {
        counter += max_size;
        child_arr.push(item)
      }
      counter += 2;
    }
    //           for(let item of new_arr){
    //     console.log(item.data.length,"==",child_arr)
    // }
    if (child_arr.length > 0) {
      let newData = { ...data };

      newData.data = child_arr;
      new_arr.push(newData);
    }

    return new_arr
  };
  stringDivider = (data, divider) => {
    let counter = 0,
      arr = [],
      startAt = 0;
    for (let [index, item] of data.data.entries()) {
      counter +=
        Math.ceil(item.text.length / 141) > 1
          ? Math.ceil(item.text.length / 141) - 0.5
          : 1;
      if (counter >= divider) {
        splitArrayFunction(data, startAt, index - 1);
        startAt = index;
        counter = 0;
      }
    }
    if (counter != 0) {
      splitArrayFunction(data, startAt, data.data.length - 1);
    }
    function splitArrayFunction(data, startAt, num) {
      let obj = { ...data };
      obj["data"] = [];

      for (let j = startAt; j <= num; j++) {
        if (data.data[j]) {
          obj["data"].push(data.data[j]);
        } else break;
      }
      arr.push(obj);
    }

    return arr;
  };
  render() {
    return <div>{this.generatePdf()}</div>;
  }
}
function mapStateToPros(state) {
  return {
    REPORT_HEADER_DATA: state.REPORT_HEADER_DATA,
    ANALYSIS_LINK_COUNTER: state.ANALYSIS_LINK_COUNTER,
  };
}
const mapDispatchToProps = {
  setReportHeaderData,
  setLinkCounter,
};

export default connect(mapStateToPros, mapDispatchToProps)(FinalReport);
