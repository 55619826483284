import styled from "styled-components";


export const ExecutiveWrapper = styled.div`
    .svg-image{
       width:150px;
       height:110px;
       display: flex;
       align-items: center;
    justify-content: center;
    }
    .svg-image svg{
        width:90px;
        height:90px;
    }
  
`;
