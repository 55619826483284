import React, { Component } from "react";
import {
  MobileOutlined,
  MailFilled,
  PrinterFilled,
  HomeFilled,
} from "@ant-design/icons";
import KeyStrategicThemesForFeedAdditiveCEOSDemoDataBG from "../images/KeyStrategicThemesForFeedAdditiveCEOS.svg";
import Footer from "../components/Footer";

var Base64 = require("js-base64").Base64;

export const KeyStrategicThemesForFeedAdditiveCEOSDemoData = {
  title: "Key Strategic Themes For Feed Additive CEOS",
  slideName: "KeyStrategicThemesForFeedAdditiveCEOS",
  id: "KEY STRATEGIC THEMES FOR FEED ADDITIVE CEOS",
};

class KeyStrategicThemesForFeedAdditiveCEOS extends Component {
  state = {
    data: this.props.data || KeyStrategicThemesForFeedAdditiveCEOSDemoData,
    reseller: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }

  render() {
    let { section_id, slug } = this.state.data;
    const str = this.state.data.title;
    const slide_title = str.slice(0, str.length - 1).toUpperCase() + str.charAt(str.length - 1).toLowerCase();
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    return (
      <div className="page" style={{ paddingTop: "25px" }} id={pageId}>
        <div className="KeyStrategicThemesForFeedAdditiveCEOS">
          <h1 className="KeyStrategicThemesForFeedAdditiveCEOSTitle">
            {slide_title}
          </h1>
          <div className="keystrategicthemes_container">
            <div className="keystrategicthemes_card1">
              <h2>MARKETS & CONSUMERS</h2>
              <ul>
                <li>
                  How to maintain and acquire key accounts with significant
                  consumption volume?
                </li>
                <li>
                  How to align with the changing regulatory norms and consumer
                  requirements?
                </li>
                <li>
                  How to increase and strengthen presence in emerging markets?
                </li>
              </ul>
            </div>
            <div className="keystrategicthemes_card2">
              <h2>ROUTE TO MARKET</h2>
              <ul>
                <li>
                  How to ensure consumer retention and enhance the existing
                  sales channel?
                </li>
                <li>
                  How to leverage e-commerce to reach out to B2B consumers?{" "}
                </li>
                <li>
                  How to reach out to multi-brand and exclusive distributors?
                </li>
              </ul>
            </div>
            <div className="keystrategicthemes_card3">
              <h2>VALUE PROPOSITION</h2>
              <ul>
                <li>
                  How to identify and focus on potential fast growing segements?
                </li>
                <li>
                  How to sustain profitability in a rapidly changing market
                  scenario?
                </li>
                <li>
                  How to prove credibility and quality in an increasingly
                  competitive market?
                </li>
              </ul>
            </div>
          </div>
          { this.state.reseller ? "" : <div style={{ color: "white", padding: "5px 40px" }}>
            Mordor Intelligence's customized consulting solution can help you
            provide answers to these questions, based on your company internal
            strategy.
          </div>}

          { this.state.reseller ? "" : <div className="KeyStrategicThemesForFeedAdditiveCEOSContact">
            <div className="heading">For more information:</div>
            <div className="contact contact1">
              <MobileOutlined />
              +1 617 765 2493
            </div>
            <div className="contact contact2">
              <MailFilled />
              info@mordorintelligence.com
            </div>
            <div className="contact contact3">
              <PrinterFilled />
              www.mordorintelligence.com
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export default KeyStrategicThemesForFeedAdditiveCEOS;
