import React, { Component } from "react";
import CompanyOverviewWrapper from "../styles/CompanyOverview.style";
import company_logo from "../images/company_logo.png";
import date_logo from "../images/date_logo.png";
import location_logo from "../images/location_logo.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Bar from "../graphs/bar/Main";
import Pie from "../graphs/pie/Main";
import Analysis from "../components/Analysis";
var Base64 = require("js-base64").Base64;

export const companyProfileDemoData = {
  slideName: "companyprofile",
  title: "4.4.1 BASF S.E. - OVERVIEW",
  slideNo: 10,
  slug: "NORTH AMERICA ENZYME MARKET",
  company_name: "BASF S.E",
  slideReference: "4 COMPETITIVE LANDSCAPE",
  image:
    "https://mordor-graph-bucket.s3.amazonaws.com/113/1609252633141_test~113_113.png",
  meta: {
    "Strategic Outlook":
      "Chr. Hansen A/S is a global bioscience company focused on multiple segments across human and animal nutrition. The company has a key strategic position in the feed probiotics market, being a key supplier to poultry segment. The company has been focusing on product development for the poultry segment and has a key focus on the Asia-Pacific market.",
    "Market Positioning":
      "Chr. Hansen A/S is a global bioscience company focused on multiple segments across human and animal nutrition. The company has a key strategic position in the feed probiotics market, being a key supplier to poultry segment. The company has been focusing on product development for the poultry segment and has a key focus on the Asia-Pacific market.",
    "Number of Products": 5,
    "Focus Segment": "LACTOBACILLI",
    "Focus Animal": "POULTRY",
    Year: 2019,
    Location: "Headquarters in Hyderabad, India",
    Website: "http://mordorintelligence.com",
    Phone: "+91 3698521478",
    unit: "USD million",
    segmental_revenue: "586.98",
    segmental_revenue_share: "13",
    segmental_rank: "3",
  },
  meta_data: [
    {
      label: "USD milion Global Revenue (All Segment)",
      value: "70,070",
    },
    {
      label: "USD milion Feed Enzymes Segemntal Revenue",
      value: "68.5",
    },
    {
      label: "Revenue Share Global Feed Enzyme Market",
      value: "10%",
    },
    {
      label: "Global Rank -Global Feed Enzyme Market",
      value: "4th",
    },
  ],
  trends_heading: "CORE SOLUTION/ PRODUCT OFFERING",
  trends: [
    {
      analysis: [
        {
          text: "BASF SE offers Feed Enzyme products under the brand names Natugrain ® and Natuphos ®. While Natugrain ®is a group of carbohydrase enzymes, Natuphos ® is a group of phytase enzymes.",
          superScript: 1,
          hyperLink: "#abc",
        },
        {
          text: "Natugrain series of enzymes is available in powder form and made from grains such as wheat, barley and oats.",
        },
        {
          text: "",
        },
      ],
    },
  ],
  global_trends_data: {
    trends: [
      {
        analysis: [
          {
            text: "BASF SE is a global chemicals conglomerate with interests in chemicals, plastics, performance products, and agricultural products, to oil and gas",
            superScript: 1,
            hyperLink: "#abc",
          },
          {
            text: "Natugrain series of enzymes is available in powder form and made from grains such as wheat, barley and oats.",
          },
          {
            text: "",
          },
        ],
      },
    ],
    meta: {
      headquaters: "Ludwigshafen, Germany",
      founded: "1865",
      revenue_type: "Global",
    },
  },

  revenue: {
    2018: "100.30",
  },
  graph_heading: "BASE SE NUMBER OF PRODUCTS BY SEGMENT",
  graphs: [
    {
      graphType: "pie",
      data: [
        {
          label: "Carbohydrases",
          value: 28,
        },
        {
          label: "Phytases",
          value: 3,
        },
        {
          label: "Other Enzymes",
          value: 69,
        },
      ],
    },
    {
      graphType: "pie",
      data: [
        {
          label: "Ruminants",
          value: 19,
        },
        {
          label: "Poultry",
          value: 42,
        },
        {
          label: "Swine",
          value: 25,
        },
        {
          label: "Aquaculture",
          value: 6,
        },
        {
          label: "Other Animal Types",
          value: 8,
        },
      ],
    },
  ],
};

export const companyProfileDemoDataLatest = {
  title: "Glanbia PLC",
  subtitle: "Glanbia PLC",
  market_company: {
    meta: {
      company_highlights: [""],
      other_key_information: {
        heading: "KEY INFORMATION -slug",
        key_information: [
          "Out of company’s production facilities in 32 countries, the major ones are located in Ireland, the US, the UK, Germany and China.  ",
          "The company claimed the leading position in the production of whey protein isolates in the United States.",
        ],
      },
      market_segment: "Whey Protein",
      company_vertical: "Glanbia Nutritionals",
    },
    core_products: {
      products: [
        "Glanbia Nutritionals comprises Nutritional Solutions and US Cheese divisions. Nutritional Solutions manufactures and sells nutritional and functional ingredient solutions, targeting the increased focus on health and nutrition in the market.",
        "The company offers whey protein ingredients, which fall under the Proteins sub-segment, through its Provon and Thermax brands. These offerings have major applications in food, beverage, and supplements.",
      ],
      heading: "CORE SOLUTIONS & PRODUCT OFFERINGS",
    },
    segmental_revenue: {
      year: "2020",
      currency: "usd",
      units: "Millions",
      source: "",
      value: 143.42,
    },
    market_share: {
      value: "14342 %",
      year: "2020",
    },
    rank: {
      value: 1,
    },
  },
  global_company: {
    analysis: {
      heading: "OVERVIEW",
      analysis: [
        "Glanbia is a key player in the nutrition and ingredients segment, with leading market positions in sports nutrition, cheese, dairy ingredients, specialty non-dairy ingredients, and vitamin and mineral premixes. ",
        "The company has operations in 34 countries and sells its products in over 130 countries. ",
        "The company also has 29 production facilities worldwide, with two facilities under construction in the United States.",
      ],
    },
    revenue: {
      currency: "Million",
      source:
        "https://www.glanbia.com/sites/glanbia/files/glanbia/investors/annual-report/glanbia-annual-report-2019.pdf",
      units: "USD",
      value: 4185.76,
      year: "1997-2019",
    },
    core_business_segments: {
      heading: "CORE BUSINESS SEGMENTS",
      business_segments: [
        {
          heading: "Proteins",
          products: ["Dairy-based", "Plant-based", "Functional Solutions"],
        },
        {
          heading: "Cheese",
          products: [
            "Cheddar",
            "Colby & Jack",
            "Gouda",
            "Specialty Cheese",
            "Innovation Cheese",
            "Organic Cheese",
          ],
        },
        {
          heading: "Premix Solutions",
          products: [
            "Sports & Performance Nutrition",
            "Weight Management",
            "Antioxidant & Immunity",
            "Heart Health",
            "Bone & Joint Health",
            "Sleep & Cognition",
            "Healthy Aging",
            "Maternal & Toddler Health",
          ],
        },
        {
          heading: "Bioactives",
          products: [
            "Simpleat™ Ultra-Clean Plant-based Solutions",
            "BEV Solutions",
            "SNACK Solutions",
            "CUISINE Solutions",
            "PET Solutions",
            "Plant-Based Protein",
          ],
        },
      ],
    },
    employees: {
      text: "Employees",
      value: 7385,
    },
    founded: {
      text: "Founded",
      value: 1997,
    },
    headquarters: {
      text: "Headquarters",
      value: "Kilkenny, Ireland",
    },
    logo_url:
      "https://mordorintelligence1-my.sharepoint.com/personal/jafar_mordorintelligence_com/Documents/Hub-Protein%20Market/Logos/1200px-Glanbia_logo.svg.png",
  },
};

class CompanyProfile extends Component {
  state = {
    graphFooterText:
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: companyProfileDemoDataLatest,
    reseller: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }


  ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };

  render() {
    const { id, title, global_company, market_company } = this.state.data;
    const { slideNo } = this.props;
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    return (
      <CompanyOverviewWrapper id={pageId}>
        <div className="page">
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Header slideTitle="test" />
          ) : null}
          <div className="container">
            <h2 className="heading">{title}</h2>
            <div className="profile_container">
              <div className="left_container">
                <img
                  style={{ marginBottom: 20, width: 380, height: 143 }}
                  src={global_company && global_company.logo_url}
                  alt=""
                />
                <div className="company_bio">
                  <div className="company_date">
                    <img
                      src={date_logo}
                      alt=""
                    />
                    Founded in {global_company && global_company.founded.value}
                  </div>
                  <div className="company_date">
                    <img
                      src={location_logo}
                      alt=""
                    />
                    {global_company &&
                      global_company.headquarters &&
                      global_company.headquarters.value}
                  </div>

                  {global_company &&
                  global_company.analysis &&
                  global_company.analysis.analysis
                    ? global_company.analysis.analysis.map((item) => (
                        <div className="company_content">
                          <p1 style={{ marginBottom: "15px" }}>{item}</p1>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
              <div className="company_stats">
                <div className="inner-stats-container">
                  <div className="stats-card-1">
                    <div className="heading">{global_company && global_company.revenue.value}</div>
                    <div className="stats_content_card">
                      {global_company.revenue.currency.toUpperCase()}{" "}
                      {global_company.revenue.units} ({" "}
                      {global_company.revenue.year} )
                    </div>
                  </div>
                  <div className="stats-card-2">
                    <div className="heading">
                      {market_company && market_company.segmental_revenue.value}
                    </div>
                    <div className="stats_content_card">
                      {market_company.segmental_revenue.currency.toUpperCase()}{" "}
                      {market_company.segmental_revenue.units} All Segments ({" "}
                      {market_company.segmental_revenue.year} )
                    </div>
                  </div>
                  <div className="stats-card-3">
                    <div className="heading">
                      {market_company && market_company.market_share.value}
                    </div>
                    <div className="stats_content_card">
                      Market Share Year {market_company.market_share.year}
                    </div>
                  </div>
                  <div className="stats-card-4">
                    <div className="heading">
                      {ordinal_suffix_of(
                        market_company && market_company.rank.value
                      )}
                    </div>
                    <div className="stats_content_card">Global Rank</div>
                  </div>
                </div>
                <div>
                  <h3>
                    {market_company &&
                      market_company.core_products &&
                      market_company.core_products.heading}
                  </h3>
                </div>
              </div>
              <div className="company_chart">
                <h3 className="subheading" style={{ width: "75%" }}>
                  {global_company &&
                    global_company.core_business_segments.heading}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </CompanyOverviewWrapper>
    );
  }
}
function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export default CompanyProfile;
