import React, { Component } from "react";
import Footer from "../components/Footer";
import { Image } from "antd";
import ExecSummaryBox from '../components/ExecutiveSummary/ExecSummaryBox';
import ExecutiveSummaryCard from '../components/ExecutiveSummary/ExecutiveSummaryCard';
import Barline from "../graphs/barline/Main";
import segmentByType from "../images/segmentByType.png";
import byAnimalType from "../images/byAnimalType.png";
import largestRegMarket from "../images/largestRegMarket.png";
import fgregionalMarket from "../images/fgregionalMarket.png";
import { ExecutiveWrapper } from "../styles/Executive.style";
import leadingMarketPlayer from "../images/leadingMarketPlayer.png";
import Config from "../config/Config";

var Base64 = require("js-base64").Base64;
class ExecutiveSummary extends Component {
  state = {
    data: this.props.data || {},
    reseller: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }


  render() {
    let { section_id, title, subtitle, slug, trends, graphs } = this.state.data
      ? this.state.data
      : {};
    let companycount = 0

    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    let bgcolor = ["#D7F6EB", "#E6E3FC", "#FFEDC7", "#E0F9FF", "#EEF1F2"];
    let segmentBgcolor = ["#46CDCF", "#ECB449", "#B285FF", "#4A8CBB"];
    let logocolor = ["#BDE3DF", "#C8CBF1", "#EFD699", "#C4E6F3"];
    let hrbgcolor = ["#C1E1DE", "#D0D2EF", "#E4D9BF", "#C5E1EE", "#D5DDE5"];
    return (
      <>
        {this.props.data.data &&
        this.props.data.data.blocks &&
        this.props.data.data.blocks.length ? (
      <div className="page" id={pageId}>
      
          <div className="excuSumry">
            <h2 className="heading">EXECUTIVE SUMMARY & KEY FINDINGS</h2>
            <div className="excuSumryBoxes">
              <div className="segment-block-container">
                {this.props.data.data.segment_data_blocks &&
                  this.props.data.data.segment_data_blocks.map(
                    ({ label, value }, k) => (
                      <ExecutiveSummaryCard segmentBgcolor={segmentBgcolor[k]} segmentBlockValue={value} segmentBlockLabel={label}/>
                    )
                  )}
              </div>
              {this.props.data.data.blocks &&
                this.props.data.data.blocks.map(
                  (
                    {
                      analysis,
                      block,
                      value,
                      label,
                      icon,
                      percentage,
                      metric,
                      specificDimension,
                      name,
                    },
                    index
                  ) => {
                    if(specificDimension == "company"){
                      companycount++
                    }
                    let logoname =
                      name && specificDimension != "company"
                        ? name.toLowerCase().replace(/ /g, "_")
                        : "";
                    return (
                      <ExecSummaryBox
                      icon={icon}
                      bgColor={bgcolor[index]}
                      specificDimension={specificDimension}
                      metric={metric}
                      companycount={companycount}
                      percentage={percentage}
                      label={label}
                      analysis={analysis}
                      hrbgcolor={hrbgcolor[index]}
                      logocolor={logocolor[index]}
                      logoname={logoname}
                      />
                    );
                  }
                )}
            </div>
          </div>
        

        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Footer
            slug={slug}
            slideNo={slideNo}
            slideNoElementId={slideNoElementId}
            reseller={this.state.reseller}
          />
        ):null}
      </div>
       ):!this.props.fromInterface || this.props.fromInterface == undefined ?null:<h3>No Data</h3>}
      </>
    )
   
  }
}

export default ExecutiveSummary;
