import styled from 'styled-components'

export const InterFaceStyleComponent = styled.div`

.ce-toolbar__settings-btn{
    display: none
}

.content{
    width:100%;
    margin:0;
    padding: 0 0px 0px 0px;
    box-sizing: border-box;
    display:flex;
    h4{
    font-size: 16px;
    color: rgb(44, 142, 191);
    font-family: regular_semibold;
    padding-left:16px;
    }
}
.leftColumn{
    background-color:#ffff;
    width: 20%;
    padding:0 20px;

    color:white;
    font-size:14px;
    border-right: 1px solid rgb(238, 238, 238);
    height: calc(100vh - 105px);
    overflow: hidden scroll !important;
}
.leftColumn li{
    line-height: 40px;
    color: unset;
    list-style-type: unset;
    list-style-position: unset;
    margin-bottom: unset;
    text-align: unset;
    display: list-item;
    text-align: -webkit-match-parent;
}

/* .leftColumn li span{
    color: white !important;
} */
.rightColumn{
    width: 80%;
    height: calc(100vh - 105px);
    overflow: hidden auto;
}
.grnadchild{
    padding-left: 20px;
    color: inherit;
    cursor: pointer;
}
.paneltext:hover,.ant-collapse-header:hover{
        cursor:pointer;
        color:#189CDE !important;
    
}
.customize-report-button {
    background-image: linear-gradient(to right, rgb(44, 141, 191), rgb(70, 205, 207));
    color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
   
}
.data-charts-button {
    background-image: linear-gradient(to right, rgb(255, 95, 88), rgb(255, 119, 114));
    color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
}
.customize-report-button, .data-charts-button {
    width: 90%;
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
    padding: 3px 20px;
    height: auto;
}
.sideNav{
    display:block;
 }
 .SelectedToc{
     color:#2c8ebf !important;
 }

 .codex-editor__redactor{
     padding-bottom: 300px !important
 }

 #bar, #line{
     width: 100% !important;

 }

 .rdg.fill-grid{
     height: 100%
 }

 .rdg.fill-grid::-webkit-scrollbar {
    width: 1em !important;
    height: 1em !important;
}
/* .rdg.fill-grid::-webkit-scrollbar-button {
    background: #ccc
}

.rdg.fill-grid::-webkit-scrollbar-thumb {
    background: #3CCAD3
}​ */

 textarea{
     line-height: 1.5em;
     appearance: none;

box-sizing: border-box;
width: 100%;
height: 100%;
padding: 0px 6px 0 6px;
border: 2px solid #ccc;
vertical-align: top;
color: var(--color);
background-color: #fff;

font-family: inherit;
font-size: var(--font-size);

&:focus {
  border-color: #ddd;
  outline: none;
}

&::placeholder {
  color: #999;
  opacity: 1;
}
 }

 

 /* .react-grid-multiline-content .react-grid-Cell__value {
  white-space: normal !important;
} */

 .rdg-selected {
	border: 0px !important;
    appearance: none;

box-sizing: border-box;
width: 100%;
height: 100%;
padding: 0px 6px 0 6px;
border: 2px solid #ccc;
vertical-align: top;
color: var(--color);
background-color: var(--background-color);

font-family: inherit;
font-size: var(--font-size);

&:focus {
  border-color: #66afe9;
  outline: none;
}

&::placeholder {
  color: #999;
  opacity: 1;
}
}


div[role="columnheader"]{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* white-space: normal; */
}

div[role="gridcell"]{
    white-space: normal;
}


div[role="gridcell"]:nth-last-child(-n+2) {
    justify-content: center;
}

.rdg-header-sort-name{
    text-align: center;
}


  .filter-cell, .rdg-cell {
    line-height: 25px;
    padding: 0;
    /* text-transform: capitalize; */

    > div {
      padding: 0 8px;

      /* &:first-child {
        border-bottom: 1px solid var(--border-color);
      } */
    }
  }

  .dropdown-css{
    appearance: none;
    border: 2px solid #ccc;
    box-sizing: border-box;
    font-family: inherit;
    height: 100%;
    padding: 0 6px;
    vertical-align: top;
    width: 100%;
  }
  .filterClassname{
      width: 100%;
      padding: 4px;
      font-size: 14px;
  }

  .highlightClassname{
      background: blue !important;
  }

  .normalClassname{
      background: white !important;
  }

  .centerClassname{
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .draft-tab-dropdown .ant-select-selector{
  background: : 'rgba(0, 0, 0, 0.25)';
  color:white !important;
 }

 .stage-dropdown-color .ant-select-selector{
     background: none !important;
     border: none !important;
     color: white !important;
 }

 .stage-dropdown-color .ant-select-arrow{
     color: white !important;
 }

 .INITIAL-stage-color{
     background: #9889F2; 
 }

 .QC1-stage-color, .QC2-stage-color{
     background: #3FD0D9;
    }

.BACK-stage-color{
    background: #fd5c63;
     
    }
 .EDITING-stage-color{
        background: #FFC700;
    }
 .EDITED-stage-color{
     background: #49FCBC ;
    }
.PUBLISHED-stage-color{
    background: ##006A4E;
    }

    .black-loader .ant-select-arrow-loading{
        color: black !important;
    }

;

@media only screen and (max-width: 960px) {
    margin-bottom: 60px;
     .sideNav{
        display:none;
     }
     .drawer span{
         padding-top:20px !important;
     }
    
}

@media only screen and (max-width: 991px) {
    .rightColumn{
        width: 100%;
        height: calc(100vh-105px);
    }
}

@media only screen and (max-width: 480px) { 

    .rightColumn{
        width: 100%;
        height: auto;
        overflow: auto;
    }

}

`