import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import valueChain from "../images/Value_Chain.svg";
import proteins_valuechain from "../images/proteins_valuechain.svg";

import { ProteinsVCWrapper } from "../styles/Proteins-VC.style";
var Base64 = require("js-base64").Base64;


export const ValueChainDemoData2 = {
  title: "Value Chain & Distribution Channel Analysis",
  subtitle: "Value Chain & Distribution Channel Analysis",
  type: "valueChain",
  section_id: "",
  template: "valueChainlubricant",
  url: "https://s3.mordorlabs.com/Value%20Chain/1625051083229_test~Value_Chain_Value_Chain.svg",
  data: [
    {
      type: "Base Oil Companies",
      list: [
        "Chevron Corporation",
        "Holly Frontier",
        "Exxon Mobil Corporation",
      ],
    },
    {
      type: "Additive Companies",
      list: [
        "Afton Chemical",
        "The Lubrizol Corporation",
        "Chevron Corporation",
      ],
    },
    {
      type: "Lubricant Companies",
      list: ["Royal Dutch Shell Plc", "BP p.l.c.", "Valvoline Inc."],
    },
    {
      type: "OEMs",
      list: [
        "Honda Motor Co.",
        "Ltd.",
        "Ford Motor Company",
        "Daimler Trucks North America LLC",
      ],
    },
    {
      type: "Distributors",
      list: [
        "U.S. Venture",
        "Inc.",
        "Brenntag SE",
        "Comercial Importadora",
        "(CISA)",
      ],
    },
  ],
};

class ProteinsVC extends React.Component {
  state = {
    data: this.props.data ? this.props.data : ValueChainDemoData2,
    // section1_data: [],
    // section2_data: [],
    // section3_data: [],
    reseller: false
  };

//   componentDidMount = () => {
//     this.splitToChunks();
//   };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }


//   splitToChunks = () => {
//     const { data } = this.props.data ? this.props.data : this.state.data;
//     let section1_data = data && data.length > 1 ? data.slice(0, 3) : [];
//     let section2_data = data && data.length > 1 ? data.slice(3, 4) : [];
//     let section3_data = data && data.length > 1 ? data.slice(4, 6) : [];
//     this.setState({
//       section1_data,
//       section2_data,
//       section3_data,
//     });
//   };

  renderValueChainList = (element) => {

    let globArr = [];
    const newStr = element.split(";")
    newStr.forEach( e => {
      return globArr.push(e)
    });
    return globArr.length > 0 && globArr.map(elem => {
      return  (
        <div className="font_design_proteins_vc">
          <div>•</div>
          <div style={{ marginLeft: "5px"}}>{elem}</div>
        </div>
      )
    })
  }

  render() {
    const { section_id } = this.state.data;
    const { slideNo, data } = this.props;
    const slug = this.state.data.slug;
    const pageId = Base64.encode(section_id);
    let slideNoElementId = undefined;
    console.log("Section_data",this.props.data)

    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <ProteinsVCWrapper
          fromInterface={
            !this.props.fromInterface || this.props.fromInterface == undefined
              ? true
              : false
          }
        >
          <h2 className="heading architectural_valuchain_title">
            {this.props.data ? this.props.data.title : this.state.data.title}
          </h2>

          <div style={{ height: "560px", position: "relative" }}>
            <img
              src={
                this.state.data && this.state.data.meta && !this.state.data.meta.sample
                  ? this.state.data.url
                  : proteins_valuechain
              }
              alt="Value Chain"
              style={{ width: "100%", height: "100%", marginTop: "10px" }}
            />

            
{ this.state.data && this.state.data.meta && !this.state.data.meta.sample ? 

              <div className="section1_list">
              <div className="proteins_title">TOP RAW MATERIAL SUPPLIERS</div>
                {data.data.length ? data.data[0]['list'].map((elem) => {
                  return this.renderValueChainList(elem)
                }): ""}
              </div> : ''}

              { this.state.data && this.state.data.meta && !this.state.data.meta.sample ? 

              <div className="section2_list">
                  <div className="proteins_title">Top protein companies</div>
                  {data.data.length ? data.data[1]['list'].map((elem) => {
                  return this.renderValueChainList(elem)
                }): ""}
              </div> :''}

              { this.state.data && this.state.data.meta && !this.state.data.meta.sample ? 

              <div className="section3_list">
                  <div className="proteins_title">Top protein SUPPLIERS</div>
                  {data.data.length ? data.data[2]['list'].map((elem) => {
                  return this.renderValueChainList(elem)
                }): ""}
              </div> : ''}

              { this.state.data && this.state.data.meta && !this.state.data.meta.sample ? 

              <div className="section4_list">
                  <div className="proteins_title">Top End User Companies​</div>
                  {data.data.length ? data.data[3]['list'].map((elem) => {
                  return this.renderValueChainList(elem)
                }): ""}
              </div> :''}
          </div>

          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer slug={slug} slideNo={slideNo} reseller={this.state.reseller} />
          ) : null}
        </ProteinsVCWrapper>
      </div>
    );
  }
}

export default ProteinsVC;
