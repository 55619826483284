import React from "react";
import { Form, Input, Button, Typography, Select, message } from "antd";
import { useAuth } from "../firebase/contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { SignupWrapper } from "../styles/Signup.style";
import firebase from "firebase/app";
import config from "../config/Config";
import configs from "../config/Api";

export default function SignUp() {
  const axios = require("axios");
  const { Option } = Select;
  const { Title } = Typography;
  const { signup, sendVerificationEmail, login } = useAuth();
  const history = useHistory();
  const key = "SignUpMessage";

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const openLoading = () => {
    message.loading({ content: "Signing Up...", key, duration: 0 });
  };
  const openSuccess = () => {
    message.success({
      content:
        "Account Registered. Please check your email for email verification.",
      key,
      duration: 2.5,
    });
  };
  const openFailed = (msg = "Oops something went wrong!") => {
    message.error({
      content: msg,
      key,
      duration: 1.2,
    });
  };

  const onFinish = async (values) => {
    if (values.email.includes("@mordorintelligence.com")) {
      openLoading();
      await signup(values.email, values.password)
        .then((result) => {
          window.localStorage.removeItem(config.LOCAL_STORAGE_TOKEN.Name);
          window.localStorage.removeItem(config.LOCAL_STORAGE_TOKEN.meta);
          window.localStorage.removeItem("user");
          return result;
        })
        .then(async (result) => {
          let responseCustomClaims,
            responseInsertInDb,
            url,
            idToken,
            headers,
            role,
            access;

          url = configs.api.base_url;
          role = values.role;
          headers = { "Content-Type": "application/json" };
          access = {
            hubs: [1, 2, 3],
            edit: [
              "INITIAL",
              "QC1",
              "QC2",
              "SENT BACK",
              "EDITING",
              "EDITED",
              "PUBLISHED",
            ],
            move: [
              "INITIAL",
              "QC1",
              "QC2",
              "SENT BACK",
              "EDITING",
              "EDITED",
              "PUBLISHED",
            ],
          };

          //idToken = result.user.za;
          idToken = await result.user.getIdToken(true);

          //  idToken = result.user.za.getIdTokenResult(true);
          //  idToken = idToken.token;

          responseCustomClaims = await axios.post(
            `${url}/api/firebase/setCustomClaims`,
            {
              idToken: idToken,
              customClaims: { role: role, access: access },
            },
            headers
          );

          responseInsertInDb = await axios.post(
            `${url}/api/users/createNewUser`,
            {
              name: values.name,
              uid: result.user.uid,
              email_id: result.user.email,
              authentication_from: "firebase",
              role: role,
              access: access,
              active:
                responseCustomClaims.request.status === 200 ? true : false,
            },
            headers
          );

          if (responseCustomClaims.request.status !== 200)
            throw responseCustomClaims;

          if (responseInsertInDb.request.status !== 200)
            throw responseInsertInDb;

          //console.log("currentUser",)
          //login(values.email, values.password);
          await result.user.sendEmailVerification();
          // console.log(
          //   "result obj after signup",
          //   result.user.sendEmailVerification()
          // );
          //await result.sendVerificationEmail();
          // await sendVerificationEmail(result);

          setTimeout(() => {
            openSuccess();
          }, 250);
        })

        .catch((e) => {
          console.log("I m error ", e.status === 204);
          if (e.code || e.status) {
            if (e.code === "auth/invalid-email")
              openFailed("Invalid Email format.");
            if (e.code === "auth/weak-password")
              openFailed("Password should be at least 6 characters.");
            if (e.code === "auth/email-already-in-use" || e.status === 204)
              openFailed("The Email Id is already registered.");
          } else {
            console.log("Error at signup func ", e);
            openFailed("Something went wrong. Please contact support.");
          }
        });
    } else openFailed("Invalid Email.");
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <SignupWrapper>
      <div className="login-form">
        <div className="login-title">Create an account</div>
        <Form
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {" "}
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input
              placeholder="Name"
              // suffix="@mordorintelligence.com"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              // suffix="@mordorintelligence.com"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input placeholder="Password" type="password" />
          </Form.Item>
          <Form.Item
            name="role"
            rules={[
              {
                required: true,
                message: "Please input your Role",
              },
            ]}
          >
            <Select placeholder="Select a role">
              <Option value="SUPER_ADMIN">Super Admin</Option>
              <Option value="admin">Admin</Option>
              <Option value="client">Client</Option>
              <Option value="premium_user">Premium User</Option>
              <Option value="free_user">Free User</Option>
            </Select>
          </Form.Item>
          <div className="buttons">
            <Form.Item>
              <Button htmlType="submit">SignUp</Button>
            </Form.Item>
            <Form.Item>
              <Button
                className="signup_login_form_button"
                onClick={() => {
                  history.push("/login");
                }}
              >
                Login
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </SignupWrapper>
  );
}
