import React, { Component } from "react";
import CompanyMarketSpecific from "./CompanyMarketSpecific";
import ProductAndServicesV2 from "./ProductAndServicesV2";
import RecentDevelopment from "./RecentDevelopment";
import CompanyProfileOverview from "./CompanyProfileOverview";
import CompanyProfileEditing from '../components/CompanyProfileEditing';
var Base64 = require("js-base64").Base64;

const companyProfile = {
  title: "Royal Dutch Shell",
  subtitle: "Royal Dutch Shell",
  type: "company",
  section_id: "9ebab7c2-b3ff-4ed0-ac32-5f4898a0103c",
  template: "companyProfile1",
  data: {
    market_company: {
      meta: {
        company_highlights: [
          "Shell has been improving its technology portfolio, with over 150 patents filed in lubricants and base oils. Apart from its retail stores and business contracts, the company has a dominant market presence in the fast-lubes chain in North America.",
        ],
        other_key_information: {
          heading: "KEY INFORMATION",
          key_information: [
            "Royal Dutch Shell manages operations in more than 70 countries across the world. Pennzoil and Quaker State are the major lubricant companies based in the United States, operated by Royal Dutch Shell PLC. ",
            "",
            "",
          ],
        },
        market_segment: "Automotive Lubricants",
        company_vertical: "Downstream Lubricants",
      },
      core_products: {
        products: [
          "Shell engages in the refining of crude oil into numerous products, such as lubricants, gasoline, aviation fuel, marine fuel, liquefied natural gas, production and sale of petrochemicals for industrial chemicals, refining, trading and supply, and alternative energy businesses. ",
          "Shell operates its Lubricants Business through its Downstream segment. Helix, Rimula, and ROTELLA are some of the major lubricant brands of the company.",
          "",
        ],
        heading: "CORE SOLUTIONS & PRODUCT OFFERINGS",
      },
      segmental_revenue: {
        year: "2019",
        currency: "usd",
        units: "Millions",
        source: "",
        value: "651.20 M Liters",
        label_type: "Volume",
      },
      market_share: {
        value: "15 %",
        year: "2019",
      },
      rank: {
        value: 1,
      },
      logo_url: "https://s3.mordorlabs.com/561/1625130334788_test~561_561.png",
    },
    global_company: {
      analysis: {
        heading: "OVERVIEW",
        analysis: [
          {
            text: "Royal Dutch Shell PLC is engaged in the exploration and extraction of crude oil, natural gas, and natural gas liquids across the world. ",
            hyperLink: "",
            counter: "",
          },
          {
            text: "Shell Oil Company, Raízen, Aera Energy LLC, and BG Group PLC are some of the subsidiaries of Royal Dutch Shell PLC.",
            hyperLink: "",
            counter: "",
          },
          {
            text: "The company has a presence in Europe, North and South America, Asia-Pacific, and Middle East & Africa.",
            hyperLink: "",
            counter: "",
          },
        ],
      },
      revenue: {
        currency: "USD",
        units: "million",
        value: "180543.0",
        year: 2020,
        source:
          '"4th Quarter 2020 and Full Year Unaudited Results", Royal Dutch Shell plc, February 4, 2021, https://www.shell.com/investors/results-and-reporting/quarterly-results/_jcr_content/par/grid/p0/textimage.stream/1612367462808/f6e86f369c7083244d6999144f17fae917d82093/q4-2020-qra-document.pdf',
      },
      core_business_segments: {
        heading: "CORE BUSINESS SEGMENTS",
        business_segments: [
          {
            heading: "Integrated Gas",
            products: [
              "LNG",
              "Conversion of Natural Gas into GTL Fuels and Other Products",
            ],
          },
          {
            heading: "Upstream",
            products: [
              "Exploration and Extraction of Crude Oil and Natural Gas",
            ],
          },
          {
            heading: "Downstream",
            products: ["Oil Products and Chemicals"],
          },
        ],
      },
      employees: {
        text: "Employees",
        value: 81000,
      },
      founded: {
        text: "Founded",
        value: 1907,
      },
      headquarters: {
        text: "Headquarters",
        value: "Hague, Netherlands",
      },
      logo_url: "https://s3.mordorlabs.com/561/1625130334788_test~561_561.png",
    },
    products: [],
    Key_development: [
      [
        {
          date: "March 2021",
          type: "Product Innovation",
          description: [
            {
              text: "In 2020, Royal Dutch Shell Plc decided to market two of its refineries in the United States. The organization revealed its plans to reshape its refining portfolio across the world to use Shell's characteristic strengths and integration contingency.",
              hyperLink: "developments5610",
              counter: 1,
            },
          ],
        },
        {
          date: "February 2021",
          type: "Product Innovation",
          description: [
            {
              text: "Royal Dutch Shell introduced and added new range of lubricants called carbon neutral lubricants to its products portfolio for passenger cars, heavy duty diesel engines and industrial applications to reduce the carbon emissions globally.",
              hyperLink: "developments5611",
              counter: 2,
            },
          ],
        },
        {
          date: "August 2019",
          type: "Partnership",
          description: [
            {
              text: "Shell Lubricants and Comercial Importadora SA de CV (CISA) announced a new joint venture partnership in Mexico. This partnership may strengthen CS’s leading position in automotive lubricants in Mexico.",
              hyperLink: "developments5612",
              counter: 3,
            },
          ],
        },
      ],
    ],
  },
  startAtAnalysisCounter: 3,
  source: [
    {
      text: '"Shell Oil Products to Market Two Refineries in Line with Downstream Strategy", Royal Dutch Shell plc, March 5, 2020, https://www.shell.us/media/2020-media-releases/shell-oil-products-to-market-two-refineries-in-line-with-downstream-strategy.html',
      hyperLink: "developments5610",
    },
    {
      text: '"Shell introduces a global portfolio of carbon neutral lubricants", Royal Dutch Shell Plc, February 23, 2021, https://www.shell.com/business-customers/lubricants-for-business/news-and-media-releases/2021/shell-introduces-a-global-portfolio-of-carbon-neutral-lubricant.html',
      hyperLink: "developments5611",
    },
    {
      text: '"Shell and CISA announce joint venture in the Mexican lubricants market", Fuels and Lubes, August 21, 2019, https://www.fuelsandlubes.com/shell-cisa-announce-joint-venture-mexican-lubricants-market/',
      hyperLink: "developments5612",
    },
  ],
  listOfFigures: [],
  access: true,
};
class CompanyProfileContainer extends Component {
  state = {
    data: this.props.data ? this.props.data : companyProfile,
    didmount: false,
  };
  componentDidMount() {
    let new_keydevlopment = [].concat(...this.state.data.data.Key_development);
    let new_productANDservies = [].concat(...this.state.data.data.products)

    let new_data = {
      ...this.state.data,
      data: {
        ...this.state.data.data,
        Key_development: new_keydevlopment,
        products:new_productANDservies
      },
    };
    this.setState({
      data: new_data,
      didmount: true,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.data.data) !== JSON.stringify(prevProps.data.data)) {
      let didUpdate_keydevlopment = [].concat(...this.props.data.data.Key_development);
      let didUpdate_products = [].concat(...this.props.data.data.products);
      let didUpdate_data = {
        ...this.props.data,
        data: {
          ...this.props.data.data,
          market_company: this.props.data.data.market_company,
          Key_development: didUpdate_keydevlopment,
          products: didUpdate_products,
        },
      };
      this.setState({
        data: didUpdate_data,
        didmount: true,
      });
    }
  }
  render() {
    const { products, global_company, market_company, Key_development } =
      this.state.data.data;
    const section_id = this.state.data;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    const breaks =
      !this.props.fromInterface || this.props.fromInterface == undefined
        ? null
        : [<hr style={{ height: "1px", padding: "0 20px" }} />];
    return (
      this.state.didmount && (
        !this.props.edit_mode ?
        <div style={{ padding: "0 20px" }}>
           {global_company ? 
          <CompanyProfileOverview
            title={this.state.data.title}
            data={{ global_company }}
            slideNo={this.props.slideNo}
            fromInterface={this.props.fromInterface}
            section_id={this.state.data.section_id}
          />:null}
          {breaks}
         {market_company? <CompanyMarketSpecific
            title={this.state.data.title}
            template={this.state.data.template}
            data={{ market_company }}
            slideNo={this.props.slideNo}
            fromInterface={this.props.fromInterface}
            section_id={this.state.data.section_id}
          />:null}
          {breaks}
          {products && products.length ? (
            <>
              <ProductAndServicesV2
                title={this.state.data.title}
                data={{ products }}
                slideNo={this.props.slideNo}
                fromInterface={this.props.fromInterface}
                section_id={this.state.data.section_id}
              />
              {breaks}
            </>
          ) : null}
          {Key_development && Key_development.length ? (
            <RecentDevelopment
              title={this.state.data.title}
              data={{ Key_development }}
              fromInterface={this.props.fromInterface}
              slideNo={this.props.slideNo}
              section_id={this.state.data.section_id}
            />
          ) : null}
        </div>
        :
          <CompanyProfileEditing slide_details={this.props.slide_details} handleSwitchchange={this.props.handleSwitchchange}/>
      )
    );
  }
}

export default CompanyProfileContainer;
