import React, { Component } from 'react'
import BulkUploadComponent from './BulkUploadComponent';
import {BulkUploadContainerWrapper} from  './BulkUpload.style';

class BulkUploadContainer extends Component {
  render() {
    return (
      <BulkUploadContainerWrapper>
        <BulkUploadComponent />
      </BulkUploadContainerWrapper>
    )
  }
}

export default BulkUploadContainer
