import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BarLine from "../../graphs/barline/Main";
import Line from "../../graphs/line/Main";
import Graph from "../../components/Graphs";
import { LiveStockContainer } from "../../styles/Livestocks.style";
import Analysis from "../../components/Analysis";
import IndicatorEditing from "../../components/IndicatorEditing";
import { data } from "../SegmentDefinition";
var Base64 = require("js-base64").Base64;

class Indicator1 extends React.Component {
  state = {
    data: this.props.data ? this.props.data : {},
    reseller: false
  };
  componentDidUpdate(prevProps, prevState) {

    if (prevState && prevState.data.data != this.props.data.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }

  render() {
    const { section_id, slug, title, subtitle } = this.props.data;
    const { slideNo } = this.props;
    let heightOfHeading = false;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    if(this.props.data.data.length >1){
      if(this.props.data.data[0].trends.heading.length >=56){
        heightOfHeading= true
      }
      if(this.props.data.data[1].trends.heading.length >=56){
        heightOfHeading= true
      }
    }
    console.log("indicator",this.props.data.data);
    return (
      <LiveStockContainer id={pageId}>
        <div className="page">
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Header slideTitle={this.state.data.type} />
          ) : null}
                     {this.props.edit_mode ? 
            <IndicatorEditing data={this.props.data} edit_mode={this.props.edit_mode} template_type='indicator1' draft_drawer_visible={this.props.draft_drawer_visible} slideNo={this.props.slideNo}
            published_drawer_visible={this.props.published_drawer_visible} handleSwitchchange={this.props.handleSwitchchange} fromInterface={this.props.fromInterface}/>
            : 
          <div className="container">
            {title?<h2 className="heading">{title}</h2>:null}
            <div className="main-container">
              {this.state.data.data.map((item, i) => {
                return this.state.data.data.length > 1 ? (
                  <div className="indicator-container1">
                    {i == 0 || i == 1 ? null : <hr className="indicator-hr" />}
                    {/* {item.graph ?<h3 className="subheading">
                       {item.graph.heading }
                    </h3>: null} */}
                    {item.graph && item.graph.data != "hidden" ? (
                    
                      <Graph
                      wrapHeadingWidth={-100}
                        chartHeadingGap={item.graph.heading.length > 60 ? 0 : -10}
                        yHeadingMargin={item.graph.heading.length > 60 ? 30 : 20}
                        xHeadingMargin={0}
                        headers={item.graph.heading}
                        graphType={item.graph.graphType}
                      width={
                        !this.props.fromInterface ||
                        this.props.fromInterface == undefined
                          ? "566"
                          : "100%"
                      }
                      height={250}
                          data={ item.graph.data }
                          dynamiclegends={item.graph["legends"]}
                          axes={false}
                          totalData={item.graph}
                          labels={true}
                          footers={this.state.graphFooterText}
                          rotateValues={1}
                        />
                    ) : null}
                    {item.trends.heading?<h3 className="subheading subheading2" style={{height: heightOfHeading ? "51px" :"25px"}}>
                      {item.trends.heading}
                    </h3>:null}

                    <Analysis trends={item.trends} />
                  </div>
                ) : (
                  <div className="indicator-container1 full-width">
                    {i == 0 || i == 1 ? null : <hr className="indicator-hr" />}
                    {item.graph ?<h3 className="subheading" >
                       {item.graph.heading }
                    </h3>: null}
                    {item.graph && item.graph.data != "hidden" ? (

                      <Graph
                        wrapHeadingWidth={-100}
                        chartHeadingGap={item.graph.heading.length > 60 ? 0 : -10}
                        yHeadingMargin={item.graph.heading.length > 60 ? 30 : 20}
                        xHeadingMargin={0}
                        headers={item.graph.heading}
                        graphType={item.graph.graphType}
                        width={
                        !this.props.fromInterface ||
                        this.props.fromInterface == undefined
                          ? "566"
                          : "100%"
                      }
                        height={250}
                          data={ item.graph.data }
                          dynamiclegends={item.graph["legends"]}
                          axes={false}
                          totalData={item.graph}
                          labels={true}
                          footers={this.state.graphFooterText}
                          rotateValues={1}
                        />

                    ) : null}
                    
                   {item.trends.heading?<h3 className="subheading subheading2">
                      {item.trends.heading}
                    </h3>:null}

                    <Analysis trends={item.trends} />
                  </div>
                );
              })}
            </div>
          </div>
  }
          {!this.props.fromInterface ||
          this.props.fromInterface == undefined ? (
            <Footer slideNo={slideNo} slug={slug} reseller={this.state.reseller}/>
          ) : null}
        </div>
      </LiveStockContainer>
    );
  }
}

export default Indicator1;
