import styled from 'styled-components'

export const  LiveStockContainer =styled.div`
 
 .main-container{
    display:flex;
    width:100%;
    flex-wrap: wrap;
    justify-content:space-between;
  }
  .indicator-container1{
    margin-bottom:30px;
  }
  .indicator-container1 hr,.indicator-container2 hr{
    margin-bottom:50px;
    border-top: 1px solid #DCDCDC;
  }
  .indicator2-hr{
    margin-top:40px;
  }
  .indicator-container1:nth-child(odd){
    width:48%;
  }
  .indicator-container1.full-width{
    width:100%;
  }
  .indicator-container1:nth-child(even){
    width:48%;
  }
  .indicator-container2, .indicator-container{
    width:100%;
  }

  
  .subheading.subheading2{
    height:51px;
    margin:15px 0 10px;
  }
 
  .livestocklist{
    margin:15px 0;
  }

  #bar, #line{
    width: 100% !important;
  }


  
`