import React from "react";
import Footer from "../components/Footer";
import reportOffers from "../images/reportOffers.svg"

export const demoreportofferdata={
"slug":"North america"
}
class ReportOffers extends React.Component{
  state={
    data:this.props.data||demoreportofferdata
  }
  render(){
    const { slideNo } = this.props;
    const {slug}=this.state.data
    return(
      <div className="page">
      <div className="reportoffer-container" >
        <img src={reportOffers} alt='report offers'/>
      </div>
      {!this.props.fromInterface || this.props.fromInterface==undefined?<Footer slug={slug} slideNo={slideNo} />:null}
      </div>
    )
  }
}


export default ReportOffers;