import React from "react";
import { CompanyProfileOverviewUpdatedWrapper } from "../styles/CompanyProfileUpdated.style";
import CompanyMarketSpecific from "./CompanyMarketSpecific";
import ProductAndServicesV2 from "./ProductAndServicesV2";
import RecentDevelopment from "./RecentDevelopment";
import OverviewComponent from '../components/CompanyProfiles/OverviewComponent.js';
import CardComponent from '../components/CompanyProfiles/CardComponent.js';
import LogoComponent from "../components/CompanyProfiles/LogoComponent";
import Header from "../components/Header";
import Footer from "../components/Footer";
var Base64 = require("js-base64").Base64;

class CompanyProfileOverview extends React.Component {
  state = {
    graphFooterText:
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: this.props.data ? this.props.data : {},
    reseller: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }

  render() {
    
    const { global_company } = this.props.data
      ? this.props.data
      : this.state.data;
    const { section_id } = this.props.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    // let num = Number(global_company.revenue.value);
    
    return (
      
      this.props.data.global_company? 
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <CompanyProfileOverviewUpdatedWrapper
          interface={
            !this.props.fromInterface || this.props.fromInterface == undefined
              ? false
              : true
          }
        >
          <>
            <div className="container">
              <h2 className="heading" key={this.props.title}>
                {!this.props.fromInterface ||
                this.props.fromInterface == undefined
                  ? this.props.data.title
                  : this.props.title}{" "}
                - Global Overview
              </h2>
              <div className="profile_container">
                <div className="left_container">
                  <LogoComponent logo_url={global_company && global_company.logo_url}/>

                  <OverviewComponent heading={global_company &&
                        global_company.analysis &&
                        global_company.analysis.heading} analysis={global_company &&
                          global_company.analysis &&
                          global_company.analysis.analysis.map((item)=>item.text)}/>

                </div>
                <div className="middle_container">
                  <div className="inner-stats-container">
                    <CardComponent revenue={global_company &&
                          global_company.revenue &&
                          Number(global_company.revenue.value).toLocaleString('en-US')!=0?
                          Number(global_company.revenue.value).toLocaleString('en-US'):Number(global_company.revenue.value)} currencyDenominator={global_company &&
                            global_company.revenue &&
                            global_company.revenue.currency == "Million"
                              ? "M"
                              : global_company.revenue &&
                                global_company.revenue.currency == "Billion"
                              ? "Bn"
                              : "M"}
                              currency={global_company.revenue &&global_company.revenue.currency&&
                                global_company.revenue.currency.toUpperCase()}
                                year ={global_company.revenue && global_company.revenue.year}
                                type="card-1" bgColor='rgb(215, 246, 235)'
                                />

                    <CardComponent heading={parseInt(global_company.employees.value).toLocaleString('en-US')} text={global_company.employees.text} type='card-2' bgColor = 'rgb(255, 237, 199)' />
                    <CardComponent heading={global_company.founded.value} text={global_company.founded.text} type='card-2' bgColor='rgb(215, 246, 235)'/>
                    <CardComponent heading={global_company.headquarters.value.toUpperCase()} text={global_company.headquarters.text} type='card-2' bgColor='rgb(230, 227, 252)'/>
                  </div>
                </div>
                <div className="right_container">
                  <h3 className="subheading">
                    {global_company.core_business_segments.heading}
                  </h3>
                  {global_company.core_business_segments.business_segments &&
                  global_company.core_business_segments.business_segments.length
                    ? global_company.core_business_segments.business_segments.map(
                        (item) => (
                          <>
                            <h4>{item.heading}</h4>
                            {item.products && item.products.length
                              ? item.products.map((prod) => (
                                  <div style={{ paddingLeft: "16px" }}>
                                    <p>
                                      {prod}
                                      <br />
                                    </p>
                                  </div>
                                ))
                              : ""}
                          </>
                        )
                      )
                    : ""}
                </div>
              </div>
            </div>
          </>
        </CompanyProfileOverviewUpdatedWrapper>
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Footer slideNo={slideNo} reseller={this.state.reseller}/>
        ) : null}
      </div> :null
    );
  }
}

export default CompanyProfileOverview;
