const INITIAL_STATE = {
  TOC_DATA: {},
  IS_AUTH: false,
  SEARCH_PARAMS: false,
  ACTIVE_TAB: "drafts",
  REPORT_HEADER_DATA: [],
  ANALYSIS_LINK_COUNTER: 0,
  PLACEMENT: "right",
  PUBLISHED_DRAWER_VISIBLE: false,
  DRAFT_DRAWER_VISIBLE: false,
  draft_versions: {},
  published_versions: {},
  draft_data_heading:'',
  draft_data_analysis: [],
  draft_data_sources: [],
  USER_DATA:{},
  draft_stage: 'INITIAL',
  draft_stage_from_get_specific_data: '',
  no_drafts_found: false,
  user_edit_access: true,
  draft_versions_loading: false,
  initial_draft_versions_loading: false,
  published_versions_loading: false,
  specific_draft_loading: false,
  select_stage_change_loader: false,
  analysis_from_latest_draft:[],
  sources_from_latest_draft:[],
  switch_disabled: false,
  selected_indicator_heading: '',
  indicator_object_to_show: {},
  parent_id: '',
  cache_id:'',
  template_type:'',
  draft_id: null,
  indicator_dropdown_options:[],
  company_profile_data:[],
  update_company_profile_data: false,
  company_profile_dimensions:{},
  companyType:'marketCompany',
  fetch_full_data: false,
  character_count_error: false,
};

export const APP_REDUCER = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GETTOCDATA":
      return {
        ...state,
        TOC_DATA: action.data,
      };
    case "SETAUTH":
      return {
        ...state,
        IS_AUTH: action.data,
      };
      case "SET_USER_DATA":
      return {
        ...state,
        USER_DATA: action.data,
      };
    case "SET_SEARCH_PARAM":
      return {
        ...state,
        SEARCH_PARAMS: action.data,
      };
    case "ACTIVE_TAB":
      return {
        ...state,
        ACTIVE_TAB: action.data,
      };
    case "REPORT_HEADER_DATA":
      return {
        ...state,
        REPORT_HEADER_DATA: action.data,
      };
    case "ANALYSIS_LINK_COUNTER":
      return {
        ...state,
        ANALYSIS_LINK_COUNTER: action.data,
      };
    case "SHOW_PUBLISHED_DRAWER":
      return {
        ...state,
        PUBLISHED_DRAWER_VISIBLE: action.data,
      };
    case "SHOW_DRAFT_DRAWER":
      return {
        ...state,
        DRAFT_DRAWER_VISIBLE: action.data,
      };
    case "SET_DRAFT_VERSIONS":
      return {
        ...state,
        draft_versions: action.data,
      };
    case "SET_PUBLISHED_VERSIONS":
      return {
        ...state,
        published_versions: action.data,
      };

      case "SET_DRAFT_DATA_HEADING":
        return {
          ...state,
          draft_data_heading: action.data
        };  

    case "SET_DRAFT_DATA":
      return {
        ...state,
        draft_data_analysis: action.data,
      };
    case "SET_DRAFT_DATA_SOURCES":
      return {
        ...state,
        draft_data_sources: action.data,
      };
    case "SET_SAVE_DRAFT_LOADER":
      return {
        ...state,
        save_draft_loader: action.data,
      };
      case "SET_DRAFT_STAGE": //SELECTED DRAFT STAGE BY USER
        return {
          ...state,
          draft_stage: action.data,
        };
        case "SET_DRAFT_STAGE_FROM_GET_SPECIFIC_DATA": // DEFAULT STAGE FROM getSpecificData and subsequently from DRAFTS
          return {
            ...state,
            draft_stage_from_get_specific_data: action.data,
          };
        case "SET_USER_EDIT_ACCESS":
          return {
            ...state,
            user_edit_access: action.data,
          };
        case "SET_NO_DRAFTS_FOUND":
        return {
          ...state,
          no_drafts_found: action.data,
        };
        case "SET_DRAFT_VERSIONS_LOADING":
          return {
            ...state,
            draft_versions_loading: action.data
          };
          case "SET_INITIAL_DRAFT_VERSIONS_LOADING":
          return {
            ...state,
            initial_draft_versions_loading: action.data
          };
          case "SET_PUBLISHED_VERSIONS_LOADING":
            return {
              ...state,
              published_versions_loading: action.data
            };
            case "SET_SPECIFIC_DRAFT_LOADING":
              return {
                ...state,
                specific_draft_loading: action.data
              };
              case "SET_SELECT_STAGE_CHANGE_LOADER":
                return {
                  ...state,
                  select_stage_change_loader: action.data
                }; 
                
                case "SET_ANALYSIS_FROM_LATEST_DRAFT":
                  return {
                    ...state,
                    analysis_from_latest_draft: action.data
                  };  

                  case "SET_SOURCES_FROM_LATEST_DRAFT":
                    return {
                      ...state,
                      sources_from_latest_draft: action.data
                    };  
                   
                      case "SET_DISABLE_SWITCH":
                        return {
                          ...state,
                          switch_disabled: action.data
                        }; 

                        case "SET_SELECTED_INDICATOR_HEADING":
                          return {
                            ...state,
                            selected_indicator_heading: action.data
                          }; 

                          case "SET_INDICATOR_OBJECT_TO_SHOW":
                            return {
                              ...state,
                              indicator_object_to_show: action.data
                            }; 

                            case "SET_PARENT_ID":
                            return {
                              ...state,
                              parent_id: action.data
                            }; 

                            case "SET_CACHE_ID":
                              return {
                                ...state,
                                cache_id: action.data
                              }; 

                            case "SET_TEMPLATE_TYPE":
                              return {
                                ...state,
                                template_type: action.data
                              }; 

                              case "SET_DRAFT_ID":
                                return {
                                  ...state,
                                  draft_id: action.data
                                }; 

                                case "SET_INDICATOR_DROPDOWN_OPTIONS":
                                  return {
                                    ...state,
                                    indicator_dropdown_options: action.data
                                  }; 

                                  case "SET_COMPANY_PROFILE_DATA":
                                    return {
                                      ...state,
                                      company_profile_data: action.data,
                                    };

                                    case "SET_UPDATE_COMPANY_PROFILE_DATA":
                                    return {
                                      ...state,
                                      update_company_profile_data: action.data,
                                    };
                                    case "SET_COMPANY_PROFILE_DIMENSIONS":
                                      return {
                                        ...state,
                                        company_profile_dimensions: action.data,
                                      };
                                    case "SET_COMPANY_TYPE":
                                      return {
                                        ...state,
                                        companyType: action.data,
                                      };
                                    
                                      case "SET_FETCH_FULL_DATA":
                                        return {
                                          ...state,
                                          fetch_full_data: action.data,
                                        };
                                        case "SET_CHARACTER_COUNT_ERROR":
                                        return {
                                          ...state,
                                          character_count_error: action.data,
                                        };
                                
    case "RESET_STATE":
      return INITIAL_STATE;

    default:
      return state;
  }
};
