const config={
    CONTINENT_CONFIG: {
        "asia": 'AS',
        "asia-pacific":'AS',
        "europe": 'EU',
        "africa": 'AF',
        "north america": 'NA',
        "south america":'SA',
        "australia":'AU'
    },
}

export default config;