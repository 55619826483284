import React from "react";
import { Typography, Image } from "antd";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DataHidden from "../components/DataHidden";
import { RegulatoryFrameworkV2Wrapper } from "../styles/RegulatoryFramework.style";
import Analysis from "../components/Analysis";
const { Title } = Typography;
var Base64 = require("js-base64").Base64;

function ReturnTitleAndBulletPoints(arrayOfContent) {
  console.log("content", arrayOfContent)
  arrayOfContent.map((item1) => {
    return item1.analysis.forEach(function (BulletPoint, j) {
      console.log("bulletpoint", item1.type, j )
      if (!j)
        return (
          <div>
            {item1.type}
            {BulletPoint.text}
          </div>
        );
      else return <div>{BulletPoint.text}</div>;
    });
  });
}

class RegulatoryFrameworkV2 extends React.Component {
  state = {
    data: this.props.data ? this.props.data : {},
    reseller: false
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.data != this.props.data.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }


  render() {
    const { section_id, slug } = this.props.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <RegulatoryFrameworkV2Wrapper id={pageId} className="page">
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}

        <div className="container regulatoryFramework">
          <h2 className="heading regulatoryFramework">
          {!this.props.fromInterface || this.props.fromInterface == undefined ?this.state.data.title && this.state.data.title:"Regulatory Framework"}
          </h2>

          {this.state.data.data.map((CountryData, k) => {
            return (
              <>
                <div className="regulatoryFrameworkCountryv2">
                 {CountryData.icon? <img
                    className="RegulatoryFrameworkCountryFlag"
                    src={CountryData.icon}
                  />:null}
                  <span>{CountryData.country.toUpperCase()}</span>
                </div>
                <div className="RegulatoryFrameworkBoxSlide">
                  {CountryData.data.map(function (Box, i) {
                    let Heading = Box.type.toUpperCase();

                    return (
                      <div
                        className="RegulatoryFrameworkBoxv2"
                        id={"RegulatoryFrameworkBox" + i}
                      >
                        <span
                          className="RegulatoryFrameworkBoxHeading"
                          id={"RegulatoryFrameworkBoxHeading" + i}
                        >
                          {(Heading.substring(0, 30)) + ((Heading.length > 30) ?  " ..." : "")}
                        </span>
                        <p className="RegulatoryFramework_BulletPointsv2">
                          <Analysis
                            trends={{ analysis: Box.analysis }}
                            fromRegulatoryFrameWork
                            index={i}
                          />
                        </p>
                      </div>
                    );
                  })}
                </div>

                {ReturnTitleAndBulletPoints(CountryData.data)}
                {k == this.state.data.data.length - 1 ? null : (
                  <hr className="indicator2-hr" />
                )}
              </>
            );
          })}
        </div>

        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Footer slug={slug} slideNo={slideNo} slideNoElementId={"27"} reseller={this.state.reseller}/>
        ) : null}
      </RegulatoryFrameworkV2Wrapper>
    );
  }
}

export default RegulatoryFrameworkV2;
