import React, { Component } from 'react';
import { Row, Col, Input } from 'antd';
import axios from 'axios';
import ReactFC from 'fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts';
import FusionCharts from 'fusion_modules/fusioncharts-suite-xt-master/addlicense';
import Charts from 'fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts';
import FusionTheme from 'fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

class AnswerEngine extends Component {
    state = {}
    handleSearch = async (text) => {
        try {
            const response = await axios.get("https://test-query.mordorlabs.com/api/mongo-lorien/nlpPipeline?question=" + text);
            const { nlpResponse, query, result } = response.data;
            this.setState({ nlpResponse, query, result });
        } catch (e) {
            this.setState({ nlpResponse: null, query: null, result: null });
            console.log(e);
            if(e.response?.data?.message) {
                alert(e.response?.data?.message)
            }
        }
    }
    render() {
        const { nlpResponse, query, result } = this.state;
        const graph = result?.interface?.graph;
        const hasGraph = !!graph?.type;
        const paragraphs = result?.interface?.paragraphs;
        const hasParagraphs = paragraphs?.length > 0;

        const itetrator = [];
        if(query?.q1) {
            for(let i = 1; i <= 5; i++) {
                if(query["q" + i]?.[0].$match) {
                    itetrator.push(query["q" + i][0].$match)
                }
            }
        } else if(query?.[0]) {
            itetrator.push(query[0].$match)
        };
        let data = {}
        for(let item of itetrator) {
            Object.keys(item).reduce((acc, cur) => {
                if(typeof cur === 'string' && cur.includes("dimensions")) {
                    const dataKey = cur.split(".")[1];
                    const dataVal = typeof item[cur] === 'object' ? JSON.stringify(item[cur]) : item[cur];
                    if(data[dataKey]) {
                        if(!data[dataKey].includes(dataVal))
                            data[dataKey].push(dataVal)
                    } else {
                        data[dataKey] = [dataVal]
                    }
                }
                return acc;
            }, data)
        }
        console.log(data);
        return (
            <div style={{ padding: '20px 0px' }}>
                <Row>
                    <Col span={4}></Col>
                    <Col span={16}>
                        <Input.Search
                            size="large"
                            placeholder="Enter a query"
                            defaultValue="top 4 countries in North America Automotive lubricants market in terms of market size?"
                            // defaultValue="what is the market size of North America Automotive lubricants market"
                            onSearch={(searchText) => this.handleSearch(searchText)}
                        />
                        { !result && (
                            <div style={{padding: '20px'}}>
                                <p style={{textAlign: 'center'}}>Please enter a query</p>
                            </div>
                        )}
                        {
                            result && (
                                <div style={{fontSize: '20px'}}>
                                    <h4>Showing results for:</h4>
                                    <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <li style={{listStyle: 'none', padding: '0 10px'}}><strong>Metric</strong> - {nlpResponse.metrics[0]}</li>
                                        {
                                            Object.keys(data).map((key, index) => (
                                                <li key={index} style={{listStyle: 'none', padding: '0 10px'}}><strong>{key} - </strong> {data[key].join(",")}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            )
                        }
                        {
                            hasGraph && (
                                <ReactFC 
                                    type={graph.type}
                                    width="100%"
                                    height={400}
                                    dataFormat="JSON"
                                    dataSource={graph.dataSource}
                                />
                            )
                        }
                        {
                            hasParagraphs && (
                                <ul style={{marginTop: '40px'}}>
                                    {
                                        paragraphs.map((item, index) => (
                                            <li style={{fontSize: '20px'}} key={index}>{item}</li>
                                        ))
                                    }
                                </ul>
                            )
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AnswerEngine;