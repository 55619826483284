import styled from 'styled-components'

export const TypeDetailContainer = styled.div`


  .top-container,.bottom-container{
    display:flex;
    justify-content:space-between;
    width:100%;
    flex-direction: row;
  }
  .fullWidthCOntainer,.left-container-full{
    width:100%;
  }
  .left-container,.right-container{
    width:48%;
    display: flex;
    flex-direction: column;
  }
  

  .circle-graph{
    display:flex;
    justify-content:space-between;
  }
  @media only screen and (max-width: 960px) {
    .top-container,.bottom-container{
      flex-direction: column;
    }
    .left-container,.right-container{
      width:100%;
    }
  }

  `