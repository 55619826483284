import React, { Component } from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import "./App.css";
import FinalReport from "./components/FinalReport";

//import DetailedToc from "./pages/DetailedToc";
import Interface from "./components/Interface";
import BulkUpload from "./components/BulkUpload/BulkUploadContainer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Layout from "./components/Layout/Layout";
import SearchComponent from "./components/Search";
import Indicator1 from "./pages/Indicators/Indicator1";
import Indicator2 from "./pages/Indicators/Indicator2";
import Indicator3 from "./pages/Indicators/Indicator3";
import Introduction from "./pages/Introduction";
import RegulatoryFramework from "./pages/RegulatoryFramework";
import RegulatoryFrameworkV2 from "./pages/RegulatoryFrameworkv2";
import KeyStrategicThemesForFeedAdditiveCEOS from "./pages/KeyStrategicThemesForFeedAdditiveCEOS";
import FeedProduction from "./pages/FeedProduction";
import TypeOverview from "./pages/TypeOverview";
import FeedbackAndAudit from "./pages/FeedbackAndAudit";
import TypeDetail from "./pages/TypeDetail";
import GlobalOverview from "./pages/GlobalOverview";
import RegionOverview from "./pages/RegionOverview";
import CompanyProfile from "./pages/CompanyProfileContainer";
import MarketShare from "./pages/MarketShare";
import MarketShareAnalysis from "./pages/MarketShareAnalysis";
import RecentDevelopment from "./pages/RecentDevelopment";
import SegmentDefinition from "./pages/SegmentDefinition";
import RdGraph from "./pages/RdGraph";
import ProductAndServices from "./pages/ProductsAndServices";
import ProductAndServicesV2 from "./pages/ProductAndServicesV2";
import ForMoreInformationPleaseContact from "./pages/ForMoreInformationPleaseContact";
import GrowthStrategyMatrix from "./pages/GrowthStrategyMatrix";
import ExecutiveSummary from "./pages/ExecutiveSummary";
import KeyStrategicMoves from "./pages/KeyStratagicMoves";
import ReportOffers from "./pages/ReportOffers";
import StudyAssumptions from "./pages/StudyAssumptions";
import ResearchMethodology from "./pages/ResearchMethodology";
import DecisionMaker from "./pages/DecisionMaker";
import LubricantVC from "./pages/Lubricants-VC";
import ArchitecturalCoatingVC from "./pages/ArchitecturalCoating-VC";
import ProteinsVC from "./pages/Proteins-VC";
import ScopeStudy from "./pages/ScopeStudyStatic";
import ScopeStudyRegional from "./pages/ScopeStudy";
import Image from "./pages/GenerateImage";
import Disclaimer from "./pages/Disclaimer";
import Sources from "./pages/Sources";
import CompanyProfileOverview from "./pages/CompanyProfileOverview";
import CompanyMarketSpecific from "./pages/CompanyMarketSpecific";
import { AuthProvider } from "./firebase/contexts/AuthContext";
import Logout from "./components/Logout";
import ForgotPassword from "./pages/ForgotPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import config from "./config/Config";
import AnswerEngine from "./pages/AnswerEngine";

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <AuthProvider>
              <Route path="/login" component={Login}></Route>
              <Route path="/signup" component={SignUp} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route path="/answer-engine" component={AnswerEngine} />
              <ProtectedRoute exact path="/home" component={Home} />
              <ProtectedRoute path="/editing/:tab" component={Layout} />
              <ProtectedRoute  path="/bulk-upload" component={BulkUpload} />
              <ProtectedRoute path="/interface/:report" component={Interface} />
              <ProtectedRoute path="/search" component={SearchComponent} />
              <Route path="/final-report/:folder" component={FinalReport} />
              <ProtectedRoute
                path="/scopeofthestudycountry"
                component={ScopeStudy}
              />
              <ProtectedRoute
                path="/scopeofthestudyregional"
                component={ScopeStudyRegional}
              />
              <ProtectedRoute path="/reportoffers" component={ReportOffers} />
              <ProtectedRoute path="/disclaimer" component={Disclaimer} />
              <ProtectedRoute path="/sources" component={Sources} />
              <ProtectedRoute
                path="/studyassumptions"
                component={StudyAssumptions}
              />
              <ProtectedRoute
                path="/researchmethodology"
                component={ResearchMethodology}
              />
              <ProtectedRoute path="/image" component={Image} />
              <ProtectedRoute path="/decisionmaker" component={DecisionMaker} />
              <ProtectedRoute
                path="/lubricants-valuechain"
                component={LubricantVC}
              />
              <ProtectedRoute
                path="/architecturalcoating-valuechain"
                component={ArchitecturalCoatingVC}
              />
              <ProtectedRoute
                path="/proteins-valuechain"
                component={ProteinsVC}
              />
              <ProtectedRoute path="/introduction" component={Introduction} />
              <ProtectedRoute path="/indicator1" component={Indicator1} />
              <ProtectedRoute path="/indicator2" component={Indicator2} />
              <ProtectedRoute path="/indicator3" component={Indicator3} />
              <ProtectedRoute
                path="/feedproduction"
                component={FeedProduction}
              />
              <ProtectedRoute
                path="/ForMoreInformationPleaseContact"
                component={ForMoreInformationPleaseContact}
              />
              <ProtectedRoute
                path="/regulatoryframework"
                component={RegulatoryFramework}
              />
              <ProtectedRoute
                path="/regulatoryframeworkv2"
                component={RegulatoryFrameworkV2}
              />
              <ProtectedRoute
                path="/KeyStrategicThemesForFeedAdditiveCEOS"
                component={KeyStrategicThemesForFeedAdditiveCEOS}
              />
              <ProtectedRoute
                path="/FeedbackAndAudit"
                component={FeedbackAndAudit}
              />
              <ProtectedRoute path="/typeoverview" component={TypeOverview} />
              <ProtectedRoute path="/typedetail" component={TypeDetail} />
              <ProtectedRoute
                path="/globaloverview"
                component={GlobalOverview}
              />
              <ProtectedRoute
                path="/regionoverview"
                component={RegionOverview}
              />
              <ProtectedRoute
                path="/companyprofile"
                component={CompanyProfile}
              />
              <ProtectedRoute path="/marketshare" component={MarketShare} />
              <ProtectedRoute
                path="/marketshareanalysis"
                component={MarketShareAnalysis}
              />
              <ProtectedRoute
                path="/keystrategicmoves"
                component={KeyStrategicMoves}
              />
              <ProtectedRoute
                path="/recentdevelopment"
                component={RecentDevelopment}
              />
              <ProtectedRoute
                path="/SegmentDefinition"
                component={SegmentDefinition}
              />
              <ProtectedRoute
                path="/executiveSummery"
                component={ExecutiveSummary}
              />
              <Route exact path="/rd-graph" component={RdGraph} />
              <ProtectedRoute
                path="/productandservices"
                component={ProductAndServices}
              />
              <ProtectedRoute
                path="/productandservicesv2"
                component={ProductAndServicesV2}
              />
              <ProtectedRoute
                path="/growthstrategymatrics"
                component={GrowthStrategyMatrix}
              />
              <ProtectedRoute
                path="/companyoverview"
                component={CompanyProfileOverview}
              />
              <ProtectedRoute
                path="/companymarketspecific"
                component={CompanyMarketSpecific}
              />
            </AuthProvider>
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}
