import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.powercharts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import {roundOfMaxYAxisValue,roundOfpositive_NegativeMaxValue} from '../commanFunction';
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
FusionCharts.options.export.useCanvas = "true";

class Chart extends Component {
  state = {
    pmaxYaxisValue: 0,
    smaxYaxisValue: 0,
    data: [
      {
        label: "2002",
        value: 36,
        volume: 6,
      },
      {
        label: "2003",
        value: 27,
        volume: 7,
      },
      {
        label: "2004",
        value: 8,
        volume: 8,
      },
      {
        label: "2005",
        value: 10,
        volume: 10,
      },
      {
        label: "2006",
        value: 20,
        volume: 13,
      },
      {
        label: "2007",
        value: 14,
        volume: 14,
      },
      {
        label: "2008",
        value: 15,
        volume: 15,
      },
      {
        label: "2009",
        value: 17,
        volume: 17,
      },
      {
        label: "2010",
        value: 7,
        volume: 7,
      },
      {
        label: "2011",
        value: 48,
        volume: 8,
      },
      {
        label: "2012",
        value: 29,
        volume: 10,
      },
      {
        label: "2013",
        value: 3,
        volume: 13,
      },
      {
        label: "2014",
        value: 20,
        volume: 14,
      },
      {
        label: "2015",
        value: 15,
        volume: 15,
      },
      {
        label: "2016",
        value: 30,
        volume: 17,
      },
      {
        label: "2017",
        value: 10,
        volume: 20,
      },
      {
        label: "2018",
        value: 10,
        volume: 2,
      },
      {
        label: "2019",
        value: 1,
        volume: 20,
      },
      {
        label: "2020",
        value: 10,
        volume: 20,
      },
    ],
    label: [],
    volume: [],
    value: [],
  };

  componentDidMount() { 
    const data = this.props.data
    let catagory = [],
      BarData = [],
      LineData = [],
      parr = [],sarr=[];
    for (let item of data) {
      let label = {
        label: item.label,
      };
      catagory.push(label);
      let volume = {
        value: item.volume,
      };
      BarData.push(volume);
      let value = {
        value: item.value,
      };
      if ((item.label + "").substring(0, 4) == "2020")
        volume = {
          ...volume,
          showValue: 1,
        };
      parr.push(item.volume);
      sarr.push(item.value);
      LineData.push(value);
    }
    parr.sort(function (a, b) {
      if (a > b) return -1;
      else return 1;
    });
    sarr.sort(function (a, b) {
      if (a > b) return -1;
      else return 1;
    });
    this.setState({
      label: catagory,
      volume: BarData,
      value: LineData,
      data,
      pmaxYaxisValue: roundOfMaxYAxisValue(parr[0]*1.7),
      smaxYaxisValue: roundOfpositive_NegativeMaxValue(sarr[0],sarr[sarr.length-1])
    });
  
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState &&
      JSON.stringify(prevState.data) !== JSON.stringify(this.props.data)
    ) {
      const data = this.props.data
    let catagory = [],
      BarData = [],
      LineData = [],
      parr = [],sarr=[];
    for (let item of data) {
      let label = {
        label: item.label,
      };
      catagory.push(label);
      let volume = {
        value: item.volume,
      };
      BarData.push(volume);
      let value = {
        value: item.value,
      };
      if ((item.label + "").substring(0, 4) == "2020")
        volume = {
          ...volume,
          showValue: 1,
        };
      parr.push(item.volume);
      sarr.push(item.value);
      LineData.push(value);
    }
    parr.sort(function (a, b) {
      if (a > b) return -1;
      else return 1;
    });
    sarr.sort(function (a, b) {
      if (a > b) return -1;
      else return 1;
    });
    this.setState({
      label: catagory,
      volume: BarData,
      value: LineData,
      data,
      pmaxYaxisValue: roundOfMaxYAxisValue(parr[0]*1.7),
      smaxYaxisValue: roundOfpositive_NegativeMaxValue(sarr[0],sarr[sarr.length-1])
    });
    }
  }
  bytesToString = (d, width) => {
    let k = "";
    let computed_array = [];
    let number_of_letter = 0;
    number_of_letter = this.props.width / 4;

    let counter = 1,
      line = "";
    d = d + "";
    for (let item of d) {
      line += item;
      if (isNaN(item)) {
        counter++;
      } else {
        counter += 2;
      }
      if (counter >= number_of_letter) {
        if (
          d.charAt(k.length + line.length - 1) !== " " &&
          d.charAt(k.length + line.length) !== " " &&
          k.length + line.length !== d.length
        ) {
          k += line;
          line += "-";
        } else k += line;
        computed_array.push(line);
        line = "";
        counter = 1;
      }
    }
    if (counter < number_of_letter) computed_array.push(line);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  };
  render() {
    let width,
      height,
      rotateValues=0,
      legendPosition = "bottom",
      valueFontSize=12,
      small = false,
      headers="",
      footer =
        "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research";
    let dataType = this.props.dataType
      ? (this.props.dataType + "").toLowerCase().trim()
      : "";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.legendPosition) legendPosition = this.props.legendPosition;
    if (this.props.valueFontSize) valueFontSize = this.props.valueFontSize;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.rotateValues==1) rotateValues = this.props.rotateValues;
    if (this.props.small) {
      small = true;
    }
    console.log("reached heading",headers)
    footer = "";
    const chartConfigs = {
      type: "mscombidy2d",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          // divLineColor: "#ffffff",
          animation: false,
          captionFont: "Avenir bold",
          captionFontBold: true,
          legendCaptionFont: "Avenir Medium",
          legendItemFont: "Avenir Medium",
          paletteColors: "#4A90E2,#D755A3",
          showvalues: "0",
          showanchors: "1",
          legendPosition: legendPosition,
          plothighlighteffect: "fadeout",
          theme: "fusion",
          showLabels: true,
          captionAlignment: "left",
          valueFontColor: "#002F75",
          valueFontBold: false,
          valueFont: "Avenir Heavy",
          captionFontColor: "#7f7f7f",
          captionFontSize: 14,
          valueFontSize: valueFontSize,
          chartTopMargin: 80+this.props.chartHeadingGap,
          chartBottomMargin:10,
          yAxisValueFontSize: 14,
          xAxisValueFontSize: 14,
          yAxisValueFont: "Avenir Medium",
          labelFontSize: 14,
          rotateLabels:0,
          labelFont: "Avenir Medium",
          sNumberSuffix: " %",
          legendItemFontSize: 14,
          chartLeftMargin: 4,
          yAxisValueDecimals: "1",
          pYAxisMaxValue: this.state.pmaxYaxisValue,
          sYAxisMaxValue: this.state.smaxYaxisValue,
          "sYAxisMinValue": Number(this.state.smaxYaxisValue)*-1,
          showYAxisValues: !this.props.dataHidden,
          legendItemFontColor:"#7f7f7f",
          yAxisValueFontColor:"#7f7f7f",
          labelFontColor:"#7f7f7f",
          decimals: 1,
          pYAxisValueDecimals:0,
          sYAxisValueDecimals:0,
          adjustDiv: "0", 
          "numDivLines": "4",
          "numberScaleValue": "1000,1000,1000",
          "numberScaleUnit": "k,M,B",
          xaxislinecolor: "#4A90E2",
          yaxislinecolor: "#4A90E2",
          showxaxisline: !this.props.dataHidden,
          showYAxisline: !this.props.dataHidden,
          "rotateValues": rotateValues,
          baseFont: "Avenir Medium",
          baseFontSize:14,
          baseFontColor:"#7f7f7f"
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {},
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers+"").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${570+this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                },
              ],
            },
            {
              id: "user-images",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: "9",
                  font: "Avenir Medium",
                  text: this.bytesToString(footer, 2),
                  bold: "1",
                  wrap: "0",
                  wrapWidth: "30",
                  x: "$canvasStartY",
                  y: width > 500 ? "$canvasEndY+75" : "$canvasEndY+100",
                },
              ],
            },
          ],
        },
        categories: [
          {
            category: this.state.label,
          },
        ],
        dataset: [
          {
            seriesname: `${this.props.dynamiclegends[0]}`,
            plottooltext: `$value ${this.props.dynamiclegends[0]}`,
            placeValuesInside: "0",
            showValues: !this.props.dataHidden,
            data: this.state.volume,
          },

          {
            seriesname: `${this.props.dynamiclegends[1]}(%)`,
            parentyaxis: "S",
            renderas: "line",
            showvalues: "0",
            plottooltext: `$value ${this.props.dynamiclegends[1]}`,
            data: this.state.value,
          },
        ],
        },
    };
    const chartConfigs1 = {
      type: "multiaxisline",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          anchorRadius: 5,
          yAxisValueFontSize: small ? 12 : 14,
          yAxisValueFont: "Avenir semibold",
          rotateLabels: "0",
          legendItemFontSize: small ? 12 : 14,
          labelFontSize: small ? 11 : 12,
          labelFont: "Avenir Semibold",
          baseFont: "Avenir semibold",
          showvalues: "0",
          showanchors: "1",
          formatNumberScale: 0,
          chartLeftMargin: "1",
          legendBorderAlpha: "0",
          legendShadow: "0",
          alignCaptionWithCanvas: "0",
        },
        categories: [
          {
            category: this.state.label,
          },
        ],
        axis: [
          {
            titlepos: "left",
            tickwidth: "10",
            renderas: "spline",
            dataset: [
              {
                seriesname: "Volume",
                data: this.state.volume,
              },
            ],
          },
          {
            titlepos: "RIGHT",
            axisonleft: "0",
            tickwidth: "10",
            numberSuffix: "",
            dataset: [
              {
                seriesname: "YOY(%)",
                data: this.state.value,
              },
            ],
          },
        ],
      },
    };

    return (
      <div style={{ textAlign: "left" }}>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

export default Chart;
