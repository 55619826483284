import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Graph from "../../components/Graphs";
import BarLine from "../../graphs/barline/Main";
import { LiveStockContainer } from "../../styles/Livestocks.style";
import Analysis from "../../components/Analysis";
import IndicatorEditing from "../../components/IndicatorEditing";
var Base64 = require("js-base64").Base64;

export const livestockDemoData3 = {
  slideName: "livestock",
  title: "Livestock production",
  slideNo: 10,
  subtitle: "Livestock production",
  slug: "US PROBIOTICS MARKET",
  slideReference: "2. KEY INDUSTRY TRENDS",
  trends: [
    {
      title: "BEEF PRODUCTION SEES ROBUST GROWTH",
      analysis: [
        {
          text: "The global beef production, which stood at 200 million metric ton in 2011",
          superScript: 1,
          hyperLink: "#abc",
        },
        {
          text: "The lag in production in developed markets was offset by robust growth in production in emerging markets such as China. However, the domestic demand in the United States was stable, despite competition from other sources of protein.",
        },
        {
          text: "Moving into 2020, beef trade is expected to have an impact due to declining demand on account of COVID-19",
        },
        {
          text: "with the retail trade expected to struggle to offset the demand reduction in the foodservice sector.",
        },
      ],
    },
    {
      title: "MILK PRODUCTION INCREASES IN EMERGING MARKETS",
      analysis: [
        {
          text: "The global milk production, which stood at 200 million metric ton in 2011",
        },
        {
          text: "registered a CAGR of 5.0% to reach 295.5 million metric ton in the year 2019.",
        },
        {
          text: "India is the global leader in the production of milk and increasing industrialization of the Indian dairy industry and the sustained initiatives by government agencies in improving productivity has seen the country take the top position in the global market moving into 2020.",
        },
        {
          text: "Declining prices of milk and the emergence of other dairy alternatives are challenging the global milk market and these trends are expected to constrain the market over the forecast period.",
        },
      ],
    },
  ],
  graphs: [
    {
      graphType: "bar",
      data: [
        {
          label: 2010,
          value: 70,
        },
        {
          label: 2011,
          value: 92,
        },
        {
          label: 2012,
          value: 92,
        },
        {
          label: 2013,
          value: 92,
        },
        {
          label: 2014,
          value: 92,
        },
        {
          label: 2015,
          value: 92,
        },
        {
          label: 2016,
          value: 92,
        },
        {
          label: 2017,
          value: 36,
        },
        {
          label: 2018,
          value: 36,
        },
        {
          label: 2019,
          value: 36,
        },
      ],
      url: "https://i.ibb.co/YXGm7RD/bar.png",
      heading: "Beef Production in million metric ton, North America 2011-2019",
      icon: "https://mordor-graph-bucket.s3.amazonaws.com/aquaculture/1609249570678_test~aquaculture_aquaculture.png",
    },
    {
      graphType: "bar",
      data: [
        {
          label: "Poultry",
          value: 7,
        },
        {
          label: "Swine",
          value: 92,
        },
        {
          label: "Aquaculture",
          value: 36,
        },
      ],
      url: "https://i.ibb.co/YXGm7RD/bar.png",
      heading: "graph heading2",
      icon: "https://mordor-graph-bucket.s3.amazonaws.com/aquaculture/1609249570678_test~aquaculture_aquaculture.png",
    },
  ],
};

class Indicator3 extends React.Component {
  state = {
    data: this.props.data ? this.props.data : {},
    reseller: false

  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.data != this.props.data.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true
        }
      }
    }
  }

  render() {
    const { id, title, subtitle, slug, trends, graphs, section_id, } = this.state.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <LiveStockContainer id={pageId}>
        <div className="page">
          {!this.props.fromInterface ||
            this.props.fromInterface == undefined ? (
            <Header slideTitle={this.state.data.type} />
          ) : null}
          {this.props.edit_mode ?
            <IndicatorEditing data={this.props.data} edit_mode={this.props.edit_mode} template_type='indicator3' draft_drawer_visible={this.props.draft_drawer_visible} slideNo={this.props.slideNo}
              published_drawer_visible={this.props.published_drawer_visible} handleSwitchchange={this.props.handleSwitchchange} fromInterface={this.props.fromInterface} />
            :
            <div className="container">
              <h2 className="heading">{title}</h2>
              {this.state.data.data.map((item, i) => {
                return (
                  <div className="indicator-container2">
                      {/* {item.graph ?<h3 className="subheading">
                       {item.graph.heading }
                    </h3>: null} */}
                      {item.graph && item.graph.data != "hidden" ? (
                        <Graph
                        wrapHeadingWidth={400}
                        // chartHeadingGap={-30}
                        // yHeadingMargin={15}
                        chartHeadingGap={item.graph.heading.length > 60 ? 0 : -30}
                        yHeadingMargin={item.graph.heading.length > 60 ? 30 : 15}
                        xHeadingMargin={0}
                        headers={item.graph.heading}
                        width={
                          !this.props.fromInterface ||
                          this.props.fromInterface == undefined
                            ? "1180"
                            : "100%"
                        }
                          height={250}
                          graphType={item.graph.graphType}
                          data={item.graph.data}
                          dynamiclegends={item.graph["legends"]}
                          axes={false}
                          totalData={item.graph}
                          labels={true}
                          footers={this.state.graphFooterText}
                        />
                      ) : null}
                     {item.trends.heading?<h3 className="subheading subheading2">
                      {item.trends.heading}
                    </h3>:null}

                      <Analysis trends={item.trends} />
                      {i == this.state.data.data.length - 1 ? null : (
                        <hr className="indicator2-hr" />
                      )}
                  

                  </div>
                )
              })
              }
            </div>
          }
          {!this.props.fromInterface ||
            this.props.fromInterface == undefined ? (
            <Footer slideNo={slideNo} slug={slug} reseller={this.state.reseller} />
          ) : null}
        </div>
      </LiveStockContainer>
    );
  }
}

export default Indicator3;
