import styled from 'styled-components';

const HeaderWrapper = styled.div`
    display:flex;
    width:100%;
    justify-content:space-around;
    background-color:#E9F8FF;
    padding:0 10px;
    height:37px;

   
    .header-title{
        color:#A4BBCD;
        padding:10px 0px;
    }
    .header-title a{
      color:#A4BBCD;
      font-family: "Avenir Roman";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-transform: uppercase;
    }
    ${props => props.data && (props.data == 'keyIndustryTrends' || props.data == "decisionmaker" || props.data == "valueChain" || props.data == "feedproduction" ||
    props.data == "regulatoryFramework") ?
    ` .header-title.keyindustry{
        background-color:#189CDE;
        padding:10px 28px;
       
      },.header-title.keyindustry a{color:#ffffff;}`: props.data && (props.data == 'typeoverview' || props.data == "typedetail" || props.data == "globaloverview" || props.data == "regionoverview") ?
      `.header-title.marketsegment{
        background-color:#189CDE;
        padding:10px 28px;
        
        },.header-title.marketsegment a{color:#ffffff; }`
      : props.data && (props.data == 'companyProfiles' || props.data == "company" || props.data == "keyStrategicMoves" || props.data == "marketShares" ||
        props.data == "recentdevelopment" || props.data == "growthstrategymatrics") ?
        `.header-title.complandscape{
        background-color:#189CDE;
        padding:10px 28px;
        },.header-title.complandscape a{
          color:#ffffff;
        }`:props.data && (props.data == 'studyAssumptions' || props.data == "scopeOfStudy" || props.data == "researchMethodology")?
         `.header-title.intro{
          background-color:#189CDE;
          padding:10px 28px;
          
      },.header-title.intro a{
        color:#ffffff;
      }`: null
    }
`
export default HeaderWrapper;