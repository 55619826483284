import React, { Component } from "react";
import Column from "./GraphComponents/Cagr/Main";

class GraphActionCagr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      propsData: {},
    };
  }
  componentDidMount() {
    let datas = reset(this.props.data);
    this.setState({ data: datas, loading: false, propsData: this.props.data });
  }
  render() {
    let datas = "";
    if (!this.state.loading) datas = this.state.data;
    let selected_graph = "";
    if (datas != "") {
      let type = datas.graph_type.toLowerCase();
      switch (type) {
        case "bar":
          selected_graph = <Column data={datas} />;
          break;

        default:
          selected_graph = null;
          break;
      }
    }

    return <div>{selected_graph}</div>;
  }
}
function getFinalValue(beginningValue, CAGR, numOfPeriods) {
  beginningValue = parseInt(beginningValue);
  CAGR = parseFloat(CAGR) / 100;
  return beginningValue * Math.pow(1 + CAGR, numOfPeriods);
}

function reset(data) {
  let base_year = parseInt(data.base_year);
  let cagr = 1;
  if (data.cagr) {
    cagr = parseFloat(data.cagr);
  }
  let end_year = parseInt(data.study_period_end);
  let time_periods = end_year - (base_year + 1),
    slug = data.slug;
  let graph_data = {
    heading: `${slug}_Market Summary`,
    alt_tag: `${slug}_Market Summary`,
    graph_type: "bar",
    component: "key_factor",
    slug: slug,
    cagr: Number(cagr).toFixed(2),
    source: "Mordor Intelligence",
    data: [],
    time: data.time,
  };

  let initial_data = {
    label: base_year + "",
    value: 10,
  };

  let final_data = {
    label: end_year + "",
    value: getFinalValue(initial_data.value, cagr, time_periods),
  };
  graph_data.data = [initial_data, final_data];
  return graph_data;
  //this.setState({data:graph_data,loading:false});
}
export default GraphActionCagr;
