import axios from 'axios';
import config from '../config/Api';
import { notification } from 'antd';

export const setTocData = (data) => ({
    type: 'GETTOCDATA',
    data
});

export const setAuthstatus =(data)=>({
    type:'SETAUTH',
    data
});
export const setSearchParams=(data)=>({
    type:'SET_SEARCH_PARAM',
    data
});
export const setActiveTab=(data)=>{
  return ({
    type:'ACTIVE_TAB',
    data
  })
};
export const setLinkCounter=(data)=>({
    type:'ANALYSIS_LINK_COUNTER',
    data
});
export const setReportHeaderData=(data)=>({
    type:'REPORT_HEADER_DATA',
    data
});

export const setPublishedDrawer=(data)=>({
    type:'SHOW_PUBLISHED_DRAWER',
    data
});

export const setDraftDrawer=(data)=>({
    type:'SHOW_DRAFT_DRAWER',
    data
});

export const setDraftVersions=(data)=>{
    return ({
        type:'SET_DRAFT_VERSIONS',
        data
    })
};

export const setPublishedVersions=(data)=>({
    type:'SET_PUBLISHED_VERSIONS',
    data
});


export const setDraftDataHeading=(data)=>{  
  return ({
    type:'SET_DRAFT_DATA_HEADING',
  data
})
};

//Draft Data from Analysis
export const setDraftDataAnalysis=(data)=>{  
    return ({
      type:'SET_DRAFT_DATA',
    data
  })
};

//Draft Data from Sources
export const setDraftDataSources=(data)=>{
    return ({
      type:'SET_DRAFT_DATA_SOURCES',
      data
  })
};


export const setUserData=(data)=>({
  type:'SET_USER_DATA',
  data
});

export const setDraftStage=(data)=>({ //SELECTED STAGE BY USER
  type:'SET_DRAFT_STAGE',
  data
});


export const setDefaultDraftStageFromGetSpecificDataAndDraftVersions=(data)=>{
  return ({
  type:'SET_DRAFT_STAGE_FROM_GET_SPECIFIC_DATA',
  data
})
};


export const setUserEditAccess=(data)=>({
  type:'SET_USER_EDIT_ACCESS',
  data
});


export const setNoDraftsFound=(data)=>{
  return ({
    type:'SET_NO_DRAFTS_FOUND',
    data
  })
};

export const setDraftVersionsLoading = (data) => ({
  type: "SET_DRAFT_VERSIONS_LOADING",
  data,
});

export const setInitialDraftVersionsLoading = (data) => ({
  type: "SET_INITIAL_DRAFT_VERSIONS_LOADING",
  data,
});


export const setSpecificDraftLoading = (data) => ({
  type: "SET_SPECIFIC_DRAFT_LOADING",
  data,
});

export const setPublishedVersionsLoading = (data) => ({
  type: "SET_PUBLISHED_VERSIONS_LOADING",
  data,
});

export const setSelectStageChangeLoader = (data) => ({
  type: "SET_SELECT_STAGE_CHANGE_LOADER",
  data,
});

export const setAnalysisFromLatestDraft = (data) => ({
  type: "SET_ANALYSIS_FROM_LATEST_DRAFT",
  data,
});

export const setSourcesFromLatestDraft = (data) => ({
  type: "SET_SOURCES_FROM_LATEST_DRAFT",
  data,
});

export const setSwitchDisable=(data)=>{
  return ({
    type:'SET_DISABLE_SWITCH',
    data
  })
};

export const setSelectedIndicatorHeading=(data)=>{
  return ({
    type:'SET_SELECTED_INDICATOR_HEADING',
    data
  })
};

export const setIndicatorObjectToShow=(data)=>{
  return ({
    type:'SET_INDICATOR_OBJECT_TO_SHOW',
    data
  })
};

export const setParentId=(data)=>{
  return ({
    type:'SET_PARENT_ID',
    data
  })
};

export const setCacheId=(data)=>{
  return ({
    type:'SET_CACHE_ID',
    data
  })
};

export const setTemplateType=(data)=>{
  return ({
    type:'SET_TEMPLATE_TYPE',
    data
  })
};

export const setDraftId=(data)=>{
  return ({
    type:'SET_DRAFT_ID',
    data
  })
};

export const setIndicatorDropdownOptions=(data)=>{
  return ({
    type:'SET_INDICATOR_DROPDOWN_OPTIONS',
    data
  })
};

export const setCompanyProfileData=(data)=>{
  return ({
    type:'SET_COMPANY_PROFILE_DATA',
    data
  })
};

export const setUpdateCompanyProfileData=(data)=>{
  return ({
    type:'SET_UPDATE_COMPANY_PROFILE_DATA',
    data
  })
};


export const setCompanyProfileDimensions=(data)=>{
  return ({
    type:'SET_COMPANY_PROFILE_DIMENSIONS',
    data
  })
};



export const setCompanyType=(data)=>{
  return ({
    type:'SET_COMPANY_TYPE',
    data
  })
};


export const setFetchFulldata=(data)=>{
  return ({
    type:'SET_FETCH_FULL_DATA',
    data
  })
};

export const setCharacterCountError=(data)=>{
  return ({
    type:'SET_CHARACTER_COUNT_ERROR',
    data
  })
};





export const resetState=()=>({
  type:'RESET_STATE',
});



export const getDrafts = (parent_id, type) => {
    return function(dispatch) {
      dispatch(setDraftVersionsLoading(true))
      axios({
        method: "GET",
        url:`${config.api.base_url}/api/editing/drafts?parent_id=${parent_id}&type=${type}&list=true`
      })
        .then((response) => {
          // console.log(response, "RESP");
      dispatch(setDraftVersionsLoading(false))
          if (response.status === 200 || response.status === 201) {
            dispatch(setDraftVersions(response.data))
            if(response.data.versions && !response.data.versions.length){
            setNoDraftsFound(true);
            setDraftStage('NONE');
            setDefaultDraftStageFromGetSpecificDataAndDraftVersions(response.data.stage ? response.data.stage : 'INITIAL');
            } else if(response.data.versions && response.data.versions.length){
            setNoDraftsFound(false);
            setDefaultDraftStageFromGetSpecificDataAndDraftVersions(response.data.stage ? response.data.stage : 'INITIAL');
            setDraftStage(response.data.stage ? response.data.stage : 'INITIAL');
            }
            dispatch(setUserEditAccess(response.data.user_edit_access))
          }
        })
        .catch((error) => {
      dispatch(setDraftVersionsLoading(false))
          if (error.response) {
            notification.error({
              message: 'Error',
              description: error.response.data.message
            })
          } 
          
          dispatch(setDraftVersions({}))
        });
    };
  };

  export const getPublishedVersions = (parent_id) => {
    return function(dispatch) {
      dispatch(setPublishedVersionsLoading(true))
      axios({
        method: "GET",
        url:`${config.api.base_url}/api/editing/publish?parent_id=${parent_id}`

      })
        .then((response) => {
          // console.log(response, "RESP");
      dispatch(setPublishedVersionsLoading(false))
          if (response.status === 200 || response.status === 201) {
            dispatch(setPublishedVersions(response.data))
          }
        })
        .catch((error) => {
      dispatch(setPublishedVersionsLoading(false))
          if (error.response) {
            notification.error({
              message: 'Error',
              description: error.response.data.message
            })
          } 
          dispatch(setPublishedVersions({}))
        });
    };
  };

