import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import Config from "../../config/Config";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "Venezuela",
          value: "290",
        },
        {
          label: "Saudi",
          value: "260",
        },
        {
          label: "Canada",
          value: "180",
        },
        {
          label: "Iran",
          value: "140",
        },
        {
          label: "Russia",
          value: "115",
        },
        {
          label: "UAE",
          value: "100",
        },
        {
          label: "US",
          value: "30",
        },
        {
          label: "China",
          value: "30",
        },
      ],
    };
    this.bytesToString = this.bytesToString.bind(this);
    this.removeLegends = this.removeLegends.bind(this);
    this.placeValueOutside = this.placeValueOutside.bind(this);
    this.data_length = this.data_length.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      let datas = [];
      for (let item of this.props.data) {
        if (this.props.width) item.label = this.bytesToString(item.label, 2);
        else item.label = this.bytesToString(item.label, 1);
        datas.push(item);
      }
      let legends_zero_elimination=this.props.legends_zero_elimination==false?false:true;
  
      if(legends_zero_elimination==true)
      datas = this.removeLegends(datas);
      this.placeValueOutside(datas);
      datas = datas.reverse();
      this.setState({
        data: datas,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)
    ) {
      let datas = [];
      for (let item of this.props.data) {
        if (this.props.width) item.label = this.bytesToString(item.label, 2);
        else item.label = this.bytesToString(item.label, 1);
        datas.push(item);
      }
      let legends_zero_elimination=this.props.legends_zero_elimination==false?false:true;
      
      if(legends_zero_elimination==true)
      datas = this.removeLegends(datas);
      this.placeValueOutside(datas);
      datas = datas.reverse();
      this.setState({
        data: datas,
      });
    }
  }
  bytesToString(d, width) {
   

    let number_of_letter = width / 7 + 10;
    if (width == 1) number_of_letter = (400 - 150) / 9;
    if (width == 2) number_of_letter = this.props.width / 16;

    let str = "";
    let computed_array = [];
    let string_arr = d.trim().split(" ");
    for (let item of string_arr) {
      if ((item + str).length >= number_of_letter) {
        computed_array.push(str);
        str = ""
      }
      str += " " + item;
    }
    if (str !== "")
      computed_array.push(str);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  }
  removeLegends(data) {
    // let total = 0;
    // for (let item of data) {
    //   total += parseInt(item.value);
    // }
    // let delete_items=[]
    // for (let [index,item] of data.entries()) {
    //   let percent = (item.value / total) * 100;
    //   if(percent<=1){
    //     delete_items.push(index)
    //   }
    //   // if (percent < 6) item.label = "";
    // }
    // delete_items.reverse();
    // for(let item of delete_items){
    //   data.splice(item, 1);
    // }
    return data;
  }
  placeValueOutside(data){
    let total = 0;
    for (let item of data) {
      total += parseInt(item.value);
    }
    for (let item of data) {
      let percent = (item.value / total) * 100;
      if (percent < 4) {
        if(percent<2){
        item['labelPosition']="none";
        item['valuePosition']="none";
        item['labelFontColor']= "#ffffff";
      }
        else{
        item['labelPosition']="outside";
        item['valuePosition']="outside";
          item['labelFontColor']= "#7f7f7f";
        }
         
      }
    }
    return data;
  }
  data_length(data) {
    let datalength = 0;
    for (let item of data) {
      let count = (item.label + "").match(/<br/g);
      if (count !== null) datalength += count.length;
      if (item.label !== "") datalength++;
    }
    return datalength;
  }
  render() {
    let width = 400,
      height = 250,
      labels = false,
      radius = 85,
      centerlabel = "",
      legends = true;
    let headers = "";
    let footers =
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.footers) footers = this.props.footers;
    if (this.props.labels) labels = this.props.labels;
    if (this.props.radius) radius = this.props.radius;
    if (this.props.centerlabel) centerlabel = this.props.centerlabel;
    if (this.props && this.props.legends == false) legends = this.props.legends;

    let legendNumColumns = 1;
    if (this.state.data.length >= 11) {
      legendNumColumns = 2;
    }
    let datalength = 0;
    datalength = this.data_length(this.state.data);

    footers = "";
    let s = "Revenue Share (%), Other Probiotics, by Geography, 2019";
    let chartConfigs = {
      type: "doughnut2d",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "",
          // captionFont:"Avenir Medium",
          // captionFontColor:"#002F75",
          // captionFontSize:16,
          // captionAlignment: "left",
          // captionPadding: "40",
          // captionOnTop: 1,
          captionFontBold:"0",
          showpercentvalues: "1",
          legendposition: "absolute",
          legendNumColumns: 1,
          legendCaptionFontBold: true,
          legendXPosition: width / 2 + 20,
          legendYPosition: 75+this.props.legendsYPosition ,//+ (100 - datalength * 10) + (height - 240) / 2,
          legendItemFontSize: this.state.data.length>8?11:14,
          chartLeftMargin: !legends
            ? 0
            : -210 + (width / 2 - 173) + (400 - width) + 20,
          chartTopMargin: 100+this.props.chartHeadingGap,
          usedataplotcolorforlabels: "1",
          defaultCenterLabel: "" + centerlabel,
          centerLabel: "" + centerlabel,
          theme: "fusion",
          pieRadius: radius,
          showValues: !this.props.dataHidden,
          animation: false,
          showPlotBorder: "0",
          captionPadding: 0,
          labelFontSize: 12,
          labelFontBold: true,
          showLegend: true,
          showPercentValues: !this.props.dataHidden,
          showLabels: labels,
          valuePosition: "Inside",
          labelFontColor: "#ffffff",
          legendItemFont: "Avenir Medium",
          legendItemFontColor:"#7f7f7f",
          labelFont:"Avenir Heavy",
          labelPosition: "inside",
          paletteColors: ((Config.COLORS+"").split(",")).slice(0,this.state.data.length).reverse().join(","),
          decimals: 1,
          minAngleForValue: 3,
          reverseLegend: "1",
          smartLabelClearance:"5",
          enableSmartLabels:"0",
          labelDistance:"-12",
          baseFont: "Avenir Medium",
          baseFontSize:14,
          baseFontColor:"#7f7f7f"
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {},
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers+"").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${550+this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                },
              ],
            },
            {
              id: "user-images",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: "9",
                  font: "Avenir semibold",
                  text: footers,
                  bold: "1",
                  wrap: "1",
                  wrapWidth: width - 20,
                  x: "$canvasStartY",
                  y: "$canvasEndY +3",
                },
              ],
            },
          ],
        },
        data: this.state.data,
      },
    };
    return (
      <div
        className="pie"
        id={this.props.id}
        style={{ textAlign: "left", marginBottom: 10 }}
      >
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

export default Chart;
