import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import studyAssumptions from "../images/studyassumptions.svg";
var Base64 = require("js-base64").Base64;

export const StudyAssumptionsDemoData = {
  slideReference: "1. INTRODUCTION",
  id: "1.1 STUDY ASSUMPTIONS & MARKET DEFINATION",
  title: "1.1 STUDY ASSUMPTIONS & MARKET DEFINATION",
  type:"Enzymes",
  slug: "North America Enzymes Market",
  slideName: "studyassumption",
};
class StudyAssumptions extends React.Component {
  state = {
    data: this.props.data, 
    reseller: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }


  render() {
    let { section_id } = this.props.data;
    const { slideNo } = this.props;
    const slug = this.state.data.slug;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <div id={pageId} className="page">
        {!this.props.fromInterface || this.props.fromInterface==undefined?<Header slideTitle={this.props.data.type}/>:null}
        <div style={{ height: "626px"}}>
          <img
            src={this.props.data.url}
            className="studyAssumption"
            alt="Study Assumptions"
            style={{
              width: "100%",
              height: "100%"
            }}
          />
        </div>
        {!this.props.fromInterface || this.props.fromInterface==undefined?<Footer slug={slug} slideNo={slideNo} reseller={this.state.reseller}/>:null}
      </div>
    );
  }
}

export default StudyAssumptions;
