import React, { Component } from "react";
import "../App.css";
import { InterfaceHeaderWrapper } from "../styles/InterfaceHeader.style";
import Desktoplogo from "../images/desktop_logo.png";
import mobileLogo from "../images/mobile_logo.png";
import { HomeFilled } from "@ant-design/icons";
import {
  ShoppingCartOutlined,
  UserOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Badge, Dropdown, Menu, Spin, Image, Button, Checkbox, Divider, Space } from "antd";
import { Modal } from 'antd';
import { Select } from 'antd';
import axios from "axios";
import configApi from "../config/Api";
import { DownloadOutlined } from "@ant-design/icons";
import Logout from "./Logout";
import UploadSheet from "./UploadSheet";
import { Link } from "react-router-dom";
import { withWindowSizeHOC } from "../Common/withWindowSizeHOC";
import { connect } from "react-redux";
import Login from "../pages/Login";
import config from "../config/Config";
import BulkUploadContainer from "./BulkUpload/BulkUploadContainer";

import {
  setTocData,
  setSearchParams,
  setDraftStage,
} from "../reducersAndActions/Actions";

const CheckboxGroup = Checkbox.Group;
let Base64 = require("js-base64").Base64;
let { returnProfilePicture } = Login;

const { Option } = Select;
let CancelToken = axios.CancelToken.source();
let cancelTokenOfTitles = axios.CancelToken.source();
class InterFaceHeader extends Component {

  constructor(props) {
    super(props);
    this.uploadSheet = React.createRef();
    this.titles = [];
    this.counter= 0;
    this.menu = (
      <Menu>
        {this.state.user ? (
          <Menu.Item selectable="false" key={this.state.user.name}>
            {this.state.user.name}
          </Menu.Item>
        ) : (
          ""
        )}
        <Menu.Item
          key="upload-sheet"
          onClick={
            () => this.uploadSheet.current.toggleShowModal()
            // this.setState({ toShowModal: true }, () =>
            //   console.log("toShowModal @ menuItem", this.state.toShowModal)
            // )
          }
        >
          Upload Sheet
        </Menu.Item>
        {/* <Menu.Item key="change-password">Change Password</Menu.Item> */}
        <Menu.Item
          key="BulkExport"
          onClick={this.OpenBulkExportModal}
        >
          Bulk Export
        </Menu.Item>
        <Menu.Item 
          key="upload"
          onClick={this.OpenUploadModal}
        >
          MEs Upload
        </Menu.Item>
        <Menu.Item key="logout">
          <Logout />
        </Menu.Item>
      </Menu>
    );

    this.downloadMenu = (
      <Menu>
        <Menu.Item key="1">Purchase Report</Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            this.props.generatePdf("Report");
          }}
        >
          Generate Report
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            this.props.generatePdf("Sample");
          }}
        >
          Generate Sample
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => {
            this.props.generatePdf("Rd");
          }}
        >
          Generate RD
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={() => {
            this.props.generatePdf("reset_urls");
          }}
        >
          Purge Title
        </Menu.Item>
       
      </Menu>
    );
  }


  state = {
    menuOpend: false,
    toShowModal: false,
    mobile: window.screen.width > 960 ? false : true,
    user:
      window.localStorage.getItem(config.LOCAL_STORAGE_TOKEN.Name) &&
        window.localStorage.getItem(config.LOCAL_STORAGE_TOKEN.meta)
        ? JSON.parse(window.localStorage.getItem("user"))
        : null,
    modalForBulkExport: false,
    modalTitlesLoading: false,
    countries: [],
    selected_countries: [],
    default_regions: [],
    selectedRegions: [],
    Select_country_disabled: true,
    hubs: [],
    selected_hubs: null, reportChecked: true,
    sampleChecked: false,
    titlesList: [],
    defaultTitlesList: [],
    titlesChecked: true,
    hasTitles: false,
    disableBulkExptBtn: true,
    minTiltes: true,
    listTitlesBtn: true,
    exportloader: false,
    showUploadModal: false

  };

  operateMenu = () => {
    this.setState({
      menuOpend: !this.state.menuOpend,
    });
    this.props.openmenu(!this.state.menuOpend);
  };
  componentDidMount() {
    console.log("confid", Object.keys(config.COUNTRY_MAP));
    let hubdata,regions;
    axios.get(`${configApi.api.base_url}/api/hubs/list`).then((res) => {
      console.log("hublist", res.data.obj);
      this.setState({
        hubs: res.data.obj
      })
    }).catch((error) => {
      console.log("err", error);
    })
    regions = Object.keys(config.COUNTRY_MAP);
    regions.unshift("All")
    this.setState({
      default_regions:regions,
    })
    // this.buttonClickRef.current.focus();

  }
 
  regionMenu = () => (
    this.state.default_regions.map((regn, i) => {
      return <Option value={regn} key={regn + i}>{regn}</Option>
    })
  );
  onChangeRegion = (value) => {
    console.log('selected', value);
    if (value.length) {
      this.setState({
        selectedRegions: value,
        Select_country_disabled: false
      },()=>{
        this.regionOnblur();
      });
      
    }
    else {
      console.log("selected")
      this.setState({
        selectedRegions: [],
        selected_countries: [],
        Select_country_disabled: true
      })
    }

   if(value.length== 1 && value[0] == "All"){
     this.setState({
       selected_countries: [],
       Select_country_disabled: true
     })
   }
  

  }
  regionOnblur = () => {
    let arr = [];
    console.log("onblur region",arr,this.state.selectedRegions);
   
   if(this.state.selectedRegions.length == 1 && this.state.selectedRegions[0] == "All"){
     console.log("jj")
      this.setState({
        selected_countries: [],
        Select_country_disabled: true
      })
    }
    else{
      let regions = this.state.selectedRegions.filter((regn,k)=>{
        return regn!="All"
      })
      regions.map((item, i) => {
        console.log("item",arr, config.COUNTRY_MAP[item])
        arr.push(...config.COUNTRY_MAP[item])
      })
      this.setState({
        countries: arr,
        Select_country_disabled: false

      })
    }
    console.log("new_Arr", arr);
  }


  countriesMenu = () => {
    if (this.state.countries.length) {
      return this.state.countries.map((country, k) => {
        return <Option value={country} key={country + k}>{country}</Option>
      })
    }


  };
  onChangeCountry = (value) => {
    console.log("country", value);
    if (value.length) {
      this.setState({
        selected_countries: value
      })
    }
    else {
      this.setState({
        selected_countries: []
      })
    }

  }

  hubIdMenu = () => {
    if (this.state.hubs.length) {
      return this.state.hubs.map((item, i) => {
        return <Option value={item.id} >{item.name}</Option>
      })
    }

  };
  onChangehub = (value) => {
    console.log('selected hub', value);
    if (value != undefined || value != null) {
      this.setState({
        selected_hubs: value,
        listTitlesBtn: false
      })
    }
    else {
      this.setState({
        selected_hubs: null,
        listTitlesBtn: true
      })
    }
  }
  onSearchHub = (val) => {
    console.log("onsearch", val)
  }

  OpenUploadModal = () => {
    this.setState({
      showUploadModal: true
    })
  }

  startTour = () => { };
  OpenBulkExportModal = () => {
    console.log("modal Oepnd");
    cancelTokenOfTitles = axios.CancelToken.source();
    CancelToken = axios.CancelToken.source();
    this.setState({
      modalForBulkExport: true,
    })
  }
  setModal2Visible = (prop) => {
    let url = this.state.sampleChecked || this.state.reportChecked ? `${configApi.api.puppeteer_ms_url}/api/exportBulkPdfs` : this.state.rdChecked ? `${configApi.api.base_url}/api/generateRds` : ''
    CancelToken = axios.CancelToken.source();
    let data = {
      meta: {
        sample: this.state.sampleChecked
      },
      data: this.state.defaultTitlesList,
      hub_id: this.state.selected_hubs

    }
    this.setState({
      exportloader: true,
      listTitlesBtn:true
    })
    axios.post(url, data,{
      cancelToken: CancelToken.token
    }).then((res) => {
      console.log("onexport", res);

      if (res.status == 200) {
        if (res.data.message) {
          if(this.state.sampleChecked || this.state.reportChecked){
            window.open(res.data.message, '_blank');
          }
        }
        this.counter= 0;
        Modal.success({
          content: res.data.message
        });
        this.setState({
          modalForBulkExport: prop,
          modalTitlesLoading: false,
          selected_countries: [],
          selectedRegions: [],
          Select_country_disabled: true,
          selected_hubs: null,
          reportChecked: true,
          sampleChecked: false,
          rdChecked: false,
          titlesList: [],
          defaultTitlesList: [],
          titlesChecked: true,
          hasTitles: false,
          disableBulkExptBtn: true,
          minTiltes: true,
          listTitlesBtn: true,
          exportloader: false
        });


      }
      else {
        this.setState({
          modalForBulkExport: prop,
          modalTitlesLoading: false,
          selected_countries: [],
          selectedRegions: [],
          Select_country_disabled: true,
          selected_hubs: null,
          reportChecked: true,
          sampleChecked: false,
          titlesList: [],
          defaultTitlesList: [],
          titlesChecked: true,
          hasTitles: false,
          disableBulkExptBtn: true,
          minTiltes: true,
          listTitlesBtn: true,
          exportloader: false
        });
        this.counter= 0;
        Modal.error({
          content: 'something went wrong',
        });
      }


    }).catch((error) => {
      
      console.log("error",error)
      CancelToken = axios.CancelToken.source();
      this.setState({
        modalForBulkExport: prop,
        modalTitlesLoading: false,
        selected_countries: [],
        selectedRegions: [],
        Select_country_disabled: true,
        selected_hubs: null,
        reportChecked: true,
        sampleChecked: false,
        rdChecked: false,
        titlesList: [],
        defaultTitlesList: [],
        titlesChecked: true,
        hasTitles: false,
        disableBulkExptBtn: true,
        minTiltes: true,
        listTitlesBtn: true,
        exportloader: false

      });
      this.counter= 0;
      Modal.error({
        content: error.message == undefined? "some thing went wrong":error.message,
      });
    })


  }
  onCloseModal = (prop) => {
    console.log("modal close")
    this.counter = 0;
    this.setState({
      modalForBulkExport: prop,
      modalTitlesLoading: false,
      selected_countries: [],
      selectedRegions: [],
      Select_country_disabled: true,
      selected_hubs: null,
      reportChecked: true,
      sampleChecked: false,
      rdChecked: false,
      titlesList: [],
      defaultTitlesList: [],
      titlesChecked: true,
      hasTitles: false,
      disableBulkExptBtn: true,
      minTiltes: true,
      listTitlesBtn: true,
      exportloader: false
    });
    if(!this.state.listTitlesBtn){
      cancelTokenOfTitles.cancel();
    }else{
      CancelToken.cancel("Operation canceled by the user");
    }
   
  }

  handleLogoClick = () => {
    this.props.setSearchParams(false);
    this.props.setDraftStage("INITIAL");
  };
  selectTitles = (e, item) => {
    console.log("titles", e.target.checked, item);
    let filteredList = [], newfilteredList = [];
    if (!e.target.checked) {
      filteredList = this.state.defaultTitlesList.filter((CheckedItem) => {
        return item._id != CheckedItem._id
      })

    }
    else {
      // if(this.state.titlesList){

      // }
      newfilteredList.push(item);

      filteredList = [...this.state.defaultTitlesList, ...newfilteredList];

    }
    if (filteredList.length) {
      this.setState({
        defaultTitlesList: filteredList,
        disableBulkExptBtn: false,
        minTiltes: true
      })
    }
    else {
      this.setState({
        disableBulkExptBtn: true,
        minTiltes: false,
        defaultTitlesList: []
      })
    }

    console.log("filtered list", filteredList);

  }
  checkReportRSample = (value) => {
    console.log("reportsample", value);

  }
  onListTitles = () => {
    console.log("onlist", this.state);
    if(this.counter == 0){
      this.counter=1
    }
    let payload = {
      "hub_id": this.state.selected_hubs,
      "dimensions_array": {
        region: this.state.selectedRegions,
        country: this.state.selected_countries
      },
      meta: {
        "sample": this.state.sampleChecked
      }
    }
    let arr = [];
    this.setState({
      titlesList: [],
      defaultTitlesList: [],
      modalTitlesLoading: true,

    });

    axios.post(`${configApi.api.base_url}/api/getPdfTitles`, payload,{
      cancelToken:cancelTokenOfTitles.token
    }).then((res) => {
      console.log("res", res);
      if (res.data.data.length) {
        // res.data.data.map((item,i)=>{
        //     arr.push(item.title)
        // });
        console.log(arr, "arra")
        this.setState({
          titlesList: res.data.data,
          defaultTitlesList: res.data.data,
          modalTitlesLoading: false,
          hasTitles: true,
          minTiltes: true,
          disableBulkExptBtn: false
        })

      }
      else {
        this.setState({
          titlesList: [],
          defaultTitlesList: [],
          modalTitlesLoading: false,
          hasTitles: false,
          disableBulkExptBtn: true
        })
      }

    }).catch((error) => {
      console.log(error);
      cancelTokenOfTitles = axios.CancelToken.source();
      this.setState({
        titlesList: [],
        defaultTitlesList: [],
        modalTitlesLoading: false,
        hasTitles: false,
        disableBulkExptBtn: true,
        modalTitlesLoading:false
      })
    })

  }
  // filterOptionhub =(input, option)=>{
  //    console.log("option",option,input)

  //                   // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  // }

  render() {
    const { width } = this.props;
    console.log("render", this.state);
    return (
      <InterfaceHeaderWrapper
        data={this.props.slideTitle}
        mobile={this.state.width < 960 ? true : false}
      >
        <div className="logo-wrapper">
          {width > 960 ? (
            <Link to="/editing/search">
              <img
                src={Desktoplogo}
                alt="Mordor Logo"
                className="desktoplogo"
                // onClick={() => this.props.setSearchParams(false)}
                onClick={this.handleLogoClick}
              />
            </Link>
          ) : (
            <img src={mobileLogo} alt="Mordor Logo" className="mobilelogo" />
          )}
        </div>
        <h1>{this.props.SEARCH_PARAMS ? this.props.TOC_DATA.title : null}</h1>
        <div className="header-icons">
          <UploadSheet ref={this.uploadSheet} />

          {this.props.SEARCH_PARAMS ? (
            <Dropdown overlay={this.downloadMenu}>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                size="middle"
                loading={this.props.spinning}
              >
                Download <DownloadOutlined style={{ fontSize: "18px" }} />
              </Button>
            </Dropdown>
          ) : null}

          {/* {width > 960 ? (
              <GlobalOutlined />
          ) : null}
            <a href="#">
              <Badge>
                <ShoppingCartOutlined />
              </Badge>
            </a> */}
          <Dropdown overlay={this.menu} trigger={["click"]}>
            {this.state.user && this.state.user.profilePicture ? (
              <Image
                id="profilePicture"
                height={40}
                width={40}
                preview={false}
                src={this.state.user.profilePicture}
              />
            ) : (
              <UserOutlined />
            )}
            {/*  <UserOutlined /> */}
          </Dropdown>
        </div>
        <Modal
          className="BulkExportmodal"
          title={
          <div>
            <div>
              <p className="exportLabels"><span className="mandatory">*</span>Select Hub</p>
              <Select
                placeholder="Select Hub"
                optionFilterProp="children"
                showSearch
                onChange={this.onChangehub}
                value={this.state.selected_hubs}
                onSearch={this.onSearchHub}
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.hubIdMenu()}
              </Select>
            </div>
            <div>
              <p className="exportLabels">Select Region</p>
              <Select
                placeholder="Select Region"
                allowClear
                value={this.state.selectedRegions}
                onChange={this.onChangeRegion}
                style={{ width: '100%', maxHeight: "60px" }}
                mode="multiple"
                onBlur={this.regionOnblur}
              >
                {this.regionMenu()}
              </Select>
            </div>
            <div>
              <p className="exportLabels">Select Country</p>
              <Select
                placeholder="Select Country"
                allowClear
                disabled={this.state.Select_country_disabled}
                value={this.state.selected_countries}
                onChange={this.onChangeCountry}
                style={{ width: '100%', maxHeight: "88px", overflow: "auto" }}
                mode="multiple"
              >
                {this.countriesMenu()}
              </Select>
            </div>




            <div className="reportCheckContainer">
              <span className="mandatory">*</span>
              <Checkbox
                checked={this.state.reportChecked}

                onChange={(e) => {
                  if (e.target.checked) {
                    this.setState({
                      sampleChecked: false,
                      rdChecked: false,
                      reportChecked: e.target.checked,
                      listTitlesBtn: false
                    })
                  }
                  else {
                    this.setState({
                      sampleChecked: false,
                      rdChecked: false,
                      reportChecked: e.target.checked,
                      listTitlesBtn: true
                    })
                  }

                }}
              >
                Report
              </Checkbox>
              <Checkbox
                checked={this.state.sampleChecked}

                onChange={(e) => {
                  console.log("sample", e.target.checked)
                  if (e.target.checked) {
                    this.setState({
                      sampleChecked: e.target.checked,
                      reportChecked: false,
                      rdChecked: false,
                      listTitlesBtn: false

                    })
                  } else {
                    this.setState({
                      sampleChecked: e.target.checked,
                      reportChecked: false,
                      rdChecked: false,
                      listTitlesBtn: true
                    })
                  }
                }}
              >
                Sample
              </Checkbox>
              <Checkbox
                checked={this.state.rdChecked}

                onChange={(e) => {
                  if (e.target.checked) {
                    this.setState({
                      rdChecked: e.target.checked,
                      reportChecked: false,
                      sampleChecked: false,
                      listTitlesBtn: false

                    })
                  } else {
                    this.setState({
                      rdChecked: e.target.checked,
                      reportChecked: false,
                      sampleChecked: false,
                      listTitlesBtn: true
                    })
                  }
                }}
              >
                RD's
              </Checkbox>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={this.onListTitles} className="lustTitlesBtn" type="primary" disabled={this.state.listTitlesBtn} loading={this.state.modalTitlesLoading}>List Titles</Button>
            </div>

          </div>}
          centered
          visible={this.state.modalForBulkExport}
          // onOk={() => this.setModal2Visible(false)}
          // okButtonProps={{ disabled: this.state.disableBulkExptBtn, loading: this.state.exportloader }}
          onCancel={() => this.onCloseModal(false)}
          // okText={
          //   "Export"
          // }
          footer=
          {this.state.hasTitles ?
            <div>
              <Button onClick={() =>this.onCloseModal(false) } >Cancel</Button>
              <Button onClick={() => this.setModal2Visible(false)} type="primary" loading={this.state.exportloader} disabled={this.state.disableBulkExptBtn}>Export</Button>
            </div> : null}

        >
          {this.counter != 0?  <div className="export-antd-container">
            <Spin tip="Loading..." spinning={this.state.modalTitlesLoading}>


              <div className="titlesContainer">
                {this.state.hasTitles? <div className="titlesWrapper">
                  {
                    this.state.titlesList && this.state.titlesList.map((item, i) => {
                      return <Checkbox onChange={(e) => this.selectTitles(e, item)} defaultChecked={true}>{item.title}</Checkbox>
                    })

                  }

                  {/* <CheckboxGroup options={this.state.defaultTitlesList} defaultValue={this.state.defaultTitlesList} defaultChecked={true} onChange={this.selectTitles} /> */}
                </div> :!this.state.hasTitles && this.state.modalTitlesLoading?"": <h4 >* No titles are present</h4>}
              </div>


              {!this.state.minTiltes ? <p className="bulkExportError"> * Select atleast one title</p> : null}

            </Spin>
          </div>:null}


        </Modal>
        <Modal
          className="UploadModal"
          width={1200}
          bodyStyle={{ minHeight:"80vh"}}
          visible={this.state.showUploadModal}
          onCancel={() => this.setState({ showUploadModal: false })}
          footer={null}
        >
          <BulkUploadContainer/>
        </Modal>
      </InterfaceHeaderWrapper>
    );
  }
}
function mapStateToPros(state) {
  return {
    IS_AUTH: state.IS_AUTH,
    SEARCH_PARAMS: state.SEARCH_PARAMS,
    TOC_DATA: state.TOC_DATA,
  };
}
const mapDispatchToProps = {
  setTocData,
  setSearchParams,
  setDraftStage,
};
export default withWindowSizeHOC(
  connect(mapStateToPros, mapDispatchToProps)(InterFaceHeader)
);
