import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
var Base64 = require("js-base64").Base64;

export const sourcesDemoData = {
  slideName: "sources",
  id: "sources",
  subtitle: "References",
  slug: "US PROBIOTICS MARKET",
  sourceCountStart: 1,
  sources: [
    ' "Coronavirus very likely of animal origin, no sign of lab manipulation: WHO". Reuters. 21 April 2020. Retrieved 23 April 2020.',
    'Weiss, Todd R. (October 9, 2008). "Wikipedia simplifies IT infrastructure by moving to one Linux vendor". Computerworld. Archived from the original on October 5, 2012. Retrieved November 1, 2008.',
    'Paul, Ryan (October 9, 2008). "Wikipedia adopts Ubuntu for its server infrastructure". Ars Technica. Archived from the original on January 30, 2009. Retrieved November 1, 2008.',
    ' "Wikimedia sites to move to primary data center in Ashburn". Wikimedia Diff. January 19, 2013. Archived from the original on July 15, 2018. Retrieved July 14, 2018',
    ' "Wikimedia Endowment - Meta". July 15, 2019.',
  ],
};
export const newSourcesData = {
  title: "Appendix",
  subtitle: "References",
  slug: "US PROBIOTICS MARKET",
  type: "sources",
  template: "sources",
  data: [{ text: "somejkdlk", hyperLink: "somethig" }],
  sourceCountStart: 1,
};

class ListOfFigures extends React.Component {
  state = {
    data: this.props.data ? this.props.data : {},
    reseller: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.data && props.data.meta) {
      if(props.data.meta.sample && props.data.meta.reseller ) {
        return {
          reseller: true
        }
      }
    }
  }

  render() {
    const { data, subtitle, section_id, slug, sourceCountStart } =
      this.state.data;

    const pageId = section_id ? Base64.encode(section_id) : undefined;
    const { slideNo } = this.props;
    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Header slideTitle={this.state.data.data.type} />
        ) : null}
        <div className="row container" style={{width:"100%"}}>
          <div className="col-md-10">
            <div style={{ height: "663px", padding: "25px 50px" }}>
              <h2 className="heading" >{subtitle}</h2>
              {data.length ? data.map((source, index) => (
                <p className="sorceList">
                  {sourceCountStart + index}. {(source + "").toUpperCase()}
                </p>
              )):<h3>No Data</h3>}
            </div>
          </div>
        </div>
        {!this.props.fromInterface || this.props.fromInterface == undefined ? (
          <Footer slug={slug} slideNo={slideNo} reseller={this.state.reseller} />
        ) : null}
      </div>
    );
  }
}

export default ListOfFigures;
